import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import $ from 'jquery';
import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { INPUT_FIELDS_DEBOUNCE_TIMEOUT } from 'Common/constants/formSettings';
import {
  BROKER_NOTES_ID,
  BROKER_NOTES_MAX_LENGTH,
} from 'Common/constants/loanAppBrokerNotes';
import { APPLICATION_DATE_TYPE } from 'Common/constants/customerObjectives';
import { toastSaveDelete, toastError } from 'Common/utilities/alert';
import {
  getApplicationDateType,
  showAssetInsuranceSection,
} from 'Common/utilities/loanApplication';
import { arrayToObject } from 'Common/utilities/array';
import { LOAN_LENDING_CATEGORY } from 'Common/constants/lenderConstants';
import { isAssetFinanceLoan } from 'Common/utilities/lendingCategory';
import { getAssignedAdviserSubscription as utilGetAssignedAdviserSubscription } from 'Common/utilities/eSignSubscription';
import { LOAN_APP_ASSET_INSURANCE } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import { CLIENT_ROLE_ID } from 'Common/constants/roleType';
import {
  getInvestment,
  getDateType,
  getShowSidebarWidgets,
  openDemoVideoModal,
  embedLoanAppOnOpportunities,
  onAssistantSelect,
  isCompleteCPA,
  getPossibleAdverseAnswers,
} from './util/loanAppCtrl';
import { fundingSummaryDefaultMapper } from './util/clientAccess/loanFundingDetails';
import { getListGuarantors, getAddressHistory } from './util/application';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanAppCtrl', function LoanAppCtrl(
      $rootScope,
      $scope,
      $timeout,
      contactService,
      colorService,
      loanScenarioService,
      loanScenarioModelService,
      loanApplicationServices,
      contactSharedDataService,
      toaster,
      $state,
      $stateParams,
      utilitiesService,
      $anchorScroll,
      $location,
      $document,
      $uibModal,
      $localStorage,
      loanAppSharedData,
      userService,
      financialsSharedService,
      $window,
      uiService,
      clientInfoService,
      configService,
      objectivesQuestionService,
      loanAppDetailsService,
      eSignService,
      currentUserService,
      crmConfirmation,
      watcherService,
      blueStoneSubmissionService,
      stateService,
      gamePlanGeneratorService,
      opportunityLoanAppService,
      dashboardService,
      LEGACY_ANGULAR_HOST,
    ) {
      $scope.loanAppInheritor = {};
      $scope.isLoanAppLoaded = false;
      $scope.SECTION_ID = SECTION_ID;
      $scope.BROKER_NOTES_ID = BROKER_NOTES_ID;
      $scope.loanAppInputFieldsDebounceTimeout = INPUT_FIELDS_DEBOUNCE_TIMEOUT;
      $scope.routeContactList = stateService.routeToContactList;
      if ($state.params.loanAppId) {
        dashboardService.storeRecentlyViewedLoan($state.params.loanAppId);
      }
      const {
        assetFinance,
        bidLoanAppChangesDeclarations,
        lockLoanapp,
        loanAppReferrersNZ,
        goalSetter,
        requirementsAndObjectives,
        loanAppEmailTemplateNZ,
        goalSetterFactFindMerge,
      } = configService.feature;
      $scope.loanAppEmailTemplateNZ = loanAppEmailTemplateNZ;
      $scope.financialsSharedService = financialsSharedService;
      $scope.loanAppSharedData = loanAppSharedData;
      $scope.uiService = uiService;
      $scope.isLockLoanappFeatureEnabled = lockLoanapp;
      $scope.currentUserService = currentUserService;
      $scope.familyList = [];
      $scope.BROKER_NOTES_MAX_LENGTH = BROKER_NOTES_MAX_LENGTH;
      $scope.LOAN_LENDING_CATEGORY = LOAN_LENDING_CATEGORY;
      $scope.modalOpened = false;
      blueStoneSubmissionService.extentFunction.errorCounter = $scope;
      $scope.isShowLoanAppReferrersNZ = loanAppReferrersNZ;
      $scope.isShowFinancialGoals = goalSetter;
      $scope.isGoalSetterEnabled = goalSetter && !goalSetterFactFindMerge;
      $scope.showBidLoanAppChangesDeclarations = bidLoanAppChangesDeclarations;
      $scope.isAU = currentUserService.isAU;
      $scope.loanAppSplitFundingAndStructure =
        uiService.loanAppSplitFundingAndStructure;
      $scope.loanAppSharedData.fundingSummary = null;
      /**
       * Models
       * */

      // set parameters for loanapp fact find inside opportunity
      embedLoanAppOnOpportunities({
        $scope,
        $state,
        $rootScope,
        $stateParams,
        uiService,
        currentUserService,
      });
      $scope.onAssistantSelect = onAssistantSelect({
        $rootScope,
        $scope,
        $state,
        loanScenarioService,
      });

      $scope.getPossibleAdverseAnswers = getPossibleAdverseAnswers({
        $scope,
        loanApplicationServices,
      });
      angular.extend($scope, {
        loanAppId: $scope.loanAppId || $stateParams.loanAppId,
        familyId: $scope.familyId || $stateParams.familyId,
        loanId: $scope.loanId || $stateParams.loanId,
        mobileViewAllSection: false,
        clientHeaderName: '',
        addDate: false,
        aachange: false,
        openCalcList: false,
        prevSection: '',
        addDateHead: false,
        addDateObj: false,
        dateHovered: false,
        settlementDateEdit: false,
        financeDateEdit: false,
        LoanAppFormWarningCount: 0,
        applicationDateType: APPLICATION_DATE_TYPE,
        validSections: [],
        validation: {
          EmploymentError: [],
        },
        loanApplication: {
          LoanScenarioId: $scope.loanAppId,
          AdviserId: $scope.AssignedAdviserId,
          assignedBrokerIdId: $scope.AssignedAdviserId,
          AdviserName: 'string',
          DateCreated: '2017-04-16T15:04:43.087Z',
          Title: 'string',
          StatusName: 'string',
          FamilyId: $scope.familyId,
          CalculatorEvent: [
            {
              CalculatorEventId: 0,
              CalculatorType: 1,
              CalculatorEventName: 'string',
              CreatedDate: '2017-04-16T15:04:43.088Z',
            },
          ],
          RecommendedReport: [
            {
              CalculatorEventName: 'string',
              CreatedDate: '2017-04-16T15:04:43.088Z',
            },
          ],
          ImportantDate: [],
        },
        loanAppOptions: {
          dateTypes: [
            {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              name: 'Estimated Settlement Date',
              value: 12,
              order: 1,
            },
            {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              name: 'Finance Date',
              value: 2,
              order: 2,
            },
            {
              // eslint-disable-next-line sonarjs/no-duplicate-string
              name: 'Auction Date',
              value: 10,
              order: 3,
            },
          ],
          dates: {
            settlementDate: {
              active: false,
              isEditing: false,
              date: new Date(),
            },
            financeDate: {
              active: false,
              isEditing: false,
              date: new Date(),
            },
            auctionDate: {
              active: false,
              isEditing: false,
              date: new Date(),
            },
            objective: {
              settlementDate: {
                active: false,
                isEditing: false,
                date: new Date(),
              },
              financeDate: {
                active: false,
                isEditing: false,
                date: new Date(),
              },
              auctionDate: {
                active: false,
                isEditing: false,
                date: new Date(),
              },
            },
          },
          datePopUp: {
            settlementDate: false,
            financeDate: false,
            auctionDate: false,
          },
          editDatePopup: {
            settlement: false,
            finance: false,
            auction: false,
          },
          dateFormat: 'dd MMM yyyy',
          altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy', 'd MMM yyyy'],
          dateOptions: {
            formatYear: 'yy',
            showWeeks: false,
          },
          openDate(dateField) {
            $scope.loanAppOptions.datePopUp[dateField] = !$scope.loanAppOptions
              .datePopUp[dateField];
          },
          popupDate(dateField) {
            $scope.loanAppOptions.editDatePopup[dateField] = !$scope
              .loanAppOptions.editDatePopup[dateField];
          },
        },
        adviserDetailsForCorp: {
          selectedAdviserForCorp: {},
        },
        assistantDetails: {},
      });
      const setIsLock = (isLock) => {
        $scope.isLodged = isLock && $scope.isLockLoanappFeatureEnabled;
        $scope.$broadcast('isLodged', $scope.isLodged);
      };
      $scope.lodgedBanner = {
        text:
          'This Loan Application was submitted to a Lender and is now LODGED. Any further changes will not be saved.',
        icon: 'lock',
      };
      $scope.redirectToContactDetails = (familyId, activeTab) =>
        contactSharedDataService.redirectToContactDetails(familyId, activeTab);

      $scope.modalStopPropagation = (e, dateType, switchCase = false) => {
        e.stopPropagation();
        const type = getDateType(switchCase);
        if (e.target.classList.contains('form-input-date')) {
          $scope.loanAppOptions.openDate(type);
          $scope.loanAppOptions.popupDate(type);
        }
      };

      $rootScope.LoanAppFormWarning = false;
      $rootScope.file = {
        LoanAppFormWarning: false,
      };

      /**
       * Methods
       * */

      angular.extend($scope, {
        countValids(valid, section) {
          _.remove($scope.validSections, (o) => {
            return o.section === section;
          });
          if (!valid) {
            $scope.validSections.push({
              section,
            });
          }

          if ($scope.validSections.length < 1) {
            $rootScope.LoanAppFormWarning = false;
            $rootScope.file.LoanAppFormWarning = false;
          }
        },

        globalLoanAppEvent() {
          $scope.addDate = false;
          $scope.addDateObj = false;
          $scope.loanAppOptions.dates.settlementDate.isEditing = false;
          $scope.loanAppOptions.dates.auctionDate.isEditing = false;
          $scope.loanAppOptions.dates.financeDate.isEditing = false;
          $scope.financeDateEdit = false;
          $scope.settlementDateEdit = false;
          $scope.auctionDateEdit = false;
        },

        getClientInformation() {
          loanScenarioService
            .scenarioApplicantListGet($state.params.loanAppId)
            .then((response) => {
              const primaryApplicantList = _.get(
                response,
                `data.InvolvedPartyPerson[${$scope.familyId}]`,
                [],
              );
              $scope.client = {
                information: primaryApplicantList.map((applicant) => {
                  applicant.Role = CLIENT_ROLE_ID[applicant.Role];
                  return applicant;
                }),
              };
              clientInfoService.getClientHeader($scope.client);
              $scope.clientHeaderName = $scope.client.headerName;
              $scope.clientInitials = $scope.client.initials;
            });
        },

        BGcolorInit(refreshApplicants) {
          $scope.familyToPersonId = [];
          loanAppSharedData
            .getLoanAppApplicants($scope.loanAppId, refreshApplicants)
            .then((response) => {
              if (!response || !response.data) {
                return;
              }

              $scope.InvolvedPartyPerson = response.data.InvolvedPartyPerson;
              $scope.InvolvedPartyEntity = response.data.InvolvedPartyEntity;
              $scope.hasGuarantors = !!getListGuarantors(
                $scope.InvolvedPartyPerson,
                $scope.InvolvedPartyEntity,
              ).length;

              _.forEach($scope.InvolvedPartyPerson, (val, key) => {
                // Group FamilyId and PersonId
                $scope.familyToPersonId.push({
                  FamilyId: key,
                  PersonId: val[0].PersonId,
                });

                // Set the color for FamilyId
                $scope.setBGcolor(key);

                angular.forEach(val, (o) => {
                  // Set the color for PersonId
                  $scope.setBGcolor(o.PersonId);
                });
              });

              _.forEach($scope.InvolvedPartyEntity, (val) => {
                // Group FamilyId and PersonId
                $scope.familyToPersonId.push({
                  FamilyId: val.FamilyId,
                  PersonId: val.OrganisationId,
                });

                // Set the color for FamilyId
                $scope.setBGcolor(val.FamilyId);
                $scope.setBGcolor(val.OrganisationId);
              });

              _.forEach($scope.familyToPersonId, (val) => {
                const client = _.find(
                  $scope.financialsSharedService.bgColor,
                  (o) => {
                    return o.Id === val.PersonId;
                  },
                );

                angular.forEach($scope.financialsSharedService.bgColor, (o) => {
                  if (val.FamilyId === o.Id) {
                    if (typeof client !== 'undefined') {
                      o.Background = client.Background;
                    } else {
                      o.Background = '43357F';
                    }
                  }
                });
              });
            });
        },

        setBGcolor(Id) {
          if (typeof Id !== 'undefined') {
            // Id = parseInt(Id); //do not parseInt so strings from financial can get bg

            // check id if existing
            const IdDoesExists = _.find(
              $scope.financialsSharedService.bgColor,
              (o) => {
                return o.Id === Id;
              },
            );

            if (typeof IdDoesExists === 'undefined') {
              $scope.financialsSharedService.bgColor.push({
                Id,
                Background: colorService.getRandomColor(),
              });
            } else {
              const bgCount = $scope.financialsSharedService.bgColor.length;

              $scope.financialsSharedService.bgColor = _.uniqBy(
                $scope.financialsSharedService.bgColor,
                'Background',
              );

              $scope.financialsSharedService.bgColor = _.uniqBy(
                $scope.financialsSharedService.bgColor,
                'Id',
              );

              if ($scope.financialsSharedService.bgColor.length !== bgCount) {
                $scope.financialsSharedService.bgColor.push({
                  Id,
                  Background: colorService.getRandomColor(),
                });
              }
            }

            const bg = _.find($scope.financialsSharedService.bgColor, (o) => {
              return o.Id === Id;
            });

            if (typeof bg !== 'undefined') {
              return true;
            }
            return false;
          }
        },

        getBGcolor(Id) {
          if (typeof Id !== 'undefined') {
            // Id = parseInt(Id); //do not parseInt so strings from financial can get bg

            const bg = _.find($scope.financialsSharedService.bgColor, (o) => {
              return o.Id === Id;
            });

            if (typeof bg !== 'undefined') {
              return bg.Background;
            }
            $scope.setBGcolor(Id);
            return $scope.getBGcolor(Id);
          }
        },

        brokerNotes: {
          Label: uiService.brokerNotesLabel(),
          LoanPurposeList: [
            {
              Name: 'Investment',
              Value: 'Investment',
            },
            {
              Name: 'Owner Occupied',
              Value: 'Owner Occupied',
            },
          ],

          getLoanObjectives() {
            loanApplicationServices
              .getLoanObjectives($scope.loanAppId)
              .then(({ data }) => {
                if (!data) {
                  return;
                }
                $scope.brokerNotes.hasInvestment = getInvestment(data);
              });
          },
        },

        // Open Model For Demo Video
        openModalForDemoVideo(size) {
          openDemoVideoModal(size, $uibModal, currentUserService);
        },

        // eslint-disable-next-line sonarjs/cognitive-complexity
        togglesInit() {
          $timeout(() => {
            /**
             * Toggle for Loan App Sections
             * */
            /* Initialize */
            angular.element('.heading-controls').addClass('hidden');
            angular.element('.heading-title').addClass('flex');

            // Temporarily used this approach to solve IE issue

            angular
              // eslint-disable-next-line sonarjs/no-duplicate-string
              .element('#loan-application-v3')
              // eslint-disable-next-line sonarjs/no-duplicate-string
              .on('click', '.level-2-heading', (e) => {
                const currentClassList =
                  e.currentTarget && e.currentTarget.classList;
                const isAssetInsuranceLevel = currentClassList.contains(
                  'asset-insurance-level',
                );
                if (isAssetInsuranceLevel) {
                  performance.start(LOAN_APP_ASSET_INSURANCE);
                }
                e.preventDefault();
                const parent = angular
                  .element(e.currentTarget)
                  .parent('.level-2-section');
                const sectionHeading = parent.find('.level-2-heading');
                const sectionHeadingTitle = sectionHeading.find(
                  '.heading-title',
                );
                const sectionHeadingControls = sectionHeading.find(
                  '.heading-controls',
                );

                parent.toggleClass('is-active');
                // Collapse all section
                angular
                  .element('.level-2-section:not(.is-collapsed)')
                  .removeClass('is-active')
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  .addClass('is-collapsed');
                sectionHeadingControls.addClass('hidden');

                if (parent.hasClass('is-active')) {
                  parent.toggleClass('is-collapsed');
                  sectionHeadingControls.removeClass('hidden');
                  sectionHeadingTitle.removeClass('flex');
                } else {
                  // Expand this section
                  parent.addClass('is-collapsed');
                  sectionHeadingControls.addClass('hidden');
                  sectionHeadingTitle.addClass('flex');
                }

                const elemId = $(e.currentTarget).parent().attr('id');
                $($window.document).off('scroll.sectionloanapp');
                angular.element('.level-2-heading').removeAttr('style');
                $timeout(() => {
                  const htmlElement = angular.element(
                    // eslint-disable-next-line unicorn/prefer-query-selector
                    $window.document.getElementById(elemId),
                  );
                  $document.scrollToElementAnimated(htmlElement, 55, 800);
                  $timeout(() => {
                    const elem = $(e.currentTarget);
                    const elemParent = $(e.currentTarget).parent();
                    const offsetElem = elemParent.offset();
                    $($window.document).on('scroll.sectionloanapp', () => {
                      const elementIsActive = elemParent.hasClass('is-active');
                      const winTop = $(window).scrollTop();
                      if (typeof elem !== 'undefined' && elementIsActive) {
                        if (
                          typeof offsetElem.top !== 'undefined' &&
                          winTop > offsetElem.top &&
                          elemParent.height() + offsetElem.top - 60 > winTop
                        ) {
                          const topCalculate =
                            Math.floor(winTop - offsetElem.top) + 53;
                          elem.css({
                            position: 'relative',
                            top: `${topCalculate}px`,
                          });
                        } else {
                          elem.removeAttr('style');
                        }
                      } else {
                        elem.removeAttr('style');
                      }
                    });
                  }, 1300);
                }, 100);
                if (isAssetInsuranceLevel) {
                  const duration = performance.endPaint(
                    LOAN_APP_ASSET_INSURANCE,
                  );
                  loanAppSectionData({
                    label: LOAN_APP_ASSET_INSURANCE,
                    value: duration,
                  });
                }
              });

            const appSection = angular.copy(
              $window.sessionStorage.getItem('loanApplicationSection'),
            );
            const appPanel = angular.copy(
              $window.sessionStorage.getItem('loanApplicationPanel'),
            );

            if (appSection) {
              angular.element(`#${appSection}`).trigger('click');

              $location.hash(appPanel);
              $anchorScroll();

              // Turn this off once we have data on Product Funder Available
              if (appPanel === 'productSelection') {
                $rootScope.$broadcast('HAS_PRODUCT_SELECTION_DATA');
              }

              $window.sessionStorage.removeItem('loanApplicationSection');
              $window.sessionStorage.removeItem('loanApplicationPanel');
            }

            /**
             * Toggle for Broker Notes for each section
             * */
            /* Initialize */

            angular
              .element('#loan-application-v3')
              .on('click', '.toggle-notes', (e) => {
                /**
                 * Init
                 * */
                e.preventDefault();
                angular.element(e.currentTarget).toggleClass('is-active');
                const parent = $(e.currentTarget).parent('.level-3-section');
                const noteDiv = parent.find('.notes');
                const togNotes = parent.find('.toggle-notes');

                let imgRef = 'show';
                let actionTxt = 'Show';

                if (angular.element(e.currentTarget).hasClass('is-active')) {
                  /*
                   * Do this broker notes are being shown
                   * */
                  imgRef = 'hide';
                  actionTxt = 'Hide';
                } else {
                  /*
                   * Do this broker notes are being hidden
                   * */
                  imgRef = 'show';
                  actionTxt = 'Show';
                }

                togNotes.html(
                  `<img src="${
                    LEGACY_ANGULAR_HOST?.value || ''
                  }/assets/images/loan-app-main-icons/${imgRef}.svg" title="${actionTxt} ${
                    $scope.brokerNotes.Label
                  }"> &nbsp;Show ${
                    currentUserService.isAU ? 'broker' : 'adviser'
                  } notes`,
                );
                /*
               Hide/Show note wrapper
               */

                noteDiv.toggleClass('collapsed');
              }); // End of trigger.on('click'
          }, 0);
        },

        saveBrokerNote(subsect, content) {
          loanScenarioService
            .brokerNotesSet($scope.loanAppId, subsect, content)
            .then(() => {
              toaster.pop('info', 'Note in this section was updated.');
            });
        },

        getLoanApplication(action, isRefresh = true) {
          return loanAppSharedData
            .getScenarioDetails($scope.loanAppId, isRefresh)
            .then((response) => {
              $scope.LoanApplication = response.data || {};
              loanAppSharedData.IsTopUpOrVariation =
                $scope.LoanApplication.IsTopUpOrVariation;
              loanAppSharedData.LoanApplicationDetails = $scope.LoanApplication;
              loanAppSharedData.isAssetFinanceLoan = isAssetFinanceLoan({
                currentUserService,
                configService,
                loanAppSharedData,
              });
              $scope.LoanApplication.AdviserNameInitials = '';
              const { AdviserName, AdviserFamilyId } = $scope.LoanApplication;
              $scope.showSidebarWidgets = getShowSidebarWidgets({
                assetFinance,
                currentUserService,
                response,
              });
              if (AdviserName) {
                $scope.LoanApplication.AdviserNameInitials = `${utilitiesService.filterInitial(
                  AdviserName.split(' ')[0],
                )}${utilitiesService.filterInitial(AdviserName.split(' ')[1])}`;
              }
              $scope.getAssignedAdviserSubscription(AdviserFamilyId);
              if (
                action === 'edit' &&
                $rootScope.crmCountry === 'New Zealand'
              ) {
                $rootScope.$emit('EDIT_ADVISER_EMAIL');
              }
              if (!$scope.validSections.length) {
                $scope.modalOpened = true;
              }
              $scope.isLoanAppDetailsLoaded = true;
            });
        },

        getAssignedAdviserSubscription: utilGetAssignedAdviserSubscription(
          contactService,
          loanAppSharedData,
          configService,
          eSignService,
        ),

        updateLoanApplication() {
          $scope.loanApplicationSet = angular.copy($scope.loanApplication);

          const loanScenarioData = [$scope.loanApplicationSet];
          loanScenarioService.scenarioSet(angular.toJson(loanScenarioData));
        },

        selectedAdviserChangeForNotCorp(adviser) {
          $scope.AssignedAdviserId = adviser.familyId;
          $scope.AssignedAdviserInfo = adviser;
        },

        saveAllocAdvChange() {
          let adviserId = $scope.AssignedAdviserId;
          if (
            $scope.adviserDetailsForCorp &&
            $scope.loanAccessType === ACCESS_TYPE.CORPORATE
          ) {
            const { selectedAdviserForCorp } = $scope.adviserDetailsForCorp;
            const isValidAdviserCorp =
              selectedAdviserForCorp && selectedAdviserForCorp.description;
            if (isValidAdviserCorp) {
              adviserId = selectedAdviserForCorp.description.familyId;
            }
            $scope.AssignedAdviserInfo = selectedAdviserForCorp.description;
          }

          if ($scope.AssignedAdviserInfo) {
            swal(
              {
                title: 'Confirm Change of Adviser',
                text: `By clicking proceed, the allocated adviser of this loan will be changed from ${$scope.LoanApplication.AdviserName} to ${$scope.AssignedAdviserInfo.fullName}?`,
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: 'Proceed',
                closeOnConfirm: true,
              },
              () => {
                loanScenarioService
                  .scenarioSet({
                    LoanScenarioId: $state.params.loanAppId, // required
                    FamilyId: $state.params.familyId, // required
                    AdviserId: adviserId, // new update
                  })
                  .then(() => {
                    toaster.pop(
                      'success',
                      'Successfully',
                      'Assigned Adviser to Client',
                    );
                    $scope.aachange = false; // close edit mode
                    $scope.getLoanApplication('edit');
                  });
              },
            );
          }
        },

        onAdviserSelect(data) {
          if (currentUserService.isCorporate) {
            $scope.adviserDetailsForCorp.selectedAdviserForCorp = {
              ...$scope.adviserDetailsForCorp.selectedAdviserForCorp,
              ...data,
            };
          } else {
            const { familyId, fullName } = data;
            $scope.selectedAdviserChangeForNotCorp({ familyId, fullName });
          }
          $scope.saveAllocAdvChange();
          $scope.onAssistantSelect(0);
        },

        openCalcs() {
          $scope.openCalcList = $scope.openCalcList === false;
        },

        setSessionStorage() {
          $window.sessionStorage.setItem('loanApplication', $scope.loanAppId);
          $window.sessionStorage.setItem(
            'loanApplicationContact',
            $scope.familyId,
          );
        },

        goToFundsCalc() {
          $scope.setSessionStorage();
          $scope.redirectToFundingCalculator();
        },

        serviceabilityWithFamily(tab = 'serviceability', family) {
          if (!family) {
            return;
          }

          const { fullName: familyName, familyId, clientIds } = family;

          if (!familyName || !clientIds || !familyId) {
            return;
          }
          loanAppSharedData.serviceabilityFamilyName = familyName;
          $scope.goToLoanProfiler(tab, familyId, clientIds);
        },

        goToLoanProfiler(tab, familyId = null, clientIds = null) {
          $scope.setSessionStorage();
          $localStorage.contactLoanCalculatorLinker = {};
          loanAppDetailsService.goToLoanProfiler(
            $scope.loanAppId,
            tab,
            familyId,
            clientIds,
          );
        },

        validateImportantDates(dates = []) {
          const expectedDates = Object.values(APPLICATION_DATE_TYPE);
          expectedDates.forEach((dateType) => {
            const notFound = !dates.find(
              (date) => date.TypeOfDateID === dateType,
            );
            if (notFound) {
              $scope.resetLoanAppDate(dateType);
            }
          });
        },

        resetDateDisplays() {
          const dateDisplayKeys = [
            'displaySettlementDate',
            'displayFinanceDate',
            'displayAuctionDate',
          ];
          dateDisplayKeys.forEach((key) => {
            if (!$scope[key]) {
              $scope[key] = {};
            }
          });
        },

        getDateLoanApp(isOppServiceRefresh) {
          $scope.resetDateDisplays();

          loanApplicationServices
            .getImportantDate($scope.loanAppId)
            .then((response) => {
              const hasActDate = _.find(response.data, (data) => {
                return (
                  data.TypeOfDateID === 1 &&
                  data.Date !== '0001-01-01T00:00:00+00:00'
                );
              });

              _.forEach(response.data, (o) => {
                switch (o.TypeOfDateID) {
                  case 1:
                    if (hasActDate) {
                      angular.extend(
                        $scope.loanAppOptions.dates.settlementDate,
                        {
                          active: true,
                          isEditing: false,
                          IsEstimated: false,
                          date: new Date(o.Date),
                        },
                      );
                      Object.assign(
                        $scope.displaySettlementDate,
                        $scope.loanAppOptions.dates.settlementDate,
                      );
                    }
                    break;
                  case 12:
                    if (!hasActDate) {
                      angular.extend(
                        $scope.loanAppOptions.dates.settlementDate,
                        {
                          active: true,
                          isEditing: false,
                          IsEstimated: true,
                          date: new Date(o.Date),
                        },
                      );
                      Object.assign(
                        $scope.displaySettlementDate,
                        $scope.loanAppOptions.dates.settlementDate,
                      );
                    }
                    break;
                  case 2:
                    angular.extend($scope.loanAppOptions.dates.financeDate, {
                      active: true,
                      isEditing: false,
                      date: new Date(o.Date),
                    });
                    Object.assign(
                      $scope.displayFinanceDate,
                      $scope.loanAppOptions.dates.financeDate,
                    );
                    break;
                  case 10:
                    angular.extend($scope.loanAppOptions.dates.auctionDate, {
                      active: true,
                      isEditing: false,
                      date: new Date(o.Date),
                    });
                    Object.assign(
                      $scope.displayAuctionDate,
                      $scope.loanAppOptions.dates.auctionDate,
                    );
                    break;
                  default:
                }

                $scope.loanAppOptions.dateTypes = _.remove(
                  $scope.loanAppOptions.dateTypes,
                  (type) => {
                    return type.value !== o.TypeOfDateID;
                  },
                );
              });

              if (isOppServiceRefresh) {
                $scope.validateImportantDates(response.data);
              }
            });
        },

        onSuccessfulLoanAppDateUpdate(key) {
          $scope.loanAppOptions.dates[key].active = true;
          $scope.loanAppOptions.dates[key].isEditing = false;
          $scope.getDateLoanApp();
          opportunityLoanAppService.executeCallback('refreshScenarioDetails');
        },

        addDateLoanApp(addDateForm) {
          if (addDateForm.$invalid) {
            return false;
          }
          $scope.addDate = false;

          const dateTypes = $scope.loanAppOptions.dateTypes;
          const dateType = $scope.loanAppOptions.dateType;
          let isEstimated;

          $scope.loanAppOptions.dateTypes = _.remove(dateTypes, (type) => {
            return type.name !== dateType;
          });

          switch (dateType) {
            case 'Estimated Settlement Date':
              /**
               * if IsEstimate is undefined or true makes it Estimate Date
               * when it is false makes it Actual Date
               * */
              if (
                typeof $scope.loanAppOptions.dates.settlementDate
                  .IsEstimated === 'undefined' ||
                $scope.loanAppOptions.dates.settlementDate.IsEstimated
              ) {
                isEstimated = 12;
              } else {
                isEstimated = 1;
              }
              loanApplicationServices
                .putImportantDate(
                  $scope.loanAppId,
                  isEstimated,
                  moment(
                    $scope.loanAppOptions.dates.settlementDate.date,
                  ).format(
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    'MM/DD/YYYY',
                  ),
                )
                .then(() => {
                  $scope.settlementDateEdit = false;
                  $scope.onSuccessfulLoanAppDateUpdate('settlementDate');
                });

              break;
            case 'Finance Date':
              loanApplicationServices
                .putImportantDate(
                  $scope.loanAppId,
                  2,
                  moment($scope.loanAppOptions.dates.financeDate.date).format(
                    'MM/DD/YYYY',
                  ),
                )
                .then(() => {
                  $scope.financeDateEdit = false;
                  $scope.onSuccessfulLoanAppDateUpdate('financeDate');
                });
              break;
            case 'Auction Date':
              loanApplicationServices
                .putImportantDate(
                  $scope.loanAppId,
                  10,
                  moment($scope.loanAppOptions.dates.auctionDate.date).format(
                    'MM/DD/YYYY',
                  ),
                )
                .then(() => {
                  $scope.auctionDateEdit = false;
                  $scope.onSuccessfulLoanAppDateUpdate('auctionDate');
                });
              break;
            default:
          }

          angular.element('.notify-date').hide();
        },

        // eslint-disable-next-line sonarjs/cognitive-complexity
        openAddDate(dateType, section, event) {
          if (event) {
            event.stopPropagation();
          }

          $scope.addDate = $scope.addDate ? !$scope.addDate : true;
          if ($scope.prevSection !== '') {
            $scope.prevSection = section;
            switch (section) {
              case 'objectives':
                $scope.addDateObj = true;
                $scope.addDateHead = $scope.addDateHead
                  ? !$scope.addDateHead
                  : $scope.addDateHead;
                break;
              case 'editSettlement':
                $scope.settlementDateEdit = !$scope.settlementDateEdit;
                $scope.loanAppOptions.dateType = 'Estimated Settlement Date';
                $scope.addDateHead = $scope.addDateHead
                  ? !$scope.addDateHead
                  : $scope.addDateHead;
                $scope.addDateObj = $scope.addDateObj
                  ? !$scope.addDateObj
                  : $scope.addDateObj;
                break;
              case 'editFinance':
                $scope.financeDateEdit = !$scope.financeDateEdit;
                $scope.loanAppOptions.dateType = 'Finance Date';
                $scope.addDateHead = $scope.addDateHead
                  ? !$scope.addDateHead
                  : $scope.addDateHead;
                $scope.addDateObj = $scope.addDateObj
                  ? !$scope.addDateObj
                  : $scope.addDateObj;
                break;
              case 'editAuction':
                $scope.financeDateEdit = !$scope.auctionDateEdit;
                $scope.loanAppOptions.dateType = 'Auction Date';
                $scope.addDateHead = $scope.addDateHead
                  ? !$scope.addDateHead
                  : $scope.addDateHead;
                $scope.addDateObj = $scope.addDateObj
                  ? !$scope.addDateObj
                  : $scope.addDateObj;
                break;
              default:
                $scope.addDateHead = true;
                $scope.addDateObj = $scope.addDateObj
                  ? !$scope.addDateObj
                  : $scope.addDateObj;
                break;
            }
          } else {
            $scope.prevSection = section;
            switch (section) {
              case 'objectives':
                $scope.addDateObj = true;
                $scope.addDateHead = $scope.addDateHead
                  ? !$scope.addDateHead
                  : $scope.addDateHead;
                break;
              case 'editSettlement':
                $scope.settlementDateEdit = !$scope.settlementDateEdit;
                $scope.loanAppOptions.dateType = 'Estimated Settlement Date';
                break;
              case 'editFinance':
                $scope.financeDateEdit = !$scope.financeDateEdit;
                $scope.loanAppOptions.dateType = 'Finance Date';
                break;
              case 'editAuction':
                $scope.auctionDateEdit = !$scope.auctionDateEdit;
                $scope.loanAppOptions.dateType = 'Auction Date';
                break;
              default:
                $scope.addDateHead = true;
                $scope.addDateObj = $scope.addDateObj
                  ? !$scope.addDateObj
                  : $scope.addDateObj;
                break;
            }
          }

          $scope.loanAppOptions.dateType =
            $scope.loanAppOptions.dateTypes[0].name;
          $scope.loanAppOptions.dates.settlementDate.isEditing = false;
          $scope.loanAppOptions.dates.financeDate.isEditing = false;
          $scope.loanAppOptions.dates.auctionDate.isEditing = false;
        },

        openEditDate(event, dateType, section) {
          if (event) {
            event.stopPropagation();
          }
          $scope.addDate = false;

          if (!section) {
            if (dateType === 'financeDate') {
              $scope.loanAppOptions.dateType = 'Finance Date';
              $scope.loanAppOptions.dates.financeDate.isEditing = !$scope
                .loanAppOptions.dates.financeDate.isEditing;

              // close the other
              $scope.loanAppOptions.dates.settlementDate.isEditing = false;
              $scope.loanAppOptions.dates.auctionDate.isEditing = false;
              $scope.financeDateEdit = false;
              $scope.settlementDateEdit = false;
              $scope.auctionDateEdit = false;
            } else if (dateType === 'auctionDate') {
              $scope.loanAppOptions.dateType = 'Auction Date';
              $scope.loanAppOptions.dates.auctionDate.isEditing = !$scope
                .loanAppOptions.dates.auctionDate.isEditing;

              // close the other
              $scope.loanAppOptions.dates.financeDate.isEditing = false;
              $scope.loanAppOptions.dates.settlementDate.isEditing = false;
              $scope.financeDateEdit = false;
              $scope.settlementDateEdit = false;
              $scope.auctionDateEdit = false;
            } else {
              $scope.loanAppOptions.dateType = 'Estimated Settlement Date';
              $scope.loanAppOptions.dates.settlementDate.isEditing = !$scope
                .loanAppOptions.dates.settlementDate.isEditing;

              // close the other
              $scope.loanAppOptions.dates.financeDate.isEditing = false;
              $scope.loanAppOptions.dates.auctionDate.isEditing = false;
              $scope.financeDateEdit = false;
              $scope.settlementDateEdit = false;
              $scope.auctionDateEdit = false;
            }
          } else if (dateType === 'Finance Date') {
            $scope.loanAppOptions.dateType = 'Finance Date';
            $scope.financeDateEdit = !$scope.financeDateEdit;
            $scope.loanAppOptions.dates.objective.financeDate.isEditing = !$scope
              .loanAppOptions.dates.objective.financeDate.isEditing;

            // close the other
            $scope.settlementDateEdit = false;
            $scope.auctionDateEdit = false;
            $scope.loanAppOptions.dates.objective.auctionDate.isEditing = false;
            $scope.loanAppOptions.dates.objective.settlementDate.isEditing = false;
            $scope.loanAppOptions.dates.financeDate.isEditing = false;
            $scope.loanAppOptions.dates.settlementDate.isEditing = false;
            $scope.loanAppOptions.dates.auctionDate.isEditing = false;
          } else if (dateType === 'Auction Date') {
            $scope.loanAppOptions.dateType = 'Auction Date';
            $scope.auctionDateEdit = !$scope.auctionDateEdit;
            $scope.loanAppOptions.dates.objective.auctionDate.isEditing = !$scope
              .loanAppOptions.dates.objective.auctionDate.isEditing;

            // close the other
            $scope.financeDateEdit = false;
            $scope.settlementDateEdit = false;
            $scope.loanAppOptions.dates.objective.financeDate.isEditing = false;
            $scope.loanAppOptions.dates.objective.settlementDate.isEditing = false;
            $scope.loanAppOptions.dates.financeDate.isEditing = false;
            $scope.loanAppOptions.dates.settlementDate.isEditing = false;
            $scope.loanAppOptions.dates.auctionDate.isEditing = false;
          } else if (dateType === 'Estimated Settlement Date') {
            $scope.loanAppOptions.dateType = 'Estimated Settlement Date';
            $scope.settlementDateEdit = !$scope.settlementDateEdit;
            $scope.loanAppOptions.dates.objective.settlementDate.isEditing = !$scope
              .loanAppOptions.dates.objective.settlementDate.isEditing;

            // close the other
            $scope.financeDateEdit = false;
            $scope.auctionDateEdit = false;
            $scope.loanAppOptions.dates.objective.financeDate.isEditing = false;
            $scope.loanAppOptions.dates.objective.auctionDate.isEditing = false;
            $scope.loanAppOptions.dates.financeDate.isEditing = false;
            $scope.loanAppOptions.dates.settlementDate.isEditing = false;
            $scope.loanAppOptions.dates.auctionDate.isEditing = false;
          }
        },

        removeDateLoanApp(dateType, date, $event) {
          const dateTypeObj = getApplicationDateType(dateType);
          $scope.removeDateType = dateType;
          $event.stopPropagation();
          crmConfirmation.open({
            type: 'warning',
            title: `Are you sure you want remove the ${dateTypeObj.name}?`,
            buttonText: 'Yes, I wish to proceed',
            onConfirm: $scope.confirmRemoveDate,
            showCancelButton: true,
            modalSize: 'sm',
          });
        },

        confirmRemoveDate() {
          contactService
            .loanImportantDatesDelete(
              $scope.LoanApplication.LoanId,
              $scope.removeDateType,
              false,
            )
            .then(() => {
              $scope.loanAppOptions.dateTypes = [
                ...$scope.loanAppOptions.dateTypes,
                getApplicationDateType($scope.removeDateType),
              ];
              $scope.loanAppOptions.dateTypes.sort(
                (currentDateType, nextDateType) =>
                  currentDateType.order - nextDateType.order,
              );
              $scope.resetLoanAppDate($scope.removeDateType);
              opportunityLoanAppService.executeCallback(
                'refreshScenarioDetails',
              );
              toastSaveDelete();
              $scope.removeDateType = null;
            })
            .catch(() => {
              $scope.resetLoanAppDate($scope.removeDateType);
              toastError();
              $scope.removeDateType = null;
            });
        },

        resetLoanAppDate(dateType) {
          const key = getApplicationDateType(dateType).key;
          $scope.loanAppOptions.dates[key] = {
            ...$scope.loanAppOptions.dates[key],
            active: false,
            isEditing: false,
            date: new Date(),
          };
          $scope.loanAppOptions.dates.objective[key] = {
            ...$scope.loanAppOptions.dates.objective[key],
            active: false,
            isEditing: false,
            date: new Date(),
          };
          $scope.loanAppOptions.datePopUp[key] = false;
          $scope.loanAppOptions.editDatePopup[key] = false;
        },

        slideSidebar() {
          angular.element('.row-offcanvas').toggleClass('active');
        },

        mobileTogglesInit() {
          /* Initialize */

          const heading = angular.element('.content-heading');

          heading.on('click', (event) => {
            event.preventDefault();
            const parent = angular
              .element(event.currentTarget)
              .parent('.level-3-section');

            const sectionDiv = parent.find('.level-3-content');
            parent.toggleClass('is-active');
            sectionDiv.toggleClass('collapsed');

            if (parent.hasClass('is-active')) {
              parent
                .find('.panel-well-dropdown-btn .fa')
                .removeClass('fa-caret-down')
                .addClass('fa-caret-up');
            } else {
              parent
                .find('.panel-well-dropdown-btn .fa')
                .addClass('fa-caret-down')
                .removeClass('fa-caret-up');
            }
          });
        },

        // eslint-disable-next-line sonarjs/cognitive-complexity
        listeners() {
          $scope.$watch('crmCountry', () => {
            $scope.brokerNotes.Label = uiService.brokerNotesLabel();
          });

          $scope.$watch(
            'loanAppSharedData.REFRESH_APPLICANT_DEPENDENT_SECTIONS',
            (nv) => {
              if (nv && _.size(nv) > 0 && nv.RefreshLoanapp) {
                $scope.BGcolorInit(true);
                nv.RefreshLoanapp = false;
              }
            },
          );

          const loanObjSetWatcher = $scope.$watch(
            'validSections',
            (nv, ov) => {
              const commonTimeont = 4000;
              $timeout(() => {
                if ($scope.validSections.length) {
                  $scope.modalOpened = false;
                }
                const isModalOpened =
                  nv.length !== ov.length &&
                  !$scope.validSections.length &&
                  !$scope.modalOpened;
                if (isModalOpened) {
                  $scope.init();
                  $scope.modalOpened = true;
                }
              }, commonTimeont);
            },
            true,
          );
          watcherService.addWatcher(loanObjSetWatcher);

          angular.element(window).on('resize orientationchange load', () => {
            if (angular.element('body').width() > 991) {
              $scope.mobileViewAllSection = false;

              if (
                angular
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  .element('.top-category-nav')
                  .hasClass('slick-initialized')
              ) {
                angular.element('.top-category-nav').slick('unslick');
              }

              if (
                angular
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  .element('.main-category-nav')
                  .hasClass('slick-initialized')
              ) {
                angular.element('.main-category-nav').slick('unslick');
              }
            }
          });

          const documentOnChangeListener = (e) => {
            const target = e.currentTarget;
            if (target.checked) {
              $scope.updateReportGuideModal(true); // dont show
              $localStorage.isCpaReadyInfoPopover = true;
            } else {
              $scope.updateReportGuideModal(false); // show
              $localStorage.isCpaReadyInfoPopover = false;
            }
          };

          $document.on('change', '#dm', documentOnChangeListener);
          $scope.$on('$destroy', () => {
            if (documentOnChangeListener) {
              $document.off('change', documentOnChangeListener);
            }
          });
        },

        mobileInit() {
          $('.top-category-nav').not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            centerMode: false,
            focusOnSelect: true,
            fade: true,
            asNavFor: '.main-category-nav',
            setPosition: true,
            refresh: true,
          });
          $('.main-category-nav').not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: '.top-category-nav',
            dots: false,
            arrows: false,
            centerMode: true,
            focusOnSelect: true,
            setPosition: true,
            refresh: true,
          });

          // slickSetOption(null, null, true);

          $timeout(() => {
            angular.element('.mobile-wrapper .notes').removeClass('collapsed');
            angular
              .element('.mobile-wrapper .level-3-content')
              .addClass('collapsed');
          }, 500);

          $scope.mobileTogglesInit();
        },

        mobileViewAll() {
          $scope.mobileViewAllSection = $scope.mobileViewAllSection !== true;

          $timeout(() => {
            // jQuery(window).trigger('resize');
            $scope.mobileInit();
          }, 500);
        },

        populateAccessType() {
          userService.GetUserInfo().then((response) => {
            $scope.loanAccessType = response.data.AccessType;
          });
        },
        loanappSharedDataProcedure: (loanAppSharedDataObject, data) => {
          loanAppSharedDataObject.completionListObject =
            data.CompletionStatusList && _.cloneDeep(data.CompletionStatusList);
          loanAppSharedDataObject.loanAppSummary = data;
          $scope.loanAppSharedData.fundingSummary = null;
          $scope.loanAppSharedData.fundingSummary = fundingSummaryDefaultMapper(
            loanAppSharedData.loanAppSummary.FundingSummary,
          );
          const sectionObject = loanAppSharedDataObject.getSectionObjectById(
            loanAppSharedDataObject.completionListObject,
            SECTION_ID.POSSIBLE_ADVERSE,
          );
          loanAppSharedDataObject.isPossibleAdverseCompleted =
            sectionObject.IsCompleted;
          $scope.isLoanAppLoaded = true;

          loanAppSharedDataObject.loanCompletionObject = arrayToObject(
            loanAppSharedDataObject.completionListObject,
            'SectionID',
          );
        },

        onChangeSectionStatus(updatedCompletionStatus, key) {
          const section = SECTION_ID[key];
          $scope.loanAppSharedData.loanCompletionObject[
            section
          ].IsCompleted = updatedCompletionStatus;
        },

        onChangeSectionAdverse({ updatedCompletionStatus }) {
          $scope.loanAppSharedData.isPossibleAdverseCompleted = updatedCompletionStatus;
          $scope.countValids(updatedCompletionStatus, 'possibleAdverseChanges');
        },

        triggerValidation(callback, helpText) {
          $scope.cpaValidatorHelpText = helpText;

          loanScenarioModelService
            .getCompletionStatus($scope.loanAppId)
            .then(({ data }) => {
              if (!data || !data.completionStatusList) {
                return;
              }

              const completion = arrayToObject(
                data.completionStatusList,
                'SectionID',
              );
              const isValid = isCompleteCPA({
                $scope,
                completion,
              });

              $rootScope.LoanAppFormWarning = !isValid;
              $rootScope.showWarningCPA = !isValid;
              $rootScope.file.LoanAppFormWarning = !isValid;
              $rootScope.file.showWarningCPA = !isValid;
              !isValid &&
                angular.element('html,body').animate({ scrollTop: 0 }, 1000);

              callback(isValid);
            });
        },

        loanAppInit() {
          getAddressHistory({
            loanAppId: $scope.loanAppId,
            loanScenarioService,
            loanAppSharedData,
          });
          loanApplicationServices
            .loanSummaryGet($scope.loanAppId)
            .then(({ data }) => {
              if (!data) {
                return;
              }
              setIsLock(data.LoanDetails.IsReadOnly);
              $scope.showAssetInsuranceSection = showAssetInsuranceSection({
                currentUserService,
                configService,
                lendingCategoryId: data.LoanDetails.LendingCategoryId,
              });
              $scope.loanappSharedDataProcedure(loanAppSharedData, data);
              $scope.loanId = data.LoanDetails.LoanID;
              $scope.populateAccessType();
              $scope.togglesInit();
              $scope.getLoanApplication('', false);
              $scope.getDateLoanApp();
              $scope.mobileInit();
              $scope.loanAppOptions.dateType =
                $scope.loanAppOptions.dateTypes[0].name;
              const procedureTimeout = $timeout(() => {
                // Hide the date tooltip after 5000
                angular.element('.notify-date').hide();
              }, 5000);
              $scope.BGcolorInit();
              $scope.listeners();
              $scope.$on('$destroy', () => {
                loanAppSharedData.lenderId = null;
                loanAppSharedData.clearSharedData();
                $timeout.cancel(procedureTimeout);
              });
              loanAppSharedData.updateServiceabilityDropdown =
                $scope.initDropdown;
            });
          objectivesQuestionService.getOptions();
          loanApplicationServices
            .getReqAndObjValidation($scope.loanAppId)
            .then((isReqAndObj) => {
              $scope.displayPossibleAdverseChanges =
                requirementsAndObjectives && isReqAndObj;
            });
          $scope.getPossibleAdverseAnswers();
          gamePlanGeneratorService.reloadLoanAppApplicantsCallback = () => {
            $scope.BGcolorInit(true);
          };
        },

        getLoanAppDetails(callback) {
          loanScenarioService
            .scenarioDetailsGet($scope.loanAppId)
            .then(({ data }) => {
              if (data && !data.IsShowCPACQPRecommendation) {
                _.isFunction(callback) && callback();
              }
            });
        },

        createModal(e) {
          /**
           * Create modal container
           * */
          if (e && e.currentTarget) {
            const that = e.currentTarget;
            that.listeners();
          }

          const modalDiv = $window.document.createElement('div');
          modalDiv.className = 'recommended-reports-modal';
          modalDiv.setAttribute(
            'style',
            `overflow:hidden;height:${angular
              .element($window.document)
              .outerHeight()}px`,
          );
          $window.document.body.append(modalDiv);
          angular.element('#loan-application-v3').prepend(modalDiv);

          const hackbg = $window.document.createElement('div');
          hackbg.setAttribute('style', 'background:rgba(27,20,57,0.9)');
          angular
            .element('.app-aside .sidebar-container')
            .css({ opacity: 0.1 });
          angular.element('.navbar-header').css({ opacity: 0 });
          angular.element('#top-navbar').css({ opacity: 0 });
          angular
            .element('.fact-find-sidebar-container')
            .css({ opacity: 0.01 });
          angular.element('.calculations-widget').css({ opacity: 0.01 });

          const arrowDiv = $window.document.createElement('div');
          arrowDiv.className = 'wi';
          arrowDiv.dataset.recommendReports = 'data-recommend-reports';
          arrowDiv.setAttribute('style', 'top: 600px; right: 500px;');

          const innerWrap = $window.document.createElement('div');
          innerWrap.className = 'inner-wrap';

          const headingWrap = $window.document.createElement('div');
          headingWrap.className = 'heading-wrap';

          if ($rootScope.crmCountry === 'New Zealand') {
            headingWrap.append(
              $window.document.createTextNode(
                'Your Loan Profiler report is now ready\n',
              ),
            );
          } else {
            headingWrap.append(
              $window.document.createTextNode(
                'Your CPA / CQP report is now ready\n',
              ),
            );
          }

          innerWrap.append(headingWrap);

          innerWrap.append(
            $window.document.createTextNode(
              'Click + next to recommendation reports to create',
            ),
          );
          const img = $window.document.createElement('img');
          img.setAttribute('src', '/assets/images/arrow.svg');
          img.setAttribute('style', 'transform:rotate(170deg);right:-10px');
          img.dataset.top = 0;
          innerWrap.append(img);
          arrowDiv.append(innerWrap);
          modalDiv.append(arrowDiv);

          /**
           * Create container for Next & Finish buttons
           * */
          const modalAction = $window.document.createElement('div');
          modalAction.className = 'modal-action';
          innerWrap.append(modalAction);

          const finBtn = $window.document.createElement('BUTTON');
          finBtn.setAttribute('id', 'fin');
          modalAction.append(finBtn);
          finBtn.append($window.document.createTextNode('Got it'));

          const dontShow = $window.document.createElement('div');
          dontShow.className = 'dontShow';
          modalAction.append(dontShow);

          const checkboxDontShow = $window.document.createElement('input');
          checkboxDontShow.type = 'checkbox';
          checkboxDontShow.id = 'dm';

          const dmLabel = $window.document.createElement('label');
          dmLabel.setAttribute('for', 'dm');
          dmLabel.append($window.document.createTextNode("Don't show again"));

          dontShow.append(checkboxDontShow);
          dontShow.append(dmLabel);
        },

        updateReportGuideModal(show) {
          loanScenarioService.setLoanApplicationShowCPACQPRecommendation(
            $scope.loanAppId,
            show,
          );
        },

        init() {
          if (!$scope.loanAppSharedData) {
            return;
          }
          if (!$scope.loanAppSharedData.isOverrideValidation) {
            const showCPApopup =
              $scope.validSections &&
              !$scope.validSections.length &&
              !$scope.loanAppSharedData.isOverrideValidation;

            if (showCPApopup) {
              $scope.getLoanAppDetails();
            }
          }
        },
      });

      const reloadImportantDates = () => $scope.getDateLoanApp(true);
      opportunityLoanAppService.setCallback(
        'refreshLoanAppImportantDates',
        reloadImportantDates,
      );

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const dropdownFamilyListMapping = (applicants) => {
        const list = [];
        applicants &&
          applicants.forEach((family) => {
            let single = {};
            if (family.length > 1) {
              single = {
                fullName: family[0].FamilyFullName,
                familyId: family[0].FamilyId,
                clientIds: family.reduce((string, person) => {
                  const emptySpaceOrComma = string ? ',' : '';
                  return `${string + emptySpaceOrComma + person.PersonId}`;
                }, ''),
              };
            } else {
              single = {
                fullName: `${family[0].FirstName} ${family[0].LastName}`,
                familyId: family[0].FamilyId,
                clientIds: family[0].PersonId,
              };
            }

            list.push(single);
          });

        return list;
      };

      const listApplicantPersons = () => {
        return loanApplicationServices
          .loanSummaryGet($scope.loanAppId)
          .then((loanSummary) => {
            const { InvolvedPartyPerson: involvedPartyPerson } =
              loanSummary &&
              loanSummary.data &&
              loanSummary.data.ApplicantGuarantorList;

            const applicantGuarantorList = Object.keys(involvedPartyPerson).map(
              (key) => involvedPartyPerson[key],
            );

            return (
              (applicantGuarantorList &&
                dropdownFamilyListMapping(applicantGuarantorList)) ||
              []
            );
          });
      };
      $scope.initDropdown = () => {
        listApplicantPersons().then((list) => {
          $scope.familyList = list;
        });
      };
      $scope.getClientInformation();
      $scope.loanAppInit();
    });
