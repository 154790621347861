import moment from 'moment';
import { D_MMM_YYYY_FORMAT } from 'Common/constants/dateConfigs';

export default class AccreditationUtilityService {
  constructor() {
    'ngInject';

    this.deafaultDates = moment;
  }

  getDefaultDates() {
    return this.deafaultDates().format(D_MMM_YYYY_FORMAT);
  }

  setDate() {
    return this.deafaultDates().format('YYYY-MM-DD hh:mm:ss');
  }
}
