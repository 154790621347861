import get from 'lodash/get';
import set from 'lodash/set';
import { EDITOR_OPTIONS_NO_TABLE } from 'Common/config/wysiwigEditor';
import {
  HELLO_PACK_CHAR_LIMIT,
  PACK_MODE,
  COMMON_PLACEHOLDER,
} from 'Common/constants/helloBook';

export default class HelloPackEmailContentCtrl {
  constructor($window) {
    'ngInject';

    this.$window = $window;
    this.onUpdateContent = this.onUpdateContent.bind(this);
  }

  $onInit() {
    this.editorOptions = EDITOR_OPTIONS_NO_TABLE;
    this.helloPackEditor = {};
    this.clientDetails = {
      displayName: 'Client',
      previousDisplayName: '',
    };
    if (this.handler) {
      this.handler.setDefaultTemplate = (template) => {
        this.setDefaultTemplate(template);
      };
    }
  }

  $onChanges({ clients }) {
    this.getClientDetails(clients);
  }

  getClientPreviousDisplayName() {
    return get(this, 'clientDetails.previousDisplayName', '');
  }

  getClientDetails(clients = {}) {
    const defaultDetail = {
      displayName: '',
      previousDisplayName: this.getClientPreviousDisplayName(),
    };
    const selectedClients = get(clients, 'currentValue', []);
    this.clientDetails = selectedClients.reduce(
      (accum, client, currentIndex) => {
        if (!accum.displayName) {
          accum.displayName = client.firstName;
          return accum;
        }
        const isLastPosition = currentIndex === selectedClients.length - 1;
        if (isLastPosition) {
          accum.displayName = `${accum.displayName} and ${client.firstName}`;
        } else {
          accum.displayName = `${accum.displayName}, ${client.firstName}`;
        }

        return accum;
      },
      defaultDetail,
    );
    this.setDefaultName();
  }

  setDefaultName() {
    const isValidToReplaceName = !!(
      get(this, 'helloPackEditor.defaultEditorMessage', '') &&
      this.getClientPreviousDisplayName()
    );
    if (isValidToReplaceName) {
      this.helloPackEditor.defaultEditorMessage = this.helloPackEditor.defaultEditorMessage.replace(
        this.getClientPreviousDisplayName(),
        this.clientDetails.displayName,
      );
    } else {
      this.getInitialValue();
    }
    set(
      this,
      'clientDetails.previousDisplayName',
      get(this, 'clientDetails.displayName', ''),
    );
    this.onUpdateContent();
  }

  setDefaultTemplate(template) {
    if (template) {
      this.helloPackEditor.defaultEditorMessage = template.replace(
        COMMON_PLACEHOLDER,
        this.clientDetails.displayName,
      );
      this.onUpdateContent();
    }
  }

  getInitialValue() {
    this.DEFAULT_PLACEHOLDER = {
      [PACK_MODE.HELLO_PACK]: {
        packTitle: 'Send Hello Pack',
        bannerMessage: 'Hello.',
        defaultEditorMessage: this.onlyFactFind
          ? ' '
          : `Hi ${this.clientDetails.displayName}, <br><br>
          <p>It was great to chat and learn about what you're looking to do.</p>
        `,
      },
      [PACK_MODE.INSURANCE_PROFILER]: {
        packTitle: 'Send Insurance Profile',
        bannerMessage: 'Hello.',
        defaultEditorMessage: `Hello ${this.clientDetails.displayName}, <br><br>
          <p>As discussed, here is your insurance profile for you to take a look at.</p>
        `,
      },
      [PACK_MODE.INVITE_IOFF]: {
        packTitle: 'Invite to Insurance Online Fact Find',
        bannerMessage: `Hi ${this.clientDetails.displayName}, <br>
          We've got some questions for you
        `,
        defaultEditorMessage: `<p>Hi ${this.clientDetails.displayName}, we've got a few questions for you!</p>
          <p>We are here to ensure you are covered to protect yourself and your family.</p>
          <p>Providing the information requested will help us design a solution to best suit your needs. <br>
          Click "Complete your profile" and we will talk you through the information we required.</p>
          <p>Everything is saved as you go and when you're ready simply click the "send for review" button.</p>
        `,
      },
    };
    this.helloPackEditor = {
      ...this.DEFAULT_PLACEHOLDER[this.packMode],
    };
  }

  isEditorMessageAboveThreshold() {
    return (
      get(this, 'helloPackEditor.defaultEditorMessage.length', 0) >
      HELLO_PACK_CHAR_LIMIT
    );
  }

  onEditorReady() {
    const editor = get(this, '$window.CKEDITOR.instances.emailContent', {
      on: () => {},
    });
    const blurCallback = this.onUpdateContent;
    editor.on('blur', () => {
      blurCallback();
    });
  }

  onUpdateContent() {
    const props = {
      message: get(this, 'helloPackEditor.defaultEditorMessage', ''),
      clientDisplayName: this.clientDetails.displayName,
    };
    this.onUpdate({ props });
  }
}
