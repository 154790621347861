import angular from 'angular';
import _ from 'lodash';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import { mergeFieldsMapperForUI } from 'Common/mappers/customerCare';
import { isValidEmailAddress } from 'Common/utilities/string';
import { toastWarning } from 'Common/utilities/alert';
import { initializeSendEmail } from './util/customerCareEmailUtil';

export const loadModule = () =>
  angular.module('app').controller(
    'CustomerCareSendEmailModalBaseCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function CustomerCareSEndEmailModalBaseCtrl(
      $scope,
      $uibModal,
      toaster,
      emailTemplatesService,
      customerCareService,
      ckEditorConfigService,
      templateService,
      mailTemplateService,
      emailTemplatesSharedData,
      currentUserService,
      commonFnService,
    ) {
      initializeSendEmail({
        $scope,
        templateService,
        commonFnService,
        currentUserService,
      });
      $scope.getEmailTemplates = () => {
        emailTemplatesService.getEmailTemplates().then((response) => {
          if (!response) {
            return;
          }

          $scope.emailTemplateListing = response.emailTemplateListing;
          $scope.blankCategory = response.blankCategory;
          $scope.blankTemplate = response.blankTemplate;
          $scope.templatesStatus.emailTemplatesLoaded = true;
          $scope.templatesStatus.onAllTemplatesLoaded();
        });
      };

      $scope.getDefaultTemplate = () => {
        if (!$scope.currentModule) {
          return;
        }

        templateService
          .getModuleDefaultTemplate(
            $scope.currentModule,
            SEND_METHOD_TYPES.EMAIL,
          )
          .then((response) => {
            $scope.defaultTemplate = response.data;
            $scope.templatesStatus.defaultTemplateLoaded = true;
            $scope.templatesStatus.onAllTemplatesLoaded();
          });
      };

      $scope.onAllTemplatesLoaded = () => {
        if (
          $scope.templatesStatus.emailTemplatesLoaded &&
          $scope.templatesStatus.defaultTemplateLoaded &&
          $scope.defaultTemplate
        ) {
          const defaultIsBlankTemplate =
            $scope.blankTemplate &&
            $scope.blankTemplate.UserTemplateID ===
              $scope.defaultTemplate.UserTemplateID;
          if (defaultIsBlankTemplate) {
            $scope.setTemplateSelection($scope.blankTemplate);
            return;
          }
          $scope.initNamedDefaultTemplate();
        }
      };

      $scope.initNamedDefaultTemplate = () => {
        let defaultTemplateIndex = -1;
        const folder = _.find($scope.emailTemplateListing, (fldr) => {
          defaultTemplateIndex = _.findIndex(
            fldr.EmailTemplates,
            (template) =>
              template.UserTemplateID === $scope.defaultTemplate.UserTemplateID,
          );
          return defaultTemplateIndex !== -1;
        });
        const defaultIsNamedTemplate = folder && defaultTemplateIndex !== -1;
        if (!defaultIsNamedTemplate) {
          return;
        }

        $scope.setTemplateSelection(
          folder.EmailTemplates[defaultTemplateIndex],
          defaultTemplateIndex,
        );
      };

      $scope.useTemplate = (template, templateIndex) => {
        if (!template) {
          return;
        }

        const {
          UserTemplateID: id,
          UserTemplateName: name,
          UserTemplateContent: content,
          UserTemplateEmailSubject: subject,
        } = template;
        $scope.setTemplateSelection(id, name, content, subject, templateIndex);
      };

      $scope.translateTemplate = (templateContent, templateSubject) => {
        if (!templateContent || !templateContent.trim()) {
          $scope.email.EmailSubject = templateSubject;
          $scope.email.EmailBody = templateContent;
          return;
        }

        const mergeFieldData = mergeFieldsMapperForUI({
          ...$scope.mergeFieldsParams,
          templateContent,
          templateSubject,
        });

        mailTemplateService
          .translateMailTemplate(mergeFieldData)
          .then((response) => {
            $scope.email.EmailSubject = response.templateSubject;
            $scope.email.EmailBody = response.templateContent;
          })
          .catch((error) => {
            $scope.email.EmailSubject =
              error.templateSubject || templateSubject;
            $scope.email.EmailBody = error.templateContent || templateContent;
          });
      };

      $scope.isTemplateSelected = (templateID) => {
        return (
          $scope.selectedTemplate &&
          $scope.selectedTemplate.UserTemplateID === templateID
        );
      };

      $scope.openEmailManagement = () => {
        emailTemplatesSharedData.setShowDefaultTemplateSettings(true);
        $uibModal
          .open({
            templateUrl:
              'assets/views/corporate/emailManagement/emailManagement.html',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            controller: 'EmailManagementCtrl',
          })
          .result.then((hasChanges) => {
            if (hasChanges) {
              $scope.getEmailTemplates();
            }
          });
      };

      $scope.openDefaultTemplateSettings = () => {
        templateService.openDefaultTemplateSettings(
          $scope.selectedTemplate,
          SEND_METHOD_TYPES.EMAIL,
        );
      };

      $scope.hasValidSelectedTemplate = () => {
        return templateService.isValidTemplate($scope.selectedTemplate);
      };

      $scope.selectRecipient = (email, model) => {
        _.remove($scope.recipients.email, (item) => {
          return !item.EmailAddress || _.isString(item);
        });
        if (_.isString(email) && isValidEmailAddress(model)) {
          $scope.recipients.email.push({ EmailAddress: model });
        } else if (_.isString(email)) {
          toastWarning('Invalid email address format');
        }
      };

      $scope.init = () => {
        // Ckeditor Tollbar Configuration
        $scope.options = ckEditorConfigService.getCustomerCareEmailEditorConfig();
        $scope.content =
          '<div style="font-family: Arial;"><div><div><span style="font-family: Arial; font-size: 10pt;">Hi </span><span style="font-family: Arial; font-size: 10pt;">[Preferred Name]</span></div></div><br /><div>&nbsp;</div><div><span style="font-family: Arial; font-size: 10pt;">Best regards,</span></div><div><span style="font-family: Arial; font-size: 10pt;">[Your Name]</span></div></div><div>&nbsp;</div><div>[Email Signature]</div>';

        $scope.templatesStatus = {
          emailTemplatesLoaded: false,
          defaultTemplateLoaded: false,
          onAllTemplatesLoaded: $scope.onAllTemplatesLoaded,
        };
      };

      $scope.init();
    },
  );
