import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { displayError, alertSucess } from 'Common/utilities/alert';
import { CORPORATE_TEAM } from 'Common/constants/corporateTeam';
import { SHARING_VALUE } from 'Common/constants/documentSharing';
import { DOCUMENT_BROKEN_ERROR_MSG } from 'Common/constants/document';
import { initializeOpportunityLabel } from 'Common/utilities/addCalculationUtility';
import * as utilESignDocument from 'Common/utilities/eSignDocument';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  getContactData,
  allowCorporateTeam,
  downloadDocument,
  downloadAllAttachment,
  countDownloadAllDocs,
  showDownloadAll,
  trimTags,
} from './util/documentsDetailCtrl';
import { checkIfCanDownloadDocument } from '../loanApp/supportDocs/util/loanAppSupportDocsCtrl';

const DOC_TYPE = {
  LOAN_APP: 'loanApp',
  ESIGN: 'esign',
  OTHERS: 'others',
  GOOGLE_DRIVE: 'googleDrive',
};

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('DocumentsDetailCtrl', function DocumentsDetailCtrl(
      $scope,
      $timeout,
      $uibModal,
      $stateParams,
      contactService,
      NgTableParams,
      $filter,
      configService,
      toaster,
      generalService,
      uiService,
      $window,
      downloadDataService,
      eSignService,
      $q,
      $state,
      currentUserService,
      corporateService,
      insuranceApplicationService,
      contactModelService,
      leadOpportunitiesService,
      loanApplicationServices,
      contactSharedData,
    ) {
      $scope.filteredDocumentList = [];
      $scope.docAccordionEditHandler = {
        run: (params, currentPage) => $scope.editDocument(params, currentPage),
      };
      $scope.docAccordionDeleteHandler = {
        run: (params) => $scope.rmoveDocument(params),
      };
      $scope.groupedDocumentUploadAPI = '/grouped-documents/upload';
      $scope.fileUploadMiniAccordion = {
        uploadViewSuccess: (files, loanAppId) =>
          $scope.uploadViewSuccess(files, loanAppId),
        extensions: () => $scope.extensions,
        invalidFileInfo: () => $scope.invalidFileInfo,
        familyId: () => $scope.familyId,
        groupedDocumentUploadAPI: () => $scope.groupedDocumentUploadAPI,
      };
      $scope.utilESignDocument = utilESignDocument;
      $scope.isCorporate = currentUserService.isCorporate;
      $scope.interface = {};
      $scope.contactInfo = {};
      $scope.uploadCount = 0;
      $scope.success = false;
      $scope.error = false;
      $scope.gdriveObject = [];
      $scope.familyId = $stateParams.familyId;
      $scope.clientId = $stateParams.clientId;
      $scope.adviserOrgId = $stateParams.adviserOrgId
        ? $stateParams.adviserOrgId
        : 0;
      $scope.hideDocumentProgress = false;
      $scope.isUploaded = false;
      $scope.documentTab = 'allDocuments';
      $scope.selectedFilterInfo = [];
      const { clientId, adviserOrgId } = $state.params;
      $scope.isClientDocuments =
        !clientId && clientId !== 0 && !adviserOrgId && adviserOrgId !== 0;
      $scope.isDocumentRedesignEnable =
        configService.feature.documentsRedesign && $scope.isClientDocuments;
      $scope.docBrokenErrorMsg = DOCUMENT_BROKEN_ERROR_MSG;
      $scope.extensions = [
        'pdf',
        'docx',
        'doc',
        'odt',
        'xls',
        'xlsx',
        'ods',
        'odp',
        'ppt',
        'pptx',
        'jpg',
        'jpeg',
        'png',
        'rtf',
        'odt',
        'txt',
        'tif',
        'bmp',
        'gif',
        'html',
      ];
      $scope.invalidFileInfo = {
        title: 'Invalid',
        description: 'Invalid file NOT added',
      };

      $scope.isShowGdriveFeature = () => {
        return configService.feature.googleDrive && !uiService.isCorporateUser;
      };

      initializeOpportunityLabel({
        $scope,
        leadOpportunitiesService,
        isSingular: true,
      });

      $scope.isShowEsign =
        (configService.feature && configService.feature.esign) || 0;

      $scope.isLoadingEmails = {
        documents: true,
        googleDrive: $scope.isShowGdriveFeature(),
        isTrue: () =>
          $scope.isLoadingEmails.documents ||
          $scope.isLoadingEmails.googleDrive,
      };
      $scope.documentType = [];
      $scope.gdriveObject.models = null;
      $scope.gdriveObject.models = {
        UserAuthUrl: null,
        isAuthenticated: false,
        rawFolderFiles: {},
        hasChosenAFolder: false,
        isShowNotAssignedFolder: false,
      };
      $scope.checkIfCanDownloadDocument = checkIfCanDownloadDocument($scope);
      $scope.downloadAllAttachment = downloadAllAttachment({
        $scope,
        contactModelService,
        downloadDataService,
      });
      $scope.showDownloadAll = showDownloadAll({ $scope });
      $scope.bannerObject = {
        typeShown: '',
        textsList: {
          documentsDragTip: `Quick Tip: Did you know that you can also add a file by dragging and dropping it to the window? Try it!`,
          noGdriveIntegrated: `We could not load Google Drive files because you haven't linked your account to MyCRM yet. Click here to link`,
          noFolderAssigned: `We could not load Google Drive files because you didn't select a folder for this family yet. Click here to select`,
        },
        textToShow: () => {
          const {
            isAuthenticated,
            hasChosenAFolder,
          } = $scope.gdriveObject.models;
          const {
            noFolderAssigned,
            documentsDragTip,
            noGdriveIntegrated,
          } = $scope.bannerObject.textsList;

          if (!isAuthenticated && $scope.isShowGdriveFeature()) {
            $scope.bannerObject.typeShown = 'notIntegrated';
            return noGdriveIntegrated;
          } else if (!hasChosenAFolder && $scope.isShowGdriveFeature()) {
            $scope.bannerObject.typeShown = 'noFolder';
            return noFolderAssigned;
          }

          $scope.bannerObject.typeShown = 'documentsDrag';
          return documentsDragTip;
        },
        clicked: () => {
          const { typeShown } = $scope.bannerObject;
          const { openNewWindowForAuth } = $scope.gdriveObject.methods;

          if (typeShown === 'noFolder') {
            $scope.showSelectGdriveFolder();
          } else if (typeShown === 'notIntegrated') {
            openNewWindowForAuth();
          }
        },
      };

      const populateDocumentType = () => {
        contactService.documentTypeGet().then((response) => {
          $scope.documentType = response.data;

          if (!$scope.isShowGdriveFeature()) {
            $scope.documentType = response.data.filter(
              (object) => object.type !== DOC_TYPE.GOOGLE_DRIVE,
            );
          }

          $scope.documentType = !$scope.isShowEsign
            ? $scope.documentType.filter(
                (object) => object.type !== DOC_TYPE.ESIGN,
              )
            : $scope.documentType;

          const isClient =
            typeof $state.params.clientId === 'undefined' &&
            typeof $state.params.adviserOrgId === 'undefined';
          if (!isClient && $scope.documentType) {
            $scope.documentType = $scope.documentType.filter(
              (object) => object.type !== DOC_TYPE.LOAN_APP,
            );
          }
        });
      };
      populateDocumentType();

      /* start of gdrive methods */
      $scope.gdriveObject.methods = null;
      $scope.gdriveObject.methods = {
        init: () => {
          $scope.gdriveObject.methods
            .checkIfAuthenticated()
            .then((response) => {
              $scope.gdriveObject.models.isAuthenticated = response.AuthSuccess;

              if (
                $scope.gdriveObject.models.isAuthenticated &&
                $scope.gdriveObject.models.hasChosenAFolder
              ) {
                if (
                  response.GoogleDriveFolderFiles &&
                  response.GoogleDriveFolderFiles.length
                ) {
                  $scope.gdriveObject.models.rawFolderFiles = response.GoogleDriveFolderFiles.filter(
                    (object) => object.IsFolder === false,
                  );
                }
              } else {
                $scope.gdriveObject.models.UserAuthUrl = response.UserAuthUrl;
              }
            });
        },

        openNewWindowForAuth: () => {
          $window.open($scope.gdriveObject.models.UserAuthUrl);
        },

        checkIfAuthenticated: () => {
          $scope.isLoadingEmails.googleDrive = true;
          const defer = $q.defer();
          contactService
            .getDriveUserMapping($state.params.familyId)
            .then((response) => {
              if (response.data) {
                $scope.gdriveObject.models.hasChosenAFolder = true;
                $scope.selectedGdriveFolderId =
                  response.data.GoogleDriveFolderID;
              } else {
                $scope.gdriveObject.models.isShowNotAssignedFolder = true;
              }

              contactService
                .getGoogleDriveFolderId(
                  false,
                  $scope.selectedGdriveFolderId
                    ? $scope.selectedGdriveFolderId
                    : 'root',
                )
                .then((getGdriveResponse) => {
                  const data = getGdriveResponse.data;
                  let toReturnData;

                  if (data.AuthSuccess) {
                    toReturnData = data;
                  } else {
                    toReturnData = {
                      AuthSuccess: data.AuthSuccess,
                      UserAuthUrl: data.UserAuthUrl,
                    };
                  }
                  $scope.isLoadingEmails.googleDrive = false;

                  return defer.resolve(toReturnData);
                });
            });

          return defer.promise;
        },
      };
      /* end of gdrive */
      $scope.hideDocumentBanner = (event) => {
        event.stopPropagation();
        $scope.hideDocumentNotification = true;
      };
      $scope.isGdriveOnlyChecked = () => {
        if (!$scope.filterModel) {
          return;
        }
        return (
          $scope.filterModel.googleDrive &&
          !$scope.filterModel.loanApp &&
          !$scope.filterModel.others
        );
      };

      $scope.filterChanged = (filterInfo) => {
        $scope.filterModel = filterInfo;
      };

      $scope.isAllFilterChecked = () => {
        let toReturnValue = true;
        _.forOwn($scope.filterModel, (object) => {
          if (!object) {
            toReturnValue = false;
          }
        });
        return toReturnValue;
      };

      $scope.isShowLoanDocument = (doc) => {
        return $scope.filterModel.loanApp && doc.DocFromLoanApp;
      };

      $scope.isShowOthersDocument = (doc) => {
        return (
          $scope.filterModel.others &&
          doc.DocFromContact &&
          !$scope.documentIsDuplicate(doc)
        );
      };

      $scope.isShowSignedDocument = (doc) => {
        return $scope.filterModel.esign && doc.DocFromESign;
      };

      $scope.isShowDocuments = (doc) => {
        return (
          $scope.isShowLoanDocument(doc) ||
          $scope.isShowOthersDocument(doc) ||
          $scope.isShowSignedDocument(doc)
        );
      };

      $scope.isShowBanner = () => {
        return (
          !$scope.isLoadingEmails.isTrue() &&
          !$scope.hideDocumentNotification &&
          !$scope.isGdriveOnlyChecked()
        );
      };

      $scope.isShowNoGdriveIntegratedPanel = () => {
        return (
          !$scope.gdriveObject.models.isAuthenticated &&
          $scope.isGdriveOnlyChecked() &&
          !$scope.isLoadingEmails.isTrue()
        );
      };

      // eslint-disable-next-line unicorn/consistent-function-scoping
      const addTagsToDocumentList = (documentsList) => {
        documentsList.forEach((object) => {
          object.tag = object.DocFromLoanApp ? 'loanApp' : 'others';
        });
        return documentsList;
      };

      $scope.documentProgressVisibility = function (visibility) {
        $scope.hideDocumentProgress = parseInt(visibility, 0) < 1;
      };

      $scope.uploadViewSuccess = (files, loanAppId = null) => {
        angular.forEach(files, (o) => {
          $scope.processDocument({
            Title: o.Name,
            DocumentID: o.DocumentId,
            FamilyID: $scope.familyId,
            UploadType: o.ImageUploadType,
            LoanApplicationId: loanAppId,
          });
        });
        $scope.showDragBox = false;
      };

      $scope.openDocumentModal = function (obj, isUpdate, currentPage) {
        const documentDataObject = obj;
        const docModal = $uibModal.open({
          templateUrl:
            '/assets/views/contacts/partials/add_document_modal.html',
          controller: 'DocumentModalCtrl',
          scope: $scope,
          resolve: {
            documentDataObject() {
              return documentDataObject;
            },
            isUpdate() {
              return isUpdate;
            },
            userInfo() {
              return $scope.userInfo;
            },
            isClientDocuments() {
              return $scope.isClientDocuments;
            },
            currentPage: () => currentPage,
          },
        });

        docModal.result.then((response) => {
          $scope.documentsList = $scope.documentsList.map((docum) => {
            if (docum.GroupTitle === response.documentGroupTitle) {
              return {
                ...docum,
                isOpen: true,
                currentPage: response.currentPage,
              };
            }
            return { ...docum, isOpen: false };
          });
        });
      };
      const processAfterDocPropertySet = () => {
        $scope.loadDocumentList();
        alertSucess(
          'Success',
          'Document has been been successfully uploaded',
          true,
        );
      };
      $scope.processDocument = function (obj) {
        const documentDataObject = obj;
        const forClientGrouped = {
          Title: documentDataObject.Title,
          Tags: '',
          Description: '',
          DoucmentID: documentDataObject.DocumentID,
          FamilyID: documentDataObject.FamilyID,
          OrganisationId: $scope.adviserOrgId,
        };
        const forLoanGrouped = {
          LoanScenarioId: obj.LoanApplicationId,
          DocumentPage: 1,
          IsAdded: true,
          Notes: '',
          UploadDate: new Date(),
          UploadedByFamilyID: $scope.familyId,
          DocumentId: documentDataObject.DocumentID,
          Name: documentDataObject.Title,
          Size: 0,
          DateUploaded: new Date(),
          Createddate: new Date(),
          CreatedMonth: new Date(),
        };

        if ($scope.isDocumentRedesignEnable) {
          const isLoanGrouped = parseToInt10(obj.LoanApplicationId);
          const groupedEndpointToUse = isLoanGrouped
            ? 'postLoanGroupedDocProperty'
            : 'postDocumentProperty';
          const payloadToUse = isLoanGrouped
            ? forLoanGrouped
            : forClientGrouped;
          loanApplicationServices
            .getDocumentPageCount(documentDataObject.DocumentID)
            .then((response) => {
              const tempDocBody = {
                ...payloadToUse,
                NumberPages: response.data,
              };
              $scope.documentObject = isLoanGrouped
                ? tempDocBody
                : [tempDocBody];
              return contactModelService[groupedEndpointToUse](
                $scope.documentObject,
              );
            })
            .then(() => {
              processAfterDocPropertySet();
            });
        } else {
          $scope.documentObject = forClientGrouped;
          contactModelService
            .postDocumentProperty([$scope.documentObject])
            .then(() => {
              processAfterDocPropertySet();
            })
            .catch(displayError);
        }
      };

      $scope.editDocument = function (documentParam, currentPage) {
        const documentObj = angular.copy(documentParam) || {};
        documentObj.DocumentID =
          documentParam.DocumentId || documentParam.DocumentID;
        documentObj.Title = documentParam.Name || documentParam.Title;
        if (!_.isUndefined(documentObj.Tags)) {
          documentObj.Tags = documentObj.Tags.join(',');
        }
        documentObj.FamilyID = $scope.familyId;

        $scope.openDocumentModal(documentObj, true, currentPage);
      };

      // Douments List
      // -------------------------------------------------------------
      $scope.documentsList = [];
      $scope.documentListLoading = true;
      $scope.searchFilesText = '';
      $scope.documentsListLength = 0;
      $scope.searchDocumentFile = null;
      $scope.isDocumentAlreadyLoaded = false;
      let callSearch;
      $scope.searchDocumentCall = function () {
        $timeout.cancel(callSearch);
        callSearch = $timeout(() => {
          $scope.loadDocumentList(true);
        }, 800);
      };
      $scope.loadDocumentList = (isUsedToSearch) => {
        $scope.isLoadingEmails.documents = true;
        if ($scope.adviserOrgId > 0) {
          $scope.familyId = 0;
        }
        $scope.documentListLoading = true;
        if ($scope.isDocumentRedesignEnable) {
          contactModelService
            .getGroupedDocuments({
              familyId: $scope.familyId,
              forLoanApp: true,
              OrganisationId: $scope.adviserOrgId,
              searchName: $scope.searchDocumentFile || null,
            })
            .then((groupedDocResponse) => {
              $scope.isLoadingEmails.documents = false;
              $scope.documentListLoading = false;
              $scope.documentsList = groupedDocResponse.map((accordion) => {
                const documentList = accordion.DocumentsList.map((doc) => ({
                  ...doc,
                  Tags: doc.Tags ? trimTags(doc.Tags) : [],
                  isSettled: accordion.LoanStatusId || 0,
                  groupTitle: accordion.GroupTitle,
                }));

                return {
                  ...accordion,
                  DocumentsList: [...documentList],
                  isOpen: accordion.DocumentsList.length && isUsedToSearch,
                };
              });
              $scope.copyAllToData = $scope.documentsList.map((accordion) => ({
                groupTitle: accordion.GroupTitle,
                loanApplicationId: accordion.LoanApplicationId,
              }));
            });
        } else {
          contactService
            .getDocumentList(
              $scope.familyId,
              $scope.searchDocumentFile,
              $scope.adviserOrgId,
              true,
              $scope.isShowEsign,
            )
            .then((result) => {
              $scope.isLoadingEmails.documents = false;
              $scope.documentsList = addTagsToDocumentList(result.data);
              $scope.downloadAllDocsCount = countDownloadAllDocs(
                $scope.documentsList,
              );
              $scope.documentListLoading = false;
              $scope.tableParams = new NgTableParams(
                {
                  count: 5,
                  sorting: {},
                },
                {
                  counts: [],
                  total: $scope.documentsList.length,
                  getData($defer, params) {
                    let filterData = $scope.documentsList;

                    if (!_.isEmpty($scope.searchFilesText)) {
                      const regExp = new RegExp($scope.searchFilesText, 'i');
                      filterData = _.filter($scope.documentsList, (obj) => {
                        return (
                          String(obj.Name).match(regExp) ||
                          String(obj.Description).match(regExp) ||
                          String(obj.uploadUser.UserName).match(regExp) ||
                          String(
                            $filter('date')(obj.DateCreated, 'dd MMM yyyy'),
                          ).match(regExp) ||
                          String(obj.Tags).match(regExp)
                        );
                      });
                    }
                    params.total(filterData.length);
                    $defer.resolve(
                      filterData.slice(
                        (params.page() - 1) * params.count(),
                        params.page() * params.count(),
                      ),
                    );
                    $scope.documentsListLength = filterData.length;
                  },
                },
              );
              $scope.checkIfCanDownloadDocument();
            });
        }
      };
      contactSharedData.updateContactDocuments = $scope.loadDocumentList;
      $scope.rmoveDocument = function (documentId) {
        swal(
          {
            title: 'Please confirm action',
            text:
              'Are you sure you want to delete this document? This action cannot be undone',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#FA8E91',
            confirmButtonText: 'Delete Document',
            closeOnConfirm: true,
          },
          (isConfirm) => {
            if (isConfirm) {
              contactService
                .removeDocument($scope.familyId, documentId)
                .then(() => {
                  $scope.loadDocumentList();
                  toaster.pop('success', 'Deleted', 'File has been deleted');
                })
                .catch(displayError);
            }
          },
        );
      };

      // on watch

      if ($stateParams.adviserOrgId) {
        $scope.loadDocumentList();
      }

      // Search Document
      // -------------------------------------------------------------
      $scope.searchDocument = function ($event) {
        $scope.searchFilesText = $event.target.textContent;
        $scope.tableParams.reload();
      };

      // Clear Search Document
      // -------------------------------------------------------------
      $scope.clearSearchDocument = function () {
        $scope.searchFilesText = '';
        angular.element('.search-label').text($scope.searchFilesText);
        $scope.tableParams.reload();
      };

      const loadAllDocumentsProcedure = () => {
        $scope.loadDocumentList();
        if ($scope.isShowGdriveFeature()) {
          $scope.gdriveObject.methods.init();
        }
      };
      $scope.$watch('selectedContacts', (newValue) => {
        if (newValue === 'documents' && !$scope.isDocumentAlreadyLoaded) {
          $scope.isDocumentAlreadyLoaded = true;
          loadAllDocumentsProcedure();
        }
      });

      // Download document using DocumentGet
      downloadDocument({
        $scope,
        generalService,
        insuranceApplicationService,
        downloadDataService,
      });

      // don't remove this!, this is for corporate adviser users
      if ($stateParams.familyId && $stateParams.clientId) {
        loadAllDocumentsProcedure();
      }

      $scope.documentIsDuplicate = function (documentParam) {
        const dupeDoc = _.find($scope.documentsList, (o) => {
          return (
            o.DocumentId === documentParam.DocumentId &&
            o.DocFromLoanApp !== documentParam.DocFromLoanApp
          );
        });

        if (!dupeDoc) {
          return false;
        }
        return documentParam.DocFromLoanApp;
      };

      $scope.showSelectGdriveFolder = () => {
        $scope.gdriveObject.models.isShowNotAssignedFolder = true;

        const modalInstance = $uibModal.open({
          controller: 'GdriveFolderModalCtrl',
          controllerAs: 'vm',
          templateUrl: '/assets/views/contacts/partials/gdriveFolderModal.html',
          size: 'lg',
          windowTopClass: 'gdriveModal-class',
          resolve: {},
        });

        modalInstance.result.then(
          (object) => {
            $scope.gdriveObject.models.isShowNotAssignedFolder = false;
            $scope.selectedGdriveFolderId = object.gdriveFolderId;
            $scope.gdriveObject.models.hasChosenAFolder =
              object.isChosenAFolder;
            $scope.gdriveObject.methods.init();
          },
          () => {},
        );
      };

      contactService.getUserInfo().then((response) => {
        $scope.userInfo = response.data;
      });
      $window.authGdrive = function (code) {
        contactService.authGdrive(code).then(() => {
          $scope.showSelectGdriveFolder();
        });
      };

      $scope.accessType = ACCESS_TYPE;
      $scope.getDocumentInfo = (documentInfo) => {
        if (documentInfo.SharedWithCorporateTeamId) {
          return `Just my team (${$scope.userInfo.CorporateTeam})`;
        }
        return `All Corporate Users`;
      };
      $scope.setDocumentInfo = (documentObj) => {
        documentObj.SharedWithCorporateTeamId = documentObj.SharedWithCorporateTeamId
          ? 0
          : $scope.userInfo.CorporateTeamId;
        const obj = {
          Title: documentObj.Name,
          Tags: documentObj.Tags.join(),
          Description: documentObj.Description || '',
          DoucmentID: documentObj.DocumentId,
          FamilyID: $scope.familyId || 0,
          OrganisationId: $scope.adviserOrgId || 0,
          SharedWithCorporateTeamId: documentObj.SharedWithCorporateTeamId,
          UploadedByCorporateTeamId: null,
        };
        contactService.documentPropertySet([obj]).then(
          () => {
            toaster.pop(
              'success',
              'Success',
              `Successfully set sharing for ${$scope.getSharing(documentObj)}`,
            );
          },
          () => {
            toaster.pop('error', 'Failed to Share', 'Unable to set sharing.');
            documentObj.SharedWithCorporateTeamId = documentObj.SharedWithCorporateTeamId
              ? 0
              : $scope.userInfo.CorporateTeamId;
          },
        );
      };

      $scope.overrideByTeam = () => {
        if (!$scope.userInfo || !$scope.userInfo.CorporateTeamId) {
          return false;
        }
        const teamList = [CORPORATE_TEAM.COMPLIANCE];
        return teamList.includes($scope.userInfo.CorporateTeamId);
      };

      $scope.displaySharing = (obj) => {
        if ($scope.isClientDocuments) {
          return true;
        }
        if (!$scope.userInfo || !$scope.userInfo.CorporateTeamId) {
          return false;
        }
        const splittedTeam =
          obj.SharedWithCorporateTeamId &&
          obj.SharedWithCorporateTeamId.split(',');
        if (!splittedTeam || !splittedTeam.length) {
          return false;
        }
        const sharedByTeamList = splittedTeam.reduce((accum, value) => {
          accum.push(parseInt(value, 10));
          return accum;
        }, []);
        if (sharedByTeamList.includes(SHARING_VALUE.ALL_CORPORATE)) {
          return true;
        }
        if (sharedByTeamList.includes($scope.userInfo.CorporateTeamId)) {
          return true;
        }
        return false;
      };
      $scope.displayDeleteButton = (obj) => {
        if (
          $scope.isClientDocuments ||
          allowCorporateTeam($scope.userInfo, $scope.contactInfo)
        ) {
          return true;
        }
        if (!$scope.userInfo || !$scope.userInfo.FamilyId) {
          return false;
        }
        return (
          obj.uploadUser &&
          parseInt(obj.uploadUser.UserId, 10) ===
            parseInt($scope.userInfo.FamilyId, 10)
        );
      };

      getContactData($scope, corporateService);
    });
