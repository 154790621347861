const TO_STATE = {
  QUICKLMI: 'quickLMI',
  SERVICEABILITY: 'serviceability',
  PRODUCTFINDER: 'productFinder',
  FUNDINGCALCULATOR: 'fundingCalculator',
  ALIPORTAL: 'aliAccess',
  CONTACTS: 'contacts',
  TASKS: 'tasks',
  USERMANAGEMENT: 'userManagement',
  USERPROFILE: 'userProfile',
  USERFROMCC: 'userFromCC',
  CONTACTPIPELINE: 'contactFromPipeline',
  OPPORTUNITYCARD: 'opportunityCard',
  BROKERPEDIA: 'brokerPedia',
  PRICEFINDER: 'priceFinder',
  CONTACTRECENT: 'contactRecent',
  CONTACTBREADCRUMB: 'contactBreadCrumb',
  BREADCRUMBSIMPLE: 'breadcrumbSimple',
};

const CONTACT_TABS = {
  LOANS: 'loans',
  LENDING: 'lending',
};

const BOOL_STR = {
  TRUE: 'true',
};

const STATE = {
  BUSINESS_ACCOUNT: 'BusinessAccount',
  CONTACTS_SINGLE: 'contactsSingle',
};

export default class RenderDynamicLinkCtrl {
  constructor($state, uiService) {
    'ngInject';

    this.$state = $state;
    this.uiService = uiService;
  }

  $onInit() {
    const TO_STATE_ACTIONS = {
      [TO_STATE.QUICKLMI]: this.uiService.redirectToQuickLMICalculator,
      [TO_STATE.SERVICEABILITY]: () =>
        this.uiService.redirectToLoanProfiler('serviceability'),
      [TO_STATE.PRODUCTFINDER]: () =>
        this.uiService.redirectToLoanProfiler('productFinder'),
      [TO_STATE.FUNDINGCALCULATOR]: this.uiService.redirectToFundingCalculator,
      [TO_STATE.ALIPORTAL]: () =>
        this.uiService.checkAliAccess().then(() => this.backToDashboard()),
      [TO_STATE.CONTACTS]: this.uiService.routeContact,
      [TO_STATE.TASKS]: this.uiService.routeTask,
      [TO_STATE.USERMANAGEMENT]: this.uiService.redirectToUsers,
      [TO_STATE.USERPROFILE]: this.uiService.navigate,
      [TO_STATE.USERFROMCC]: () => {
        const { familyId, isEntity } = this.$state.params;
        this.uiService.redirectFromCustomerCare({ familyId, isEntity });
      },
      [TO_STATE.CONTACTPIPELINE]: () => {
        const { familyId, isClient } = this.$state.params;
        this.uiService.redirectBorrower({ familyId, isClient });
      },
      [TO_STATE.OPPORTUNITYCARD]: () => {
        const { familyId, loanScenarioId, loanId } = this.$state.params;
        this.uiService.redirectToOpportunity({
          familyId,
          loanScenarioId,
          loanId,
        });
      },
      [TO_STATE.BROKERPEDIA]: () => {
        this.uiService.brokerPediaTriggerClick(false);
      },
      [TO_STATE.PRICEFINDER]: () => {
        this.uiService.priceFinderTriggerClick(false);
      },
      [TO_STATE.CONTACTRECENT]: () => {
        const { familyId, contactType } = this.$state.params;
        const viewed = { FamilyId: familyId, ContactType: contactType };
        this.uiService.gotoContact(viewed);
      },
      [TO_STATE.CONTACTBREADCRUMB]: () => {
        const {
          familyId,
          isEntity: isEntityStr,
          isLinker: isLinkerStr,
          activeTab: activeTabParam,
        } = this.$state.params;
        const isEntity = isEntityStr === BOOL_STR.TRUE;
        const isLinker = isLinkerStr === BOOL_STR.TRUE;
        const activeTab =
          activeTabParam ||
          (isEntity ? CONTACT_TABS.LOANS : CONTACT_TABS.LENDING);
        this.uiService.redirectToContactDetails({
          familyId,
          activeTab,
          isLinker,
        });
      },
      [TO_STATE.BREADCRUMBSIMPLE]: () => {
        const { familyId, isEntity: isEntityStr } = this.$state.params;
        const isEntity = isEntityStr === BOOL_STR.TRUE;
        const activeTab = CONTACT_TABS.LENDING;
        const stateName = `app.${
          isEntity ? STATE.BUSINESS_ACCOUNT : STATE.CONTACTS_SINGLE
        }`;
        const config = { familyId, activeTab, isEntity };
        this.uiService.stateGo(stateName, config);
      },
    };

    const { toState: target } = this.$state.params;

    if (!TO_STATE_ACTIONS[target]) {
      this.backToDashboard();
      return 'State not found';
    }

    TO_STATE_ACTIONS[target]();
  }

  backToDashboard() {
    this.$state.go('app.dashboard');
  }
}
