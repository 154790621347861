import { COLOR } from 'Common/constants/colors';

export const LENDER_MAX_INDEX = 4;

export const ALL_VALUE = '0';

export const WIDGET_TITLE = {
  LENDER_SPLIT: 'Lender Split',
  AVG_LOAN_SIZE: 'Average Loan Size',
  SETTLEMENT_VOLUMES: 'Settlement Volumes',
  ENQUIRY_SOURCE: 'Enquiry Source',
};

export const WIDGET_ID = {
  LODGED: 32,
  APPROVED: 34,
  SETTLED: 42,
  LENDER_SPLIT_SMALL: 51,
  AVG_LOAN_SIZE: 53,
  SETTLEMENT_VOLUMES: 54,
  ENQUIRY_SOURCE: 55,
  REFERRALS_SENT: 56,
  REFERRALS_RECEIVED: 57,
  UPCOMING_FINANCE_DATES: 58,
  UPCOMING_SETTLEMENT_DATES: 59,
  INSURANCE_PIPELINE_STATUS: 60,
  INSURANCE_NOTIFICATIONS: 62,
  ESIGN_USAGE_REPORT: 63,
};

export const LENDER_SPLIT_SMALL = {
  TIME_PERIOD: '23',
  SPLIT_ON: '24',
  LENDER_CATEGORY: '25',
  ADVISERS: '3',
};

export const OPTION_ALL = {
  ADVISERS: {
    name: 'All Advisers',
    value: ALL_VALUE,
  },
  LENDER_CATEGORY: {
    name: 'All Categories',
    value: ALL_VALUE,
  },
  MEMBERS: {
    name: 'All Team Members',
    value: ALL_VALUE,
  },
};

export const LENDER_COLOR_CODES = [
  COLOR.VICTORIA,
  COLOR.BUTTER_CUP,
  COLOR.TERRACOTTA,
  COLOR.MARINER,
];

export const WIDGET_OPTION_VAL = {
  LENDER_CATEGORY: {
    ALL: '72',
    CUSTOM: '73',
    VAR_NAME: 'lenderCategoryList',
  },
  ADVISER: {
    ALL: '9',
    CUSTOM: '27',
    VAR_NAME: 'advisersDropdown',
  },
};

export const LENDER_SPLIT_ON = {
  COUNT: 70,
  VALUE: 71,
};

export const LENDER_SPLIT_PERIOD = {
  THIS_CY: 65,
  LAST_CY: 86,
  THIS_FY: 66,
  LAST_FY: 67,
  THIS_MONTH: 68,
  LAST_MONTH: 69,
};

export const LOAN_SIZE_PERIOD = {
  THIS_CY: 90,
  LAST_CY: 91,
  THIS_FY: 92,
  LAST_FY: 93,
  THIS_MONTH: 94,
  LAST_MONTH: 95,
};

export const SETTLEMENT_VOLUME_PERIOD = {
  CY: 96,
  FY: 97,
};

export const ENQUIRY_SOURCE_PERIOD = {
  THIS_CY: 98,
  LAST_CY: 99,
  THIS_FY: 100,
  LAST_FY: 101,
  THIS_MONTH: 102,
  LAST_MONTH: 103,
};

export const REFERRALS_SENT_RECEIVED_PERIOD = {
  THIS_WEEK: 104,
  LAST_WEEK: 105,
  THIS_MONTH: 106,
  LAST_MONTH: 107,
  THIS_CY: 108,
  THIS_FY: 109,
  LAST_CY: 110,
  LAST_FY: 111,
};

export const TARGET_BASED_PERIOD = {
  THIS_WEEK: 51,
  THIS_MONTH: 52,
  THIS_CY: 53,
  THIS_FY: 58,
};

export const TARGET_DD_MM = [
  TARGET_BASED_PERIOD.THIS_MONTH,
  TARGET_BASED_PERIOD.THIS_WEEK,
];

export const AVERAGE_LOAN_SIZE = {
  ADVISERS: '3',
  TIME_PERIOD: '26',
  LOAN_STATUS: '27',
};

export const SETTLEMENT_VOLUMES = {
  ADVISERS: '3',
  TIME_PERIOD: '28',
};

export const TARGET_BASED_TILE = {
  ADVISERS: 3,
  TIME_PERIOD: 17,
};

export const LOAN_STATUS_ON = {
  LODGED: 87,
  APPROVED: 88,
  SETTLED: 89,
};

export const LIST_LOAN_STATUS = [
  LOAN_STATUS_ON.LODGED,
  LOAN_STATUS_ON.APPROVED,
  LOAN_STATUS_ON.SETTLED,
];

export const LOAN_WEEKLY_DATA = [
  LOAN_SIZE_PERIOD.THIS_MONTH,
  LOAN_SIZE_PERIOD.LAST_MONTH,
];

export const ENQUIRY_SOURCE = {
  ADVISERS: '3',
  TIME_PERIOD: '29',
};

export const REFERRALS_SENT_RECEIVED = {
  TITLE: {
    [WIDGET_ID.REFERRALS_SENT]: 'Referrals Sent',
    [WIDGET_ID.REFERRALS_RECEIVED]: 'Referrals Received',
  },
  SUBTITLE: {
    [WIDGET_ID.REFERRALS_SENT]: 'Referral types sent to partners',
    [WIDGET_ID.REFERRALS_RECEIVED]: 'Referrals received by organisation',
  },
  ADVISERS: '3',
  TIME_PERIOD: '30',
};

export const CHART_SERIES = {
  LOAN_AMT: ['Target', 'Loan Amount'],
};

export const DATE_INT = {
  WEEK: 52,
  MONTH: 12,
};
