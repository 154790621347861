import angular from 'angular';
import loanBasicDetails from './loanBasicDetails';
import loanDetailsImportantDates from './loanDetailsImportantDates';
import loanFactFind from './loanFactFind';
import loanSecurityDetails from './loanSecurityDetails';
import loanThreeDotsMenu from './loanThreeDotsMenu';

export default angular
  .module('components.loanCompactDetails.components', [])
  .component('loanBasicDetails', loanBasicDetails)
  .component('loanDetailsImportantDates', loanDetailsImportantDates)
  .component('loanFactFind', loanFactFind)
  .component('loanSecurityDetails', loanSecurityDetails)
  .component('loanThreeDotsMenu', loanThreeDotsMenu).name;
