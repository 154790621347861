import { CONFIG_EMAIL } from 'Common/config/email';
import { FACT_FIND_TYPE } from 'Common/constants/factFind';
import OpenOnlineFactFindForm from 'Components/usersSubscription/openOnlineFactFindForm';
import { TICKABLE_ITEMS } from 'Common/constants/tickableItems';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';

export default class FactFindSidebarCtrl {
  constructor(
    $state,
    $uibModal,
    currentUserService,
    contactModelService,
    onlineFactFindModal,
    usersSubscriptionService,
    onlineFactFindService,
    loanScenarioService,
    contactService,
    configService,
    uiService,
    helloBookPreviewService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$uibModal = $uibModal;
    this.currentUserService = currentUserService;
    this.contactModelService = contactModelService;
    this.onlineFactFindModal = onlineFactFindModal;
    this.usersSubscriptionService = usersSubscriptionService;
    this.onlineFactFindService = onlineFactFindService;
    this.loanScenarioService = loanScenarioService;
    this.contactService = contactService;
    this.configService = configService;
    this.uiService = uiService;
    this.factFindType = FACT_FIND_TYPE;
    this.helloBookPreviewService = helloBookPreviewService;
  }

  $onInit() {
    const {
      splitOnlineFactFind,
      reviewFactFind,
      lendingLoanAppFactFindUIImprovements,
    } = this.configService.feature;
    this.splitOnlineFactFind = splitOnlineFactFind || 0;
    this.reviewFactFind = reviewFactFind || 0;
    this.showFactFindUIImprovements = this.isOpportunity
      ? !!this.uiService.viewOfAfileTurnedOn
      : !!lendingLoanAppFactFindUIImprovements;
    this.disabledShowModal = true;
    this.borrowers = [];

    this.isReviewFactFindShow = false;
    this.isBrandingReady = false;
    this.isLoadingData = true;
    this.showSplitOnlineFF = this.splitOnlineFactFind;
    this.supportEmail = this.currentUserService.isAU
      ? CONFIG_EMAIL.MYCRM_SUPPORT
      : CONFIG_EMAIL.ADVISER_SERVICES_NZ;
    this.getClientInfo();
    this.getAllocatedAdviserInfo(true);
  }

  checkFactFindFeature() {
    if (this.splitOnlineFactFind) {
      this.openGenerateFactFindModal();
    } else {
      this.openOnlineFactFindForm();
    }
  }

  callFactFindModal(type, loanId, isShowReviewButton) {
    if (this.uiService.newHelloModalEnabled && type !== FACT_FIND_TYPE.REVIEW) {
      this.helloBookPreviewService
        .open({
          familyId: this.currentFamilyId,
          isForLoanWriters: null,
          isNewModal: true,
          loanApplicationId: this.loanAppId,
          selectedService: '',
          onlyFactFind: true,
        })
        .then(() => {
          this.getClientInfo();
          this.getAllocatedAdviserInfo();
        });
    } else {
      this.onlineFactFindModal
        .open({
          loanId,
          type,
          isShowReviewButton,
        })
        .then((response) => {
          if (!response) {
            return;
          }
          const { factFindSend, factFindType } = response;
          if (factFindSend) {
            this.getClientInfo();
            this.getAllocatedAdviserInfo();
          } else if (!factFindSend && factFindType) {
            this.openGenerateFactFindModal(factFindType);
          }

          if (type === FACT_FIND_TYPE.GENERAL) {
            this.getAllocatedAdviserInfo();
          }
        });
    }
  }

  openGenerateFactFindModal(type = this.factFindType.GENERAL) {
    const {
      loanAppId: loanId,
      isReviewFactFindShow: isShowReviewButton,
    } = this;

    if (
      this.showPopUp &&
      !this.openFactFind &&
      (type === FACT_FIND_TYPE.GENERAL || type === FACT_FIND_TYPE.INVITE)
    ) {
      this.openInterceptModal(type);
      return;
    }
    this.callFactFindModal(type, loanId, isShowReviewButton);
  }

  openInterceptModal(type = this.factFindType.GENERAL) {
    const { familyId } = this;
    const props = {
      familyId,
    };
    const modalInstance = this.$uibModal.open({
      template: `<intercept-bank-connect
                modal-instance="vm.modalInstance"
                family-id="vm.props.familyId"
                >
              </intercept-bank-connect>`,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'intercept-bank-connect',
      controller: 'CommonModalPlaceholderCtrl',
      controllerAs: 'vm',
      resolve: {
        props,
      },
    });
    modalInstance.result.then((response) => {
      if (!response) {
        return;
      }
      this.openFactFind = response;
      this.openGenerateFactFindModal(type);
    });
  }

  openOnlineFactFindForm() {
    const { loanAppId: loanId } = this;
    this.$uibModal.open({
      templateUrl:
        'assets/views/profileManagement/Users/openOnlineFactFindForm.html',
      controller: OpenOnlineFactFindForm,
      controllerAs: 'vm',
      resolve: {
        loanId: () => loanId,
      },
      size: 'lg',
      windowClass: 'online-fact-find-form',
    });
  }

  getAllocatedAdviserInfo(isBankConnectPopUp = false) {
    const { intercept } = this.configService.feature;
    const { loanAppId } = this;
    this.loanScenarioService
      .scenarioDetailsGet(loanAppId)
      .then(({ data }) => {
        if (!data) {
          return;
        }
        this.LoanApplicationDetail = data;
        this.familyId = parseInt(this.LoanApplicationDetail.AdviserId, 10);
        this.getAdviserBranding();
        const { accessType } = this.currentUserService;
        const isAdviser = !!(
          accessType === ACCESS_TYPE.PRINCIPAL_ADVISER ||
          accessType === ACCESS_TYPE.ADVISER
        );
        isAdviser &&
          intercept &&
          isBankConnectPopUp &&
          this.getSubscriptionPopUp();
        this.borrowers = this.LoanApplicationDetail.SharedTo || [];
      })
      .catch(() => this.setLoadingData(false));
  }

  getSubscriptionPopUp() {
    const params = {
      brokerId: this.familyId,
      tickedItemId: TICKABLE_ITEMS.INTERCEPT,
    };
    this.contactModelService.getSubscriptionPopUp(params).then((data) => {
      const { showPopUp } = data;
      this.showPopUp = !!showPopUp;
    });
  }

  getClientInfo() {
    const { loanAppId } = this;
    this.onlineFactFindModal
      .getInvolvePartyToReview(loanAppId)
      .then((response) => {
        const hasClients =
          response && response.clients && response.clients.length;
        this.isReviewFactFindShow = hasClients || false;
      });
  }

  showReviewFactFind() {
    return this.reviewFactFind && this.isReviewFactFindShow;
  }

  toggleLockStatus(app) {
    this.onlineFactFindService.toggleLockStatus(app);
  }

  getAdviserBranding() {
    this.contactModelService
      .getFamilyAdviserTheme(this.currentFamilyId)
      .then((data) => {
        this.isBrandingReady =
          !data.isBYOB || !!(data && data.logoDocId && data.tradingName);
        this.setLoadingData(false);
      })
      .catch(() => this.setLoadingData(false));
  }

  setLoadingData(bool) {
    this.isLoadingData = bool;
  }
}
