import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { openVideoModal } from 'Common/utilities/trainingVideoModal';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import { toastSaveSuccess, toastError } from 'Common/utilities/alert';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { get } from 'lodash';
import { parseToInt10 } from 'Common/utilities/parse';
import { catchError } from 'Common/utilities/promise';

export const isCompleteCPA = ({ $scope, completion }) => {
  const { isAU, isNZ } = $scope.currentUserService;
  const CPA = [
    SECTION_ID.APPLICANTS,
    SECTION_ID.CUSTOMER_OBJECTIVES,
    SECTION_ID.FINANCIAL_POSITION,
    SECTION_ID.LOAN_FUNDING_DETAILS,
    SECTION_ID.INSURANCE,
  ];

  if (isAU || isNZ) {
    const hasNotCompleted = CPA.some((id) => {
      return !completion[id].IsCompleted;
    });
    const isValidSectionsWithFlag =
      !$scope.displayPossibleAdverseChanges ||
      completion[SECTION_ID.POSSIBLE_ADVERSE].IsCompleted;
    return !hasNotCompleted && isValidSectionsWithFlag;
  }

  return true;
};

export const getInvestment = (data) => {
  const selectedPurpose = data.filter((item) => {
    return item.QuestionId === 5;
  });

  if (selectedPurpose.length) {
    if (!selectedPurpose[0].answerInt) {
      return false;
    }
    const checkInvestment = selectedPurpose[0].answerInt.filter((o) => {
      return o === 3;
    });
    return checkInvestment.length > 0;
  }
  return false;
};

export const getDateType = (dateType) => {
  const selectOptions = {
    [`Estimated Settlement Date`]: 'settlementDate',
    [`Finance Date`]: 'financeDate',
    [`Auction Date`]: 'auctionDate',
  };
  return dateType ? selectOptions[dateType] : '';
};

export const getShowSidebarWidgets = (obj) => {
  const { assetFinance, currentUserService, response } = obj;
  const { isID } = currentUserService;
  const lendingCategoryId = get(response, 'data.LendingCategoryId');

  return assetFinance
    ? !isID && lendingCategoryId !== LENDING_CATEGORY.ASSET_FINANCE
    : !isID;
};

export const openDemoVideoModal = (size, $uibModal, currentUserService) => {
  if (currentUserService.isNZ) {
    openVideoModal($uibModal, PAGE_SOURCE.LOAN_APP_1);
    return true;
  }

  $uibModal.open({
    templateUrl: '/assets/views/partials/demo_multi_video_modal.html',
    controller: 'LoanAppVideoDemoCtrl',
    size,
    windowClass: 'demo-video-modal-window-class multi-demo-video',
  });
  return false;
};

export function embedLoanAppOnOpportunities({
  $scope,
  $state,
  $rootScope,
  $stateParams,
  uiService,
  currentUserService,
}) {
  const isMissingRequireParams =
    uiService.viewOfAfileTurnedOn &&
    (!$state.params.opportunityId || !$state.params.sourceFamilyId);

  if (isMissingRequireParams || !uiService.viewOfAfileTurnedOn) {
    return;
  }

  $scope.crmCountry =
    $scope.crmCountry ||
    $rootScope.crmCountry ||
    currentUserService.brokerRegionalization.name;
  $scope.crmCountryID =
    $scope.crmCountryID ||
    $rootScope.crmCountryID ||
    currentUserService.brokerRegionalization.countryID;

  $scope.loanAppId = $state.params.opportunityId;
  $scope.familyId = $state.params.sourceFamilyId;

  $stateParams.loanAppId = $scope.loanAppId;
  $stateParams.familyId = $scope.familyId;

  $state.params.loanAppId = $scope.loanAppId;
  $state.params.familyId = $scope.familyId;
}

export const onAssistantSelect = ({
  $rootScope,
  $scope,
  $state,
  loanScenarioService,
}) => (assistantFamilyId) => {
  const invalidParams = !$scope || !$state || !loanScenarioService;
  if (invalidParams) {
    return;
  }
  if (!assistantFamilyId && assistantFamilyId !== 0) {
    return;
  }
  loanScenarioService
    .assignAssistant($state.params.loanAppId, { assistantFamilyId })
    .then(() => {
      $scope.LoanApplication = {
        ...$scope.LoanApplication,
        AssistantFamilyId: assistantFamilyId,
      };
      toastSaveSuccess();
      $scope.loanAppEmailTemplateNZ && $rootScope.$emit('EDIT_ADVISER_EMAIL');
    })
    .catch(toastError);
};

export const setCompletionStatus = ({
  $timeout,
  sectionId,
  loanAppSharedData,
  isValidSection,
}) => {
  const sectionStatus = loanAppSharedData.sectionsCompletionStatus[sectionId];
  if (sectionStatus && sectionStatus.isCompleted === isValidSection) {
    return;
  }

  const timerDelay = sectionStatus ? 500 : 1500;

  loanAppSharedData.setCompletionStatusTimeout[sectionId] &&
    $timeout.cancel(loanAppSharedData.setCompletionStatusTimeout[sectionId]);

  const completionStatusCallback = () => {
    const sectionObject = loanAppSharedData.getSectionObjectById(
      loanAppSharedData.completionListObject,
      sectionId,
    );

    loanAppSharedData.completionStatusSet(
      sectionId,
      sectionObject.IsCompleted,
      isValidSection,
    );
  };

  loanAppSharedData.setCompletionStatusTimeout[sectionId] = $timeout(
    () => completionStatusCallback(),
    timerDelay,
  );
};

export const getPossibleAdverseAnswers = ({
  $scope,
  loanApplicationServices,
}) => () => {
  loanApplicationServices
    .getPossibleAdverseChanges({
      familyId: $scope.familyId,
      questionId: 0,
      loanApplicationId: parseToInt10($scope.loanAppId),
    })
    .then((response) => {
      $scope.loanAppSharedData.isPossibleAdverseStarted = !!response.length;
    })
    .catch((error) => catchError(error, 'LoanAppUtil'));
};
