import template from './mailTemplates.html';
import MailTemplatesCtrl from './mailTemplatesCtrl';
import './style.scss';

export default {
  template,
  controller: MailTemplatesCtrl,
  controllerAs: 'vm',
  bindings: {
    newTemplateContent: '<',
    showDefaultTemplateSettings: '<',
    onTemplatesUpdated: '&',
  },
};
