import angular from 'angular';
import { get } from 'lodash';
import {
  INSURANCE_TABS,
  CUSTOMER_CARE_TYPES,
} from 'Common/constants/customerCareModules';
import {
  checkIsSending,
  addMergeFieldsCategory,
} from 'Common/utilities/customerCare';
import { checkSingleOrBulkEmailUtil } from './util/fixedRateSendEmailModalCtrl';
import {
  initEmailRecipients,
  setEmailObject,
} from './util/customerCareEmailUtil';

export const loadModule = () =>
  angular.module('app').controller(
    'FixedRateSendEmailModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function FixedRateSendEmailModalCtrl(
      $scope,
      $rootScope,
      $uibModalInstance,
      $uibModal,
      $state,
      $controller,
      toaster,
      corporateService,
      contactService,
      customerCareService,
      customerCareInsuranceService,
      emailTemplatesService,
      ckEditorConfigService,
      fixedRateListTable,
      fixedRateObj,
      source,
      tab,
      isInsurance,
      formErrorHandlerServices,
      currentUserService,
      customerCareTemplateService,
      corporateModelService,
    ) {
      const vm = this;
      angular.extend(
        vm,
        $controller('CustomerCareSendEmailModalBaseCtrl', { $scope }),
      );
      $scope.mergeFieldsParams = fixedRateObj;
      $scope.recipients = {
        email: initEmailRecipients(fixedRateObj),
      };

      $scope.checkSingleOrBulkEmail = checkSingleOrBulkEmailUtil({
        $scope,
        currentUserService,
        fixedRateObj,
        isInsurance,
        source,
        tab,
        fixedRateListTable,
      });

      $scope.checkSingleOrBulkEmail();

      $scope.getRecipientAutoSuggestion = (userInput) => {
        contactService
          .getRecipientAutoSuggestion(userInput)
          .then(({ data }) => {
            $scope.selectEmailList = [];

            if (!data) {
              return;
            }

            $scope.withEmail = data;

            $scope.selectEmailList = $scope.withEmail.reduce((accum, curr) => {
              const { Name: FamilyFullname, EmailAddress } = curr;

              if (!EmailAddress) {
                return accum;
              }

              return accum.concat({ FamilyFullname, EmailAddress });
            }, []);
          })
          .catch(() => {
            $scope.selectEmailList = [];
          });
      };

      const getMergeFields = () => {
        $scope.preferredName = '';
        if (typeof fixedRateObj.familyID !== 'undefined') {
          $scope.familyId = fixedRateObj.familyID;
        } else {
          $scope.familyId = 0;
        }
        if (typeof fixedRateObj.loanID !== 'undefined') {
          $scope.loanId = fixedRateObj.loanID;
        } else {
          $scope.loanId = 0;
        }

        $scope.clientId = 0;
        const { FamilyID, LoanID, LoanStructureID } = fixedRateObj;

        corporateModelService
          .mergeFieldsGet({
            clientId: $scope.clientId,
            FamilyID,
            LoanID,
            LoanStructureID,
            policyId: false,
            isSMS: false,
          })
          .then((response) => {
            $scope.emailMergeFieldList = response.data;
            $scope.mergeFieldsObj = response.data[0];
            $scope.mergeFields = [];
            $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
            for (let k = 0; k < $scope.mergeFields.length; k++) {
              if ($scope.mergeFields[k].Name === 'Full Name') {
                $scope.preferredName = $scope.mergeFields[k].Value;
              }
            }

            $scope.getEmailTemplates();
            $scope.getDefaultTemplate();
          });
      };

      getMergeFields();

      $rootScope.getEmailTemplatesFromEmailPage = () => {
        $scope.getEmailTemplates('');
      };
      $scope.selectedTemplate = {
        UserTemplateID: 0,
        UserTemplateName: 'Blank',
        UserTemplateEmailSubject: '',
      };
      $scope.refreshResults = (select) => {
        const search = select.search;

        $scope.getRecipientAutoSuggestion(search);
      };

      $scope.sendEmail = (form) => {
        const isFormValid = formErrorHandlerServices.sendEmailFormValidation(
          form,
        );
        if (!isFormValid) {
          return;
        }

        $scope.recipientsEmailArray = [];
        angular.forEach($scope.recipients.email, (item) => {
          $scope.recipientsEmailArray.push(item);
        });
        $scope.email.ClientList = $scope.recipientsEmailArray;
        $scope.email.CustomerCareType = isInsurance
          ? tab.type
          : get(tab, 'type', CUSTOMER_CARE_TYPES.FIXED_RATE_EXPIRES);
        $scope.email.IsComplete = $scope.isComplete;
        $scope.email = addMergeFieldsCategory($scope.email);

        const insuranceParam =
          isInsurance && tab.type === INSURANCE_TABS[1].type
            ? { tableRecordCsvId: fixedRateObj.tableRecordIDCSV }
            : {};
        setEmailObject($scope);
        const sendEmail = isInsurance
          ? customerCareInsuranceService.sendEmail($scope.email, insuranceParam)
          : customerCareService.CustomerCareEmailSendv2($scope.email);

        checkIsSending({ $scope, isSending: true });
        sendEmail.then((fixedRateRes) => {
          checkIsSending({ $scope, isSending: false });
          if (fixedRateRes.data && fixedRateRes.data.length) {
            const fixedRateEmailValid = customerCareTemplateService.customerCareResponseMsg(
              fixedRateRes.data,
              'EMAIL',
            );
            if (fixedRateEmailValid) {
              $scope.email.EmailSubject = '';
              $scope.email.EmailBody = '';
              form.$setPristine(true);
            }
            $uibModalInstance.close({ success: true });
          }
        });
      };

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
    },
  );
