import { get, omit } from 'lodash';
import { LMI_NAME } from 'Common/constants/lmi';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { toastError } from 'Common/utilities/alert';
import { isValueNegligible } from 'Common/utilities/math';
import { validateFundingDetailsSection } from './security';

export const confirmChangeProduct = (crmConfirmation) => {
  return new Promise((resolve) =>
    crmConfirmation.open({
      type: 'warning',
      title: 'Changing products will reset fees',
      description:
        "Any fee changes you've made on the current selected product will be lost",
      buttonText: 'Yes, select new product',
      onConfirm: () => {
        resolve(true);
      },
      showCloseButton: false,
      showCancelButton: true,
      modalSize: 'md',
      cancelButtonClass: 'colored',
    }),
  );
};

const filterFees = (fees, loanStructureId) => {
  if (!fees || !fees.length) {
    return [];
  }
  return fees.filter((fee) => fee.loanStructureId === loanStructureId);
};

export const calculateCqpTotalFees = (fees = []) =>
  fees.reduce((acc, curr) => acc + (curr.isChecked ? curr.amount : 0), 0);

export const setCqpTotalFees = ($scope, loanAppSharedData, facility, fees) => {
  const loanStructureId = facility.LoanStructureId;

  const totalFee = calculateCqpTotalFees(filterFees(fees, loanStructureId));
  facility.structureGeneralFeeObject = { TotalFee: totalFee };

  loanAppSharedData.SAVE_FUNDING_PARAMETER = {
    fundsId: 0,
    fundingCalParametersId: 0,
    name: 'Lenders Fees',
    value: totalFee,
  };
};

export const toggleFeesHasManuallyChanges = (feesList, facility) => {
  const hasAnyFeeUpdated = feesList.some(
    (fee) => fee.amount !== fee.originalAmount,
  );
  facility.hasManuallyChanged = hasAnyFeeUpdated;
};

export const feeChanges = (
  $scope,
  loanScenarioModelService,
  loanAppSharedData,
) => (facility, fee, amount) => {
  const updatedFee = { ...fee, amount };

  $scope.setCQPManuallyUpdated();

  const loanStructureId = facility.LoanStructureId;

  const findSplitFees = filterFees($scope.feesList, loanStructureId);

  const updatedFees = findSplitFees.map((v) => {
    if (v.feeId === fee.feeId) {
      v.amount = parseFloat(amount);
    }
    return v;
  });

  setCqpTotalFees($scope, loanAppSharedData, facility, updatedFees);

  try {
    loanScenarioModelService
      .updateCqpDynamicFee($scope.loanAppId, fee.feeId, updatedFee)
      .then(() => {
        toggleFeesHasManuallyChanges(updatedFees, facility);
      })
      .catch(toastError);
  } catch (error) {
    setCqpTotalFees($scope, loanAppSharedData, facility, $scope.feesList);
    toggleFeesHasManuallyChanges($scope.feesList, facility);
  }
};

export const getPayableWhen = (fee, productFeesList = []) => {
  const prodFee = productFeesList.find(
    (item) => item.description === fee.FeesName,
  );
  return prodFee && prodFee.payableWhen;
};

export const feesSet = (fees, loanScenarioModelService, loanAppId) => {
  return Promise.all(
    fees.map((fee) =>
      loanScenarioModelService.addCqpDynamicFee(loanAppId, fee),
    ),
  )
    .then(() => {
      return loanScenarioModelService.getCqpDynamicFee(loanAppId, true);
    })
    .catch(toastError);
};

export const feesDelete = (feeId, loanAppId, loanScenarioModelService) => {
  return loanScenarioModelService.deleteCqpDynamicFee(loanAppId, feeId);
};

export const deleteAllFees = (
  $scope,
  loanScenarioModelService,
  loanStructureId,
) => {
  const { feesList, loanAppId } = $scope;

  if (!feesList || !feesList.length) {
    return Promise.resolve();
  }

  const findSplitFees = filterFees(feesList, loanStructureId);
  const findCustomAddedFees = $scope.feesList.filter(
    (value) => !value.loanStructureId,
  );
  const fees = [...findSplitFees, ...findCustomAddedFees];
  const toRemoveFeeIds = fees.map((fee) => fee.feeId);
  return Promise.all(
    fees.map((fee) =>
      feesDelete(fee.feeId, loanAppId, loanScenarioModelService),
    ),
  )
    .then(() => {
      $scope.feesList = feesList.filter(
        (value) => !toRemoveFeeIds.includes(value.feeId),
      );
      $scope.validateLoanDetails();
    })
    .catch(toastError);
};

export const toggleFee = (
  $scope,
  productFinderEditEnhancements,
  loanScenarioService,
  loanScenarioModelService,
  loanAppSharedData,
) => (facility, feeId, isChecked) => {
  const loanStructureId = facility.LoanStructureId;
  if (!productFinderEditEnhancements) {
    const findSplitFees = filterFees($scope.feesList, loanStructureId);
    const originalFees = {
      ...facility.structureGeneralFeeObject,
      productFee: [...findSplitFees],
    };
    loanScenarioService
      .LenderFeesSet(originalFees)
      .then(({ data }) => {
        if (data === 1) {
          $scope.setCQPManuallyUpdated();
          $scope.feesGet(
            facility.SelectedFavouriteProductData.ProductID,
            facility.BrokerEventId,
            facility.FavouriteProductData[0].LoanStructureID,
            facility.LoanStructureId,
            $scope.loanAppId,
            false,
            facility,
          );
        }
      })
      .catch(toastError);
  } else {
    loanScenarioModelService
      .toggleCqpDynamicFee($scope.loanAppId, feeId, !!isChecked)
      .then(() => {
        setCqpTotalFees($scope, loanAppSharedData, facility, $scope.feesList);
      })
      .catch(toastError);
  }
};

const toggleManualAndTotalFees = (
  $scope,
  loanAppSharedData,
  facility,
  allFees,
  findSplitFees,
) => {
  $scope.feesList = allFees;

  toggleFeesHasManuallyChanges(findSplitFees, facility);
  setCqpTotalFees($scope, loanAppSharedData, facility, allFees);
  facility.feesLoading = false;
};

export const feesGet = (
  $scope,
  productFinderEditEnhancements,
  loanScenarioService,
  loanAppSharedData,
  loanScenarioModelService,
  loanApplicationServices,
) => (
  productId,
  brokerEventId,
  loanStructureId,
  loanFacilityId,
  loanAppId,
  showLoader = true,
  facility,
  feesList,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  facility.feesLoading = !!showLoader;
  return loanScenarioService
    .LenderFeesGet(productId, brokerEventId, loanStructureId, loanAppId)
    .then(({ data: originalFees }) => {
      if (productFinderEditEnhancements) {
        new Promise((resolve) => {
          if (typeof feesList === 'undefined') {
            resolve(loanScenarioModelService.getCqpDynamicFee(loanAppId, true));
          } else {
            resolve(feesList);
          }
        }).then((fees) => {
          const findSplitFees = filterFees(fees, loanFacilityId);
          if (!findSplitFees.length) {
            loanApplicationServices
              .getProductFeesList()
              .then(({ data: productFeesList }) => {
                const formattedProductFees = originalFees.productFee.map(
                  (fee) => {
                    return {
                      description: fee.FeesName,
                      amount: fee.Amount,
                      payableWhen: getPayableWhen(fee, productFeesList),
                      loanStructureId: loanFacilityId,
                    };
                  },
                );

                feesSet(
                  formattedProductFees,
                  loanScenarioModelService,
                  loanAppId,
                ).then((allFees) => {
                  toggleManualAndTotalFees(
                    $scope,
                    loanAppSharedData,
                    facility,
                    allFees,
                    findSplitFees,
                  );
                });
              });
          } else {
            const allFees = [...fees];
            toggleManualAndTotalFees(
              $scope,
              loanAppSharedData,
              facility,
              allFees,
              findSplitFees,
            );
          }
        });
      } else {
        if (!$scope.feesList) {
          $scope.feesList = [];
        }
        const { productFee, ...structureGeneralFeeObject } = originalFees;
        const fillLoanStructureIds = productFee.map((fee) => {
          fee.loanStructureId = loanFacilityId;
          return fee;
        });

        const removeExistingFees = $scope.feesList.filter(
          (fee) => fee.loanStructureId !== loanFacilityId,
        );

        $scope.feesList = [...removeExistingFees, ...fillLoanStructureIds];

        facility.structureGeneralFeeObject = structureGeneralFeeObject;

        loanAppSharedData.SAVE_FUNDING_PARAMETER = {
          fundsId: 0,
          fundingCalParametersId: 0,
          name: 'Lenders Fees',
          value: originalFees.TotalFee,
        };
        facility.feesLoading = false;
      }
    });
};

export const getAllProductOfAllFacilities = (
  $scope,
  productFinderEditEnhancements,
  loanScenarioModelService,
  loanProfilerService,
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => (facilities = [], feesAlreadySet = false) => {
  $scope.feesList = [];

  return new Promise((resolve) => {
    if (productFinderEditEnhancements && facilities.length) {
      loanScenarioModelService
        .getCqpDynamicFee($scope.loanAppId, true)
        .then((fees) => {
          $scope.feesList = fees;
          resolve();
        })
        .catch(toastError);
    } else {
      resolve();
    }
  }).then(() => {
    facilities.map((facility) => {
      facility.FavouriteProductData = [];
      facility.SelectedFavouriteProductData = {};
      facility.TotalFees = 0;
      if (!facility.BrokerEventId) {
        return facility;
      }

      loanProfilerService
        .getSelectedProductDetailsGetByLoanStructure(facility.BrokerEventId)
        .then(({ data: productData }) => {
          facility.FavouriteProductData = productData;
          if (facility.FavouriteProductData[0]) {
            const selectedProducts =
              (facility &&
                facility.FavouriteProductData &&
                facility.FavouriteProductData.length &&
                facility.FavouriteProductData[0] &&
                facility.FavouriteProductData[0].SelectedProduct) ||
              [];

            const recommendedProduct = selectedProducts.find(
              (prod) => prod.IsRecommended,
            );

            if (recommendedProduct) {
              facility.SelectedFavouriteProductData = recommendedProduct;
              if (facility.SelectedFavouriteProductData.NewInitialRate === 0) {
                facility.SelectedFavouriteProductData.NewInitialRate = '';
              }
              if (facility.SelectedFavouriteProductData.NewOngoingRate === 0) {
                facility.SelectedFavouriteProductData.NewOngoingRate = '';
              }
              !feesAlreadySet &&
                $scope.feesGet(
                  recommendedProduct.ProductID,
                  facility.BrokerEventId,
                  facility.FavouriteProductData[0].LoanStructureID,
                  facility.LoanStructureId,
                  $scope.loanAppId,
                  true,
                  facility,
                  $scope.feesList,
                );
            }
          }
          $scope.validateLoanDetails();
          return facility;
        });

      return facility;
    });
  });
};

export const isOpenChooseProductsModal = (
  hasSavedProductComparison,
  countFavoriteProducts,
) => {
  return hasSavedProductComparison && !countFavoriteProducts;
};

export const chooseProductButtonText = (hasSavedProductComparison) => (
  countFavoriteProducts,
) => {
  if (
    isOpenChooseProductsModal(hasSavedProductComparison, countFavoriteProducts)
  ) {
    return 'Choose Products';
  }

  return countFavoriteProducts ? 'Edit Products' : 'Launch Product Finder';
};

export const showLoadSavedComparisonButton = (hasSavedProductComparison) => (
  countFavoriteProducts,
) => hasSavedProductComparison && countFavoriteProducts;

export const deleteFavoriteProduct = (
  $scope,
  loanProfilerService,
  loanScenarioModelService,
  productFinderEditEnhancements,
  ctrlUtils,
  errorToast,
) => (facility, product) => {
  const postData = {
    brokerEventID: facility.BrokerEventId,
    ProductID: product.ProductID,
    LenderID: product.LenderID,
    loanStructureID: facility.FavouriteProductData[0].LoanStructureID,
    IsFavourite: false,
  };

  // Remove the other product (Put false to their IsFavourite property)
  return loanProfilerService
    .setFavouriteProduct(postData)
    .then((response) => {
      if (response.data === 1) {
        if (product.IsRecommended && productFinderEditEnhancements) {
          $scope.setCQPManuallyUpdated();
          ctrlUtils
            .deleteAllFees(
              $scope,
              loanScenarioModelService,
              facility.LoanStructureId,
            )
            .then(() => {
              $scope.getFacilityFavoriteProductList(facility);
              $scope.validateLoanDetails();
            })
            .catch(toastError);
        } else {
          $scope.getFacilityFavoriteProductList(facility);
        }
        $scope.validateChanges && $scope.validateChanges();
      } else {
        errorToast();
      }
    })
    .catch(toastError);
};

export const deleteAllFavoriteProducts = (
  $scope,
  loanProfilerService,
  loanScenarioModelService,
  productFinderEditEnhancements,
  ctrlUtils,
) => (facility) => {
  const products =
    isValidObjectValue(
      () => facility.FavouriteProductData[0].SelectedProduct,
    ) || [];

  if (!products.length) {
    return Promise.resolve(false);
  }

  return Promise.all(
    products.map((product) => {
      const postData = {
        brokerEventID: facility.BrokerEventId,
        ProductID: product.ProductID,
        LenderID: product.LenderID,
        loanStructureID:
          isValidObjectValue(
            () => facility.FavouriteProductData[0].LoanStructureID,
          ) || 0,
        IsFavourite: false,
      };

      return loanProfilerService.setFavouriteProduct(postData);
    }),
  )
    .then((results) => {
      const hasFailed = results.some((result) => result && !result.data);
      if (hasFailed) {
        return Promise.resolve(false);
      }

      const hasSelectedRecommendedProduct = products.some(
        (product) => product.IsRecommended,
      );
      $scope.validateLoanDetails && $scope.validateLoanDetails();
      return hasSelectedRecommendedProduct && productFinderEditEnhancements
        ? ctrlUtils
            .deleteAllFees(
              $scope,
              loanScenarioModelService,
              facility.LoanStructureId,
            )
            .then(() => {
              $scope.validateLoanDetails && $scope.validateLoanDetails();
              return true;
            })
            .catch(toastError)
        : Promise.resolve(true);
    })
    .catch(toastError);
};

export const productComparisonModalResultCallback = ($scope, facility) => (
  result,
) =>
  result &&
  result.isRefresh &&
  $scope.getFacilityFavoriteProductList(facility, false);

export const productFinderModalResultCallback = (
  $scope,
  loanScenarioModelService,
  productFinderEditEnhancements,
  ctrlUtils,
) => (facility, isDeleteAllFees) => {
  if (productFinderEditEnhancements && isDeleteAllFees) {
    $scope.setCQPManuallyUpdated();
    ctrlUtils
      .deleteAllFees($scope, loanScenarioModelService, facility.LoanStructureId)
      .then(() => {
        facility.structureGeneralFeeObject = {
          TotalFee: 0,
        };
        $scope.validateLoanDetails();
        $scope.getFacilityFavoriteProductList(facility);
      })
      .catch(toastError);
  } else {
    $scope.getFacilityFavoriteProductList(facility);
  }
};

export const fundingSummaryDefaultMapper = (fundingObj = {}) => {
  return {
    ...fundingObj,
    surplus:
      (isValueNegligible(fundingObj.Surplus) ? 0 : fundingObj.Surplus) || 0,
    deficit:
      (isValueNegligible(fundingObj.Deficit) ? 0 : fundingObj.Deficit) || 0,
    isSurplus: !!fundingObj.IsSurplus,
    totalProposedLending: fundingObj.TotalProposedLending || 0,
    lvr: fundingObj.LVR || 0,
    fundingLvr: fundingObj.FundingLVR || 0,
    lendingLvr: fundingObj.LendingLVR || 0,
    needToBorrowAmount: fundingObj.NeedToBorrowAmount || 0,
    totalFundingRequired: fundingObj.TotalFundingRequired || 0,
    totalFundingAvailable: fundingObj.TotalFundingAvailable || 0,
    totalSecurityValue: fundingObj.TotalSecurityValue || 0,
    lmiAmount: fundingObj.LMIAmount || 0,
    lmiLvr: fundingObj.LMI_LVR || 0,
    lvrPercentage: 0,
    isLendingAdded:
      !!fundingObj.TotalProposedLending ||
      !!fundingObj.LendingLVR ||
      !!fundingObj.LVR ||
      !!fundingObj.LMIAmount,
  };
};

export const extendProductFinderLoanFacility = ($scope) => {
  $scope.onOpenAddLoanStructureModal = (facility) => {
    const loanStructureId = get(facility, 'LoanStructureId');
    $scope.openAddLoanStructureModal(loanStructureId);
  };
  $scope.onDeleteFacility = (facility) => {
    const loanStructureId = get(facility, 'LoanStructureId');
    $scope.deleteFacility(loanStructureId);
  };
  $scope.onChooseProductsModal = (facility) =>
    $scope.chooseProductsModal(facility);

  $scope.onSetTBA = (facility) => $scope.setTBA(facility);

  $scope.onDeleteFavoriteProduct = (facility, product) =>
    $scope.deleteFavoriteProduct(facility, product);

  $scope.onSetFacilityFavoriteProduct = (facility, product) =>
    $scope.setFacilityFavoriteProduct(facility, product);

  $scope.onFeeChanges = (facility, fee, value) =>
    $scope.feeChanges(facility, fee, value);

  $scope.onToggleFee = (facility, fee, isChecked) =>
    $scope.toggleFee(facility, fee.feeId, isChecked);
};

export const getUpdatedFavoriteLenders = (splits) => (
  loanStructureId,
  newFavoriteProduct,
) => {
  return splits.map((split) => {
    const isUpdatedProduct = split.LoanStructureId === loanStructureId;
    return isUpdatedProduct
      ? newFavoriteProduct.LenderID
      : get(split, 'SelectedFavouriteProductData.LenderID', '');
  });
};

export const updateLenderFromSplit = ($scope) => (
  selectedProduct,
  loanStructureId,
) => {
  const splits = get($scope, 'loanDetailsSet.LoanFacility', []);
  const favoriteLenderIds = getUpdatedFavoriteLenders(splits)(
    loanStructureId,
    selectedProduct,
  );
  const allSplitHasSameLender = new Set(favoriteLenderIds).size === 1;

  if (!allSplitHasSameLender || !selectedProduct.LenderID) {
    return;
  }

  if ($scope.currentUserService.isNZ) {
    const nzMappedLender = {
      ProviderId: selectedProduct.LenderID,
      ProviderName: selectedProduct.LenderName,
    };
    $scope.onLenderSelected(nzMappedLender);
  } else {
    $scope.updateLoanDetailsField('Lender', selectedProduct.LenderID, '');
  }
};

export const setLoanAppProductFinderFavoriteLimit = (
  vm,
  bidLoanAppChangesProducts,
) => {
  vm.isBidLoanAppChangesProducts = !!bidLoanAppChangesProducts;
  vm.loanAppProductFinderFavoriteLimit = bidLoanAppChangesProducts ? 5 : 3;
};

export const setLoanFacilityHasProducts = ({
  $scope,
  currentUserService,
}) => () => {
  const loanFacility = get($scope.loanDetailsSet, 'LoanFacility', []);
  $scope.loanFacilityHasProducts = currentUserService.isAU
    ? (loanFacility.length &&
        loanFacility.every((facility) => {
          const getSelectedProducts = get(
            facility,
            'FavouriteProductData[0].SelectedProduct',
            [],
          );
          return !!getSelectedProducts.length;
        })) ||
      false
    : true;
  return $scope.loanFacilityHasProducts;
};

export const reloadLoanVariationDetails = (loanAppSharedData) => {
  loanAppSharedData &&
    loanAppSharedData.IsTopUpOrVariation &&
    !loanAppSharedData.isLoadingVariationDetails &&
    loanAppSharedData.getLoanVariationDetails();
};

export const getFundingCalculatorFundsSummary = ({
  loanAppSharedData,
  fundingCalculatorService,
  configService,
}) => ({ securityBrokerEventId, loanAppId }) => {
  if (!securityBrokerEventId) {
    return;
  }

  return fundingCalculatorService
    .getFundingCalculatorFundsSummary(securityBrokerEventId, loanAppId)
    .then((summary) => {
      loanAppSharedData.fundingSummary = {
        ...loanAppSharedData.fundingSummary,
        ...summary,
      };
      validateFundingDetailsSection({
        configService,
        loanAppSharedData,
      });

      reloadLoanVariationDetails(loanAppSharedData);
    })
    .catch(toastError);
};

export const getLoanDetailSetPayload = ({
  loanDetails,
  loanAppSharedData,
  isOverrideTotalProposedLendingUserDefined = false,
}) => {
  const loanDetailsParam = omit(loanDetails, 'LoanFacility');
  loanDetailsParam.TotalProposedLendingUserDefined = parseFloat(
    loanAppSharedData.fundingSummary.totalProposedLendingUserDefined,
  );
  loanDetailsParam.StopAutoCalc = isOverrideTotalProposedLendingUserDefined
    ? true
    : !!loanDetailsParam.StopAutoCalc;
  loanDetailsParam.IsLMI = loanAppSharedData.isDeleteLMI
    ? false
    : !!loanDetailsParam.IsLMI;

  return {
    ABSPurposeId: null,
    HasReasons: false,
    HasSavedProductComparison: false,
    IsOnlineAvailable: false,
    IsReadOnly: false,
    IsRefinance: false,
    LenderId: null,
    LoanScenarioId: null,
    ReasonForLoanFacility: null,
    ReasonForSelectedLender: null,
    ...loanDetailsParam,
  };
};

export const loadLoanDetails = ({ $scope, loanScenarioService, loanAppId }) => {
  if (!$scope.loanDetails) {
    return loanScenarioService.loanDetailsGet(loanAppId).then(({ data }) => {
      $scope.loanDetails = data;
      $scope.loanSplits = data.LoanFacility;
    });
  }
  $scope.loanSplits = $scope.loanDetails.LoanFacility;
  return Promise.resolve();
};

export const saveLoanDetails = ({ loanScenarioService, loanDetails }) => {
  if (!loanDetails) {
    return;
  }

  return loanScenarioService.loanDetailsSet(loanDetails);
};

export const updateLoanDetailsLMIPremium = ({
  loanAppId,
  LMIPremium,
  loanDetails,
  loanAppSharedData,
  loanScenarioService,
}) => {
  const loanDetailsParam = getLoanDetailSetPayload({
    loanDetails: {
      ...loanDetails,
      LMI: LMIPremium,
      IsLMI: true,
      LoanScenarioId: loanAppId,
    },
    loanAppSharedData: loanAppSharedData,
  });
  return loanScenarioService.loanDetailsSet(loanDetailsParam);
};

export const setIsLoanDetailsStarted = ({
  $scope,
  uiService,
  loanAppSharedData,
}) => () => {
  const loanFacility = get($scope.loanDetailsSet, 'LoanFacility', []);
  $scope.isLoanDetailsStarted =
    (uiService.loanAppSplitFundingAndStructure &&
      (loanAppSharedData.fundingSummary.isLendingAdded ||
        get(loanAppSharedData, 'loanAppSummary.LoanDetails.LMI', 0) ||
        ($scope.loanDetailsSet && !!$scope.loanDetailsSet.LenderId) ||
        ($scope.loanDetailsSet && !!$scope.loanDetailsSet.LMI) ||
        loanFacility.length)) ||
    !uiService.loanAppSplitFundingAndStructure;
};

export const removeLMIAttachedToLoan = ({
  loanAppSharedData,
  loanScenarioService,
  isFromLoanFacility,
  isFromSecurity,
  loanAppId,
}) => {
  if (!loanAppSharedData || !loanScenarioService || !loanAppId) {
    loanAppSharedData && (loanAppSharedData.refreshLoanDetails = 1);
    return;
  }

  const loanFacilities = get(
    loanAppSharedData,
    'loanDetailsSet.LoanFacility',
    [],
  );
  const securities = get(loanAppSharedData, 'securityList', []);
  const loanDetails =
    get(loanAppSharedData, 'loanDetailsSet') ||
    get(loanAppSharedData, 'loanAppSummary.LoanDetails');

  if (!loanDetails) {
    return;
  }

  const isRemoveLoanFacilityAndNoSecurity =
    isFromLoanFacility &&
    loanFacilities.length === 1 &&
    securities.length === 0 &&
    !!loanDetails.LMI;

  const isRemoveSecurityAndNoLoanFacility =
    isFromSecurity &&
    securities.length === 1 &&
    loanFacilities.length === 0 &&
    !!loanDetails.LMI;

  if (
    !isRemoveLoanFacilityAndNoSecurity &&
    !isRemoveSecurityAndNoLoanFacility
  ) {
    loanAppSharedData.refreshLoanDetails = 1;
    return;
  }

  const loanDetailsPayload = omit(
    {
      ...getLoanDetailSetPayload({ loanDetails, loanAppSharedData }),
      LoanScenarioId: loanAppId,
      IsLMI: false,
      LMI: 0,
    },
    'LoanFacility',
  );

  return saveLoanDetails({
    loanScenarioService,
    loanDetails: loanDetailsPayload,
  }).then(() => {
    loanAppSharedData.refreshLoanDetails = 1;
    loanAppSharedData.refreshSecurityFundsSummary = 1;
  });
};

export const getFundingCalcParametersId = (
  fundingCalculatorService,
  securityBrokerEventId,
) => {
  return fundingCalculatorService.getFundingCalculatorParameters(
    securityBrokerEventId,
  );
};

export const saveLMIFunding = (
  loanAppSharedData,
  fundingCalculatorService,
  value,
) => {
  return getFundingCalcParametersId(
    fundingCalculatorService,
    loanAppSharedData.securityBrokerEventId,
  ).then((response) => {
    if (!response || !response.length) {
      return;
    }
    const [firstItem] = response;
    loanAppSharedData.SAVE_FUNDING_PARAMETER = {
      fundsId: 0,
      fundingCalParametersId: firstItem.fundingCalParametersId,
      name: LMI_NAME,
      value,
      brokerEventId: loanAppSharedData.securityBrokerEventId,
    };
  });
};

export const saveLMIFundingFromLoanDetails = ({
  loanAppSharedData,
  fundingCalculatorService,
  securityList,
}) => {
  const loanDetails =
    get(loanAppSharedData, 'loanDetailsSet') ||
    get(loanAppSharedData, 'loanAppSummary.LoanDetails');
  if (!securityList || !loanDetails) {
    return;
  }
  const isFirstSecurityAndLoanHasLMI =
    securityList.length === 0 && !!loanDetails.LMI;

  if (isFirstSecurityAndLoanHasLMI) {
    return saveLMIFunding(
      loanAppSharedData,
      fundingCalculatorService,
      loanDetails.LMI,
    );
  }
  return Promise.resolve(false);
};
