import { arrayToObject } from 'Common/utilities/array';
import { toastSaveSuccess } from 'Common/utilities/alert';

export default class MainCtrl {
  constructor(
    $stateParams,
    constants,
    currentUserService,
    mycrmLookupService,
    configService,
    modalRenderService,
    accreditationService,
  ) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.TAB_TYPES = constants.TAB_TYPES;
    this.tabs = constants.TABS;
    this.currentUserService = currentUserService;
    this.mycrmLookupService = mycrmLookupService;
    this.configService = configService;
    this.modalRenderService = modalRenderService;
    this.accreditationService = accreditationService;
    this.processSearchActions = this.processSearchActions.bind(this);
    this.processRefresh = this.processRefresh.bind(this);
    this.saveMultipleProviders = this.saveMultipleProviders.bind(this);
  }

  $onInit() {
    this.providerName = '';
    this.uibActiveTab = 0;
    this.hiddenOnSearch = true;
    this.disabledOnProduct = false;
    this.searchLenderFromchildComponentEventHandler = {
      searchHandler: null,
    };
    this.refreshHandler = null;
    this.isCorpAccreditationTableEnabled = this.configService.feature.corpAccreditationTable;
    this.familyId = this.$stateParams.familyId || 0;
    this.isIndividualProfile = !!this.isIndividualProfile;

    this.tabsLabels = arrayToObject(this.tabs, 'typeId');
    this.selectedProviders = [];

    if (this.isIndividualProfile) {
      this.tabs = this.tabs.filter(
        (toRemove) => toRemove.typeId !== this.TAB_TYPES.PRODUCTS,
      );
    }

    if (this.currentUserService.isNZ) {
      this.activeTab = this.TAB_TYPES.LENDER;
    } else {
      this.activeTab = this.TAB_TYPES.RESIDENTIAL;
    }

    const {
      LENDER,
      INSURER,
      REFERRER,
      PRODUCTS,
      RESIDENTIAL,
      ASSET,
      COMMERCIAL,
      DEPOSIT_BONDS,
      PERSONAL_LOANS,
      SMALL_BUSINESS_LENDING,
    } = this.TAB_TYPES;
    const auLenderTabSplit = [
      RESIDENTIAL,
      ASSET,
      COMMERCIAL,
      DEPOSIT_BONDS,
      PERSONAL_LOANS,
      SMALL_BUSINESS_LENDING,
    ];
    const nonAuTabs = [LENDER, INSURER, REFERRER, PRODUCTS];

    if (!this.currentUserService.isAU) {
      this.tabs = this.tabs.filter((tab) => nonAuTabs.includes(tab.typeId));
    } else {
      this.tabs = this.tabs.filter((tab) =>
        auLenderTabSplit.includes(tab.typeId),
      );
    }

    const { hasAssistantAccess, fullName } = this.currentUserService;
    if (hasAssistantAccess) {
      this.showAdviserDropdown = true;
      this.getAdviserList();
    } else {
      this.advisersList = [];
      this.adviserName = fullName;
    }
  }

  processSearchActions(handler, handlerType) {
    this.searchLenderFromchildComponentEventHandler[handlerType] = handler;
  }

  processRefresh(handler) {
    this.refreshHandler = handler;
  }

  getAdviserList() {
    const params = {
      includeInactive: false,
      loanWritersOnly: false,
      allAdviserLabel: 'Please Select',
      allAdviserValue: 0,
    };
    this.mycrmLookupService.getAdvisers(params).then((advisersList) => {
      this.advisersList = advisersList;
    });
  }

  onSearchAdviserAccreditation(familyId) {
    this.adviserName = (
      this.advisersList.find((adviser) => adviser.familyId === +familyId) || {}
    ).fullName;
    this.searchLenderFromchildComponentEventHandler.searchHandler &&
      this.searchLenderFromchildComponentEventHandler.searchHandler({
        type: 'searchAdviser',
        payload: familyId,
      });
  }

  onSearchLenders() {
    const providerName = this.providerName;
    this.searchLenderFromchildComponentEventHandler.searchHandler &&
      this.searchLenderFromchildComponentEventHandler.searchHandler({
        type: 'searchLenders',
        payload: providerName,
      });
  }

  onFocus() {
    this.hiddenOnSearch = false;
  }

  onLeave() {
    this.hiddenOnSearch = true;
  }

  onToggle() {
    this.hiddenOnSearch = !this.hiddenOnSearch;
  }

  clearSelectedProviders() {
    this.selectedProviders = [];
  }

  loadSelectedTab({ typeId }) {
    this.activeTab = typeId;
    this.clearSelectedProviders();
    this.tabs = this.tabs.map((tab) => {
      tab.isActive = tab.typeId === typeId;
      return tab;
    });
    const isProductTab = typeId === this.TAB_TYPES.PRODUCTS;
    this.disabledOnProduct = isProductTab;
    this.providerName = null;
    if (isProductTab) {
      this.hiddenOnSearch = true;
    }
  }

  selectProviders(provider) {
    if (provider.isSelected) {
      this.selectedProviders = [...this.selectedProviders, { ...provider }];
    } else {
      this.selectedProviders = this.selectedProviders.filter(
        ({ providerId }) => providerId !== provider.providerId,
      );
    }
  }

  saveMultipleProviders(form, dismissModal) {
    const successCb = () => {
      this.refreshHandler && this.refreshHandler({ payload: this.familyId });
      this.clearSelectedProviders();
      toastSaveSuccess();
      dismissModal();
    };
    this.accreditationService.setBulkProviders(form, successCb);
  }

  showEditSelectedModal() {
    if (!this.selectedProviders.length) {
      return;
    }
    this.selectedProviders = this.selectedProviders.map((item) => {
      item.familyId = this.familyId;
      return item;
    });
    const [firstProvider] = this.selectedProviders;
    const { accreditationStatus } = firstProvider;
    this.modalRenderService.openAccreditationEditMultiProvider({
      providers: this.selectedProviders,
      statusList: accreditationStatus,
      isAu: this.currentUserService.isAU,
      onSubmit: this.saveMultipleProviders,
    });
  }
}
