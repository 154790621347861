import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import {
  displayError,
  toastInfo,
  toastError,
  toastSuccess,
  alertSucess,
  showActionConfirmation,
} from 'Common/utilities/alert';
import { DOCUMENT_BROKEN_ERROR_MSG } from 'Common/constants/document';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { SUBSCRIPTIONTYPE } from 'Common/constants/subscriptionTypeCode';
import { isCancelled } from 'Common/utilities/subscription';
import { pluralize } from 'Common/utilities/string';
import { isValidObjectValue } from 'Common/utilities/objectValidation';
import { LOAN_APP_SUPPORTING_DOCS } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import { parseToInt10 } from 'Common/utilities/parse';
import {
  groupDocuments,
  dynamicSort,
  removeWorksheetDocs,
  downloadDocumentUtil,
  checkIfCanDownloadDocument,
  getDocumentOptionType,
  getDateUploadedOn,
  sequentialThumbnailGeneration,
  setNonEsignDocumentsSendAllToLenderTick,
  setEsignDocumentsSendAllToLenderTick,
  isSignedDeclarationForm,
  groupFixedSupportingDocs,
} from './util/loanAppSupportDocsCtrl';
import { setLoanAppSupportingDocsValidationMethods } from './../util/loanAppValidation';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanAppSupportDocsCtrl', function LoanAppSupportDocsCtrl(
      $scope,
      $timeout,
      $rootScope,
      $state,
      $stateParams,
      $uibModal,
      toaster,
      contactService,
      configService,
      eSignService,
      loanApplicationServices,
      loanAppSharedData,
      loanScenarioService,
      optionsService,
      downloadDataService,
      broadcastDataService,
      uiService,
      generalService,
      loanScenarioModelService,
      currentUserService,
    ) {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      const initializeSupportingDocuments = () => {
        let clickCounter = 0;
        loanAppSharedData.isValidSupportingDocs = null;
        $scope.turnOffThumbnails = configService.feature.turnOffThumbnails;
        $scope.loanAppId = $stateParams.loanAppId;
        $scope.loanAppSharedData = loanAppSharedData;
        $scope.currentUserService = currentUserService;
        $scope.selectedDocuments = [];

        $scope.documentsList = [];
        $scope.sortableDocs = [];
        $scope.notSortableDocs = [];
        $scope.searchFilesText = '';
        $scope.documentsListLength = 0;
        $scope.docBrokenErrorMsg = DOCUMENT_BROKEN_ERROR_MSG;
        $scope.inProgressDocDownload = false;
        $scope.sequentialGenerate = sequentialThumbnailGeneration({
          $scope,
          generalService,
        });

        const {
          supportingDocumentsSorting,
          deleteAllSupportingDocsInLoanApp,
          sendToLenderCheckbox,
        } = configService.feature;
        $scope.supportingDocSorting = supportingDocumentsSorting;
        $scope.toShowSorting = supportingDocumentsSorting && !$scope.isLodged;
        $scope.showDeleteAllDocs = deleteAllSupportingDocsInLoanApp;
        $scope.showSendToLenderCheckbox = sendToLenderCheckbox;

        $scope.setDocInclusion = ({ id, isSendToLender, isEsign = false }) => {
          const params = {
            SupportingDocumentIds: [parseToInt10(id)],
            IsSendToLender: isSendToLender,
            IsEsign: isEsign,
          };

          eSignService
            .setIncludeDocument(params)
            .then(({ data }) => !data && toastError());
        };

        $scope.selectAllDocuments = (isSendToLender) => {
          setNonEsignDocumentsSendAllToLenderTick(eSignService)(
            $scope.sortableDocs,
            isSendToLender,
            false,
          );

          loanAppSharedData.esignDocuments &&
            loanAppSharedData.esignDocuments.length &&
            setEsignDocumentsSendAllToLenderTick(
              loanAppSharedData.esignDocuments,
              eSignService,
            )(isSendToLender);
        };

        $scope.savePageSortOrder = (pages, ui = null) => {
          const isSortableValid = isValidObjectValue(
            () => ui.item.sortable.sourceModel.length,
          );
          const thePages = isSortableValid
            ? ui.item.sortable.sourceModel
            : pages;

          const formatPages = thePages.map((data, index) => {
            return {
              DocumentID: data.DocumentId,
              PageID: data.LoanScenarioDocument_PageID,
              SortOrderNumber: index + 1,
              LoanScenarioID: parseInt($scope.loanAppId, 10),
            };
          });

          const familyId = parseInt($scope.familyId, 10);
          loanScenarioModelService
            .postDocumentPageSortingSet(formatPages, familyId)
            .then(() => {
              toastSuccess('Document page order saved.');
            });
        };
        $scope.sortablePageOptions = {
          handle: '.list-handle',
          placeholder: 'support-doc-placeholder',
          stop: $scope.savePageSortOrder,
        };

        $scope.movePage = (pages, from, to) => {
          const item = pages.splice(from, 1)[0];
          pages.splice(to, 0, item);
          $scope.savePageSortOrder(pages);
        };

        $scope.sortOneStepPage = (pages, index, toMoveDown) => {
          const to = toMoveDown ? index - 1 : index + 1;
          $scope.movePage(pages, index, to);
        };

        $scope.saveSortOrder = () => {
          const logEntry = $scope.documentsList.map(
            ({ LoanScenarioDocumentID }, index) => {
              return {
                LoanScenarioDocumentID,
                SortOrderNumber: index + 1,
                LoanScenarioId: $scope.loanAppId,
              };
            },
          );
          loanScenarioModelService.postDocumentSorting(logEntry).then(() => {
            toastSuccess('Document order saved.');
          });
        };

        $scope.sortableOptions = {
          handle: '.list-handle',
          placeholder: 'support-doc-placeholder',
          stop: $scope.saveSortOrder,
          items: 'div.doc-sortable-item:not(.not-sortable)',
        };

        $scope.moveItem = (from, to) => {
          const item = $scope.sortableDocs.splice(from, 1)[0];
          $scope.sortableDocs.splice(to, 0, item);
          $scope.saveSortOrder();
        };

        $scope.sortOneStep = (index, toMoveDown) => {
          const to = toMoveDown ? index - 1 : index + 1;
          $scope.moveItem(index, to);
        };

        $scope.onInvalidSupportingDocs = (hasInvalidDocs) => {
          $scope.hasInvalidSupportingDocs = hasInvalidDocs;
          $scope.LoanAppFormWarning = hasInvalidDocs;
          if (clickCounter > 0) {
            $scope.validateSupportingDocs();
          }
          if (hasInvalidDocs) {
            $scope.warningMessage = uiService.viewOfAfileTurnedOn
              ? 'Looks like there are errors…'
              : 'Oops! There are errors with some documents. Please delete and re-upload them.';
          } else {
            $scope.warningMessage = null;
          }
        };

        /* listeners */
        $scope.$on('isLodged', (event, data) => {
          $scope.isLodged = data;
        });
        const packageListener = $rootScope.$on(
          'eSignPackageSent',
          (event, data) => {
            $scope.packageSent = data ? $scope.packageSent + 1 : 0;
          },
        );
        const loanAppSharedDataListener = $scope.$watch(
          'loanAppSharedData.hasInvalidSupportingDocs',
          (nv) => {
            if (typeof nv !== typeof true) {
              return;
            }
            $scope.onInvalidSupportingDocs(nv);
          },
        );
        $scope.$on('$destroy', () => {
          packageListener && packageListener();
          loanAppSharedDataListener && loanAppSharedDataListener();
        });
        /* end of listeners */
        $scope.showEsign = false;
        $scope.packageSent = 0;
        $scope.getSubscription = () => {
          const showEsign =
            (configService.feature && configService.feature.esign) || 0;
          const assignedAdviserId =
            loanAppSharedData &&
            loanAppSharedData.LoanApplicationDetails &&
            loanAppSharedData.LoanApplicationDetails.AdviserFamilyId;
          if (!assignedAdviserId || !showEsign) {
            return;
          }
          contactService
            .subscriptionInfo(assignedAdviserId)
            .then((response) => {
              if (!response || !response.length) {
                return;
              }
              const eSignSubscription =
                (response &&
                  response.find(
                    (obj) =>
                      obj.subscriptionProductId === SUBSCRIPTIONTYPE.E_SIGN,
                  )) ||
                {};
              const {
                isActive,
                isCancelled: isCancelledImmediately,
              } = eSignSubscription;
              $scope.showEsign =
                isActive ||
                isCancelled(eSignSubscription) ||
                (isCancelledImmediately && !isActive);
            });
        };

        setLoanAppSupportingDocsValidationMethods({
          $scope,
          $timeout,
          configService,
          loanAppSharedData,
        });

        $scope.processSupportDocsSection = () => {
          performance.start(LOAN_APP_SUPPORTING_DOCS);
          if (clickCounter !== 0) {
            return;
          }
          clickCounter++;
          $scope.getSubscription();

          $scope.settings = {
            uploadSucceeded: true,
          };

          $scope.documentProgressVisibility = (visibility) => {
            $scope.hideDocumentProgress = parseInt(visibility, 10) < 1;
          };

          /* droplet method for upload/view */
          angular.extend($scope, {
            uploadViewSuccess(files) {
              angular.forEach(files, (o) => {
                $scope.processDoc({
                  Title: o.Name,
                  DocumentID: o.DocumentId,
                  FamilyID: $scope.familyId,
                });
              });
            },
          });

          /**
           *  Validates every update on object
           */
          $scope.$watch(
            'selectedDocuments',
            () => {
              $scope.validateSupportingDocs();
              $scope.fnIsStartedSupportingDocs();
            },
            true,
          );

          $scope.$watch(
            () => uiService.isCountry('New Zealand'),
            (nv) => {
              if (nv) {
                $scope.extensions = ['pdf', 'jpg', 'jpeg', 'png'];
                $scope.invalidFileInfo = {
                  title: 'Invalid',
                  description:
                    'This file format will not be visible to the lender, please try uploading using PDF, JPEG or PNG format',
                };
              } else {
                $scope.extensions = [
                  'pdf',
                  'docx',
                  'doc',
                  'odt',
                  'xls',
                  'xlsx',
                  'ods',
                  'odp',
                  'ppt',
                  'pptx',
                  'jpg',
                  'jpeg',
                  'png',
                  'rtf',
                  'odt',
                  'txt',
                  'tif',
                  'bmp',
                  'gif',
                  'html',
                ];
                $scope.invalidFileInfo = {
                  title: 'Invalid',
                  description: 'Invalid Bad file NOT added',
                };
              }
            },
          );

          $scope.getRelatedDocs = () => {
            loanScenarioService
              .availableDocuments($scope.loanAppId)
              .then((response) => {
                $scope.relatedDocs = response.data;
                $scope.checkDuplicates();
              });
          };

          $scope.updateSeletedDocs = () => {
            $scope.selectedDocuments = _.filter($scope.relatedDocs, (o) => {
              return o.IsAdded === true;
            });
          };

          const getDocumentType = (country) => {
            if (country !== 'New Zealand') {
              optionsService.DocumentTypeGet().then((response) => {
                $scope.DocumentsTypeList = response.data;
                $scope.validateSupportingDocs();
                $scope.fnIsStartedSupportingDocs();
              });
            } else {
              // should watch changes on selected lender
              $scope.$watch(
                'loanAppSharedData.lenderId',
                (nv) => {
                  optionsService.NZDocumentTypeGet(nv || 0).then((response) => {
                    $scope.DocumentsTypeList = response.data;
                    $scope.validateSupportingDocs();
                    $scope.fnIsStartedSupportingDocs();
                    let isTypeExisting = null;
                    _.forEach($scope.documentsList, (val) => {
                      isTypeExisting = _.find($scope.DocumentsTypeList, (o) => {
                        return o.Value === val.PageType;
                      });
                      if (val.PageType && isTypeExisting) {
                        $scope.settings.uploadSucceeded = true;
                      }
                    });
                  });
                },
                true,
              );
            }
          };

          $scope.$watch('crmCountry', (nv) => {
            if (nv) {
              getDocumentType(nv);
            }
          });

          $scope.$watch(
            'relatedDocs',
            (list) => {
              let selectedItems = 0;
              $scope.allSelectedDocuments = _.flatten(
                _.map(list, (items) => items.Documents),
              );
              angular.forEach($scope.allSelectedDocuments, (item) => {
                selectedItems += item.IsAdded && !item.included ? 1 : 0;
              });
              $scope.selectedItems = selectedItems;
              $scope.updateSeletedDocs();
            },
            true,
          );

          $scope.showPages = (item) => {
            item.showPages = !item.showPages;
            $scope.loadPages(item);
          };

          $scope.loadPages = (item) => {
            if (
              (!item.pagesLoaded || item.pagesLoaded === false) &&
              item.DocumentId
            ) {
              item.isGettingDocuments = true;
              loanApplicationServices
                .getDocumentDetails(
                  $scope.loanAppId,
                  item.DocumentId,
                  500,
                  false,
                )
                .then((response) => {
                  item.pages = response.data;
                  item.pagesLoaded = true;
                  item.isGettingDocuments = false;
                  broadcastDataService.InlinePreloader = {
                    preLoaderText: '',
                    preLoaderSize: '',
                    showPreLoader: false,
                  };
                });
            }
          };

          $scope.checkDuplicates = () => {
            const relatedDocs = _.flatten(
              _.map($scope.relatedDocs, (items) => items.Documents),
            );
            const documentIDs = $scope.documentsList.map(
              (item) => item.DocumentId,
            );
            _.forEach(relatedDocs, (item) => {
              item.included = _.includes(documentIDs, item.DocumentId);
              item.IsAdded = _.includes(documentIDs, item.DocumentId);
            });
          };

          $scope.selectDoc = (item) => {
            if (!item.included) {
              item.IsAdded = !item.IsAdded;
            }
            $scope.updateSeletedDocs();
          };

          $scope.showAddDocsModal = () => {
            $scope.getRelatedDocs();
            $uibModal.open({
              templateUrl:
                '/assets/views/loanApplication/supportDocs/modal/selectDoc.html',
              controller($uibModalInstance) {
                $scope.cancel = () => {
                  $uibModalInstance.dismiss('cancel');
                };
                $scope.finishSelection = () => {
                  $scope.cancel();
                  toaster.pop('success', 'Success', 'Document Updated');

                  angular.forEach($scope.allSelectedDocuments, (val) => {
                    if (val.IsAdded === true && !val.included) {
                      $scope.processDoc({
                        Title: val.Name,
                        DocumentID: val.DocumentId,
                      });
                    }
                  });
                };
              },
              size: 'md',
              scope: $scope,
            });
          };

          $scope.setDocument = (
            documentObject,
            dontRefresh,
            successText,
            noModal,
            callback,
          ) => {
            // copy by value
            const obj = angular.copy(documentObject);
            obj.LoanScenarioId = $scope.loanAppId;

            // remove unnecessary attributes
            _.omit(obj, [
              'pages',
              'showPages',
              'pagesLoaded',
              'addNotes',
              'StatusName',
            ]);

            loanApplicationServices
              .setDocument(obj)
              .then(() => {
                if (!noModal) {
                  toaster.pop('success', 'Sucess', successText);
                }
                if (!dontRefresh) {
                  $scope.loadDocumentList();
                } else {
                  if ($scope.supportingDocSorting) {
                    $scope.sortableDocs = groupFixedSupportingDocs(
                      $scope.sortableDocs,
                      $scope.currentUserService.isNZ,
                    );
                  }
                }
                callback();
              })
              .catch(displayError);
          };

          $scope.processDoc = (obj) => {
            const documentDataObject = obj;

            $scope.documentThumbnail = {};
            loanApplicationServices
              .getDocumentPageCount(documentDataObject.DocumentID)
              .then((respond) => {
                $scope.setDocument(
                  {
                    LoanScenarioId: $scope.loanAppId,
                    DocumentPage: 1,
                    IsAdded: true,
                    Notes: '',
                    UploadDate: new Date(),
                    UploadedByFamilyID: $scope.familyId,
                    DocumentId: documentDataObject.DocumentID,
                    Name: documentDataObject.Title,
                    Size: 0,
                    NumberPages: respond.data,
                    DateUploaded: new Date(),
                    Createddate: new Date(),
                    CreatedMonth: new Date(),
                  },
                  false,
                  'Document has been been successfully uploaded.',
                  false,
                  _.noop,
                );
                $scope.validateSupportingDocs();
              });
          };

          // Update Notes
          $scope.updateNotes = (item) => {
            $scope.setDocument(
              item,
              true,
              'Notes successfully saved.',
              false,
              _.noop,
            );
          };

          $scope.updateDocType = (item) => {
            if (
              item.DocumentId &&
              item.LoanScenarioDocument_PageID &&
              item.PageType &&
              item.PageName &&
              item.PageNumber
            ) {
              loanApplicationServices.setDocumentPage({
                LoanScenarioId: $scope.loanAppId,
                DocumentId: item.DocumentId,
                LoanScenarioDocument_PageID: item.LoanScenarioDocument_PageID,
                PageType: item.PageType,
                PageName: item.PageName,
                PageNumber: item.PageNumber,
                ThumbnailURI: item.ThumbnailURI,
              });

              const isTypeExisting = _.find($scope.DocumentsTypeList, (o) => {
                return o.Value === item.PageType;
              });

              if (item.PageType !== '' && isTypeExisting) {
                $scope.settings.uploadSucceeded = true;
              } else {
                $scope.settings.uploadSucceeded = false;
              }
              $scope.validateSupportingDocs();
            } else {
              swal(
                'Incomplete Document Information',
                'Necessary document information is missing.',
                'error',
              );
            }
          };

          $scope.toggleDisplayBanner = (item) => {
            if (!$scope.turnOffThumbnails) {
              return;
            }
            item.showAlertBanner = !item.showAlertBanner;
          };

          $scope.showGenerateBanner = (item, override = false) => {
            const hideNoThumbnailBanner =
              ($scope.turnOffThumbnails && !override) ||
              ($scope.turnOffThumbnails && !item.showAlertBanner);
            if (hideNoThumbnailBanner) {
              return false;
            }
            return (
              item.StatusName === 'Included' &&
              item.showPages &&
              item.pages &&
              item.pages.length > 0 &&
              item.pages.length !== $scope.countPageThumbnail(item)
            );
          };

          $scope.countPageThumbnail = (item) => {
            const hasThumbs = _.filter(item.pages, (obj) => obj.ThumbNail);
            return hasThumbs.length;
          };

          $scope.generateThumbsAllPages = (item) => {
            const shouldNotGenerate =
              item.pages.length === $scope.countPageThumbnail(item) ||
              item.isGettingThumbnail ||
              $scope.inProgressDocDownload;

            if (shouldNotGenerate) {
              return;
            }

            $scope.inProgressDocDownload = true;
            item.isGettingThumbnail = true;
            item.pages = item.pages.map((page) => ({
              ...page,
              isGettingThumbnail: true,
            }));

            $scope.sequentialGenerate(item);
          };

          $scope.updateAllDocType = (item) => {
            $scope.setDocument(item, true, '', true, () => {
              if (item.showPages) {
                loanApplicationServices
                  .getDocumentDetails($scope.loanAppId, item.DocumentId, 500)
                  .then((response) => {
                    item.pages = response.data;
                  });
              }
              $scope.validateSupportingDocs();
            });
          };

          $scope.dynamicSort = dynamicSort;

          $scope.groupDocuments = groupDocuments({ $scope });

          // Douments List
          // -------------------------------------------------------------
          $scope.loadDocumentList = () => {
            $scope.isGettingDocuments = true;

            loanApplicationServices
              .getDocumentList($scope.loanAppId)
              .then((result) => {
                const filteredData = removeWorksheetDocs(result.data);
                result.data = _.map(filteredData, (res) => {
                  const identicalDoc = _.find($scope.documentsList, (doc) => {
                    return doc.DocumentId === res.DocumentId;
                  });
                  if (identicalDoc) {
                    res.showPages = identicalDoc.showPages;
                    res.pages = identicalDoc.pages;
                  }
                  return res;
                });
                // put StatusName attribute
                $scope.documentsList = loanAppSharedData.documents.fillStatusName(
                  result.data,
                );

                // check if there is notes, open automatically if there is
                _.each($scope.documentsList, (doc) => {
                  if (_.size(doc.Notes) > 0) {
                    doc.addNotes = true;
                  }
                });

                $scope.isGettingDocuments = false;

                $scope.groupDocuments($scope.documentsList);

                $scope.fnIsStartedSupportingDocs();

                /* save removed lenderWorksheet into loanAppSharedData */
                loanAppSharedData.documentListLenderWorksheet = _.remove(
                  $scope.documentsList,
                  (object) => {
                    return object.Description === 'Worksheet';
                  },
                );
                $scope.checkIfCanDownloadDocument();
                // check documentList if all has type
                $scope.validateSupportingDocs();
              });
          };
          $scope.loadDocumentList();
          $scope.checkIfCanDownloadDocument = checkIfCanDownloadDocument(
            $scope,
          );
          $scope.getDocumentOptionType = getDocumentOptionType($scope);
          $scope.getDateUploadedOn = getDateUploadedOn;
          $scope.downloadDocument = (docu) => {
            return downloadDocumentUtil({
              documentId: docu.DocumentId,
              contentType: docu.ContentType,
              downloading: docu.downloading,
              downloadDataService,
              loanappId: $scope.loanAppId,
            });
          };
          $scope.deleteAllDocument = (documentCount) => {
            // eslint-disable-next-line unicorn/consistent-function-scoping
            const onActionConfirmed = (isConfirmed) => {
              if (!isConfirmed) {
                return;
              }
              loanApplicationServices
                .deleteAllDocument($scope.loanAppId)
                .then(() => {
                  $scope.loadDocumentList();
                  $scope.validateSupportingDocs();
                  alertSucess('Deleted', 'Files has been deleted.', true);
                }, displayError);
            };
            showActionConfirmation(
              'Please confirm action',
              `Are you sure you want to delete all ${pluralize(
                documentCount,
                'document',
              )}? This action cannot be undone`,
              onActionConfirmed,
              `Yes, I am sure`,
            );
          };
          $scope.downloadAllAttachment = (documentCount) => {
            $scope.isAttachementDownloading = true;
            const title = 'Sit back .. Relax ..';
            const message = `We are currently collecting and downloading ${pluralize(
              documentCount,
              'document',
            )} for you. It might take a little while so please be patient.`;
            toastInfo(message, title);
            loanScenarioModelService
              .getDocumentsZip($scope.loanAppId)
              .then((data) => {
                $scope.isAttachementDownloading = false;
                if (!data) {
                  return;
                }
                const { documentContent, name, contentType } = data;
                const content = `data:${contentType};base64,${documentContent}`;
                downloadDataService.download(content, name, contentType);
              })
              .catch(() => {
                $scope.isAttachementDownloading = false;
                const errorMessage = `There's an error while downloading the files.`;
                toastError(errorMessage);
              });
          };

          $scope.removeDocument = (documentId, loanScenarioDocumentId) => {
            swal(
              {
                title: 'Please confirm action',
                text:
                  'Are you sure you want to delete this document? This action cannot be undone',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#FA8E91',
                confirmButtonText: 'Delete Document',
                closeOnConfirm: false,
              },
              (isConfirm) => {
                if (isConfirm) {
                  loanApplicationServices
                    .deleteDocument(
                      $scope.loanAppId,
                      documentId,
                      loanScenarioDocumentId,
                    )
                    .then(() => {
                      $scope.loadDocumentList();
                      $scope.validateSupportingDocs();
                      alertSucess('Deleted', 'File has been deleted.', true);
                    })
                    .catch(displayError);
                }
              },
            );
          };

          $scope.searchDocument = ($event) => {
            $scope.searchFilesText = $event.target.textContent;
            $scope.tableParams.reload();
          };

          // Forthcoming
          angular.extend($scope, {
            saveForthcomingDocument() {
              const docData = {
                LoanScenarioId: $scope.loanAppId,
                UploadDate: new Date(),
                UploadedByFamilyID: $scope.familyId,
                DocumentId: 0,
                Name: 'Forthcoming Document #',
                Size: 0,
                NumberPages: 0,
                DateUploaded: new Date(),
                Createddate: new Date(),
                CreatedMonth: new Date(),
                PageType: [],
                Notes: '',
                StatusId: '2',
                StatusName: loanAppSharedData.documents.getStatusName('2'),
              };

              $scope.setDocument(docData, false, '', true, _.noop);
            },
            getForthcomingNo($LoanScenarioDocumentID) {
              const forthcomingList = [];

              _.each(
                _.filter($scope.documentsList, (doc) => {
                  return doc.StatusName === 'Forthcoming';
                }),
                (doc) => {
                  forthcomingList.push({
                    LoanScenarioDocumentID: doc.LoanScenarioDocumentID,
                    StatusId: doc.StatusId,
                  });
                },
              );

              return _.findIndex(forthcomingList, (forth) => {
                return forth.LoanScenarioDocumentID === $LoanScenarioDocumentID;
              });
            },
          });
          const duration = performance.endPaint(LOAN_APP_SUPPORTING_DOCS);
          loanAppSectionData({
            label: LOAN_APP_SUPPORTING_DOCS,
            value: duration,
          });
        };

        $scope.inOpportunityPage = $state.includes('app.opportunity');
        if ($scope.inOpportunityPage) {
          $scope.processSupportDocsSection();
        }

        const sectionObject = loanAppSharedData.getSectionObjectById(
          loanAppSharedData.completionListObject,
          SECTION_ID.UPLOAD_VIEW,
        );
        $scope.countValids(true, 'documents');
        if (typeof sectionObject.IsCompleted === 'undefined') {
          $scope.processSupportDocsSection();
        } else {
          const { DocumentList } = loanAppSharedData.loanAppSummary;
          if (DocumentList && DocumentList.length) {
            $scope.documentsList = removeWorksheetDocs(DocumentList);
            $scope.documentsList.sort($scope.dynamicSort);
          }

          $scope.fnIsStartedSupportingDocs();
          $scope.validateSupportingDocs();
        }
      };

      $scope.getActualSortableList = () => {
        if (!$scope.supportingDocSorting || !$scope.currentUserService.isNZ) {
          return $scope.sortableDocs;
        }

        return $scope.sortableDocs.filter(
          (doc) =>
            !isSignedDeclarationForm(doc, $scope.currentUserService.isNZ),
        );
      };

      $scope.isDocumentPositionFixed = (item) => {
        if (!$scope.supportingDocSorting) {
          return false;
        }
        return isSignedDeclarationForm(item, $scope.currentUserService.isNZ);
      };

      $scope.isFirstSortableItem = (item) => {
        const list = $scope.getActualSortableList();
        if (!list.length) {
          return false;
        }
        return list[0].LoanScenarioDocumentID === item.LoanScenarioDocumentID;
      };

      $scope.isLastSortableItem = (item) => {
        const list = $scope.getActualSortableList();
        if (!list.length) {
          return false;
        }
        return (
          list[list.length - 1].LoanScenarioDocumentID ===
          item.LoanScenarioDocumentID
        );
      };

      $scope.$watch('isLoanAppLoaded', () => {
        if (!$scope.isLoanAppLoaded) {
          return;
        }
        initializeSupportingDocuments();
      });
    });
