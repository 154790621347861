import {
  insuranceDetailsBuilderForUI,
  insuranceDetailsBuilderForMyCRM,
} from 'Common/mappers/insuranceQuote';

class ContactInsuranceService {
  constructor($q, httpClient) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.apiBaseUrl = 'InsuranceQuote';
    this.apiContactBaseUrl = 'contacts';
    this.apiSelectOptionsBaseUrl = 'SelectOptions';
  }

  insuranceDetailsSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/InsuranceDetailsSet`,
      insuranceDetailsBuilderForMyCRM(data),
    );
  }

  insuranceProductGet(providerId, benefitTypeId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceProductGet`, {
      providerId,
      benefitTypeId,
    });
  }

  insuranceProvidersGet(providerId, benefitId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceProvidersGet`, {
      providerId,
      benefitId,
    });
  }

  insuranceQuoteProviderGet(providerId, benefitTypeId, isReport = false) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteProviderGet`, {
      providerId,
      benefitTypeId,
      isReport,
    });
  }

  insuranceExistingProviderGet(providerId, benefitTypeId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceExistingProviderGet`,
      { providerId, benefitTypeId },
    );
  }

  insuranceDetailsGet(familyId, benefitId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/InsuranceDetailsGet`, {
        familyId,
        benefitId,
      })
      .then(({ data }) => insuranceDetailsBuilderForUI(data));
  }

  insuranceBenefitTypesGet(familyId) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceBenefitTypesGet`, {
      familyId,
    });
  }

  clientInformGet(familyId, clientId) {
    return this.httpClient.get(`${this.apiContactBaseUrl}/ClientInformGet`, {
      familyId,
      clientId,
    });
  }

  insuranceBenefitsGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceBenefitsGet`,
      {},
      true,
    );
  }

  insuranceStatusGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceStatusGet`);
  }

  insuranceCalculationPeriodGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteCalculationPeriodGet`,
    );
  }

  insuranceQuoteExcessGet() {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceQuoteExcessGet`);
  }

  iPCoverType() {
    return this.httpClient.get(`${this.apiSelectOptionsBaseUrl}/IPCoverType`);
  }

  insuranceQuoteIPCoverTypeGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteIPCoverTypeGet`,
    );
  }

  insuranceDetailsListGet(
    familyId,
    status,
    providerId,
    policyNumber,
    IsProviderFireAndGeneral,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/InsuranceDetailsListGet`, {
      familyId,
      status,
      providerId,
      policyNumber,
      IsProviderFireAndGeneral,
    });
  }

  insuranceQuoteDelete(quoteId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/InsuranceQuoteDelete`, {
      quoteId,
    });
  }

  insuranceDetailsDelete(familyId, policyId) {
    return this.httpClient.delete(`${this.apiBaseUrl}/InsuranceDetailsDelete`, {
      familyId,
      policyId,
    });
  }

  insuranceFamilyProviderGet(familyId, status) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceFamilyProviderGet`,
      { familyId, status },
    );
  }

  insuranceFamilyPoliciesGet(familyId, status) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceFamilyPoliciesGet`,
      { familyId, status },
    );
  }

  insuranceQuoteWeekWaitPeriodGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteWeekWaitPeriodGet`,
    );
  }

  insuranceQuoteIPBenefitPeriodGet() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/InsuranceQuoteIPBenefitPeriodGet`,
    );
  }

  insuranceQuoteAndProfilerDOBSet(apiContactBaseUrl, data = {}) {
    const { dob, familyId } = data;
    return this.httpClient.post(
      `${apiContactBaseUrl}/dob/${familyId}?dateBirth=${dob}`,
    );
  }

  getLoadingList() {
    return this.$q.resolve([
      { Name: 'Select an option', id: 0 },
      { Name: '0%', id: 1 },
      { Name: '50%', id: 2 },
      { Name: '75%', id: 3 },
      { Name: '100%', id: 4 },
      { Name: '125%', id: 5 },
      { Name: '150%', id: 6 },
      { Name: '175%', id: 7 },
      { Name: '200%', id: 8 },
      { Name: '225%', id: 9 },
      { Name: '250%', id: 10 },
      { Name: '275%', id: 11 },
      { Name: '300%', id: 12 },
      { Name: '325%', id: 13 },
      { Name: '350%', id: 14 },
      { Name: '400%', id: 15 },
      { Name: '500%', id: 16 },
    ]);
  }
}

export default ContactInsuranceService;
