import { isOwnBrand } from 'Common/utilities/brandingCategory';

export const shouldNotSetSidebarDefaultLogo = (
  brandingCategoryId,
  isOnboarding,
) => {
  return !!(isOwnBrand(brandingCategoryId) || isOnboarding);
};

export const checkAnnouncement = ({
  currentUserService,
  $window,
  announcementReminderService,
}) => {
  const announcementTemporaryChecker = $window.localStorage.getItem(
    'myCRM_checkAnnouncement',
  );
  const snoozeValue = currentUserService.snoozeAnnouncement ? 1 : 0;
  const isCheckAnnouncement = snoozeValue || announcementTemporaryChecker;
  if (snoozeValue) {
    announcementReminderService.setAnnouncementTimer();
  }
  return isCheckAnnouncement;
};
