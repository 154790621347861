class ModalGoogleAnalyticsService {
  constructor($window) {
    'ngInject';

    this.analyticsDataLayer = $window.dataLayer;
    this.maxContentChildLength = 1;
    this.exludeModals = [
      'announcement-modal',
      'modal-popup-blocker',
      'modal-demo-video',
      'modal-demo-multi-video',
      'survey-iframe',
    ];
    this.styleNodeName = 'STYLE';
    this.divNodeName = 'DIV';
  }

  triggerAnalytic(modal) {
    if (modal && this.exludeModals.includes(modal)) {
      return;
    }

    const data = { event: 'modal', ...(modal && { modal }) };
    if (typeof this.analyticsDataLayer === 'undefined') {
      return;
    }
    this.analyticsDataLayer.push(data);
  }

  checkIfChildContentIsInvalid(childLength, firstChild, isComponent = false) {
    if (
      this.checkChildLength(childLength) &&
      !this.checkIfFirstChildIsAStyle(firstChild) &&
      !isComponent
    ) {
      this.triggerAnalytic();
      return true;
    }
  }

  checkChildLength(childLength) {
    return childLength > this.maxContentChildLength;
  }

  checkIfFirstChildIsAStyle(firstChild) {
    return firstChild.nodeName === this.styleNodeName;
  }

  checkIfFirstChildIsADiv(firstChild) {
    return firstChild.nodeName === this.divNodeName;
  }

  checkElement(contentFirstChild) {
    if (!this.checkIfFirstChildIsADiv(contentFirstChild)) {
      const {
        firstChild: componentFirstChild,
        nodeName,
        children,
      } = contentFirstChild;

      const contentNodeName = nodeName.toLowerCase();

      const isInvalidChildren = this.checkIfChildContentIsInvalid(
        children.length,
        componentFirstChild,
        true,
      );

      const modalIdentifier = isInvalidChildren
        ? contentNodeName
        : componentFirstChild.id || contentNodeName;
      this.triggerAnalytic(modalIdentifier);
    } else {
      this.triggerAnalytic(contentFirstChild.id || null);
    }
  }
}

export default ModalGoogleAnalyticsService;
