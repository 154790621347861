import angular from 'angular';
import _ from 'lodash';
import { BROKER_NOTES_ID } from 'Common/constants/loanAppBrokerNotes';
import {
  INSURANCE_LOAN_APP,
  INSURANCE_LOAN_APP_NZ,
} from 'Common/constants/insuranceOptions';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { WATCHER_CONTEXT } from 'Common/constants/watcherContext';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { parseToInt10 } from 'Common/utilities/parse';
import { excludedInsuranceQuestions } from 'Common/config/loanAppQuestions';
import {
  saveBrokerNotesInTemplateUtil,
  noteListInitUtil,
} from 'Common/utilities/loanApplication';
import {
  LOAN_APP_REFERRAL_PARTNERS,
  LOAN_APP_INSURANCE,
} from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import {
  getInsuranceTitleByCountryName,
  assignInsuranceBrokerNotes,
  validateRiskInsurance,
  validateDiscussLifeStyle,
} from './../util/clientAccess/insurance';
import { hasDefinedValue } from './../util/loanAppValidation';
import { setCompletionStatus } from './../util/loanAppCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanAppInsuranceCtrl', function LoanAppInsuranceCtrl(
      $scope,
      $state,
      $timeout,
      $stateParams,
      loanScenarioService,
      loanApplicationServices,
      optionsService,
      watcherService,
      timeoutService,
      loanAppSharedData,
      configService,
      loanAppDetailsService,
      currentUserService,
      toaster,
      loanScenarioModelService,
      uiService,
    ) {
      $scope.isInsuranceLoaded = false;
      const getValue = (key) => $scope.insuranceObjSet[key];
      const isValueDefined = hasDefinedValue(getValue);

      // eslint-disable-next-line sonarjs/cognitive-complexity
      const initializeInsurance = () => {
        // Property
        let clickCounter = 0;

        angular.extend($scope, {
          loanAppId: $stateParams.loanAppId,
          familyId: $stateParams.familyId,
          generalInsuranceOption: [],
          lifestyleInsuranceOption: [],
          riskInsuranceOption: [],
          insuranceQuestionList: [],
          insuranceObjSet: {},
          editExecCount: 0,
          uiSettings: {
            isTouched: false,
          },
          questions: INSURANCE_LOAN_APP.QUESTIONS,
          displayTypeIds: INSURANCE_LOAN_APP.DISPLAY_ID,
          generalInsuranceQuestionIds: [
            INSURANCE_LOAN_APP.QUESTIONS.GENERAL_INSURANCE,
            INSURANCE_LOAN_APP_NZ.QUESTIONS.GENERAL_INSURANCE,
          ],
        });
        $scope.BROKER_NOTES_ID = BROKER_NOTES_ID;
        $scope.excludedQuestions = excludedInsuranceQuestions;
        $scope.insuranceSectionTitle = getInsuranceTitleByCountryName(
          currentUserService.isAU,
          uiService.viewOfAfileTurnedOn && $state.params.opportunityId,
        );

        /* listeners */
        $scope.$on('isLodged', (event, data) => {
          $scope.isLodged = data;
        });

        /* end of listeners */
        const summaryData = loanAppSharedData.loanAppSummary;
        if (summaryData) {
          const { LoanDetails: loanDetails } = summaryData;
          const { LendingCategoryId: lendingCategoryId } = loanDetails;
          $scope.isAssetFinanceOrPersonal =
            lendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE ||
            lendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN;
        }
        $scope.processInsuranceSection = (fromUserClick = true) => {
          $scope.isInsuranceLoaded = true;
          performance.start(LOAN_APP_REFERRAL_PARTNERS);
          if (clickCounter !== 0) {
            return;
          }

          fromUserClick && clickCounter++;

          // Method
          const saveBrokersArgs = {
            scope: $scope,
            services: {
              loanScenarioService,
              toaster,
            },
          };
          $scope.saveBrokerNotesInTemplate = (brokerNotes) =>
            saveBrokerNotesInTemplateUtil({
              ...saveBrokersArgs,
              brokerNotes,
            });
          const assignBrokerNotes = (brokerNotesResponse) =>
            assignInsuranceBrokerNotes($scope, brokerNotesResponse);
          const noteListInitBrokerDeclaration = () =>
            noteListInitUtil({
              scope: $scope,
              loanScenarioModelService,
              assignBrokerNotes,
            });
          angular.extend($scope, {
            init() {
              const isAssetFinanceFeatureOn =
                configService.feature.assetFinance;
              const { LoanDetails } = loanAppSharedData.loanAppSummary;
              const isAssetFinance =
                LoanDetails &&
                (LoanDetails.LendingCategoryId ===
                  LENDING_CATEGORY.ASSET_FINANCE ||
                  LoanDetails.LendingCategoryId ===
                    LENDING_CATEGORY.PERSONAL_LOAN);
              $scope.showAssetInsuranceDetails =
                isAssetFinanceFeatureOn && isAssetFinance;

              loanAppSharedData.insurance.section.excludeIds.push(
                INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE,
              );

              if (fromUserClick) {
                optionsService.GeneralInsuranceGet().then((response) => {
                  $scope.generalInsuranceOption = response.data;
                });
                optionsService.LifestyleInsuranceGet().then((response) => {
                  $scope.lifestyleInsuranceOption = response.data;
                });
                optionsService.RiskInsuranceGet().then((response) => {
                  $scope.riskInsuranceOption = response.data;
                });
                optionsService.question('insurance').then((response) => {
                  const { data } = response;
                  if (!data || !data.length) {
                    return;
                  }
                  const insuranceOrder = [
                    INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE,
                    INSURANCE_LOAN_APP.QUESTIONS.GENERAL_INSURANCE,
                    INSURANCE_LOAN_APP.QUESTIONS.CONCIERGE,
                    INSURANCE_LOAN_APP.QUESTIONS.HOMENOW,
                    INSURANCE_LOAN_APP.QUESTIONS.COMMENTS,
                  ];
                  const mappedQuestions = data.map((question) => {
                    const questionOrder = insuranceOrder.indexOf(
                      question.QuestionId,
                    );
                    question.forOrdering =
                      questionOrder !== -1
                        ? questionOrder
                        : insuranceOrder.length;
                    return question;
                  });

                  $scope.insuranceQuestionList =
                    mappedQuestions &&
                    mappedQuestions.filter((question) => {
                      return (
                        question.QuestionId !==
                          INSURANCE_LOAN_APP.QUESTIONS.HOMENOW ||
                        (question.QuestionId ===
                          INSURANCE_LOAN_APP.QUESTIONS.HOMENOW &&
                          loanAppDetailsService.isHomeNowEnabled())
                      );
                    });
                  $scope.orderByInsurance = 'forOrdering';
                });
              }

              $scope.getInsuranceQuestionValue();
              noteListInitBrokerDeclaration();
              $scope.$on('$destroy', () => {
                watcherService.destroyWatchers(
                  WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL,
                );
                timeoutService.cancelTimeouts(
                  WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL,
                );
              });
            },

            getInsuranceQuestionValue() {
              loanScenarioService
                .loanInsuranceGet($scope.loanAppId)
                .then((response) => {
                  _.forEach(response.data, (obj) => {
                    let answerValue = null;
                    switch (true) {
                      case typeof obj.answerInt !== 'undefined':
                        answerValue = String(
                          obj.answerInt.length ? obj.answerInt[0] : '',
                        );
                        break;
                      case typeof obj.answerString !== 'undefined':
                        answerValue = obj.answerString.length
                          ? obj.answerString[0]
                          : '';
                        break;
                      case typeof obj.answerBool !== 'undefined':
                        answerValue = obj.answerBool.length
                          ? obj.answerBool[0]
                          : false;
                        break;
                      default:
                        break;
                    }
                    $scope.insuranceObjSet[obj.QuestionId] = answerValue;
                  });
                  $scope.validateInsurance();
                });
            },

            recheckRequiredFields() {
              _.remove(
                loanAppSharedData.insurance.section.excludeIds,
                (o) =>
                  o === INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE,
              );
              _.remove(
                loanAppSharedData.insurance.section.excludeIds,
                (o) =>
                  o ===
                  INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER,
              );
              _.remove(
                loanAppSharedData.insurance.section.excludeIds,
                (o) => o === INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP,
              );
              _.remove(
                loanAppSharedData.insurance.section.excludeIds,
                (o) => o === INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER,
              );
              if (
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
                ]
              ) {
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                ] = false;
                loanAppSharedData.insurance.section.excludeIds.push(
                  INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE,
                );
              } else {
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER
                ] = false;
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP
                ] = false;
                $scope.insuranceObjSet[
                  INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER
                ] = false;
                loanAppSharedData.insurance.section.excludeIds.push(
                  INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER,
                  INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP,
                  INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER,
                );
              }
            },

            resetLifestyleQuestion() {
              const options = [
                INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE,
                INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER,
                INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP,
                INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER,
              ];
              _.each(options, (o) => {
                const insuranceSet = {
                  LoanScenarioId: $scope.loanAppId,
                  FamilyId: $stateParams.familyId,
                  QuestionId: o,
                  DisplayOptionId:
                    o !== INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                      ? INSURANCE_LOAN_APP.FIELD.RADIOBOX_ON_YES
                      : INSURANCE_LOAN_APP.FIELD.RADIOBOX_ON_NO,
                  answerBool: [false],
                };
                loanScenarioService.loanInsuranceSet(insuranceSet);
              });
            },

            updateInsuranceQuestion(
              questionId,
              displayType,
              displayTypeId,
              otherArgs,
            ) {
              if (
                questionId === INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
              ) {
                $scope.resetLifestyleQuestion();
                $scope.recheckRequiredFields();
              }

              // if text is empty will return to avoid undefined
              if (
                typeof $scope.insuranceObjSet[questionId] === 'undefined' &&
                !otherArgs
              ) {
                return false;
              }
              const otherArgsId = parseToInt10(
                _.get(otherArgs, 'mainObj.QuestionId'),
              );
              $scope.toggleTouch();
              let insuranceSet = {};
              if (!otherArgs) {
                insuranceSet = {
                  LoanScenarioId: $scope.loanAppId,
                  FamilyId: $stateParams.familyId,
                  QuestionId: questionId,
                  DisplayOptionId: displayTypeId,
                };

                switch (displayType) {
                  case 'CheckBox':
                  case 'RadioButton':
                  case 'YesNoOnly':
                  case 'RadioBoxOnYes':
                  case 'RadioBoxOnNo':
                    insuranceSet.answerBool = [
                      $scope.insuranceObjSet[questionId],
                    ];
                    break;
                  case 'TextboxOnNo':
                  case 'TextboxOnYes':
                  case 'TextOnly':
                    insuranceSet.answerString = [
                      String($scope.insuranceObjSet[questionId]),
                    ];
                    break;
                  case 'MultiDropDown':
                  case 'SingleDropDown':
                  case 'SingleDropDownNoFrame':
                    insuranceSet.answerInt = [
                      parseInt($scope.insuranceObjSet[questionId], 10),
                    ];
                    break;
                  default:
                    break;
                }
                loanScenarioService.loanInsuranceSet(insuranceSet);
              } else if (
                [
                  INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE,
                  INSURANCE_LOAN_APP.QUESTIONS.RISK_INSURANCE,
                ].includes(otherArgsId)
              ) {
                let subQuestions = angular.copy(otherArgs.mainObj.SubQuestion);
                const filterSubQuestions = (o) =>
                  o.QuestionDisplayType === 'RadioBoxOnYes' ||
                  o.QuestionDisplayType === 'RadioBoxOnNo';
                subQuestions = _.filter(subQuestions, filterSubQuestions);

                _.forEach(subQuestions, (o) => {
                  if (
                    o.QuestionDisplayType === 'RadioBoxOnYes' ||
                    o.QuestionDisplayType === 'RadioBoxOnNo'
                  ) {
                    // uncheck the rest
                    $scope.insuranceObjSet[o.QuestionId] =
                      parseInt(questionId, 10) === parseInt(o.QuestionId, 10);

                    insuranceSet = {
                      LoanScenarioId: $scope.loanAppId,
                      FamilyId: $stateParams.familyId,
                      QuestionId: o.QuestionId,
                      DisplayOptionId: o.QuestionDisplayTypeId,
                      answerBool: [
                        parseInt(questionId, 10) === parseInt(o.QuestionId, 10),
                      ],
                    };
                    loanScenarioService.loanInsuranceSet(insuranceSet);
                  }
                });
              }
              $scope.validateInsurance();
            },
            validateRiskInsurance: validateRiskInsurance({
              currentUserService,
              isValueDefined,
              getValue,
            }),
            validateDiscussLifeStyle: validateDiscussLifeStyle({
              currentUserService,
              isValueDefined,
              getValue,
            }),
            validateInsurance() {
              let validDeclarationProp = false;
              let countDeclarationPop = 0;

              _.each($scope.insuranceObjSet, (val, key) => {
                const touchedLifestyle =
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
                  ] ||
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
                  ] === false;
                if (touchedLifestyle) {
                  validDeclarationProp =
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                    ] ||
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS
                        .WEALTH_MARKET_FINANCIAL_ADVISER
                    ] ||
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP
                    ] ||
                    $scope.insuranceObjSet[
                      INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER
                    ];
                }

                if (val) {
                  validDeclarationProp = true;
                }

                if (
                  validDeclarationProp &&
                  !loanAppSharedData.insurance.section.excludeIds.includes(
                    parseInt(key, 10),
                  )
                ) {
                  countDeclarationPop++;
                }
              });

              $scope.isValidRiskInsurance = $scope.validateRiskInsurance();
              $scope.isValidDiscussLifeStyle = $scope.validateDiscussLifeStyle();

              $scope.isValidGeneralInsurance = currentUserService.isAU
                ? isValueDefined(INSURANCE_LOAN_APP.QUESTIONS.GENERAL_INSURANCE)
                : isValueDefined(
                    INSURANCE_LOAN_APP_NZ.QUESTIONS.GENERAL_INSURANCE,
                  );

              $scope.isStartedInsurance = countDeclarationPop >= 1;
              $scope.isValidInsurance =
                $scope.isValidRiskInsurance &&
                $scope.isValidDiscussLifeStyle &&
                $scope.isValidGeneralInsurance &&
                validDeclarationProp &&
                countDeclarationPop >= 2;

              setCompletionStatus({
                $scope,
                $timeout,
                sectionId: SECTION_ID.INSURANCE,
                loanAppSharedData,
                isValidSection: $scope.isValidInsurance,
              });

              $scope.countValids($scope.isValidInsurance, 'insurance');
            },

            toggleTouch() {
              $scope.uiSettings.isTouched = true;
            },

            incEditCount() {
              const incEditCountTimeout = $timeout(() => {
                if ($scope.isValidInsurance) {
                  $scope.editExecCount++;
                }
              }, 200);
              timeoutService.addTimeout(
                incEditCountTimeout,
                WATCHER_CONTEXT.LOAN_APP_INSURANCE_CTRL,
              );
            },

            checkHasAnswer: (hasAnswer, questionId) => {
              if (
                parseInt(questionId, 10) ===
                INSURANCE_LOAN_APP.QUESTIONS.DISCUSS_LIFESTYLE
              ) {
                const discussLifestyle =
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.WEALTH_MARKET_FINANCIAL_ADVISER
                  ] ||
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.ALI_GROUP
                  ] ||
                  $scope.insuranceObjSet[
                    INSURANCE_LOAN_APP.QUESTIONS.EXISTING_ADVISER
                  ];
                return (
                  ((hasAnswer && discussLifestyle) ||
                    (!hasAnswer &&
                      $scope.insuranceObjSet[
                        INSURANCE_LOAN_APP.QUESTIONS.DONT_DISCUSS_LIFESTYLE
                      ])) &&
                  $scope.isValidDiscussLifeStyle
                );
              }
              if (questionId === INSURANCE_LOAN_APP.QUESTIONS.HOMENOW) {
                return (
                  hasAnswer &&
                  !!$scope.insuranceObjSet[INSURANCE_LOAN_APP.QUESTIONS.HOMENOW]
                );
              } else if (
                questionId === INSURANCE_LOAN_APP.QUESTIONS.RISK_INSURANCE
              ) {
                return $scope.isValidRiskInsurance;
              }
              if ($scope.generalInsuranceQuestionIds.includes(questionId)) {
                return $scope.isValidGeneralInsurance;
              }
              return hasAnswer;
            },
          });

          $scope.init();
          const duration = performance.endPaint(LOAN_APP_REFERRAL_PARTNERS);
          loanAppSectionData({
            label: currentUserService.isNZ
              ? LOAN_APP_INSURANCE
              : LOAN_APP_REFERRAL_PARTNERS,
            value: duration,
          });
        };

        const sectionObject = loanAppSharedData.getSectionObjectById(
          loanAppSharedData.completionListObject,
          SECTION_ID.INSURANCE,
        );
        $scope.countValids(sectionObject.IsCompleted, 'insurance');
        $scope.processInsuranceSection(false);
      };
      $scope.$watch('isLoanAppLoaded', () => {
        if (!$scope.isLoanAppLoaded) {
          return;
        }
        initializeInsurance();
      });
    });
