class ColorSelectorCtrl {
  onBeforeShow() {
    this.allowChange = true;
  }

  changeColorPicker(color) {
    if (!this.allowChange || !color) {
      return;
    }
    this.showColorPicker = false;
    this.colorModel = color;
    this.onColorChange({ color });
  }

  displayColorPicker(flag = true) {
    this.showColorPicker = flag;
  }

  cancelColorSelect() {
    this.displayColorPicker(false);
    this.onCancelSelect();
  }

  colorPickerMoved(color) {
    this.currentColor = color;
  }

  isColorChanged() {
    return this.brandingColor !== this.currentColor;
  }

  $onInit() {
    this.allowChange = false;
    this.currentColor = this.brandingColor;
    this.colorpickerOpts = {
      color: this.brandingColor,
      showInput: true,
      showInitial: true,
      hideAfterPaletteSelect: true,
      preferredFormat: 'hex',
      clickoutFiresChange: false,
      flat: true,
    };
  }
}

export default ColorSelectorCtrl;
