import _ from 'lodash';
import moment from 'moment';
import { SEND_METHOD_TYPES } from 'Common/constants/sendMethodTypes';
import {
  FIXED_RATE_EXPIRES_ID,
  APPROVAL_EXPIRIES_ID,
  BIRTHDAYS_ID,
  ANNUAL_REVIEWS_ID,
  POST_SETTLEMENT_FOLLOW_UP_ID,
  INSURANCE_TAB_ID,
  INSURANCE_TABS,
} from 'Common/constants/customerCareModules';
import { D_MMM_FORMAT } from 'Common/constants/dateConfigs';
import { toastWarning, toastSuccess, toastError } from 'Common/utilities/alert';
import { stripHtmlSpaces } from 'Common/utilities/string';
import {
  sendFixedRateEmailBuilderForUI,
  sendInsuranceEmailBuilderForUI,
  mergeFieldsMapperForUI,
} from 'Common/mappers/customerCare';
import { addMergeFieldsCategory } from 'Common/utilities/customerCare';
import { mapAssistantsToDropdown } from 'Components/sendEmail/util/sendEmailComponentCtrl';

export default class CustomerCareSendEmail {
  constructor(
    $q,
    $uibModal,
    $window,
    $timeout,
    emailTemplatesService,
    ckEditorConfigService,
    templateService,
    mailTemplateService,
    emailTemplatesSharedData,
    currentUserService,
    commonFnService,
    contactService,
    customerCareService,
    customerCareInsuranceService,
    customerCareTemplateService,
    corporateModelService,
    configService,
    optionsService,
    uiService,
  ) {
    'ngInject';

    this.$q = $q;
    this.$uibModal = $uibModal;
    this.$window = $window;
    this.$timeout = $timeout;
    this.emailTemplatesService = emailTemplatesService;
    this.ckEditorConfigService = ckEditorConfigService;
    this.templateService = templateService;
    this.mailTemplateService = mailTemplateService;
    this.emailTemplatesSharedData = emailTemplatesSharedData;
    this.currentUserService = currentUserService;
    this.commonFnService = commonFnService;
    this.contactService = contactService;
    this.customerCareService = customerCareService;
    this.customerCareInsuranceService = customerCareInsuranceService;
    this.customerCareTemplateService = customerCareTemplateService;
    this.corporateModelService = corporateModelService;
    this.configService = configService;
    this.optionsService = optionsService;
    this.uiService = uiService;
  }

  $onInit() {
    this.isAdviserDropdownChanged = false;
    this.defaultAdviserList = [];
    this.modifiedAdviserList = [];
    this.DEFAULT_ADVISER = 'Default adviser';
    this.isEnabledFromToFeature = this.uiService.isSetToFromEmailEnabled;
    this.defaultAdviserOption = {
      familyId: '',
      email: '',
      fullName: this.DEFAULT_ADVISER,
    };
    this.selectedEmailAdviser = this.defaultAdviserOption;
    this.currentModule = this.isInsurance ? this.tab.id + 7 : this.tab.id;
    this.isComplete = !this.currentUserService.isNZ;
    this.selectAllEmailList = [];
    this.singleData =
      this.singleData && this.singleData.customer
        ? this.singleData
        : this.tableData[0];
    this.isLoading = false;
    this.validEmailsCount = 0;
    this.invalidEmailContentCount = 0;
    this.selectedCustomer = {};
    this.selectedCustomerIndex = 0;
    this.emailMergeFieldsLoaded = false;
    this.recipients = [];
    this.recipientLimit = 2;
    this.templateLimit = 2;
    this.tmpAttachedFile = [];
    this.attachments = [];
    this.attachedFile = [];
    this.templatesStatus = {
      emailTemplatesLoaded: false,
      defaultTemplateLoaded: false,
    };
    this.modelOptions = {
      debounce: {
        default: 500,
        blur: 250,
      },
      getterSetter: true,
    };

    this.email = {
      body: '',
      subject: '',
    };
    this.emailTemplateDefault = {};
    this.emailDefault = {};
    this.emailTemplateListing = [];

    this.options = this.ckEditorConfigService.getCustomerCareSelectConfig(503);
    this.content = this.ckEditorConfigService.getDefaultContent();

    this.getEmailTemplates();
    if (this.isEnabledFromToFeature) {
      this.getAdviserListFromRecipients();
    }
  }

  addAdviserEmailToRecipients(adviser = this.defaultAdviserOption) {
    this.selectedEmailAdviser = adviser;
    this.tableData = this.tableData.map((recipient) => ({
      ...recipient,
      adviserEmailAddress: adviser.email || this.selectedEmailAdviser.email,
      adviserName:
        this.DEFAULT_ADVISER === adviser.fullName
          ? ''
          : adviser.fullName || this.selectedEmailAdviser.fullName,
    }));
  }

  adviserChanged(adviser) {
    this.isAdviserDropdownChanged = true;
    this.addAdviserEmailToRecipients(adviser);
  }

  getAdviserListFromRecipients() {
    let assignedAdviserPromises = [];
    this.tableData.forEach((recipient) => {
      assignedAdviserPromises = [
        ...assignedAdviserPromises,
        this.contactService.getContactAssignedAdviser(recipient.familyID),
      ];
    });
    this.$q
      .all(assignedAdviserPromises)
      .then((responses) => {
        const assignedAdviserIds = [
          ...new Set(responses.map((adviser) => adviser.data)),
        ];

        this.defaultAdviserList = this.adviserList.filter((adviser) => {
          return assignedAdviserIds.includes(adviser.familyId);
        });

        this.modifiedAdviserList = this.adviserList.filter(
          (adviser) => adviser.familyId,
        );

        if (this.modifiedAdviserList.length > 1) {
          this.modifiedAdviserList = [
            this.defaultAdviserOption,
            ...this.modifiedAdviserList,
          ];
          this.addAdviserEmailToRecipients();
        } else {
          this.selectedEmailAdviser = _.get(
            this.modifiedAdviserList,
            '[0]',
            this.defaultAdviserOption,
          );
          this.addAdviserEmailToRecipients(this.selectedEmailAdviser);
        }
        return this.optionsService.assistantsWithEmailGet(
          this.currentUserService.familyId,
        );
      })
      .then((data) => {
        const mappedAssistantList = data.map(mapAssistantsToDropdown);
        this.modifiedAdviserList = [
          ...this.modifiedAdviserList,
          ...mappedAssistantList,
        ];
      });
  }

  viewMoreTemplate() {
    this.templateLimit = this.emailTemplateListing.length;
  }

  onAllTemplatesLoaded() {
    const isTemplatedReady =
      this.templatesStatus.emailTemplatesLoaded &&
      this.templatesStatus.defaultTemplateLoaded &&
      this.defaultTemplate;

    if (isTemplatedReady) {
      const defaultIsBlankTemplate =
        this.blankTemplate &&
        this.blankTemplate.UserTemplateID ===
          this.defaultTemplate.UserTemplateID;
      if (defaultIsBlankTemplate) {
        this.setTemplateSelection(this.blankTemplate);
        return;
      }
      this.initNamedDefaultTemplate();
    }
    this.emailMergeFieldsLoaded = true;
  }

  removeRecipient(user, index) {
    if (typeof index === 'undefined') {
      this.tableData = this.tableData.filter(
        (item) =>
          item.tableRecordID !== user.tableRecordID ||
          item.loanStructureID !== user.loanStructureID,
      );
      this.selectedCustomer = {};
    } else if (index > -1) {
      this.tableData.splice(index, 1);
    }
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  getEmailTemplates() {
    this.emailTemplatesService.getEmailTemplates().then((response) => {
      if (!response) {
        return;
      }

      this.emailTemplateListing = response.emailTemplateListing;
      this.blankCategory = response.blankCategory;
      this.blankTemplate = response.blankTemplate;
      this.templatesStatus = {
        emailTemplatesLoaded: true,
        defaultTemplateLoaded: false,
      };
      this.getDefaultTemplate();
    });
  }

  getDefaultTemplate() {
    this.templateService
      .getModuleDefaultTemplate(this.currentModule, SEND_METHOD_TYPES.EMAIL)
      .then((response) => {
        this.defaultTemplate = response.data;
        this.templatesStatus.defaultTemplateLoaded = true;
        this.onAllTemplatesLoaded();
      });
  }

  isValidEmailTemplate() {
    return !!this.email.subject.trim();
  }

  invalidEmailContent() {
    this.invalidEmailContentCount = 0;
    this.tableData.map((item) => {
      if (!item.subject || (item.subject && !item.subject.trim())) {
        if (this.isValidEmailTemplate() && item.isSelected) {
          item.body = this.email.body;
          item.subject = this.email.subject;
        } else {
          this.invalidEmailContentCount = this.invalidEmailContentCount + 1;
        }
        if (!item.isSelected) {
          this.selectCustomer(item);
        }
      }
      return item;
    });
    return this.invalidEmailContentCount;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  formatCustomersToSend() {
    this.validEmailsCount = 0;
    this.selectAllEmailList = [];

    this.tableData.map((item) => {
      const emails = item.email ? item.email.split(',') : [''];

      const expDate = moment(item[this.tab.dateName]).format(D_MMM_FORMAT);
      const notiHeader = `${this.tab.label} (${expDate}) Completed via Email`;

      emails.map((email) => {
        const isValid = !!email;
        this.validEmailsCount = isValid
          ? this.validEmailsCount + 1
          : this.validEmailsCount;

        const formattedSubject = item.isSelected
          ? this.email.subject
          : item.emailSubject;
        const formattedBody = item.isSelected
          ? this.email.body
          : item.emailBody;
        const eachContact = {
          ...item,
          email,
          attachments: this.attachments,
          body: formattedBody || '',
          subject: formattedSubject || '',
        };
        const contact = !this.isInsurance
          ? sendFixedRateEmailBuilderForUI(eachContact, notiHeader)
          : sendInsuranceEmailBuilderForUI(eachContact, notiHeader);
        this.selectAllEmailList.push(contact);
        return email;
      });
      return item;
    });
    if (this.selectAllEmailList.length > 0) {
      this.recipients.email = this.selectAllEmailList;
      this.noOfPeople = this.selectAllEmailList.length;
    }
    return this.validEmailsCount;
  }

  sendEmail() {
    if (!this.selectedCustomer.customer) {
      this.assignEditedBeforeSubmit();
    }
    if (this.invalidEmailContent()) {
      toastError(`Email subject is required.`);
      return;
    }

    const submitTimout = this.$timeout(() => {
      const validEmailsCount = this.formatCustomersToSend();

      if (!validEmailsCount) {
        toastError(`Unable to send email. Please check client's email.`);
        return;
      }

      this.isLoading = true;
      const customerCareType = this.tab.type;
      const params = {
        EmailSubject: this.email.emailSubject,
        EmailBody: this.email.emailBody,
        CustomerCareType: customerCareType,
        IsComplete: this.isComplete,
        IsCustomBulk: true,
        ClientList: this.recipients.email,
      };
      const formattedParams = addMergeFieldsCategory(params);

      const insuranceParam =
        this.isInsurance && this.tab.type === INSURANCE_TABS[1].type
          ? { tableRecordCsvId: this.singleData.tableRecordIDCSV }
          : {};
      const sendEmail = this.isInsurance
        ? this.customerCareInsuranceService.sendEmail(
            formattedParams,
            insuranceParam,
          )
        : this.customerCareService.CustomerCareEmailSendv2(formattedParams);

      sendEmail.then((response) => {
        this.isLoading = false;
        if (response.data && response.data.length) {
          toastSuccess('Email successfully sent.');
          this.modalInstance.close({ success: true });
        }
      });
      this.$timeout.cancel(submitTimout);
    }, 0);
  }

  onContactSelected(item) {
    this.searchRecipient = '';
    this.tableData.push({
      ...item,
      ...this.email,
    });
  }

  getRecipientAutoSuggestion(userInput) {
    return this.contactService
      .getRecipientAutoSuggestion(userInput)
      .then(({ data }) => {
        this.selectEmailList = [];

        if (!data) {
          return this.selectEmailList;
        }

        this.withEmail = data;

        this.selectEmailList = this.withEmail.reduce((accum, curr) => {
          const { Name: customer, EmailAddress: email } = curr;
          if (!email) {
            return accum;
          }
          return accum.concat({ customer, email });
        }, []);
        return this.selectEmailList;
      })
      .catch(() => {
        this.selectEmailList = [];
        return this.selectEmailList;
      });
  }

  initNamedDefaultTemplate() {
    let defaultTemplateIndex = -1;
    const folder = _.find(this.emailTemplateListing, (fldr) => {
      defaultTemplateIndex = _.findIndex(
        fldr.EmailTemplates,
        (template) =>
          template.UserTemplateID === this.defaultTemplate.UserTemplateID,
      );
      return defaultTemplateIndex !== -1;
    });
    const defaultIsNamedTemplate = folder && defaultTemplateIndex !== -1;
    if (!defaultIsNamedTemplate) {
      return;
    }

    this.setTemplateSelection(
      folder.EmailTemplates[defaultTemplateIndex],
      defaultTemplateIndex,
    );
  }

  useTemplate(template, templateIndex) {
    if (!template) {
      return;
    }

    const {
      UserTemplateID: id,
      UserTemplateName: name,
      UserTemplateContent: content,
      UserTemplateEmailSubject: subject,
    } = template;
    this.setTemplateSelection(id, name, content, subject, templateIndex);
  }

  setTemplateSelection(template = {}, index = -1) {
    this.attachments = [];
    this.isLoadingAttachment = !!template.AttachmentDocumentIds;
    this.selectedTemplate = { ...template };
    this.iconChange = !this.iconChange;
    this.showTick = true;

    if (!this.email) {
      this.email = {};
    }
    this.translateTemplate(
      template.UserTemplateContent || '',
      template.UserTemplateEmailSubject || '',
    );
    this.index = index;
    this.templateService
      .getTemplateAttachments(template.AttachmentDocumentIds)
      .then((attachments) => {
        this.isLoadingAttachment = false;
        this.attachments = [...attachments];
      })
      .catch(() => {
        this.isLoadingAttachment = false;
      });
  }

  attachFileResult(modalInstance) {
    return modalInstance.result.then((arr) => {
      this.attachedFile = arr;
      this.attachedFile &&
        Object.keys(this.attachedFile).forEach((x) => {
          this.tmpAttachedFile.push(this.attachedFile[x]);
        });
      this.attachments = [...this.attachments, ...(this.tmpAttachedFile || [])];
    });
  }

  openAttachment() {
    const modalInstance = this.commonFnService.attachFileMgmt(this.familyId);
    this.attachFileResult(modalInstance);
  }

  resetActiveTemplate(item, index) {
    const isSubjectEdited =
      stripHtmlSpaces(this.$window, item.emailSubject) !==
      stripHtmlSpaces(this.$window, this.email.subject);
    const isBodyEdited =
      stripHtmlSpaces(this.$window, item.emailBody) !==
      stripHtmlSpaces(this.$window, this.email.body);
    item.subject = isSubjectEdited ? this.email.subject : item.subject;
    item.body = isBodyEdited ? this.email.body : item.body;
    this.assignMergedDataToContact(item.body, item.subject, item, index);
    this.email = { ...this.emailDefault };
  }

  assignTemplateToUnselected() {
    this.tableData.map((item, index) => {
      const isValidIdMatching = this.validIdMatching(
        this.selectedCustomer,
        item,
      );

      if (isValidIdMatching) {
        item.subject = this.email.subject;
        item.body = this.email.body;
        return this.assignMergedDataToContact(
          this.email.body,
          this.email.subject,
          item,
          index,
        );
      }
      return item;
    });
  }

  validTabIdChecker(customer) {
    const isValidLoanId =
      (!this.isInsurance &&
        ((customer.loanID && this.tab.id === APPROVAL_EXPIRIES_ID) ||
          this.tab.id === POST_SETTLEMENT_FOLLOW_UP_ID)) ||
      (customer.familyID && this.tab.id === BIRTHDAYS_ID) ||
      this.tab.id === ANNUAL_REVIEWS_ID ||
      (customer.loanStructureID && this.tab.id === FIXED_RATE_EXPIRES_ID);
    const isValidInsuranceId =
      this.isInsurance &&
      ((customer.tableRecordID &&
        this.tab.id !== INSURANCE_TAB_ID.ISSUED_FOLLOWUP) ||
        (customer.tableRecordIDCSV &&
          this.tab.id === INSURANCE_TAB_ID.ISSUED_FOLLOWUP));
    return isValidInsuranceId || isValidLoanId;
  }

  validIdMatching(customer, item) {
    const matchLoanStructureID =
      !this.isInsurance &&
      this.tab.id === FIXED_RATE_EXPIRES_ID &&
      customer.loanStructureID &&
      customer.loanStructureID === item.loanStructureID;
    const matchLoanID =
      !this.isInsurance &&
      (this.tab.id === APPROVAL_EXPIRIES_ID ||
        this.tab.id === POST_SETTLEMENT_FOLLOW_UP_ID) &&
      customer.loanID &&
      customer.loanID === item.loanID;
    const matchFamilyID =
      !this.isInsurance &&
      (this.tab.id === BIRTHDAYS_ID || this.tab.id === ANNUAL_REVIEWS_ID) &&
      customer.familyID &&
      customer.familyID === item.familyID;
    const matchInsuranceId =
      this.isInsurance &&
      ((this.tab.id !== INSURANCE_TAB_ID.ISSUED_FOLLOWUP &&
        customer.tableRecordID === item.tableRecordID) ||
        (this.tab.id === INSURANCE_TAB_ID.ISSUED_FOLLOWUP &&
          customer.tableRecordIDCSV === item.tableRecordIDCSV));
    return (
      matchLoanStructureID ||
      (!matchLoanStructureID && (matchFamilyID || matchLoanID)) ||
      matchInsuranceId
    );
  }

  assignEditedBeforeSubmit() {
    if (this.selectedCustomer.customer) {
      this.assignTemplateToUnselected();
      this.resetActiveTemplate(
        this.selectedCustomer,
        this.selectedCustomerIndex,
      );
    } else {
      this.assignTemplateToContact();
      this.emailDefault = { ...this.email };
    }

    const selectedUserIndex = this.tableData
      .map((e) => e.isSelected)
      .indexOf(true);
    if (typeof selectedUserIndex !== 'undefined') {
      this.tableData.map((item, index) => {
        let currentItem = item;
        if (index === selectedUserIndex) {
          currentItem = { ...this.selectCustomer, isSelected: true };
        }
        return currentItem;
      });
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  selectCustomer(customer, index) {
    this.selectedCustomerIndex = index;
    const hasValidUniqueId = this.validTabIdChecker(customer);

    if (!hasValidUniqueId) {
      toastWarning('Not customizable.');
      return;
    }

    if (!customer.isSelected && this.selectedCustomer.customer) {
      this.assignTemplateToUnselected();
    }

    if (!this.selectedCustomer.customer) {
      this.emailDefault = { ...this.email };
      this.tableData.map((item, tableIndex) => {
        this.assignTemplateToContact(false, item, tableIndex);
        return item;
      });
      this.emailTemplateDefault = { ...this.emailDefault };
    }

    if (!customer.isSelected) {
      this.email.subject = customer.emailSubject;
      this.email.body = customer.emailBody;

      if (!this.isAdviserDropdownChanged) {
        this.contactService
          .getContactAssignedAdviser(customer.familyID)
          .then(({ data }) => {
            this.addAdviserEmailToRecipients(
              this.adviserList.find((adviser) => adviser.familyId === data),
            );
          });
      }
    } else {
      this.resetActiveTemplate(customer, index);

      if (!this.isAdviserDropdownChanged) {
        this.addAdviserEmailToRecipients();
      }
    }

    if (!this.selectedCustomer.customer) {
      this.emailTemplateDefault = { ...this.emailDefault };
    }
    this.tableData.map((item, tableIndex) => {
      const isValidIdMatching = this.validIdMatching(customer, item);
      if (isValidIdMatching) {
        this.setSingleData(customer);

        this.selectedCustomer = !item.isSelected ? item : {};
        item.isSelected = !item.isSelected;
        if (!this.selectedCustomer.customer && item.isSelected) {
          this.emailDefault = { ...this.email };
          this.assignTemplateToContact(false, customer, tableIndex);
          this.emailTemplateDefault = { ...this.emailDefault };
        }
      } else {
        item.isSelected = false;
      }
      return item;
    });
  }

  setSingleData(customer) {
    this.singleData = customer;
  }

  toggleContactData(reload = false, item = null, index) {
    const newItem = Object.assign({}, item);

    const isSubjectEdited =
      stripHtmlSpaces(this.$window, item.subject) !==
      stripHtmlSpaces(this.$window, this.emailTemplateDefault.subject);
    const isBodyEdited =
      stripHtmlSpaces(this.$window, item.body) !==
      stripHtmlSpaces(this.$window, this.emailTemplateDefault.body);
    item.subject = isSubjectEdited ? item.subject : this.email.subject;
    item.body = isBodyEdited ? item.body : this.email.body;

    if (!reload) {
      this.assignMergedDataToContact(item.body, item.subject, newItem, index);
    }

    return item;
  }

  assignTemplateToContact(reload = false, contact = null, index = 0) {
    if (contact) {
      return this.toggleContactData(reload, contact, index);
    }
    this.tableData.map((item, itemIndex) => {
      this.toggleContactData(reload, item, itemIndex);
      return item;
    });
  }

  assignTemplateToContactOnLoad(reload) {
    this.assignTemplateToContact(reload);
    this.emailDefault = { ...this.email };
  }

  translateTemplate(templateContent, templateSubject) {
    this.email.subject = templateSubject;
    this.email.body = templateContent;
    this.emailDefault.subject = templateSubject;
    this.emailDefault.body = templateContent;
    this.emailTemplateDefault.subject = templateSubject;
    this.emailTemplateDefault.body = templateContent;

    this.emailMergeFieldsLoaded = false;
    if (!templateContent || !templateContent.trim()) {
      this.emailMergeFieldsLoaded = true;
      return;
    }

    const result = this.tableData.reduce(
      (accumulatorPromise, nextItem, index) => {
        return accumulatorPromise.then(() => {
          return this.assignMergedDataToContact(
            templateContent,
            templateSubject,
            nextItem,
            index,
          );
        });
      },
      Promise.resolve(),
    );

    result.then(() => {
      this.emailMergeFieldsLoaded = true;
    });
  }

  assignMergedDataToContact(templateContent, templateSubject, item, index) {
    const mergeFieldData = mergeFieldsMapperForUI({
      ...item,
      templateContent,
      templateSubject,
    });

    return this.mailTemplateService
      .translateMailTemplate(mergeFieldData, item.actualData)
      .then((response) => {
        this.tableData[index].subject = templateSubject;
        this.tableData[index].body = templateContent;
        this.tableData[index].emailSubject = response.templateSubject;
        this.tableData[index].emailBody = response.templateContent;
        this.tableData[index].actualData = response.actualData;

        if (
          this.tableData[index].isSelected &&
          response.templateSubject !== this.email.subject
        ) {
          this.email.subject = response.templateSubject;
        }
        if (
          this.tableData[index].isSelected &&
          response.templateContent !== this.email.body
        ) {
          this.email.body = response.templateContent;
        }
      });
  }

  isTemplateSelected(templateID) {
    return (
      this.selectedTemplate &&
      this.selectedTemplate.UserTemplateID === templateID
    );
  }

  openEmailManagement() {
    this.emailTemplatesSharedData.setShowDefaultTemplateSettings(true);
    this.$uibModal
      .open({
        templateUrl:
          'assets/views/corporate/emailManagement/emailManagement.html',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        controller: 'EmailManagementCtrl',
      })
      .result.then((hasChanges) => {
        if (hasChanges) {
          this.getEmailTemplates();
        }
      });
  }

  openDefaultTemplateSettings() {
    this.templateService.openDefaultTemplateSettings(
      this.selectedTemplate,
      SEND_METHOD_TYPES.EMAIL,
      this.isInsurance,
    );
  }

  hasValidSelectedTemplate() {
    return this.templateService.isValidTemplate(this.selectedTemplate);
  }
}
