import { filterClientList } from '../possibleAdverseChanges/util/possibleAdverseChangesCtrl';
import { isValidArray } from 'Common/utilities/array.js';
import { LOANAPP_SECTIONS } from 'Common/constants/loanappSectionIds';
import { TYPE_ID } from 'Common/constants/futureFinancialGoals';

class FutureFinancialGoalsCtrl {
  constructor(
    $stateParams,
    $timeout,
    contactModelService,
    financialGoalModelService,
    configService,
  ) {
    'ngInject';

    this.contactModelService = contactModelService;
    this.stateParams = $stateParams;
    this.$timeout = $timeout;
    this.financialGoalModelService = financialGoalModelService;
    this.configService = configService;
  }

  $onInit() {
    this.isValidFinancialGoals = false;
    this.disclaimerText = '';
    this.isStarted = false;
    this.isCompleted = false;
    this.isLoadingCategories = false;
    this.isApplicantsListLoaded = false;
    this.familyId = this.stateParams.familyId;
    this.categories = [];
    this.clientList = [];
    this.categoriesByFamily = {};
    this.financialGoalSummary = this.configService.feature.financialGoalSummary;

    this.financialGoalSummary && this.getDisclaimerText();
  }

  $onChanges(changes) {
    const { loanApplicants } = changes;
    if (!loanApplicants || !loanApplicants.currentValue) {
      return;
    }

    const { InvolvedPartyPerson: involvedPerson } = loanApplicants.currentValue;
    this.clientList = filterClientList(involvedPerson);
    if (!this.clientList) {
      return;
    }
    const isFamilyIdExists = this.clientList.find(
      (data) => data.FamilyId === this.familyId,
    );
    this.familyId = isFamilyIdExists
      ? isFamilyIdExists.FamilyId
      : this.clientList[0].FamilyId;
    this.filterDuplicateClientList();

    if (this.hasApplicantsChanged(loanApplicants.previousValue)) {
      this.getApplicantsCategories();
    }
  }

  $onDestroy() {
    this.loaderTimeout && this.$timeout.cancel(this.loaderTimeout);
  }

  filterDuplicateClientList() {
    this.clientList = this.clientList.reduce((acc, current) => {
      const duplicate = acc.find((item) => item.FamilyId === current.FamilyId);
      return duplicate ? acc : acc.concat([current]);
    }, []);
  }

  hasApplicantsChanged(previousApplicants) {
    if (
      (!previousApplicants && this.loanApplicants) ||
      (previousApplicants && !this.loanApplicants)
    ) {
      return true;
    }

    const previousPersons = filterClientList(
      previousApplicants.InvolvedPartyPerson,
    );
    const currentPersons = filterClientList(
      this.loanApplicants.InvolvedPartyPerson,
    );
    const previousPersonIds = previousPersons
      .map((p) => p.PersonId)
      .sort()
      .join('-');
    const currentPersonIds = currentPersons
      .map((p) => p.PersonId)
      .sort()
      .join('-');
    return previousPersonIds !== currentPersonIds;
  }

  getDisclaimerText() {
    this.financialGoalModelService.getDisclaimer().then(({ data }) => {
      this.disclaimerText = data;
    });
  }

  getCategories(familyId, validate = true) {
    this.categories = [];
    if (validate) {
      this.isLoadingCategories = true;
    }
    return this.financialGoalModelService
      .getFutureFinancialGoalCategory(familyId)
      .then((data) => {
        const modifiedData = this.financialGoalSummary
          ? data
          : data.filter((goal) => goal.typeId !== TYPE_ID.SUMMARY);
        this.categoriesByFamily[familyId] = modifiedData || [];
        if (modifiedData && !modifiedData.length) {
          this.getFutureFinancialCategories(familyId);
        } else if (validate) {
          this.categories = modifiedData;
          this.checkStarted();
        }
      })
      .finally(() => {
        if (validate) {
          this.loaderTimeout = this.$timeout(() => {
            this.isLoadingCategories = false;
          }, 0);
        }
      });
  }

  getApplicantsCategories() {
    if (!isValidArray(this.clientList, true)) {
      return;
    }
    this.isLoadingCategories = true;
    this.categoriesByFamily = {};
    return Promise.all(
      this.clientList.map((client) => {
        return this.getCategories(client.FamilyId, false);
      }),
    )
      .then(() => {
        this.changeSelectedClient(this.familyId, false);
      })
      .catch(() => {
        this.changeSelectedClient(this.familyId);
      })
      .finally(() => {
        this.checkStarted();
        this.isLoadingCategories = false;
        this.isApplicantsListLoaded = true;
      });
  }

  getFutureFinancialCategories(familyId) {
    this.financialGoalModelService.getFinancialGoalType().then(({ data }) => {
      this.futureFinancialGoalsCategories = [...data];
      return this.assignCategoryFromConstant(familyId);
    });
  }

  assignCategoryFromConstant(familyId) {
    this.futureFinancialGoalsCategories.map((result) => {
      this.isLoadingCategories = true;
      return this.saveDescription({
        id: 0,
        typeId: result.typeId,
        type: result.type,
        details: '',
        familyId,
      }).then((resultData) => {
        this.isLoadingCategories = false;
        this.assignCategoriesValues({
          id: resultData.data,
          typeId: result.typeId,
          type: result.type,
          details: '',
          familyId,
        });
      });
    });
  }

  checkStarted() {
    const hasCompleteDetails =
      !!this.categoriesByFamily &&
      Object.values(this.categoriesByFamily).every((categories) => {
        return (
          categories &&
          categories.every(
            (category) =>
              !(category.isRequired && this.financialGoalSummary) ||
              !!category.details,
          )
        );
      });
    this.isCompleted = hasCompleteDetails;
    const hasDetails =
      !!this.categoriesByFamily &&
      Object.values(this.categoriesByFamily).some((categories) => {
        return categories && categories.some((category) => category.details);
      });
    this.isStarted = hasDetails;
    this.countValids &&
      this.countValids({
        valid: this.isCompleted,
        section: LOANAPP_SECTIONS.FUTURE_FINANCIALS,
      });
  }

  assignCategoriesValues(category) {
    this.categoriesByFamily[category.familyId] = (
      this.categoriesByFamily[category.familyId] || []
    ).concat([category]);
    if (this.familyId === category.familyId) {
      this.categories = [...(this.categories || []), category];
    }
  }

  saveDescription(category) {
    this.updateCategoryModel(category.details, category.id);
    const data = {
      ...category,
      familyId: category.familyId || this.familyId,
    };
    return this.contactModelService.saveFutureFinancialGoalCategory(data);
  }

  updateCategoryModel(details, id) {
    this.isStarted = false;
    this.isCompleted = false;
    if (this.categories && !this.categories.length) {
      return;
    }
    const updatedCategory = this.categories.find(
      (category) => category.id === id,
    );
    if (updatedCategory) {
      updatedCategory.details = details;
      this.categoriesByFamily[this.familyId] = [...this.categories];
    }
    this.checkStarted();
  }

  onBlurEvent(category, { data }) {
    if (!category) {
      return;
    }
    category.details = data || '';
    this.saveDescription(category);
  }

  changeSelectedClient(familyId, fetchCategories = true) {
    this.clientSelected = this.clientList.find(
      (client) => client.FamilyId === familyId,
    );

    if (!this.clientSelected) {
      return;
    }

    this.familyId = this.clientSelected.FamilyId;
    if (!this.familyId) {
      return;
    }

    if (fetchCategories) {
      this.getCategories(this.familyId);
    } else {
      this.categories = this.categoriesByFamily[familyId];
    }
  }
}

export default FutureFinancialGoalsCtrl;
