class InsuranceFactFindService {
  constructor($q, httpClient) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.apiBaseUrl = 'insurance-fact-find';
  }

  shareWithClient(data) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/share-with-client`, data)
      .then(({ data }) => data);
  }
}

export default InsuranceFactFindService;
