import { get } from 'lodash';
import { PARTY_GROUPING } from 'Common/constants/partyTypes';
import { SORT_TYPE } from 'Common/constants/sort';
import { CLIENT_ROLE_TYPE } from 'Common/constants/roleType';
import {
  getContactFullname,
  getPhoneNumberByType,
} from 'Common/utilities/contact';
import { sortArray } from 'Common/utilities/sort';

const {
  APPLICANT_CLIENT,
  APPLICANT_ENTITY,
  GUARANTOR_CLIENT,
  GUARANTOR_ENTITY,
  SOLICITOR,
  ACCOUNTANT,
} = PARTY_GROUPING;

export const getPartyGroup = (party) => {
  if (!party) {
    return;
  }
  const { PersonId, IsApplicant, IsSolicitor, IsAccountant } = party;

  if (PersonId) {
    if (IsApplicant) {
      return { role: 'Applicant', order: APPLICANT_CLIENT };
    }
    if (IsSolicitor) {
      return { role: 'Solicitor', order: SOLICITOR };
    }
    if (IsAccountant) {
      return { role: 'Accountant', order: ACCOUNTANT };
    }
    return { role: 'Guarantor', order: GUARANTOR_CLIENT };
  }

  return IsApplicant
    ? { role: 'Applicant', order: APPLICANT_ENTITY }
    : { role: 'Guarantor', order: GUARANTOR_ENTITY };
};

export const getPartyIcon = (group) => {
  switch (group) {
    case GUARANTOR_CLIENT:
    case GUARANTOR_ENTITY:
      return `<icon-guarantor class='icon' />`;
    case APPLICANT_ENTITY:
      return `<icon-building class='icon' />`;
    default:
      return `<icon-person-mf class='icon' />`;
  }
};

export const flattenInvolvedPartiesList = (
  involvedParties,
  includeRelatedParty,
) => {
  if (!involvedParties) {
    return [];
  }
  const defaultKeys = ['InvolvedPartyPerson', 'InvolvedPartyEntity'];
  const involvedPartyKeys = includeRelatedParty
    ? [...defaultKeys, 'RelatedParty']
    : defaultKeys;

  return Object.keys(involvedParties).reduce((accum, key) => {
    const involvedPartyCollection = involvedParties[key] || null;
    return involvedPartyKeys.includes(key)
      ? [...involvedPartyCollection, ...accum]
      : accum;
  }, []);
};

export const formatInvolvedParties = (involvedParties, includeRelatedParty) => {
  const flattenedList = flattenInvolvedPartiesList(
    involvedParties,
    includeRelatedParty,
  );
  const formattedList = flattenedList.map((party) => {
    const partyGroup = getPartyGroup(party);
    const partyOrder = partyGroup.order;
    const ids = party.PersonId || party.OrganisationId;
    const personIds = party.personIds || (ids && [ids]) || [];
    return {
      ...party,
      displayName: party.PersonId
        ? party.displayName || getContactFullname(party)
        : party.FamilyFullName,
      partyOrder,
      icon: getPartyIcon(partyOrder),
      displayRole: partyGroup.role,
      displayPhone:
        getPhoneNumberByType(party.Phone, 'Mobile') ||
        get(party, 'Phone.0.Number', ''),
      personIds,
    };
  });
  return sortArray(formattedList, 'partyOrder', SORT_TYPE.ASC);
};

export const involvedPartyMapper = ({
  selectedValues = [],
  isSelectAllByDefault = false,
}) => (data) => ({
  familyId: data.FamilyId,
  displayName: data.FamilyFullName,
  isClient: data.IsClient || false,
  isApplicant: data.IsApplicant || false,
  isGuarantor: data.IsGuarantor || false,
  isSelected: isSelectAllByDefault || selectedValues.includes(data.FamilyId),
});

export const mappedInvolvedPartyPersonToFamily = ({
  clientInfoService,
  involvedPartyPersons,
  partyMapper,
}) => {
  if (!involvedPartyPersons) {
    return [];
  }
  return Object.keys(involvedPartyPersons).reduce((accum, key) => {
    const family = involvedPartyPersons[key];
    family.map((person) => {
      person.Role = CLIENT_ROLE_TYPE.ADULT;
      return person;
    });
    const personIds = family.map((person) => person.PersonId);
    const [primary] = family;
    const familyInfo = { information: family };
    clientInfoService.getClientHeader(familyInfo, true);
    primary.FamilyFullName = familyInfo.headerName;
    primary.personIds = personIds;
    return accum.concat(partyMapper(primary));
  }, []);
};

export const mappedInvolvedPartyPersonToFamilyPolicy = ({
  involvedPartyPersons,
}) => {
  if (!involvedPartyPersons) {
    return [];
  }
  return Object.values(involvedPartyPersons).reduce((accum, value) => {
    if (!value || !value.length) {
      return accum;
    }
    return accum.concat(
      value.map((person) => {
        person.Role = CLIENT_ROLE_TYPE.ADULT;
        person.displayName = person.LegalName;
        return person;
      }),
    );
  }, []);
};

export const convertPartiesToGrouped = ({
  clientInfoService,
  involvedParties = {},
  selectedValues = [],
  isSelectAllByDefault = false,
}) => {
  const partyMapper = involvedPartyMapper({
    selectedValues,
    isSelectAllByDefault,
  });
  const clients = mappedInvolvedPartyPersonToFamily({
    clientInfoService,
    involvedPartyPersons: involvedParties.InvolvedPartyPerson,
    partyMapper,
  });
  const entities = get(involvedParties, 'InvolvedPartyEntity', []).map(
    partyMapper,
  );
  const parties = [...clients, ...entities];
  return {
    borrowers: parties.filter((party) => party.isApplicant) || [],
    guarantors: parties.filter((party) => party.isGuarantor) || [],
  };
};

export const partyMapper = (data) => ({
  ...data,
  displayName: data.FamilyFullName,
});

export const getAllInvolvedParties = (
  involvedParties,
  clientInfoService,
  includeRelatedParty = true,
  oneTouchPrivacy = false,
) => {
  const { InvolvedPartyPerson: involvedPartyPersons } = involvedParties;
  const partyPersons = oneTouchPrivacy
    ? mappedInvolvedPartyPersonToFamilyPolicy({
        involvedPartyPersons,
      })
    : mappedInvolvedPartyPersonToFamily({
        clientInfoService,
        involvedPartyPersons,
        partyMapper: (data) => ({
          ...data,
          displayName: data.FamilyFullName,
        }),
      });
  const party = { ...involvedParties, InvolvedPartyPerson: partyPersons };
  return formatInvolvedParties(party, includeRelatedParty);
};
