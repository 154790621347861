class ChangeColorCtrl {
  onChange(color) {
    if (!color || this.color === color) {
      return;
    }
    this.onColorChange({ color });
  }

  $onInit() {
    this.colorpickerOpts = {
      color: this.color,
      showInput: true,
      showInitial: true,
      showPalette: false,
      preferredFormat: 'hex',
      clickoutFiresChange: false,
      containerClassName: `change-color ${this.customClass}`,
    };
  }
}

export default ChangeColorCtrl;
