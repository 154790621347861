export const MONTH_NAMES = [
  {
    value: 1,
    name: 'January',
  },
  {
    value: 2,
    name: 'Febuary',
  },
  {
    value: 3,
    name: 'March',
  },
  {
    value: 4,
    name: 'April',
  },
  {
    value: 5,
    name: 'May',
  },
  {
    value: 6,
    name: 'June',
  },
  {
    value: 7,
    name: 'July',
  },
  {
    value: 8,
    name: 'August',
  },
  {
    value: 9,
    name: 'September',
  },
  {
    value: 10,
    name: 'October',
  },
  {
    value: 11,
    name: 'November',
  },
  {
    value: 12,
    name: 'December',
  },
];
