import { complianceReviewRatingBuilderForUI } from 'Common/mappers/complianceReviewRating';
import { accessControlMatrixesForUI } from 'Common/mappers/accessControlMatrixes';
import { contactsByUserOrgBuilderForUI } from 'Common/mappers/contactsByUserOrg';
import { teamContactDetailBuilderForUI } from 'Common/mappers/teamContactDetail';
import { CONTACT_TAB } from 'Common/constants/contactTab';
import {
  preferredAdvisersBuilderForUI,
  adviserProfileBuilderForUI,
  adviserProfileBuilderForMyCRM,
  workingDaysBuilderForUI,
  leadsProfileListBuilderForUI,
  onboardingDocumentChecklistBuilderForUI,
  onboardingDocumentChecklistHeaderBuilderForUI,
  familyComplianceSeparationsBuilderForUI,
  familyComplianceSeparationsBuilderForMyCRM,
  onlineOnboardingSectionBuilderForUI,
  applicationCategoryBuilderForUI,
  onboardingConsentBuilderForUI,
  adviserOrganizationThemeBuilderForMyCRM,
  adviserOrganizationThemeBuilderForUI,
  referrerPreferredAdviserBuilderForUI,
  adviserAccreditationStatusListBuilderForUI,
  oktaLastLoginBuilderForUI,
} from 'Common/mappers/corporate';
import {
  operatingCountriesBuilderForUI,
  reportingRegionsBuilderForUI,
  reportingStatesBuilderForUI,
} from 'Common/mappers/address';
import {
  importantDocumentDetailsBuilderForUI,
  importantDocumentDetailsBuilderForMyCRM,
} from 'Common/mappers/importantDocument';
import { pdfBuilderForUI } from 'Common/mappers/document';
import { triggerGA } from 'Common/utilities/googleAnalytics';

class CorporateModelService {
  constructor(httpClient, $q) {
    'ngInject';

    this.httpClient = httpClient;
    this.$q = $q;

    this.apiBaseUrl = 'corporate';
  }

  complianceReviewGet(adviserFamilyId, complianceReviewId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ComplianceReviewGet`, {
        complianceReviewId,
        adviserFamilyId,
      })
      .then((response) =>
        response.data.map(complianceReviewRatingBuilderForUI),
      );
  }

  complianceReviewSet(complianceObj) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ComplianceReviewSet`,
      {},
      complianceObj,
    );
  }

  accessControlMatrixes(
    pageName = null,
    tabName = null,
    subTabName = null,
    panelName = null,
    fieldName = null,
  ) {
    return this.httpClient
      .get(`/access-control/matrixes/`, {
        pageName,
        tabName,
        subTabName,
        panelName,
        fieldName,
      })
      .then(
        (response) =>
          response.data && response.data.map(accessControlMatrixesForUI),
      );
  }

  operatingCountriesGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/OperatingCountriesGet`)
      .then((response) => response.data.map(operatingCountriesBuilderForUI));
  }

  reportingRegionsGet(countryId = null) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ReportingRegionsGet`, { countryId })
      .then((response) => response.data.map(reportingRegionsBuilderForUI));
  }

  reportingStatesGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ReportingStatesGet`)
      .then((response) => response.data.map(reportingStatesBuilderForUI));
  }

  importantDocumentDetailsGet(documentId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/ImportantDocumentDetailsGet`, { documentId })
      .then((response) => importantDocumentDetailsBuilderForUI(response.data));
  }

  importantDocumentSet(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ImportantDocumentSet`,
      importantDocumentDetailsBuilderForMyCRM(data),
    );
  }

  contactsByUserOrganizationGet(searchName) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/organization/contacts`, { searchName })
      .then((response) => response.data.map(contactsByUserOrgBuilderForUI));
  }

  teamContactDetailGet() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/team-contact-detail`)
      .then((response) => response.data.map(teamContactDetailBuilderForUI));
  }

  isFirstFamilyLoan(familyId, loanId = 0) {
    return this.httpClient.get(`${this.apiBaseUrl}/is-first-family-loan`, {
      familyId,
      loanId,
    });
  }

  getAgreementPreferredAdvisers(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/agreement-preferred-advisers`, params)
      .then((response) => response.data.map(preferredAdvisersBuilderForUI));
  }

  getFeaturesSettings() {
    return this.httpClient.get(`${this.apiBaseUrl}/features/settings`);
  }

  putFeaturesSettings({ id, settingTypeId }) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/features/${id}`,
      {},
      { settingTypeId },
    );
  }

  getCommissionTiers(userId = 0, commissionTierId = null) {
    return this.httpClient.get(`${this.apiBaseUrl}/CommissionTiersGet`, {
      userId,
      commissionTierId,
    });
  }

  getAdviserProfile(adviserId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/adviser-profile`, { adviserId })
      .then((response) => adviserProfileBuilderForUI(response.data));
  }

  setAdviserProfile(data) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/adviser-profile`,
        adviserProfileBuilderForMyCRM(data),
      )
      .then((response) => response.data);
  }

  getLeadAllocationWorkingDays() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/lead-allocation-working-days`)
      .then((response) => response.data.map(workingDaysBuilderForUI));
  }

  deleteAdditionalStaff(partnerFamilyId) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/partner-contact?partnerFamilyId=${partnerFamilyId}`,
    );
  }

  getOnlineOnboardingActive(adviserId) {
    return this.httpClient.get(`${this.apiBaseUrl}/online-onboarding-active`, {
      adviserId,
    });
  }

  setOnlineOnboardingActive(
    adviserId = 0,
    adviserClientId = 0,
    onlineOnboardingActive = false,
  ) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/online-onboarding-active?adviserId=${adviserId}&adviserClientId=${adviserClientId}&onlineOnboardingActive=${onlineOnboardingActive}`,
    );
  }

  getAdviserLeadsProfileList() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/adviser-leads-profile-distances`)
      .then(
        (response) =>
          response.data && response.data.map(leadsProfileListBuilderForUI),
      );
  }

  getOnlineOnboardingDocumentChecklist() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/online-onboarding-document-checklist`)
      .then(
        (response) =>
          response.data &&
          response.data.map(onboardingDocumentChecklistBuilderForUI),
      );
  }

  getOnlineOnboardingDocumentChecklistHeaders(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/online-onboarding-document-checklist-headers`, {
        familyId,
      })
      .then(
        (response) =>
          response.data &&
          response.data.map(onboardingDocumentChecklistHeaderBuilderForUI),
      );
  }

  deleteOnlineOnboardingDocumentChecklist(params) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/online-onboarding-document-checklist`,
      params,
    );
  }

  ceaseAdviser(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/cease-adviser`, {}, params);
  }

  getAssistantTabs() {
    const defer = this.$q.defer();
    defer.resolve([
      {
        name: 'Assistant Details',
        page: 'assets/views/corporate/assitant/assistant-details.html',
        value: 'assistant-details',
      },
      {
        name: 'Access Management',
        page: 'assets/views/corporate/assitant/access-management.html',
        value: 'access-management',
      },
      {
        name: 'Communications',
        page: 'assets/views/contacts/client/emails.html',
        value: CONTACT_TAB.COMMUNICATIONS,
      },
      {
        name: 'Notes',
        page: 'assets/views/contacts/client/notes.html',
        value: 'notes',
      },
      {
        name: 'Documents',
        page: 'assets/views/contacts/client/documents.html',
        value: 'documents',
      },
    ]);
    return defer.promise;
  }

  mergeFieldsGet(params) {
    return this.httpClient.get(`MergeFieldsGet`, params);
  }

  deleteEmailTemplates(id) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/email-template?userTemplateId=${id}`,
    );
  }

  getFamilyComplianceSeparations(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/family-compliance-separations`, {
        familyId,
      })
      .then((response) =>
        familyComplianceSeparationsBuilderForUI(
          response.data && response.data.length > 0 && response.data[0],
        ),
      );
  }

  setFamilyComplianceSeparations(data) {
    return this.httpClient
      .post(
        `${this.apiBaseUrl}/family-compliance-separations`,
        familyComplianceSeparationsBuilderForMyCRM(data),
      )
      .then((response) => response.data);
  }

  getOnlineOnboardingSections(sectionId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/online-onboarding-sections`, { sectionId })
      .then(
        (response) =>
          response.data &&
          response.data.map(onlineOnboardingSectionBuilderForUI),
      );
  }

  setOnlineOnboardingSection(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/online-onboarding-section`,
      {},
      params,
    );
  }

  getOnlineOnboardingApplicationChecklistHeader(familyId) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/online-onboarding-application-checklist-headers`,
        { familyId },
      )
      .then(
        (response) =>
          response.data && response.data.map(applicationCategoryBuilderForUI),
      );
  }

  setApplicationChecklist(params) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/ApplicationChecklistSet`, {}, params)
      .then((response) => response.data);
  }

  setImportantDocumentFavourite(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/important-document-favourite`,
      {},
      params,
    );
  }

  getOptionsForMortgageBrokerYears() {
    const defer = this.$q.defer();
    defer.resolve({
      data: ['Under 2 years', '2-4 years', '5+ years'],
    });
    return defer.promise;
  }

  getAuthorityConsent(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/authority-consent`, { familyId })
      .then(({ data }) => data && data.map(onboardingConsentBuilderForUI));
  }

  setAuthorityConsent(params) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/authority-consent`, {}, params)
      .then(({ data }) => data);
  }

  getOnlineOnboardingSnapshot(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/online-onboarding-snapshot`, {
        familyId,
      })
      .then((response) => pdfBuilderForUI(response.data));
  }

  setFeatureArchive(featureId, isArchived) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/feature/${featureId}/archive`,
      isArchived,
    );
  }

  setShowToCorpFeature(featureId, showToCorp) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/feature/${featureId}/show`,
      showToCorp,
    );
  }

  getAdviserOrganizationTheme(adviserOrganizationId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/adviser-organization-theme`, {
        adviserOrganizationId,
      })
      .then(({ data }) => adviserOrganizationThemeBuilderForUI(data));
  }

  setAdviserOrganizationTheme(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/adviser-organization-theme`,
      adviserOrganizationThemeBuilderForMyCRM(data),
    );
  }

  setMyLeadGeneratorLink(familyId) {
    const gaEvent = {
      event: 'subscription',
      subscription: 'My Lead Generator',
    };
    triggerGA(gaEvent);
    return this.httpClient.post(
      `${this.apiBaseUrl}/my-lead-generator-url?familyId=${familyId}`,
    );
  }

  getAgreementReferrerPreferredAdviser(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/agreement-referrer-preferred-adviser`, params)
      .then(
        ({ data }) => data && data.map(referrerPreferredAdviserBuilderForUI),
      );
  }

  setGmailSyncOnDemand(familyId) {
    return this.httpClient.post(`google/gmail-sync-on-demand`, { familyId });
  }

  getAdviserAccreditationStatusList() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/adviser-accreditation-status-list`)
      .then(
        ({ data }) =>
          data && data.map(adviserAccreditationStatusListBuilderForUI),
      );
  }

  getOktaLastLogin(email, familyId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/auth-service/last-login`, {
        email,
        familyId,
      })
      .then(({ data }) => oktaLastLoginBuilderForUI(data));
  }
}

export default CorporateModelService;
