import noop from 'lodash/noop';
import { responseBuilderForUI } from 'Common/mappers/response';
import { constructConfigRequest } from 'Common/utilities/request';
import { getAccessToken } from 'Common/utilities/oktaAuth';
import { buildCommonHttpHeader } from 'Common/config/http';
import { handleError } from 'Assets/js/services/util/request';

export const loadModule = () =>
  app.factory('httpRequest', function ($http, configService, $window) {
    const getResourceUrl = function (url) {
      const splitUrl = url.split('?');

      if (
        typeof configService.exclude !== 'undefined' &&
        configService.exclude.indexOf(splitUrl[0]) !== -1
      ) {
        url = `${$window.location.protocol}//${
          $window.location.host
        }/json/${url.replace(/\?/, '.json?')}`;
      } else {
        url = `${configService.resource}/${url}`;
      }

      return url;
    };

    const fireRequest = (request) => {
      getAccessToken().then((token) => {
        $http.defaults.headers.common = buildCommonHttpHeader(
          `Bearer ${token}`,
        );
        request();
      });
    };

    const getMethod = function (
      url,
      successCallback,
      customErrorCallback = noop,
      enableErrorModal = true,
    ) {
      url = getResourceUrl(url);
      const request = () =>
        $http
          .get(url, constructConfigRequest())
          .then((response) => successCallback(responseBuilderForUI(response)))
          .catch(handleError(url, customErrorCallback, enableErrorModal));

      fireRequest(request);
    };

    const postMethod = function (
      url,
      data,
      successCallback,
      customErrorCallback = noop,
      enableErrorModal = true,
    ) {
      url = getResourceUrl(url);
      const request = () =>
        $http
          .post(url, data, constructConfigRequest())
          .then((response) => successCallback(responseBuilderForUI(response)))
          .catch(handleError(url, customErrorCallback, enableErrorModal));

      fireRequest(request);
    };

    const putMethod = function (
      url,
      data,
      successCallback,
      customErrorCallback,
      enableErrorModal = true,
    ) {
      if (typeof customErrorCallback === 'undefined') {
        customErrorCallback = function () {};
      }
      url = getResourceUrl(url);
      const request = () =>
        $http
          .put(url, data, constructConfigRequest())
          .then((response) => successCallback(responseBuilderForUI(response)))
          .catch(handleError(url, customErrorCallback, enableErrorModal));

      fireRequest(request);
    };

    const patchMethod = function (
      url,
      data,
      successCallback,
      customErrorCallback = noop,
      enableErrorModal = true,
    ) {
      url = getResourceUrl(url);
      const request = () =>
        $http
          .patch(url, data, constructConfigRequest())
          .then((response) => successCallback(responseBuilderForUI(response)))
          .catch(handleError(url, customErrorCallback, enableErrorModal));

      fireRequest(request);
    };

    const deleteMethod = function (
      url,
      successCallback,
      customErrorCallback = noop,
      enableErrorModal = true,
    ) {
      url = getResourceUrl(url);
      const request = () =>
        $http
          .delete(url, constructConfigRequest())
          .then((response) => successCallback(responseBuilderForUI(response)))
          .catch(handleError(url, customErrorCallback, enableErrorModal));

      fireRequest(request);
    };

    return {
      get: getMethod,
      post: postMethod,
      put: putMethod,
      delete: deleteMethod,
      patch: patchMethod,
    };
  });
