import angular from 'angular';
import { filter, last, forEach, sortBy } from 'lodash';
import {
  COVER_TYPE,
  ACTIVE_TAB,
  BENEFIT_LIST,
  FREQUENCY_VALUES,
} from 'Common/constants/coverType';
import {
  INSURANCE_OPTIONS,
  ZERO_PREMIUM,
  CLIENTS_OCCURENCE,
} from 'Common/constants/insuranceOptions';
import {
  generateAgeList,
  sortPeopleEntities,
} from 'Common/utilities/insurance';
import {
  formatRawNewLine,
  isInIPHealthDataList,
} from 'Common/utilities/string';
import { toastError, toastWarning } from 'Common/utilities/alert';
import $ from 'jquery';
import swal from 'sweetalert';
import { PAGE_SOURCE } from 'Common/constants/youtubeLinks';
import {
  settingsModal,
  onIPLevelSuggestionChange,
  onIPFrequencyChange,
  resetDefaultProfileValue,
  sendNewEmailReport,
  sendOldEmailReport,
} from './util/insuranceProfiler';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('InsuranceProfilerCtrl', function InsuranceProfilerCtrl(
      $scope,
      $timeout,
      $window,
      $uibModal,
      $state,
      $q,
      insuranceProfilerService,
      insuranceQuoteService,
      InsuranceStaticModelSharedService,
      contactService,
      toaster,
      LimitClients,
      currentUserService,
      insuranceSummaryOfCoverService,
      crmConfirmation,
      stateService,
      helloPackService,
      configService,
    ) {
      $scope.isProfilerDocCOC = configService.feature.profilerDocCOC;
      $scope.routeContactList = stateService.routeToContactList;
      $scope.COVER_TYPE = COVER_TYPE;
      $scope.options_health = INSURANCE_OPTIONS.HEALTH;
      $scope.options_occType = INSURANCE_OPTIONS.OCCUPATION_TYPE;
      $scope.isCalculating = false;
      $scope.profilerSettings = {};
      $scope.CLIENT_TYPE_OPTIONS = INSURANCE_OPTIONS.CLIENT_TYPE;
      $scope.clientType = INSURANCE_OPTIONS.CLIENT_TYPE.NEW;
      $scope.existingClient = false;
      $scope.isNewClient = true;
      $scope.addMortgageInsurance = true;
      $scope.showdMortgageInsurance = false;
      $scope.contactName = '';
      $scope.profilerData = {};
      $scope.clientSelectedCount = {
        Health: 1,
        Income: 1,
        TPD: 1,
        Life: 1,
        Trauma: 1,
        Mortgage: 1,
      };
      $scope.profilerFirstLoad = true;
      $scope.progressFlagCount = 0;
      $scope.iPCoverLevelUpdateCount = 0;
      $scope.IPHealthDataList = [];
      $scope.IPIncomeProtectionDataList = {};
      $scope.IPMortgageRepaymentDataList = {};
      $scope.IPTraumaDataList = {};
      $scope.IPTPDProfilerDataList = {};
      $scope.IPLifeCoverDataList = {};
      $scope.incomeProtectionPayment = [];
      $scope.incomeProtectionType = [];
      $scope.occTypeList = [];
      $scope.fullNameClient = [];
      $scope.fullNameDependent = [];
      $scope.frequencyTypes = [];
      $scope.selectedClients = [
        {
          Health: true,
          Income: true,
          Mortgage: true,
          TPD: true,
          Trauma: true,
          Life: true,
        },
      ];
      $scope.adultAgeList = [];
      $scope.childAgeList = [];
      $scope.profilerForm = {};
      $scope.tabIPisActive = '';
      $scope.pageSource = PAGE_SOURCE.INSURANCE_PROFILER;
      $scope.currentUserService = currentUserService;

      $scope.ageType = (type) => {
        $scope[`${type}AgeList`] = generateAgeList(type);
      };

      $scope.getTotalPremiumBallPark = {
        totalHolder: [],
        benefits: (data) => {
          $scope.getTotalPremiumBallPark.totalHolder.push(data);
        },
        sum: (total, num) => {
          return total + num;
        },
        spreadEachBallParkPremium: (premiumData) => {
          if (!Array.isArray(premiumData)) {
            return;
          }
          premiumData.forEach((data) => {
            $scope.getTotalPremiumBallPark.totalHolder.push(data.Premium);
          });
        },
        total: () => {
          if (!$scope.getTotalPremiumBallPark.totalHolder.length) {
            return;
          }
          return $scope.getTotalPremiumBallPark.totalHolder.reduce(
            $scope.getTotalPremiumBallPark.sum,
          );
        },
      };

      $scope.computeTotalBallParkPremium = () => {
        $scope.getTotalPremiumBallPark.totalHolder = [];
        $scope.getTotalPremiumBallPark.spreadEachBallParkPremium(
          $scope.IPHealthDataList,
        );
        $scope.getTotalPremiumBallPark.spreadEachBallParkPremium(
          $scope.IPLifeCoverDataList,
        );
        $scope.getTotalPremiumBallPark.spreadEachBallParkPremium(
          $scope.IPTraumaDataList,
        );
        $scope.getTotalPremiumBallPark.spreadEachBallParkPremium(
          $scope.IPTPDProfilerDataList,
        );
        $scope.getTotalPremiumBallPark.spreadEachBallParkPremium(
          $scope.IPIncomeProtectionDataList,
        );
        $scope.getTotalPremiumBallPark.spreadEachBallParkPremium(
          $scope.IPMortgageRepaymentDataList,
        );
      };

      $scope.createFullName = (people, idx) => {
        if (people) {
          const tempName = !people.IsChild ? 'Client' : 'Dependent';
          const clienttype = `fullName${tempName}`;
          const fullname = `${people.FirstName || `${tempName} ${idx + 1}`} ${
            people.LastName || ''
          }`;
          const firstname = people.FirstName;
          if (!$scope[clienttype][idx]) {
            $scope[clienttype].push({
              fullName: fullname,
              firstName: firstname,
            });
          } else {
            $scope[clienttype][idx] = {
              fullName: fullname,
              firstName: firstname,
            };
          }
        }
      };

      $scope.searchNow = (qry) => {
        if (qry && qry.length > 2) {
          const byPassFilter = true;
          $scope.isSearching = byPassFilter;
          const { familyId } = currentUserService;
          const queryParams = {
            searchCriteria: qry,
            adviserFamilyId: familyId,
            searchOnAdviserOrg: true,
          };
          contactService
            .familyListGet(queryParams, byPassFilter)
            .then((response) => {
              $scope.isSearching = false;
              const data = response.data;
              if (data && data.FamilyList && data.FamilyList.length) {
                $scope.familyList = data.FamilyList;
                $scope.hasResults = true;
              } else {
                $scope.hasResults = false;
              }
            });
        } else {
          $scope.hasResults = false;
        }
      };

      $scope.tempNoteStorage = (note) => {
        $scope.tempNote = note;
      };
      const templateNote = {
        healthNote: [],
        iPNote: [],
        lifeNote: [],
        mortgageNote: [],
        tPDNote: [],
        traumaNote: [],
      };
      const contcatNotes = (propName, data) => {
        if (!templateNote[propName]) {
          return;
        }
        templateNote[propName].push(data);
        return templateNote[propName].join(' ');
      };

      $scope.saveInusranceNotesBytType = (insuranceType, template) => {
        const dataProfilerNoteSet = {
          ProfilerId: $scope.profilerData.profilerId,
          BenefitTypeId: insuranceType,
          Note: template,
        };
        $scope.saveNoteTemplateService(dataProfilerNoteSet);
      };

      $scope.saveInsuranceNote = (coverType, note, fromNgBlur) => {
        const dataProfilerNoteSet = {
          ProfilerId: $scope.profilerData.profilerId,
          BenefitTypeId: coverType,
          Note: note,
        };

        if (fromNgBlur) {
          $scope.concatInsuranceNotesByTypeOfInsurance(coverType, note);
        } else {
          $scope.saveNoteTemplateService(dataProfilerNoteSet);
        }
      };

      $scope.saveNoteTemplateService = (data = {}) => {
        insuranceProfilerService.profilerInsuranceProfilerNotesSet(data);
      };

      $scope.concatInsuranceNotesByTypeOfInsurance = (coverType, note) => {
        switch (coverType) {
          case COVER_TYPE.HEALTH_COVER:
            templateNote.healthNote = [];
            $scope.profilerFormData.HealthNote = contcatNotes(
              'healthNote',
              note,
            );
            $scope.saveInusranceNotesBytType(
              COVER_TYPE.HEALTH_COVER,
              $scope.profilerFormData.HealthNote,
            );

            break;
          case COVER_TYPE.INCOME_PROTECTION:
            templateNote.iPNote = [];
            $scope.profilerFormData.IPNote = contcatNotes('iPNote', note);
            $scope.saveInusranceNotesBytType(
              COVER_TYPE.INCOME_PROTECTION,
              $scope.profilerFormData.IPNote,
            );
            break;
          case COVER_TYPE.MORTGAGE_REPAYMENT:
            templateNote.mortgageNote = [];
            $scope.profilerFormData.MortgageNote = contcatNotes(
              'mortgageNote',
              note,
            );
            $scope.saveInusranceNotesBytType(
              COVER_TYPE.MORTGAGE_REPAYMENT,
              $scope.profilerFormData.MortgageNote,
            );
            break;
          case COVER_TYPE.LIFE_COVER:
            templateNote.lifeNote = [];
            $scope.profilerFormData.LifeNote = contcatNotes('lifeNote', note);
            $scope.saveInusranceNotesBytType(
              COVER_TYPE.LIFE_COVER,
              $scope.profilerFormData.LifeNote,
            );
            break;
          case COVER_TYPE.TOTAL_PERMANENT_DISABILITY:
            templateNote.tPDNote = [];
            $scope.profilerFormData.TPDNote = contcatNotes('tPDNote', note);
            $scope.saveInusranceNotesBytType(
              COVER_TYPE.TOTAL_PERMANENT_DISABILITY,
              $scope.profilerFormData.TPDNote,
            );
            break;
          case COVER_TYPE.TRAUMA_COVER:
            templateNote.traumaNote = [];
            $scope.profilerFormData.TraumaNote = contcatNotes(
              'traumaNote',
              note,
            );
            $scope.saveInusranceNotesBytType(
              COVER_TYPE.TRAUMA_COVER,
              $scope.profilerFormData.TraumaNote,
            );
            break;
          default:
            break;
        }
      };

      $scope.toggleShowNotesByTabs = (noteType, noteTemplate) => {
        templateNote[noteType] = [];
        contcatNotes(noteType, noteTemplate);
      };

      $scope.selectTabIP = (selectedTab, showDescription) => {
        $scope.tabIPisActive = selectedTab.tabId;
        if (!showDescription) {
          return;
        }

        $uibModal.open({
          templateUrl:
            'assets/views/insuranceTools/modals/benefitTypeDescription.html',
          controller: 'BenefitTypeDescriptionCtrl',
          controllerAs: 'vm',
          windowClass: 'benefit-type-description',
          resolve: {
            benefitType: () => selectedTab.benefitType,
          },
        });
      };

      $scope.getBenefitTypeById = (id) => {
        return (
          $scope.benefitTypes &&
          $scope.benefitTypes.find((benefitType) => {
            return id === benefitType.qprBenefitId;
          })
        );
      };

      $scope.hoverTabIP = (hoveredTab) => {
        switch (hoveredTab) {
          case 'health_insurance':
            $('.PDH-graph-svg #treatment').attr('class', 'hovered');
            break;
          case 'income_protection':
            $('.PDH-graph-svg #sick').attr('class', 'hovered');
            break;
          case 'trauma_insurance':
            $('.PDH-graph-svg #hospital').attr('class', 'hovered');
            break;
          case 'total_permanent_disablement':
            $('.PDH-graph-svg #disabled').attr('class', 'hovered');
            break;
          case 'life_insurance':
            $('.PDH-graph-svg #dead').attr('class', 'hovered');
            break;
          default:
        }
      };

      $scope.hoverOutTabIP = () => {
        $(
          '.PDH-graph-svg #treatment, .PDH-graph-svg #sick, .PDH-graph-svg #hospital, .PDH-graph-svg #disabled, .PDH-graph-svg #dead',
        ).attr('class', '');
      };

      $scope.getFrequencyTypes = () => {
        insuranceProfilerService.getFrequencyTypes().then((response) => {
          $scope.frequencyTypes = response;
        });
      };

      /** ******************************************* */
      const staticModels = InsuranceStaticModelSharedService;
      $scope.profilerFormData = {};
      $scope.profilerData = {};
      $scope.profilerIdGet = null;
      const getNewPeopleEntity = (
        tempMR,
        MortgageDebt,
        TotalOtherDebts,
        isPrimary = false,
      ) => {
        const miscInfo = staticModels.getMiscInfoStaticModel();
        miscInfo.ClientId = 0;
        miscInfo.FirstName = '';
        miscInfo.LastName = '';
        miscInfo.Gender = null;
        miscInfo.Age = null;
        miscInfo.tempMR = tempMR;
        miscInfo.tempGAI = null;
        miscInfo.MortgageRepayments = tempMR || 0;
        miscInfo.MortgageDebt = MortgageDebt;
        miscInfo.TotalOtherDebts = TotalOtherDebts;
        miscInfo.IsChild = false;
        miscInfo.DateOfBirth = '';
        miscInfo.dobChunked = {
          year: '0',
        };
        miscInfo.BenefitList = [staticModels.getBenefitsStaticModel()];
        miscInfo.IsSelected = false;
        miscInfo.IsPrimary = isPrimary;

        return miscInfo;
      };

      const getNewProfilerEntityInput = () => {
        return {
          Frequency: 12,
          NumberOfChildren: 0,
          NumberOfClients: 1,
          reCalculateClientIndex: 0,
          PeopleEntity: [getNewPeopleEntity(0, 0, 0, true)],
          lastIndexChild: 0,
          lastIndexAdult: 0,
          TotalClientCount: 1,
          isNewClient: true,
        };
      };

      $scope.profilerFormData = angular.copy(getNewProfilerEntityInput());

      const checkBoxOptions = {
        Health: true,
        Income: true,
        Mortgage: true,
        TPD: true,
        Trauma: true,
        Life: true,
      };

      const processDependentData = (formData) => {
        if (
          formData.NumberOfChildren >
          formData.TotalClientCount - formData.NumberOfClients
        ) {
          formData.PeopleEntity.push(getNewPeopleEntity(0, 0, 0));
          $scope.indexId += 1;
          formData.PeopleEntity[formData.PeopleEntity.length - 1].indexId =
            $scope.indexId;
          formData.lastIndexChild =
            formData.PeopleEntity[formData.PeopleEntity.length - 1].indexId;
          formData.PeopleEntity[
            formData.PeopleEntity.length - 1
          ].IsChild = true;
          formData.TotalClientCount++;
        } else {
          for (let d = 0; d < formData.PeopleEntity.length; d++) {
            if (formData.PeopleEntity[d].indexId === formData.lastIndexChild) {
              formData.PeopleEntity.splice(d, 1);
            }
          }
          formData.TotalClientCount--;
          for (let i = formData.PeopleEntity.length - 1; i > 0; i--) {
            if (formData.PeopleEntity[i].IsChild) {
              formData.lastIndexChild = formData.PeopleEntity[i].indexId;
            }
          }
        }
        return formData;
      };

      const processAdultData = (formData) => {
        if (
          formData.NumberOfClients >
          formData.TotalClientCount - formData.NumberOfChildren
        ) {
          if (formData.PeopleEntity && formData.PeopleEntity.length) {
            const {
              MortgageRepayments,
              MortgageDebt,
              TotalOtherDebts,
            } = formData.PeopleEntity[0];
            const newPeopleEntity = angular.copy(
              getNewPeopleEntity(
                MortgageRepayments,
                MortgageDebt,
                TotalOtherDebts,
              ),
            );
            formData.PeopleEntity.push(newPeopleEntity);
          }
          $scope.indexId += 1;
          formData.PeopleEntity[formData.PeopleEntity.length - 1].indexId =
            $scope.indexId;
          formData.lastIndexAdult =
            formData.PeopleEntity[formData.PeopleEntity.length - 1].indexId;
          formData.TotalClientCount++;
        } else {
          for (let d = 0; d < formData.PeopleEntity.length; d++) {
            if (formData.PeopleEntity[d].indexId === formData.lastIndexAdult) {
              formData.PeopleEntity.splice(d, 1);
            }
          }
          formData.TotalClientCount--;
          for (let i = formData.PeopleEntity.length - 1; i > 0; i--) {
            if (!formData.PeopleEntity[i].IsChild) {
              formData.lastIndexAdult = formData.PeopleEntity[i].indexId;
            }
          }
        }
        return formData;
      };

      $scope.clientTypeChange = (typeOfClient) => {
        const isNewClient = typeOfClient === $scope.CLIENT_TYPE_OPTIONS.NEW;
        const hasFamilyIdUrlParam = !!$state.params.familyId;
        const hasProfilerIdUrlParam = !!$state.params.insProfilerId;
        const shouldReload =
          isNewClient && (hasFamilyIdUrlParam || hasProfilerIdUrlParam);

        if (shouldReload) {
          $state.go('app.insuranceProfiler');
          return;
        }

        $scope.clientType = typeOfClient;
        $scope.isExistingClient =
          $scope.clientType === $scope.CLIENT_TYPE_OPTIONS.EXISTING;
        $scope.isNewClient = !$scope.isExistingClient;
        $scope.profilerFormData = {};
        $scope.profilerData = {};
        $scope.contactName = '';
        $scope.IPHealthDataList = [];
        $scope.IPIncomeProtectionDataList = {};
        $scope.IPMortgageRepaymentDataList = {};
        $scope.IPTraumaDataList = {};
        $scope.IPTPDProfilerDataList = {};
        $scope.IPLifeCoverDataList = {};
        $scope.profilerFirstLoad = true;
        $scope.isCalculated = false;
        $scope.profilerIdGet = 0;
        $scope.profilerForm.form.$setPristine();

        const timer = $timeout(() => {
          $scope.profilerFormData = {
            ...getNewProfilerEntityInput(),
          };
        });

        $scope.$on('destroy', () => {
          $timeout.cancel(timer);
        });
      };

      $scope.indexId = 0;
      $scope.updatePeopleEntity = (type) => {
        let formData = angular.copy($scope.profilerFormData);
        formData.NumberOfClients = parseInt(formData.NumberOfClients, 10);
        formData.NumberOfChildren = parseInt(formData.NumberOfChildren, 10);

        $scope.selectedClients.push(checkBoxOptions);
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (type) {
          case 'Dependent':
            formData = processDependentData(formData);
            break;
          default:
            formData = LimitClients.onlyTwoClientsProfiler(
              formData.NumberOfClients,
              processAdultData(formData),
            );
            break;
        }
        $scope.profilerFormData = angular.copy(formData);
      };

      const peopleDataMapper = (data) => {
        let indexId = 0;
        const tempPeople = [];
        data.PeopleEntity.forEach((people) => {
          const tempdata = staticModels.getMiscInfoStaticModel();
          tempdata.indexId = indexId;
          tempdata.Age = people.Age || null;
          tempdata.ClientId = 0;
          tempdata.FamilyClientID = people.FamilyClientID;
          tempdata.FirstName = people.FirstName;
          tempdata.LastName = people.LastName;
          tempdata.BenefitList = [staticModels.getBenefitsStaticModel()];
          tempdata.Gender = String(people.Gender);
          tempdata.IsChild = people.IsChild;
          tempdata.IsPrimary = people.IsPrimary;
          tempdata.DateOfBirth = people.DateOfBirth;
          tempdata.Smoker = people.Smoker;
          tempdata.OccupationID = people.OccupationID;
          tempPeople.push({
            ...tempdata,
          });
          indexId++;
        });
        data.PeopleEntity = angular.copy(tempPeople);
        $scope.profilerData.familyInfo = {
          ...tempPeople,
        };
        $scope.profilerFormData = {};
        $timeout(() => {
          $scope.profilerFormData = {
            ...data,
          };
        });
      };

      $scope.selectResult = (family) => {
        $scope.hasResults = false;
        $scope.contactName = family.FamilyFullName;
        $scope.profilerData.familyId = family.FamilyID;
        $scope.profilerData.familyFullName = $scope.contactName;

        insuranceProfilerService
          .profilerInsuranceProfilerFamilyGet(family)
          .then((response) => {
            const data = response.data;
            if (data) {
              peopleDataMapper(data);
            }
          });
      };

      $scope.deleteClientWidget = (indexId, type) => {
        const deletedPerson = $scope.profilerFormData.PeopleEntity.find(
          (person) => person.indexId === indexId,
        );
        if (!deletedPerson) {
          return;
        }

        if (deletedPerson.ClientId) {
          const clientId = deletedPerson.ClientId;
          const profilerId = $scope.profilerIdGet;
          insuranceQuoteService
            .profilerByClientIDDelete({
              clientId,
              profilerId,
            })
            .then(
              () => {
                $scope.onPersonDeleted(deletedPerson, type);
              },
              () => {
                toaster.pop('error', 'Error', `Unable to delete the ${type}.`);
              },
            );
        } else {
          $scope.onPersonDeleted(deletedPerson, type);
        }
      };

      $scope.onPersonDeleted = (deletedPerson, type) => {
        $scope.profilerFormData.PeopleEntity = filter(
          $scope.profilerFormData.PeopleEntity,
          (people) => people.indexId !== deletedPerson.indexId,
        );
        $scope.profilerData.familyInfo = [
          ...$scope.profilerFormData.PeopleEntity,
        ];
        $scope.profilerFormData.TotalClientCount--;
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (type) {
          case 'child':
            $scope.profilerFormData.NumberOfChildren--;
            $scope.profilerFormData.lastIndexChild = last(
              $scope.profilerFormData.PeopleEntity,
              (people) => {
                return people.isChild;
              },
            ).indexId;
            break;

          default:
            $scope.profilerFormData.NumberOfClients--;
            angular.forEach($scope.profilerFormData.PeopleEntity, (people) => {
              if (!people.isChild) {
                $scope.profilerFormData.lastIndexAdult = people.indexId;
              } else {
                $scope.profilerFormData.lastIndexChild = 0;
              }
            });
            break;
        }
      };

      $scope.dobAgeUpdate = (index) => {
        $scope.profilerFormData.PeopleEntity[index].DateOfBirth = '';
        $scope.profilerFormData.PeopleEntity[index].dobChunked = {
          year: '0',
        };
      };

      /** ****************************************************** */
      $scope.updateShowIPInsurersGraph = (value) => {
        $scope[value] = !$scope[value];
      };

      const getInsuranceHealthData = () => {
        insuranceProfilerService
          .profilerHealthCoverGet($scope.profilerFormData, $scope.profilerIdGet)
          .then((response) => {
            const data = response.data;
            if (data && data.length) {
              forEach(data, (value) => {
                value.isIncluded = true;
              });
              $scope.IPHealthDataList = data;
              $scope.inProgressHealth = false;

              forEach($scope.profilerFormData.PeopleEntity, (people) => {
                people.BenefitList[0].HealthCover = data[0];
              });
              forEach($scope.IPHealthDataList, (total) => {
                $scope.getTotalPremiumBallPark.benefits(total.Premium);
              });
            }
          });
      };

      const getIncomeProtectionData = () => {
        insuranceProfilerService
          .profilerIncomeProtectionGet(
            $scope.profilerFormData,
            $scope.profilerIdGet,
          )
          .then((response) => {
            const data = response.data;
            if (data && data.length) {
              $scope.iPCoverLevelUpdateCount = 0;

              $scope.IPIncomeProtectionDataList = data;

              $scope.IPIncomeProtectionDataList.map((item) => {
                item.IPFrequency = item.IPFrequency ? item.IPFrequency : 1;
                return item;
              });
              $scope.inProgressIncomeP = false;
              $scope.inProgressIPMortgage = false;
              forEach($scope.profilerFormData.PeopleEntity, (people, index) => {
                const incomeProtectionCover = data[index];
                if (incomeProtectionCover) {
                  people.BenefitList[0].IncomeProtection = incomeProtectionCover;
                }
              });
              forEach($scope.IPIncomeProtectionDataList, (total) => {
                $scope.getTotalPremiumBallPark.benefits(total.Premium);
              });
            }
          });
      };

      const getMortgageRepaymentData = (mortgageData, coverType) => {
        const mortgages = $scope.profilerFormData;
        let index = 0;
        if (mortgages.PeopleEntity && mortgages.PeopleEntity.length) {
          mortgages.PeopleEntity = mortgages.PeopleEntity.map((item) => {
            item.IsChanged = true;
            return item;
          });
        }
        if (coverType && mortgages.PeopleEntity.length) {
          forEach(mortgages.PeopleEntity, (people) => {
            people.BenefitList[0].BenefitId = coverType;
            people.BenefitList[0].MortgageRepaymentCover = {
              LevelOfCover: mortgageData[index].LevelOfCover,
              PaymentPeriod: mortgageData[index].PaymentPeriod,
              Premium: mortgageData[index].Premium,
              WaitingPeriod: mortgageData[index].WaitingPeriod,
            };
            index++;
          });
        }

        insuranceProfilerService
          .profilerMortgageRepaymentGet(mortgages, $scope.profilerIdGet)
          .then((response) => {
            const data = response.data;
            if (data && data.length) {
              $scope.IPMortgageRepaymentDataList = data;
              $scope.inProgressIPMortgage = false;

              forEach($scope.profilerFormData.PeopleEntity, (people) => {
                people.BenefitList[0].MortgageRepaymentCover = data[0];
              });
              forEach($scope.IPMortgageRepaymentDataList, (total) => {
                $scope.getTotalPremiumBallPark.benefits(total.Premium);
              });
            }
          });
      };

      const getTraumaData = () => {
        insuranceProfilerService
          .profilerTraumaGet($scope.profilerFormData, $scope.profilerIdGet)
          .then((response) => {
            const data = response.data;
            if (data && data.length) {
              $scope.IPTraumaDataList = data;
              $scope.inProgressTrauma = false;

              forEach($scope.profilerFormData.PeopleEntity, (people) => {
                people.BenefitList[0].TraumaCover = data[0];
              });
              forEach($scope.IPTraumaDataList, (total) => {
                $scope.getTotalPremiumBallPark.benefits(total.Premium);
              });
            }
          });
      };

      const getTPDProfilerData = () => {
        insuranceProfilerService
          .profilerTPDProfilerGet($scope.profilerFormData, $scope.profilerIdGet)
          .then((response) => {
            const data = response.data;
            if (data && data.length) {
              $scope.IPTPDProfilerDataList = data;
              $scope.inProgressTPD = false;

              forEach($scope.profilerFormData.PeopleEntity, (people) => {
                people.BenefitList[0].TotalAndPermanentDisability = data[0];
              });
              forEach($scope.IPTPDProfilerDataList, (total) => {
                $scope.getTotalPremiumBallPark.benefits(total.Premium);
              });
            }
          });
      };

      const getProfilerLifeCoverData = () => {
        insuranceProfilerService
          .profilerLifeCoverGet($scope.profilerFormData, $scope.profilerIdGet)
          .then((response) => {
            const data = response.data;
            if (data && data.length) {
              $scope.IPLifeCoverDataList = data;
              $scope.inProgressLI = false;

              forEach($scope.profilerFormData.PeopleEntity, (people) => {
                people.BenefitList[0].LifeCover = data[0];
              });
              forEach($scope.IPLifeCoverDataList, (total) => {
                $scope.getTotalPremiumBallPark.benefits(total.Premium);
              });
            }
          });
      };

      const lookupGet = () => {
        insuranceProfilerService.lookup().then((response) => {
          const lookup = response.data;
          if (lookup) {
            $scope.occList = lookup.QuoteOccupation;
            $scope.occTypeList = lookup.ProfileOccupation;
            $scope.incomeProtectionPayment = lookup.BenefitPeriod;
            $scope.incomeProtectionType = lookup.CoverType;
            $scope.healthListCoverSuggested = lookup.Excess;
            $scope.incomeProtectionWaiting = lookup.WeekWaitPeriod;
          }
        });
      };

      const initBenefitTypeTabs = () => {
        $scope.benefitTypeTabs = [
          {
            tabId: 'health_insurance',
            heading: 'Health Insurance',
            contentTemplate:
              'assets/views/insuranceTools/partials/health_insurance.html',
            benefitType: $scope.getBenefitTypeById(
              INSURANCE_OPTIONS.BENEFIT_TYPE.HEALTH_COVER,
            ),
          },
          {
            tabId: 'income_protection',
            heading: 'Income Protection',
            contentTemplate:
              'assets/views/insuranceTools/partials/income_protection.html',
            benefitType: $scope.getBenefitTypeById(
              INSURANCE_OPTIONS.BENEFIT_TYPE.INCOME_PROTECTION,
            ),
          },
          {
            tabId: 'trauma_insurance',
            heading: 'Trauma Insurance',
            contentTemplate:
              'assets/views/insuranceTools/partials/trauma_insurance.html',
            benefitType: $scope.getBenefitTypeById(
              INSURANCE_OPTIONS.BENEFIT_TYPE.TRAUMA_COVER,
            ),
          },
          {
            tabId: 'total_permanent_disablement',
            heading: 'Total Permanent Disablement',
            contentTemplate:
              'assets/views/insuranceTools/partials/tpd_insurance.html',
            benefitType: $scope.getBenefitTypeById(
              INSURANCE_OPTIONS.BENEFIT_TYPE.TPD,
            ),
          },
          {
            tabId: 'life_insurance',
            heading: 'Life Insurance',
            contentTemplate:
              'assets/views/insuranceTools/partials/life_insurance.html',
            benefitType: $scope.getBenefitTypeById(
              INSURANCE_OPTIONS.BENEFIT_TYPE.LIFE_COVER,
            ),
          },
        ];
      };

      const initBenefitTypes = () => {
        insuranceQuoteService.insuranceBenefitTypeGet().then((response) => {
          $scope.benefitTypes = response;
          initBenefitTypeTabs();
        });
      };

      const insuranceProfileSettingsGet = () => {
        insuranceProfilerService
          .insuranceProfileSettingsGet()
          .then((response) => {
            if (response.data) {
              $scope.insuranceProfilerSettings = response.data;
            }
          });
      };

      const functSetIntialBenefits = (obj) => {
        obj.BenefitList.forEach((item) => {
          if (Object.keys(item.HealthCover).length) {
            $scope.IPHealthDataList = [];
            $scope.IPHealthDataList.push(item.HealthCover);
          }
          if (Object.keys(item.IncomeProtection).length) {
            $scope.IPIncomeProtectionDataList.push(item.IncomeProtection);
          }
          if (Object.keys(item.MortgageRepaymentCover).length) {
            $scope.IPMortgageRepaymentDataList.push(
              item.MortgageRepaymentCover,
            );
          }

          if (Object.keys(item.TraumaCover).length) {
            $scope.IPTraumaDataList.push(item.TraumaCover);
          }
          if (Object.keys(item.TotalAndPermanentDisability).length) {
            $scope.IPTPDProfilerDataList.push(item.TotalAndPermanentDisability);
          }
          if (Object.keys(item.LifeCover).length) {
            $scope.IPLifeCoverDataList.push(item.LifeCover);
          }
        });
        if ($scope.IPMortgageRepaymentDataList.length) {
          $scope.showdMortgageInsurance = true;
        }
        $scope.computeTotalBallParkPremium();
      };

      // eslint-disable-next-line sonarjs/cognitive-complexity
      const editProfiler = () => {
        if ($state.params.insProfilerId) {
          $scope.profilerData.familyId = $state.params.familyId;
          $scope.moduleToProfiler = true;
          $scope.isCalculated = true;
          $scope.clientType = $scope.CLIENT_TYPE_OPTIONS.EXISTING;
          insuranceProfilerService
            .profilerInsuranceProfilerDetailsGet(
              parseInt($state.params.insProfilerId, 10) || 0,
            )
            .then((response) => {
              const details = response.data;
              if (details) {
                $scope.profilerFirstLoad = false;
                $scope.profilerIdGet =
                  parseInt($state.params.insProfilerId, 10) || 0;
                $scope.profilerData.profilerId = $scope.profilerIdGet;
                $scope.profilerFormData.ProfilerID =
                  parseInt($state.params.insProfilerId, 10) || 0;
                $scope.profilerFormData.Frequency = details.Frequency;
                $scope.profilerFormData.NumberOfChildren =
                  details.NumberOfChildren;
                $scope.profilerFormData.NumberOfClients =
                  details.NumberOfClients;
                $scope.profilerFormData.HealthNote = formatRawNewLine(
                  details.HealthNote,
                );
                $scope.profilerFormData.IPNote = formatRawNewLine(
                  details.IPNote,
                );
                $scope.profilerFormData.MortgageNote = formatRawNewLine(
                  details.MortgageNote,
                );
                $scope.profilerFormData.TraumaNote = formatRawNewLine(
                  details.TraumaNote,
                );
                $scope.profilerFormData.TPDNote = formatRawNewLine(
                  details.TPDNote,
                );
                $scope.profilerFormData.LifeNote = formatRawNewLine(
                  details.LifeNote,
                );
                $scope.summaryAdviceNote = formatRawNewLine(
                  details.SummaryNote,
                );
                const obj = sortPeopleEntities(response.data.PeopleEntity);
                if (response.data.FamilyFullName) {
                  $scope.profilerData.familyFullName =
                    response.data.FamilyFullName;
                }
                $scope.profilerFormData.PeopleEntity = [];
                $scope.IPIncomeProtectionDataList = [];
                $scope.IPMortgageRepaymentDataList = [];
                $scope.IPTraumaDataList = [];
                $scope.IPTPDProfilerDataList = [];
                $scope.IPLifeCoverDataList = [];
                $timeout(() => {
                  Object.keys(obj).forEach((key) => {
                    $scope.profilerFormData.PeopleEntity[key] = {
                      ...getNewPeopleEntity(),
                      indexId: $scope.profilerFormData.PeopleEntity.length,
                    };
                    $scope.profilerFormData.PeopleEntity[key].ClientId =
                      obj[key].ClientId;
                    $scope.profilerFormData.PeopleEntity[key].FirstName =
                      obj[key].FirstName;
                    $scope.profilerFormData.PeopleEntity[key].LastName =
                      obj[key].LastName;
                    $scope.profilerFormData.PeopleEntity[key].Gender = String(
                      obj[key].Gender,
                    );
                    $scope.profilerFormData.PeopleEntity[key].DateOfBirth =
                      obj[key].DateOfBirth;
                    $scope.profilerFormData.PeopleEntity[key].Age =
                      obj[key].Age;
                    $scope.profilerFormData.PeopleEntity[key].Smoker =
                      obj[key].Smoker;
                    $scope.profilerFormData.PeopleEntity[key].OccupationID =
                      obj[key].OccupationID;
                    $scope.profilerFormData.PeopleEntity[key].OccupationType =
                      obj[key].OccupationType;
                    $scope.profilerFormData.PeopleEntity[
                      key
                    ].EmploymentStatusID = obj[key].EmploymentStatusID;
                    $scope.profilerFormData.PeopleEntity[
                      key
                    ].GrossAnnualIncome = obj[key].GrossAnnualIncome;
                    $scope.profilerFormData.PeopleEntity[key].tempGAI =
                      obj[key].GrossAnnualIncome;
                    $scope.profilerFormData.PeopleEntity[
                      key
                    ].MortgageRepayments = obj[key].MortgageRepayments;
                    $scope.profilerFormData.PeopleEntity[key].tempMR =
                      obj[key].MortgageRepayments;
                    $scope.profilerFormData.PeopleEntity[key].MortgageDebt =
                      obj[key].MortgageDebt;
                    $scope.profilerFormData.PeopleEntity[key].TotalOtherDebts =
                      obj[key].TotalOtherDebts;
                    $scope.profilerFormData.PeopleEntity[key].IsChild =
                      obj[key].IsChild;
                    $scope.profilerFormData.PeopleEntity[key].IsPrimary =
                      obj[key].IsPrimary;
                    $scope.profilerFormData.PeopleEntity[key].BenefitList =
                      obj[key].BenefitList;
                    $scope.profilerFormData.PeopleEntity[
                      key
                    ].HasHealthInsurance = obj[key].HasHealthInsurance;
                    $scope.profilerFormData.PeopleEntity[
                      key
                    ].HasMortgageInsurance = obj[key].HasMortgageInsurance;
                    $scope.profilerFormData.PeopleEntity[
                      key
                    ].HasIncomeProtection = obj[key].HasIncomeProtection;
                    $scope.profilerFormData.PeopleEntity[
                      key
                    ].HasTraumaInsurance = obj[key].HasTraumaInsurance;
                    $scope.profilerFormData.PeopleEntity[key].HasTPD =
                      obj[key].HasTPD;
                    $scope.profilerFormData.PeopleEntity[key].HasLifeInsurance =
                      obj[key].HasLifeInsurance;

                    functSetIntialBenefits(obj[key]);
                  });
                  const occurrence = Object.values(CLIENTS_OCCURENCE);
                  const primaryFirst = (a, b) => {
                    if (!a.IsChild && !b.IsChild) {
                      return (
                        occurrence.includes(a.IsPrimary) -
                        occurrence.includes(b.IsPrimary)
                      );
                    }
                  };
                  $scope.profilerFormData.PeopleEntity.sort(primaryFirst);
                });

                $scope.isSubmitted = true;
                $scope.isNewClient = false;
              }
            });
        }
      };

      const fromContactNew = () => {
        if ($state.params.familyId && !$state.params.insProfilerId) {
          $scope.isExistingClient = true;
          $scope.clientType = INSURANCE_OPTIONS.CLIENT_TYPE.EXISTING;
          $scope.profilerData.familyId = $state.params.familyId;
          const familyDetails = {
            familyId: $state.params.familyId,
          };

          insuranceProfilerService
            .profilerInsuranceProfilerFamilyGet(familyDetails)
            .then((response) => {
              $scope.isNewClient = false;
              const data = response.data;
              if (data) {
                $scope.profilerData.familyFullName = data.FamilyFullName;
                peopleDataMapper(data);
              }
            });
        }
      };

      const saveFinalData = () => {
        insuranceProfileSettingsGet();
        getProfilerLifeCoverData();
        getTPDProfilerData();
        getTraumaData();
        getIncomeProtectionData();
        getInsuranceHealthData();
      };

      const getFormattedPeopleEntityData = () => {
        return $scope.profilerFormData.PeopleEntity.map((person) => {
          person.Age = parseInt(person.Age, 10);
          person.Gender = parseInt(person.Gender, 10);
          person.OccupationID = parseInt(person.OccupationID, 10);
          person.GrossAnnualIncome = parseInt(person.GrossAnnualIncome, 10);
          person.MortgageRepayments = parseInt(person.MortgageRepayments, 10);
          person.MortgageDebt = parseInt(person.MortgageDebt, 10);
          person.TotalOtherDebts = parseInt(person.TotalOtherDebts, 10);
          person.IsSelected = true;
          return person;
        });
      };

      const setClientIdsForPersons = (personsWithNoIds) => {
        const requests = personsWithNoIds.reduce((accum) => {
          return [...accum, insuranceProfilerService.profilerClientIdGet()];
        }, []);
        $q.all(requests).then(
          (responses) => {
            const clientIds = sortBy(
              responses.map((response) => response.data),
            );
            const sortedPersonsWithNoIds = sortBy(
              personsWithNoIds,
              (person) => {
                return !person.IsChild ? 0 : 1;
              },
            );
            clientIds.forEach((clientId, index) => {
              sortedPersonsWithNoIds[index].ClientId = clientId;
            });
            $scope.profilerFormData.PeopleEntity = getFormattedPeopleEntityData();
            $scope.profilerData.familyInfo = [
              ...$scope.profilerFormData.PeopleEntity,
            ];
            saveFinalData();
          },
          () => {
            $scope.isCalculated = false;
            toaster.pop('error', 'Error', 'Unable to calculate.');
          },
        );
      };

      const parseData = () => {
        toaster.pop('info', 'Calculating...', 'Calculations in Progress...');
        $scope.getTotalPremiumBallPark.totalHolder = [];
        const personsWithNoIds = $scope.profilerFormData.PeopleEntity.filter(
          (person) => !parseInt(person.ClientId, 10),
        );
        if (personsWithNoIds.length) {
          setClientIdsForPersons(personsWithNoIds);
        } else {
          $scope.profilerFormData.PeopleEntity = getFormattedPeopleEntityData();
          saveFinalData();
        }

        $scope.isCalculated = true;
        $scope.profilerData.familyInfo = [
          ...$scope.profilerFormData.PeopleEntity,
        ];
      };

      $scope.functionDomino = (idx) => {
        if (!idx) {
          const mortgageRepayments =
            $scope.profilerFormData.PeopleEntity[idx].tempMR;
          const mortgageDebt =
            $scope.profilerFormData.PeopleEntity[idx].MortgageDebt;
          const totalOtherDebts =
            $scope.profilerFormData.PeopleEntity[idx].TotalOtherDebts;
          const mortgageRepaymentsType =
            $scope.profilerFormData.PeopleEntity[idx].tempMRType;
          angular.forEach($scope.profilerFormData.PeopleEntity, (obj, key) => {
            if (key) {
              obj.tempMR = mortgageRepayments;
              obj.MortgageRepayments = mortgageRepayments;
              obj.MortgageDebt = mortgageDebt;
              obj.TotalOtherDebts = totalOtherDebts;
              obj.tempMRType = mortgageRepaymentsType;
            }
          });
        }
      };

      // update GAI on temp change
      $scope.updateGAI = (value, idx) => {
        switch ($scope.profilerFormData.PeopleEntity[idx].tempGAIType) {
          case 52:
            $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncome =
              parseInt(value, 10) * 52;
            break;
          case 26:
            $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncome =
              parseInt(value, 10) * 26;
            break;
          case 12:
            $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncome =
              parseInt(value, 10) * 12;
            break;
          default:
            $scope.profilerFormData.PeopleEntity[
              idx
            ].GrossAnnualIncome = parseInt(value, 10);
            break;
        }
      };
      const getFirstClientValue = (isFirst, mortgageRepaymentsValue) => {
        const firstClient = 0;
        const secondClient = $scope.profilerFormData.PeopleEntity.length - 1;
        if (firstClient === isFirst) {
          $scope.profilerFormData.PeopleEntity[
            secondClient
          ].MortgageRepayments = mortgageRepaymentsValue;
        }
      };

      // GrossAnualIncome recalc on TF change
      $scope.calcGAI = (idx) => {
        switch ($scope.profilerFormData.PeopleEntity[idx].tempGAIType) {
          case 52:
            $scope.profilerFormData.PeopleEntity[idx].tempGAI =
              $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncome / 52;
            break;
          case 26:
            $scope.profilerFormData.PeopleEntity[idx].tempGAI =
              $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncome / 26;
            break;
          case 12:
            $scope.profilerFormData.PeopleEntity[idx].tempGAI =
              $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncome / 12;
            break;
          default:
            $scope.profilerFormData.PeopleEntity[idx].tempGAI =
              $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncome;
            break;
        }
        $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncomeType =
          $scope.profilerFormData.PeopleEntity[idx].tempGAIType;
      };

      // update MR on temp change
      $scope.updateMR = (value, idx) => {
        $scope.profilerFormData.PeopleEntity[idx].MortgageRepayments = parseInt(
          value,
          10,
        );
        switch ($scope.profilerFormData.PeopleEntity[idx].tempMRType) {
          case 52:
            $scope.profilerFormData.PeopleEntity[idx].storedMR =
              parseInt(value, 10) * 52;
            $scope.profilerFormData.PeopleEntity[idx].MortgageRepayments =
              (parseInt(value, 10) * 52) / 12;
            break;
          case 26:
            $scope.profilerFormData.PeopleEntity[idx].storedMR =
              parseInt(value, 10) * 26;
            $scope.profilerFormData.PeopleEntity[idx].MortgageRepayments =
              (parseInt(value, 10) * 26) / 12;
            break;
          case 12:
            $scope.profilerFormData.PeopleEntity[idx].storedMR =
              parseInt(value, 10) * 12;
            $scope.profilerFormData.PeopleEntity[
              idx
            ].MortgageRepayments = parseInt(value, 10);
            break;
          default:
            $scope.profilerFormData.PeopleEntity[idx].storedMR = parseInt(
              value,
              10,
            );
            $scope.profilerFormData.PeopleEntity[idx].MortgageRepayments =
              parseInt(value, 10) / 12;
            break;
        }

        $scope.functionDomino(idx);
        getFirstClientValue(
          idx,
          $scope.profilerFormData.PeopleEntity[idx].MortgageRepayments,
        );
      };

      // Mortgage Repayments recalc on TF change
      $scope.calcMR = (idx) => {
        switch ($scope.profilerFormData.PeopleEntity[idx].tempMRType) {
          case 52:
            $scope.profilerFormData.PeopleEntity[idx].tempMR =
              $scope.profilerFormData.PeopleEntity[idx].storedMR / 52;
            break;
          case 26:
            $scope.profilerFormData.PeopleEntity[idx].tempMR =
              $scope.profilerFormData.PeopleEntity[idx].storedMR / 26;
            break;
          case 12:
            $scope.profilerFormData.PeopleEntity[idx].tempMR =
              $scope.profilerFormData.PeopleEntity[idx].storedMR / 12;
            break;
          default:
            $scope.profilerFormData.PeopleEntity[idx].tempMR =
              $scope.profilerFormData.PeopleEntity[idx].storedMR;
            break;
        }
        $scope.profilerFormData.PeopleEntity[idx].MortgageRepaymentsType =
          $scope.profilerFormData.PeopleEntity[idx].tempGAIType;
        $scope.profilerFormData.PeopleEntity[idx].MortgageRepayments =
          $scope.profilerFormData.PeopleEntity[idx].tempMR;
        $scope.functionDomino(idx);
      };

      const profilerIdGetMethod = () => {
        insuranceProfilerService.profilerIdGet().then((response) => {
          const data = response.data;
          $scope.profilerIdGet = data;
          $scope.profilerData.profilerId = $scope.profilerIdGet;
          if ($state.params.insProfilerId) {
            $scope.profilerIdGet =
              parseInt($state.params.insProfilerId, 10) || 0;
            $scope.profilerData.profilerId = $scope.profilerIdGet;
          }
          parseData();
        });
      };

      const processCalculation = (isToRecalc = false) => {
        if (!$scope.isCalculated) {
          $scope.isSubmitted = true;
          $scope.profilerFirstLoad = false;
          let fieldsChecker;
          if ($scope.isSubmitted) {
            fieldsChecker = $window.document.querySelectorAll('.invalidField');
          }

          if (fieldsChecker && fieldsChecker.length) {
            toaster.pop('error', 'Error', 'Please fill the required field');
            return;
          }

          if ($scope.showdMortgageInsurance) {
            $scope.addInsuranceMortgage();
          }

          $scope.inProgressHealth = true;
          $scope.inProgressIncomeP = true;
          $scope.inProgressIPMortgage = true;
          $scope.inProgressTrauma = true;
          $scope.inProgressLI = true;
          $scope.inProgressTPD = true;

          if (!$scope.profilerIdGet) {
            profilerIdGetMethod();
          } else {
            $scope.profilerData.profilerId = $scope.profilerIdGet;
            if (isToRecalc) {
              resetDefaultProfileValue({ $scope });
            }
            parseData();
          }
        } else {
          $scope.confirmCalc();
        }
      };

      $scope.confirmCalc = () => {
        swal(
          {
            title: 'IMPORTANT - Please Check',
            text: `<p>Clicking 'Generate Profiler' again will overwrite all changes you have made to Benefit Types, Cover Amounts and Lives Insured</p><p>&nbsp;</p>
            <p>
            <div class="swal-highlight bg-light-yellow">
              MyCRM automatically recalculates the Ballpark Premiums when changes are made - there is no need to recalculate
            </div>  </p>
            <p>&nbsp;</p>
            To save your changes select 'Actions' & generate a Report or Save
            `,
            html: true,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#5b5084',
            confirmButtonText: 'YES - Overwrite',
            closeOnConfirm: true,
          },
          (confirm) => {
            if (confirm) {
              $scope.isCalculated = false;
              processCalculation(true);
            }
          },
        );
      };

      $scope.calculateProfiler = (profilerForm) => {
        $scope.profilerFormData.PeopleEntity = $scope.defaultToTrue(
          $scope.profilerFormData.PeopleEntity,
        );

        if (!profilerForm.$valid) {
          toastError('Please fill all required fields.');
          return;
        }
        profilerForm.$setPristine();

        if (!$scope.moduleToProfiler) {
          processCalculation();
        } else {
          $scope.confirmCalc();
        }
      };

      $scope.clientCounter = (idx, coverType) => {
        const idxLength = parseInt(idx, 10);
        const currentLength = $scope.selectedClients.length - 1;
        const eXcluded = currentLength < idxLength && idxLength > currentLength;
        let index = idxLength;
        if (eXcluded) {
          index = 0;
        }
        const peopleEntity = $scope.profilerFormData.PeopleEntity;
        $scope.selectedClients[index][`${coverType}`] =
          peopleEntity[index][`isIncluded_${coverType}`];
        const clients = filter(peopleEntity, (people) => {
          return people[`isIncluded_${coverType}`];
        });
        if (clients) {
          $scope.clientSelectedCount[coverType] = clients.length;
        }
        $scope.reCalculateClientChecked(idx, coverType);
      };

      $scope.repaymentPeriod = (item = '', type) => {
        let totalDate = item;
        switch (type) {
          case COVER_TYPE.REPAYMENT_PERIOD:
            $scope.incomeProtectionPayment.forEach((key) => {
              if (key.Value === item) {
                totalDate = key.Key;
              }
            });
            break;
          case COVER_TYPE.WAITING_PERIOD:
            // eslint-disable-next-line sonarjs/no-identical-functions
            $scope.incomeProtectionWaiting.forEach((key) => {
              if (key.Value === item) {
                totalDate = key.Key;
              }
            });
            break;
          default:
            break;
        }
        return totalDate;
      };
      $scope.incomeProtectionTypefilter = (item = '') => {
        let protectionType = item;
        $scope.incomeProtectionType.forEach((obj) => {
          if (obj.Value === item) {
            protectionType = obj.Key;
          }
        });
        return protectionType;
      };

      $scope.reCalculateClientChecked = (idx, coverType) => {
        const isCheckBox = true;
        switch (coverType) {
          case ACTIVE_TAB.HEALTH:
            $scope.healthCoverOptions();
            break;
          case ACTIVE_TAB.INCOME:
            $scope.refreshPremiums(
              idx,
              COVER_TYPE.INCOME_PROTECTION,
              {},
              isCheckBox,
            );
            break;
          case ACTIVE_TAB.TRAUMA:
            $scope.refreshPremiums(
              idx,
              COVER_TYPE.TRAUMA_COVER,
              {},
              isCheckBox,
            );
            break;
          case ACTIVE_TAB.TPD:
            $scope.refreshPremiums(
              idx,
              COVER_TYPE.TOTAL_PERMANENT_DISABILITY,
              {},
              isCheckBox,
            );
            break;
          case ACTIVE_TAB.LIFE:
            $scope.refreshPremiums(idx, COVER_TYPE.LIFE_COVER, {}, isCheckBox);
            break;
          case ACTIVE_TAB.MORTGAGE:
            $scope.refreshPremiums(
              idx,
              COVER_TYPE.MORTGAGE_REPAYMENT,
              {},
              isCheckBox,
            );
            break;
          default:
            break;
        }
      };
      $scope.healthCoverOptions = () => {
        const insuranceChecked = [];
        const currentIndex = 0;
        angular.forEach($scope.IPHealthDataList, (obj) => {
          if (obj.DentalOptical) {
            insuranceChecked.push(INSURANCE_OPTIONS.HEALTH.DENTALOPTICAL);
          }
          if (obj.GPOption) {
            insuranceChecked.push(INSURANCE_OPTIONS.HEALTH.GPOPTION);
          }
          if (obj.SpecialistsTest) {
            insuranceChecked.push(INSURANCE_OPTIONS.HEALTH.SPECIALIST);
          }
        });
        for (const element of insuranceChecked) {
          $scope.eachCoverOptionChecked(element);
        }
        $scope.refreshPremiums(
          currentIndex,
          COVER_TYPE.HEALTH_COVER,
          {},
          false,
        );
      };

      $scope.updateLevel = (coverType, idx) => {
        const gai = $scope.profilerFormData.PeopleEntity[idx].GrossAnnualIncome;
        if (coverType === 1) {
          $scope.IPIncomeProtectionDataList[idx].LevelOfCover = gai * 0.55;
        } else if (coverType === 6) {
          $scope.IPIncomeProtectionDataList[idx].LevelOfCover = gai * 0.625;
        } else {
          $scope.IPIncomeProtectionDataList[idx].LevelOfCover = gai * 0.75;
        }

        $scope.refreshPremiums(idx, COVER_TYPE.INCOME_PROTECTION);
      };
      $scope.eachCoverOptionChecked = (idx = 0, inCoverOption) => {
        const isValidHealthtData = isInIPHealthDataList(
          $scope.IPHealthDataList,
        );
        if (isValidHealthtData) {
          switch (inCoverOption) {
            case INSURANCE_OPTIONS.HEALTH.SPECIALIST:
              $scope.IPHealthDataList[idx].SpecialistsTest = !$scope
                .IPHealthDataList[idx].SpecialistsTest;
              break;
            case INSURANCE_OPTIONS.HEALTH.GPOPTION:
              $scope.IPHealthDataList[idx].GPOption = !$scope.IPHealthDataList[
                idx
              ].GPOption;
              break;
            case INSURANCE_OPTIONS.HEALTH.DENTALOPTICAL:
              $scope.IPHealthDataList[idx].DentalOptical = !$scope
                .IPHealthDataList[idx].DentalOptical;
              break;
            default:
              break;
          }
        }
      };

      $scope.healthOptions = (idx, coverOption) => {
        if ($scope.IPHealthDataList && $scope.IPHealthDataList.length) {
          switch (coverOption) {
            case INSURANCE_OPTIONS.HEALTH.SPECIALIST:
              $scope.IPHealthDataList[idx].SpecialistsTest = !$scope
                .IPHealthDataList[idx].SpecialistsTest;
              break;
            case INSURANCE_OPTIONS.HEALTH.GPOPTION:
              $scope.IPHealthDataList[idx].GPOption = !$scope.IPHealthDataList[
                idx
              ].GPOption;
              break;
            case INSURANCE_OPTIONS.HEALTH.DENTALOPTICAL:
              $scope.IPHealthDataList[idx].DentalOptical = !$scope
                .IPHealthDataList[idx].DentalOptical;
              break;
            default:
              break;
          }
        }

        $scope.refreshPremiums(idx, COVER_TYPE.HEALTH_COVER);
      };

      $scope.addInsuranceMortgage = () => {
        getMortgageRepaymentData();
        $scope.showdMortgageInsurance = true;
      };

      $scope.assignProfilerFormDataLoop = (dataList, coverType) => {
        angular.forEach(dataList, (obj, key) => {
          const dataExistence =
            $scope.profilerFormData.PeopleEntity[key] &&
            $scope.profilerFormData.PeopleEntity[key].BenefitList[0];
          if (dataExistence) {
            $scope.profilerFormData.PeopleEntity[key].BenefitList[0][
              coverType
            ] = obj;
          }
        });
      };

      $scope.assignProfilerFormData = (coverType) => {
        switch (coverType) {
          case COVER_TYPE.LIFE_COVER:
            $scope.assignProfilerFormDataLoop(
              $scope.IPLifeCoverDataList,
              'LifeCover',
            );
            break;
          case COVER_TYPE.TRAUMA_COVER:
            $scope.assignProfilerFormDataLoop(
              $scope.IPTraumaDataList,
              'TraumaCover',
            );
            break;
          case COVER_TYPE.TOTAL_PERMANENT_DISABILITY:
            $scope.assignProfilerFormDataLoop(
              $scope.IPTPDProfilerDataList,
              'TotalAndPermanentDisability',
            );
            break;
          case COVER_TYPE.INCOME_PROTECTION:
            $scope.assignProfilerFormDataLoop(
              $scope.IPIncomeProtectionDataList,
              'IncomeProtection',
            );
            break;
          case COVER_TYPE.MORTGAGE_REPAYMENT:
            $scope.assignProfilerFormDataLoop(
              $scope.IPMortgageRepaymentDataList,
              'MortgageRepaymentCover',
            );
            break;
          default:
            break;
        }
      };

      function updateRefreshPremiumsBenefitId(benefitListValue) {
        angular.forEach(benefitListValue, (value) => {
          value.BenefitId = null;
        });
      }
      const isIncludedCover = (coverType) => {
        let coverTypeList = '';
        switch (coverType) {
          case COVER_TYPE.HEALTH_COVER:
            coverTypeList = 'HasHealthInsurance';
            break;
          case COVER_TYPE.INCOME_PROTECTION:
            coverTypeList = 'HasIncomeProtection';
            break;
          case COVER_TYPE.TRAUMA_COVER:
            coverTypeList = 'HasTraumaInsurance';
            break;
          case COVER_TYPE.TOTAL_PERMANENT_DISABILITY:
            coverTypeList = 'HasTPD';
            break;
          case COVER_TYPE.LIFE_COVER:
            coverTypeList = 'HasLifeInsurance';
            break;
          case COVER_TYPE.MORTGAGE_REPAYMENT:
            coverTypeList = 'HasMortgageInsurance';
            break;
          default:
            break;
        }
        return coverTypeList;
      };

      $scope.defaultToTrue = (data = []) => {
        return data.map((item) => {
          item.IsChanged = true;
          return item;
        });
      };

      $scope.isChanged = (idx, peopleEntity = []) => {
        return peopleEntity.map((item, index) => {
          item.IsChanged = index === idx;
          return item;
        });
      };

      $scope.onIPCoverLevelChange = (index, coverType) => {
        $scope.iPCoverLevelUpdateCount++;
        $scope.refreshPremiums(index, coverType);
      };

      $scope.calculateCoverLevel = (index, frequency) => {
        const coverLevel =
          $scope.IPIncomeProtectionDataList[index].LevelOfCover;
        if (frequency === FREQUENCY_VALUES.MONTHLY) {
          const newCover = coverLevel / frequency;
          $scope.IPIncomeProtectionDataList[index].LevelOfCover =
            Math.round(newCover * 100) / 100;
        } else {
          const newCover = coverLevel * FREQUENCY_VALUES.MONTHLY;
          $scope.IPIncomeProtectionDataList[index].LevelOfCover = Math.round(
            newCover,
          );
        }
      };

      $scope.onIPLevelSuggestionChange = (value, index, coverType) => {
        onIPLevelSuggestionChange({ $scope, value, index, coverType });
      };

      $scope.onIPFrequencyChange = (coverType, index) => {
        onIPFrequencyChange({ $scope, coverType, index });
      };

      $scope.refreshPremiums = (
        idx,
        coverType,
        benefitObj = {},
        isCheckBox,
        isSuggested = false,
        // eslint-disable-next-line sonarjs/cognitive-complexity
      ) => {
        const benefitType = BENEFIT_LIST[coverType];
        const PeopleEntity = $scope.isChanged(
          idx,
          $scope.profilerFormData.PeopleEntity,
        );

        $scope.isCalculating = true;
        $scope.assignProfilerFormData(coverType);

        const temporayFormData = {
          ...$scope.profilerFormData,
          PeopleEntity,
        };
        const isEmptyObj =
          Object.keys(benefitObj).length === 0 &&
          benefitObj.constructor === Object;
        if (!isEmptyObj) {
          temporayFormData.PeopleEntity[idx].BenefitList[0][
            benefitType
          ] = benefitObj;
        }
        let tempHealthData = {};
        let peopleEntityLastItem = [];
        if (temporayFormData.PeopleEntity) {
          peopleEntityLastItem = temporayFormData.PeopleEntity.slice(-1);

          if (coverType === COVER_TYPE.HEALTH_COVER) {
            angular.forEach(temporayFormData.PeopleEntity, (value) => {
              value.IsSelected = value.HasHealthInsurance;
              value.IsChanged = value.IsSelected;
              updateRefreshPremiumsBenefitId(value.BenefitList);
            });
          } else {
            angular.forEach(
              temporayFormData.PeopleEntity,
              (value, personIndex) => {
                if (personIndex === idx) {
                  value.IsSelected = value[`${isIncludedCover(coverType)}`];
                  value.IsChanged = value.IsSelected;
                }
                updateRefreshPremiumsBenefitId(value.BenefitList);
              },
            );
          }
        }
        const isValidHealthCoverProp =
          peopleEntityLastItem &&
          peopleEntityLastItem[0] &&
          peopleEntityLastItem[0].BenefitList &&
          peopleEntityLastItem[0].BenefitList[0] &&
          peopleEntityLastItem[0].BenefitList[0].HealthCover;

        if (isValidHealthCoverProp) {
          tempHealthData = peopleEntityLastItem[0].BenefitList[0].HealthCover;
        }

        if (coverType === COVER_TYPE.HEALTH_COVER) {
          angular.forEach(temporayFormData.PeopleEntity, (value) => {
            const validHealthCover =
              value.BenefitList &&
              value.BenefitList[0] &&
              value.BenefitList[0].HealthCover;
            if (validHealthCover) {
              value.BenefitList[0].BenefitId = coverType;
              value.BenefitList[0].HealthCover = tempHealthData;
            }
          });
        } else {
          angular.forEach(temporayFormData.PeopleEntity, (value) => {
            if (!value.IsChild) {
              value.BenefitList[0].BenefitId = coverType;
            }
          });
        }

        const delay = isCheckBox ? 100 : 500;
        $timeout.cancel($scope.timeoutPromise);
        $scope.timeoutPromise = $timeout(() => {
          if (!isSuggested) {
            $scope[`${idx}${coverType}_calculating`] = true;
          }
          if (isSuggested) {
            if (COVER_TYPE.MORTGAGE_REPAYMENT === coverType) {
              $scope.IPMortgageRepaymentDataList[
                idx
              ].isCalculatingSuggestedPremium = true;
            } else {
              $scope.IPIncomeProtectionDataList[
                idx
              ].isCalculatingSuggestedPremium = true;
            }
          }
          $scope.updateBallParkPremiums(
            temporayFormData,
            $scope.profilerIdGet,
            coverType,
            idx,
            isSuggested,
          );
        }, delay);
      };

      $scope.updateBallParkPremiums = (
        temporayFormData,
        profilerIdGet,
        coverType,
        idx,
        isSuggested = false,
        // eslint-disable-next-line sonarjs/cognitive-complexity
      ) => {
        insuranceProfilerService
          .profilerBallParkPremiumGet(temporayFormData, {
            profilerId: profilerIdGet,
            isSuggested,
          })
          .then((response) => {
            const data = response.data || ZERO_PREMIUM;

            switch (coverType) {
              case COVER_TYPE.HEALTH_COVER:
                if ($scope.IPHealthDataList[idx]) {
                  $scope.IPHealthDataList[idx].Premium = data;
                }
                break;
              case COVER_TYPE.LIFE_COVER:
                if ($scope.IPLifeCoverDataList[idx]) {
                  $scope.IPLifeCoverDataList[idx].Premium = data;
                }
                break;
              case COVER_TYPE.TRAUMA_COVER:
                if ($scope.IPTraumaDataList[idx]) {
                  $scope.IPTraumaDataList[idx].Premium = data;
                }
                break;
              case COVER_TYPE.TOTAL_PERMANENT_DISABILITY:
                if ($scope.IPTPDProfilerDataList[idx]) {
                  $scope.IPTPDProfilerDataList[idx].Premium = data;
                }
                break;
              case COVER_TYPE.INCOME_PROTECTION:
                if (!isSuggested) {
                  $scope.IPIncomeProtectionDataList[idx].Premium = data;
                } else {
                  $scope.IPIncomeProtectionDataList[
                    idx
                  ].Suggested_Premium = data;
                }
                break;
              case COVER_TYPE.MORTGAGE_REPAYMENT:
                if ($scope.IPMortgageRepaymentDataList[idx]) {
                  $scope.IPMortgageRepaymentDataList[idx].Premium = data;
                }
                break;
              default:
                break;
            }
            $scope.computeTotalBallParkPremium();
            $scope[`${idx}${coverType}_calculating`] = false;
            $scope.IPIncomeProtectionDataList[
              idx
            ].isCalculatingSuggestedPremium = false;
            if ($scope.IPMortgageRepaymentDataList[idx]) {
              $scope.IPMortgageRepaymentDataList[
                idx
              ].isCalculatingSuggestedPremium = false;
            }
            $scope.isCalculating = false;
            $scope.inProgressHealth = false;
            $scope.inProgressIncomeP = false;
            $scope.inProgressIPMortgage = false;
            $scope.inProgressTrauma = false;
            $scope.inProgressTPD = false;
            $scope.inProgressLI = false;
            toaster.pop('success', 'Success', 'Update completed successfully.');
          });
      };

      $scope.traumaTotalChange = (traumaObj, idx) => {
        const newtraumaObj = Object.keys(traumaObj).reduce(
          (newtraumaObjs, current) => {
            if (Number.isNaN(traumaObj[current]) || traumaObj[current] === '') {
              traumaObj[current] = 0;
            }
            newtraumaObjs[current] = parseInt(traumaObj[current], 10);

            return newtraumaObjs;
          },
          {},
        );
        newtraumaObj.Other = parseInt(newtraumaObj.Other, 10);
        newtraumaObj.SupportFund = parseInt(newtraumaObj.SupportFund, 10);
        newtraumaObj.MortgageCover = parseInt(newtraumaObj.MortgageCover, 10);
        newtraumaObj.DebtsCover = parseInt(newtraumaObj.DebtsCover, 10);

        newtraumaObj.Total =
          newtraumaObj.Other +
          newtraumaObj.SupportFund +
          newtraumaObj.MortgageCover +
          newtraumaObj.DebtsCover;
        traumaObj.Total = newtraumaObj.Total;
        $scope.refreshPremiums(idx, COVER_TYPE.TRAUMA_COVER, newtraumaObj);
      };

      $scope.tpdTotalInit = (tpdObjInit, occType, idx) => {
        const newtpdObj = Object.keys(tpdObjInit).reduce(
          (newtpdObjs, current) => {
            if (
              Number.isNaN(tpdObjInit[current]) ||
              tpdObjInit[current] === ''
            ) {
              tpdObjInit[current] = 0;
            }
            newtpdObjs[current] = parseInt(tpdObjInit[current], 10);

            return newtpdObjs;
          },
          {},
        );

        newtpdObj.MortgageCover = parseInt(newtpdObj.MortgageCover, 10);
        newtpdObj.DebtsCover = parseInt(newtpdObj.DebtsCover, 10);
        newtpdObj.SupportFund = parseInt(newtpdObj.SupportFund, 10);
        newtpdObj.Other = parseInt(newtpdObj.Other, 10);

        newtpdObj.Total =
          newtpdObj.MortgageCover +
          newtpdObj.DebtsCover +
          newtpdObj.SupportFund +
          newtpdObj.Other;
        if (occType !== INSURANCE_OPTIONS.OCCUPATION_TYPE.FULLTIME) {
          newtpdObj.Total +=
            newtpdObj.EducationalFund + newtpdObj.FamilyExpensesFund;
        }
        tpdObjInit.Total = newtpdObj.Total;
        $scope.refreshPremiums(
          idx,
          COVER_TYPE.TOTAL_PERMANENT_DISABILITY,
          newtpdObj,
        );
      };

      $scope.tpdTotalChange = (tpdObj, occType, idx) => {
        const newtpdObj = Object.keys(tpdObj).reduce((newtpdObjs, current) => {
          if (Number.isNaN(tpdObj[current]) || tpdObj[current] === '') {
            tpdObj[current] = 0;
          }
          newtpdObjs[current] = parseInt(tpdObj[current], 10);

          return newtpdObjs;
        }, {});

        newtpdObj.MortgageCover = parseInt(newtpdObj.MortgageCover, 10);
        newtpdObj.DebtsCover = parseInt(newtpdObj.DebtsCover, 10);
        newtpdObj.SupportFund = parseInt(newtpdObj.SupportFund, 10);
        newtpdObj.Other = parseInt(newtpdObj.Other, 10);

        newtpdObj.Total =
          newtpdObj.MortgageCover +
          newtpdObj.DebtsCover +
          newtpdObj.SupportFund +
          newtpdObj.Other;
        if (occType !== INSURANCE_OPTIONS.OCCUPATION_TYPE.FULLTIME) {
          newtpdObj.Total +=
            newtpdObj.EducationalFund + newtpdObj.FamilyExpensesFund;
        }
        tpdObj.Total = newtpdObj.Total;
        $scope.refreshPremiums(
          idx,
          COVER_TYPE.TOTAL_PERMANENT_DISABILITY,
          newtpdObj,
        );
      };

      $scope.lifeInsuranceTotalChange = (lifeInsuranceObj, idx) => {
        const newLifeInsuranceObj = Object.keys(lifeInsuranceObj).reduce(
          (lifeObj, current) => {
            if (
              Number.isNaN(lifeInsuranceObj[current]) ||
              lifeInsuranceObj[current] === ''
            ) {
              lifeInsuranceObj[current] = 0;
            }
            lifeObj[current] = parseInt(lifeInsuranceObj[current], 10);

            return lifeObj;
          },
          {},
        );
        newLifeInsuranceObj.MortgageCover = parseInt(
          newLifeInsuranceObj.MortgageCover,
          10,
        );
        newLifeInsuranceObj.DebtsCover = parseInt(
          newLifeInsuranceObj.DebtsCover,
          10,
        );
        newLifeInsuranceObj.FamilyExpensesFund = parseInt(
          newLifeInsuranceObj.FamilyExpensesFund,
          10,
        );
        newLifeInsuranceObj.EducationalFund = parseInt(
          newLifeInsuranceObj.EducationalFund,
          10,
        );
        newLifeInsuranceObj.RetirementFund = parseInt(
          newLifeInsuranceObj.RetirementFund,
          10,
        );
        newLifeInsuranceObj.FuneralFund = parseInt(
          newLifeInsuranceObj.FuneralFund,
          10,
        );
        newLifeInsuranceObj.Other = parseInt(newLifeInsuranceObj.Other, 10);

        newLifeInsuranceObj.Total =
          newLifeInsuranceObj.MortgageCover +
          newLifeInsuranceObj.DebtsCover +
          newLifeInsuranceObj.FamilyExpensesFund +
          newLifeInsuranceObj.EducationalFund +
          newLifeInsuranceObj.RetirementFund +
          newLifeInsuranceObj.FuneralFund +
          newLifeInsuranceObj.Other;

        lifeInsuranceObj.Total = newLifeInsuranceObj.Total;
        $scope.refreshPremiums(idx, COVER_TYPE.LIFE_COVER, newLifeInsuranceObj);
      };

      $scope.getBenefitCalculationStatus = (idx, coverType) => {
        return $scope[`${idx}${coverType}_calculating`];
      };

      const recalculateWithChanges = () => {
        $scope.isCalculated = false;
        processCalculation();
      };

      $scope.settingsModal = () => {
        settingsModal({
          $uibModal,
          isCalculated: $scope.isCalculated,
          crmConfirmation,
          recalculateWithChanges,
        });
      };

      $scope.toFadedLook = () => {
        return !$scope.isCalculated && !$scope.moduleToProfiler;
      };

      $scope.emailReport = () => {
        if (!$scope.isCalculated && !$scope.moduleToProfiler) {
          toastWarning(
            'Please calculate first before sending PDF report via email.',
          );
          return;
        }

        if ($scope.isProfilerDocCOC) {
          sendNewEmailReport({ $scope, helloPackService });
        } else {
          sendOldEmailReport({ $scope, $uibModal });
        }
      };

      $scope.onHealthCoverTemplateSelected = (template) => {
        $scope.profilerFormData.HealthNote = contcatNotes(
          'healthNote',
          template.content,
        );
        $scope.onNoteTemplateSelected(
          COVER_TYPE.HEALTH_COVER,
          $scope.profilerFormData.HealthNote,
        );
      };

      $scope.onIncomeProtectionTemplateSelected = (template) => {
        $scope.profilerFormData.IPNote = contcatNotes(
          'iPNote',
          template.content,
        );
        $scope.onNoteTemplateSelected(
          COVER_TYPE.INCOME_PROTECTION,
          $scope.profilerFormData.IPNote,
        );
      };

      $scope.onMortgageRepaymentInsuranceTemplateSelected = (template) => {
        $scope.profilerFormData.MortgageNote = contcatNotes(
          'mortgageNote',
          template.content,
        );
        $scope.onNoteTemplateSelected(
          COVER_TYPE.MORTGAGE_REPAYMENT,
          $scope.profilerFormData.MortgageNote,
        );
      };

      $scope.onLifeInsuranceTemplateSelected = (template) => {
        $scope.profilerFormData.LifeNote = contcatNotes(
          'lifeNote',
          template.content,
        );
        $scope.onNoteTemplateSelected(
          COVER_TYPE.LIFE_COVER,
          $scope.profilerFormData.LifeNote,
        );
      };

      $scope.onTPDTemplateSelected = (template) => {
        $scope.profilerFormData.TPDNote = contcatNotes(
          'tPDNote',
          template.content,
        );
        $scope.onNoteTemplateSelected(
          COVER_TYPE.TOTAL_PERMANENT_DISABILITY,
          $scope.profilerFormData.TPDNote,
        );
      };

      $scope.onTraumaInsuranceTemplateSelected = (template) => {
        $scope.profilerFormData.TraumaNote = contcatNotes(
          'traumaNote',
          template.content,
        );
        $scope.onNoteTemplateSelected(
          COVER_TYPE.TRAUMA_COVER,
          $scope.profilerFormData.TraumaNote,
        );
      };

      $scope.onNoteTemplateSelected = (benefitType, template) => {
        $scope.saveInsuranceNote(benefitType, template || '', false);
      };

      $scope.onGeneratingReport = () => {
        $scope.isGeneratingReport = true;
      };

      $scope.onReportGenerated = () => {
        $scope.isGeneratingReport = false;
      };

      $scope.onBirthdayUpdated = (updatedPerson) => {
        const { PeopleEntity: peopleEntity } = $scope.profilerFormData;
        const person =
          peopleEntity &&
          peopleEntity.find(
            (entity) => entity.indexId === updatedPerson.indexId,
          );
        if (person) {
          person.Age = updatedPerson.Age;
          person.DateOfBirth = updatedPerson.DOB;
        }
      };

      $scope.hideSummaryNote = true;
      $scope.summaryLabeltxt = 'Show Summary Advice Note';
      $scope.showAdviceNoteSummary = () => {
        if (!$scope.isCalculated) {
          toastWarning('Please calculate first before adding advice note.');
          return;
        }
        if (!$scope.hideSummaryNote) {
          $scope.hideSummaryNote = true;
          $scope.summaryLabeltxt = 'Show Summary Advice Note';
        } else {
          $scope.existingSummaryNotes();
          $scope.summaryLabeltxt = 'Hide Summary Advice Note';
          $scope.hideSummaryNote = false;
        }
      };

      const summaryAdviceNotes = {
        summaryNotes: [],
      };

      const contcatSummaryNotes = (propName, data) => {
        summaryAdviceNotes[propName].push(data);
        return summaryAdviceNotes[propName].join(' ');
      };

      $scope.quickTemplateSelected = (template) => {
        $scope.summaryAdviceNote = contcatSummaryNotes(
          'summaryNotes',
          template.content,
        );
        $scope.saveInsuranceNote(
          COVER_TYPE.GENERAL_NOTES,
          $scope.summaryAdviceNote || '',
        );
      };
      $scope.existingSummaryNotes = () => {
        summaryAdviceNotes.summaryNotes = [];
        contcatSummaryNotes('summaryNotes', $scope.summaryAdviceNote);
      };
      $scope.summaryAdviceNoteOnleave = (template) => {
        summaryAdviceNotes.summaryNotes = [];
        $scope.summaryAdviceNote = contcatSummaryNotes(
          'summaryNotes',
          template,
        );
        $scope.saveInsuranceNote(
          COVER_TYPE.GENERAL_NOTES,
          template || '',
          false,
        );
      };

      $scope.openSummaryOfCoverModal = () => {
        if (!$scope.profilerData || !$scope.profilerData.profilerId) {
          toastWarning('Please calculate first to view summary of cover.');
          return;
        }
        insuranceSummaryOfCoverService.openSummaryOfCoverModal(
          $scope.profilerData,
        );
      };

      $scope.getIpFrequency = () => {
        insuranceQuoteService.getIpFrequencyTypes().then((response) => {
          $scope.ipFrequencyTypes = response;
        });
      };

      /** ************************************************************************* */
      // init only!!! dont put anything ~ thanks!
      const init = () => {
        lookupGet();
        editProfiler();
        fromContactNew();
        initBenefitTypes();
        $scope.ageType('adult');
        $scope.ageType('child');
        $scope.getIpFrequency();
        $scope.getFrequencyTypes();
      };

      init();
    });
