import _ from 'lodash';
import { shortenAmount } from 'Common/utilities/currency';

class SendMailCtrl {
  constructor(
    $stateParams,
    $document,
    $uibModal,
    $filter,
    SweetAlert,
    ckEditorConfigService,
    contactService,
    mailTemplateService,
    templateService,
    insuranceQuoteService,
    uiService,
    stateService,
  ) {
    'ngInject';

    this.$stateParams = $stateParams;
    this.$document = $document;
    this.$uibModal = $uibModal;
    this.$filter = $filter;
    this.SweetAlert = SweetAlert;
    this.ckEditorConfigService = ckEditorConfigService;
    this.contactService = contactService;
    this.mailTemplateService = mailTemplateService;
    this.templateService = templateService;
    this.insuranceQuoteService = insuranceQuoteService;
    this.stateService = stateService;
    this.uiService = uiService;
    this.mail = {
      familyId: this.familyId,
      workbenchLoanId: this.loanId || 0,
      workbenchQuoteID: this.quoteId || 0,
    };
    this.templates = {};
    this.options = ckEditorConfigService.getCommonEmailEditorConfig();
    this.mailTemplateListing = [];
    this.insurerListOfPolicy = [];
    this.placeHolderInsurancTemplate = '';
  }

  clearEditor() {
    if (!this.mail.Content || !this.mail.Content.length) {
      return;
    }

    this.SweetAlert.swal(
      {
        title: 'Are you sure?',
        text: 'Your content will be removed from the editor.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          this.resetEditor();
        }
      },
    );
  }

  resetEditor() {
    this.templates.selectedTemplate = {
      UserTemplateID: 0,
      UserTemplateName: 'Blank',
      UserTemplateContent: null,
    };
    this.mail.Content = null;
    this.mail.UserTemplateName = 'Blank';
    this.selectedLoanInvalid = false;
  }

  getMailTemplates(isRefresh) {
    this.contactService.mailTemplatesGet().then((response) => {
      if (!response.data) {
        return;
      }
      this.blankCategory = _.remove(response.data, (folder) =>
        this.templateService.isBlankFolderOrTemplate(folder),
      )[0];
      this.blankTemplate = this.templateService.getBlankTemplateIn(
        this.blankCategory,
      );
      if (!isRefresh) {
        this.setTemplateSelection(this.blankTemplate);
      }
      this.mailTemplateListing = this.templateService.filterTemplatesByUserType(
        response.data,
      );
    });
  }

  isBlankTemplateSelected() {
    if (
      this.templates &&
      this.templates.selectedTemplate &&
      this.blankTemplate
    ) {
      return (
        this.templates.selectedTemplate.UserTemplateID ===
        this.blankTemplate.UserTemplateID
      );
    }
  }

  setTemplateSelection(template) {
    if (template) {
      const {
        UserTemplateID,
        UserTemplateName,
        UserTemplateContent,
      } = template;
      this.templates.selectedTemplate = { UserTemplateID, UserTemplateName };
      this.mail.UserTemplateName = UserTemplateName;
      this.placeHolderInsurancTemplate = UserTemplateContent;
      this.translateTemplate(UserTemplateContent, this.selectedLoanPolicyId);
    }
  }

  translateTemplate(templateContent, policyId = 0) {
    if (templateContent && templateContent.trim()) {
      const loan = _.find(
        this.clientLoansList,
        (item) => item.LoanID === this.selectedLoan,
      );
      const {
        familyId,
        primaryClient: client,
        selectedLoanStructure: loanStructureId,
      } = this;
      const mergeFieldData = {
        familyId,
        client,
        loan,
        templateContent,
        loanStructureId,
        policyId,
      };
      this.mailTemplateService.translateMailTemplate(mergeFieldData).then(
        (response) => {
          this.mail.Content = response;
          this.selectedLoanInvalid = false;
        },
        (error) => {
          this.mail.Content = error.templateContent || templateContent;
          this.selectedLoanInvalid = error.data === 'No loan selected';
        },
      );
    } else {
      this.mail.Content = null;
    }
  }

  openMailTemplatesManagement(contentToSaveAsTemplate) {
    this.$uibModal
      .open({
        templateUrl:
          'assets/views/contacts/mailTemplatesManagement/mail_templates_management.html',
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
        controller: 'MailTemplatesManagementCtrl as mailTemplatesVm',
        resolve: {
          contentToSaveAsTemplate: () => contentToSaveAsTemplate,
          showDefaultTemplatesSettings: false,
        },
      })
      .result.then((hasChanges) => {
        if (!hasChanges) {
          return;
        }
        this.getMailTemplates(true);
      });
  }

  saveCurrentContentAsTemplate() {
    if (this.mail.Content && this.mail.Content.trim().length) {
      this.openMailTemplatesManagement(this.mail.Content);
    }
  }

  onLoanSelected(loanId) {
    if (!loanId) {
      return;
    }

    const selectedLoan = this.clientLoansList.find(
      (loan) => loan.LoanID === loanId,
    );
    if (selectedLoan.LoanStructureList) {
      this.loanStructuresList = selectedLoan.LoanStructureList.map(
        (loanStructure) => {
          const loanStructureAmount = loanStructure.Value || 0;
          const formattedAmount = `$${shortenAmount(loanStructureAmount, 2)}`;
          loanStructure.LoanStructureName = `${formattedAmount} ${loanStructure.InterestRateType} ${loanStructure.LoanStructureType}`;
          return loanStructure;
        },
      );
    } else {
      this.loanStructuresList = [];
    }
    this.applyLoanDetailsToTemplate(loanId);
  }

  applyLoanDetailsToTemplate(loan) {
    if (loan && this.mail && this.mail.Content && this.mail.Content.trim()) {
      this.selectedLoanInvalid = false;
      this.mail.Content = this.translateTemplate(this.mail.Content);
    }
  }

  onInvalidLoan() {
    this.selectedLoanInvalid = true;
  }

  getClientInfo() {
    this.contactService.clientInformGet(this.familyId).then((response) => {
      if (response.data) {
        this.clientHeaderName = this.mailTemplateService.constructClientFullName(
          response.data,
        );
        this.primaryClient = this.mailTemplateService.getPrimaryClientFrom(
          response.data,
        );
      }
    });
  }

  getClientLoans() {
    this.mailTemplateService
      .getMergeFieldLoansSelection(this.familyId)
      .then((response) => {
        this.clientLoansList = response;
      });
  }

  getIsurancePolicy() {
    this.insuranceQuoteService
      .getInsurerMergeField(this.familyId)
      .then((response) => {
        this.insurerListOfPolicy = response;
      })
      .catch(() => {});
  }

  validateMergeFieldOption() {
    const hasInsurancePolicies =
      this.insurerListOfPolicy && this.insurerListOfPolicy.length;
    const hasLoans = this.clientLoansList && this.clientLoansList.length;
    return hasInsurancePolicies || hasLoans;
  }

  onlyForNz() {
    const hasInsurancePolicies =
      this.insurerListOfPolicy && this.insurerListOfPolicy.length;
    return hasInsurancePolicies && this.uiService.isCountry('New Zealand');
  }

  translateInsurancePolicy(templateContent, policyId) {
    const loan = _.find(
      this.clientLoansList,
      (item) => item.LoanID === this.selectedLoan,
    );
    const { loanStructureId = '0' } = { loanStructureId: 0 };
    const { familyId, primaryClient: client } = this;
    const mergeFieldData = {
      familyId,
      client,
      loan,
      templateContent,
      loanStructureId,
      policyId,
    };
    this.mailTemplateService.translateMailTemplate(mergeFieldData).then(
      (response) => {
        this.mail.Content = response;
      },
      (error) => {
        this.mail.Content = error.templateContent;
      },
    );
  }

  changeInsurancPolicy(policyId) {
    this.mail.Content = this.translateTemplate(
      this.placeHolderInsurancTemplate,
      policyId,
    );
  }

  $onInit() {
    this.getClientInfo();
    this.getClientLoans();
    this.getMailTemplates();
    this.resetEditor();
    this.getIsurancePolicy();
  }
}

export default SendMailCtrl;
