import { getFirstSecurityWithAddress } from '../expandableTable/util';

export default class LoanCompactDetailsCtrl {
  $onInit() {
    this.loanDetails &&
      (this.loanDetails.firstSecurityWithAddress = getFirstSecurityWithAddress(
        this.loanDetails.SecurityInfo,
      ));
  }
}
