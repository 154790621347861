import { INSURANCE_TAB_ID } from 'Common/constants/customerCareModules';
import {
  D_MMM_YYYY_FORMAT,
  TO_MYCRM_DATE_FORMAT,
} from 'Common/constants/dateConfigs';
import moment from 'moment';
import angular from 'angular';
import { isDateSentActive } from 'Assets/js/controllers/customerCare/partial/util/customerCareTabsCtrl';
import { dateFilter, tabTypes } from './util/tabContent';
import { PICKER_RANGE } from 'Common/constants/pickerRange';
import customPickerOptions from './util/customPickerOptions';

export default class TabContentCtrl {
  constructor(
    $uibModal,
    $localStorage,
    $state,
    configService,
    commonFnService,
    customerCareService,
    customerCareInsuranceService,
    customerCareTabsService,
    insurancePipelineService,
    currentUserService,
    mycrmLookupService,
  ) {
    'ngInject';

    this.$uibModal = $uibModal;
    this.$localStorage = $localStorage;
    this.$state = $state;
    this.configService = configService;
    this.commonFnService = commonFnService;
    this.customerCareService = customerCareService;
    this.customerCareInsuranceService = customerCareInsuranceService;
    this.customerCareTabsService = customerCareTabsService;
    this.insurancePipelineService = insurancePipelineService;
    this.userInfo = currentUserService;
    this.mycrmLookupService = mycrmLookupService;
    this.INSURANCE_TAB_ID = INSURANCE_TAB_ID;
  }

  $onInit() {
    this.isBulkEmailActive = this.customerCareTabsService.isBulkEmailActive();
    this.noTimeFrame =
      this.tab.id === this.INSURANCE_TAB_ID.CURRENT_CLAIMS ||
      this.tab.id === this.INSURANCE_TAB_ID.IN_ARREARS;
    this.statuses = [];
    this.tableData = [];
    this.checkedTableData = [];
    this.customerCareDateRange = [];
    this.customerCareAdviserList = [];
    this.filterUpdateCount = 0;
    this.refreshCount = 0;
    this.tabType = tabTypes(this.tab);
    this.isIssuedFollowUp = this.tabType.isIssuedFollowUp;
    this.isAnnualReviews = this.tabType.isAnnualReviews;
    this.noTimeFrame = this.tabType.isCurrentClaims || this.tabType.isInArrears;

    this.dateSentFeature = {
      isActive: isDateSentActive(this.configService),
      actionColumnTitle: isDateSentActive(this.configService)
        ? 'Communication Sent'
        : '',
    };
    this.filterData = {
      brokerId: 0,
      pageSize: 10,
      isCompleted: false,
      dateRange: this.isIssuedFollowUp || this.isAnnualReviews ? 0 : 60,
      benefitTypeId: '',
    };

    this.getTabBrokers();
    this.getCustomerCareDateRange();
    this.loadInsuranceStatuses();
    this.dateRangeFilter();
    this.isDateFilterOpen = false;
    this.filterDateDisplay = '';

    customPickerOptions.pickerCtrl(this);
  }

  onDetectChanges({ models }, tabId) {
    this.vmApply(tabId)('dateRangeDefaults').newValue(models);
  }

  resetIndex(open, tabId) {
    const currentData = this.vmAView(tabId);
    const customLength =
      this.customerCareDateRange.length - PICKER_RANGE.CUSTOM_INDEX;
    const evaluateIndex = currentData('dateActiveRangeIndex') === customLength;

    if (open && currentData('isCustomRange')) {
      this.vmApply(tabId)('dateActiveRangeIndex').newValue(customLength);
    }

    if (!open && evaluateIndex) {
      this.vmApply(tabId)('dateActiveRangeIndex').newValue(null);
    }
  }

  onDateRangeSelected(activeRangeIndex, tabId) {
    const customRange = this.vmApply(tabId);
    customRange('dateActiveRangeIndex').newValue(activeRangeIndex);
    const evaluateIndex =
      activeRangeIndex !==
      this.customerCareDateRange.length - PICKER_RANGE.CUSTOM_INDEX;
    if (evaluateIndex) {
      const { title, value } = {
        ...this.customerCareDateRange[activeRangeIndex],
      };
      this.filterDateDisplay = title;
      this.isDateFilterOpen = false;
      this.filterData.dateRange = value;
      customRange('isCustomRange').newValue(!evaluateIndex);
      this.dateRangeFilter();
      return;
    }
    customRange('isCustomRange').newValue(true);
  }

  onCalendarUpdated({ DateCreatedStart, DateCreatedEnd }, tabId) {
    if (DateCreatedStart === 'custom') {
      this.filterDateDisplay = '';
      this.vmApply(tabId)('isCustomRange').newValue(true);
    }
    if (DateCreatedEnd) {
      const dateReviewFormat = (dates) =>
        moment(dates).format(D_MMM_YYYY_FORMAT);
      this.filterData.reviewDateStart = dateReviewFormat(DateCreatedStart);
      this.filterData.reviewDateEnd = dateReviewFormat(DateCreatedEnd);
      this.vmApply(tabId)('dateRangeDefaults').newValue({
        dateStart: moment(DateCreatedStart).format(TO_MYCRM_DATE_FORMAT),
        dateEnd: moment(DateCreatedEnd).format(TO_MYCRM_DATE_FORMAT),
      });
      this.updateFilter();
      this.filterDateDisplay = `From ${moment
        .utc(DateCreatedStart)
        .format('DD MMM')} to ${moment
        .utc(DateCreatedEnd)
        .format('DD MMM YYYY')}`;
      this.isDateFilterOpen = false;
    }
  }

  filterCheckedItems() {
    if (this.filterData.isCompleted) {
      this.tableData.map((item) => {
        item.isChecked = true;
        return item;
      });
    }
    return this.tableData.filter((item) => item.isChecked);
  }

  sendEmailToSelected(toSelected = true) {
    const props = {
      adviserList: this.customerCareAdviserList,
      tableData: toSelected
        ? angular.copy(this.checkedTableData)
        : angular.copy(this.tableData),
      isInsurance: true,
      singleData: {},
      tab: this.tab,
    };

    if (this.isBulkEmailActive) {
      const modalInstance = this.customerCareTabsService.openSendEmailModal(
        props,
      );
      this.sendingResult(modalInstance);
    } else {
      this.openSendEmailModal('lg', '', 'all');
    }
  }

  onTableDataLoaded(event) {
    this.tableData = event.tableData;
    this.checkedTableData = this.filterCheckedItems();
  }

  onSendModal(event) {
    this.currentItem = event.item;
    this.currentSendType = event.sendVia;
    this[event.sendVia]('lg', event.item, 'single');
  }

  goToContact(event) {
    this.commonFnService.hiddenClientForNz().then((isHidden) => {
      if (!event.familyId || isHidden) {
        return;
      }
      this.$state.go('app.contactsSingle', { familyId: event.familyId });
    });
  }

  loadInsuranceStatuses() {
    this.insurancePipelineService
      .getInsurancePipelineStatus(false)
      .then((response) => {
        this.statuses = [{ id: '', name: 'All Application' }, ...response];
      });
  }

  dateRangeFilter() {
    const issuedMoment = moment().subtract(
      Number(this.filterData.dateRange),
      'months',
    );
    const nowDate = this.isIssuedFollowUp
      ? issuedMoment.startOf('month').format(D_MMM_YYYY_FORMAT)
      : moment().format(D_MMM_YYYY_FORMAT);
    const upToDate = this.isIssuedFollowUp
      ? issuedMoment.endOf('month').format(D_MMM_YYYY_FORMAT)
      : moment()
          .add(Number(this.filterData.dateRange), 'days')
          .format(D_MMM_YYYY_FORMAT);

    this.filterData.reviewDateStart =
      this.filterData.dateRange >= 0 ? nowDate : upToDate;
    this.filterData.reviewDateEnd =
      this.filterData.dateRange >= 0 ? upToDate : nowDate;

    if (this.isAnnualReviews) {
      this.filterData = {
        ...this.filterData,
        ...dateFilter(this.filterData.dateRange),
      };
    }

    this.updateFilter();
  }

  updateFilter() {
    this.filterUpdateCount++;
  }

  refreshResult() {
    this.refreshCount++;
  }

  getTabBrokers() {
    const params = {
      allAdviserLabel: 'All Advisers',
      alwaysShowAllOption: true,
    };
    this.mycrmLookupService.getAdvisers(params).then((data) => {
      this.customerCareAdviserList = data;
    });
  }

  getCustomerCareDateRange() {
    let dateRange = this.customerCareService.customerCareDateRange();
    if (this.isIssuedFollowUp) {
      dateRange = this.customerCareInsuranceService.getDateRangeFilter();
    } else if (this.isAnnualReviews) {
      dateRange = this.customerCareInsuranceService.getAnnualDateRangeFilter();
    }
    dateRange.then((data) => {
      const customerCareDateRange = (data || []).map((formats) => {
        formats.text = formats.title;
        formats.range = [];
        return formats;
      });
      this.customerCareDateRange = [
        ...customerCareDateRange,
        ...[
          {
            text: 'Custom',
            range: ['custom'],
          },
        ],
      ];
      this.filterDateDisplay = customerCareDateRange[0].text;
      this.dateRangeCustomIndex = this.customerCareDateRange.length - 1;
    });
  }

  openSendEmailModal(size, fixedRateObj, source) {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_email_modal.html',
      resolve: {
        fixedRateListTable: () => this.tableData,
        fixedRateObj: () => fixedRateObj,
        source: () => source,
        isInsurance: () => true,
        tab: () => this.tab,
      },
      controller: 'FixedRateSendEmailModalCtrl',
      size,
    });
    this.sendingResult(modalInstance);
  }

  openSendMailModal(size, fixedRateObj, source) {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_mail_modal.html',
      resolve: {
        listTableData: () => this.tableData,
        sendMailObj: () => fixedRateObj,
        source: () => source,
        currentModuleId: () => this.tab.id,
        currentModuleType: () => this.tab.type,
        currentModuleName: () => this.tab.label,
        isInsurance: () => true,
        tab: () => this.tab,
      },
      controller: 'OpenSendMailModalCtrl',
      size,
    });
    this.sendingResult(modalInstance);
  }

  openSMSModal(size, fixedRateObj, source) {
    const modalInstance = this.$uibModal.open({
      templateUrl:
        '/assets/views/customerCare/partials/modal/send_sms_modal.html',
      resolve: {
        fixedRateListTable: () => this.tableData,
        fixedRateObj: () => fixedRateObj,
        source: () => source,
        isInsurance: () => true,
        tab: () => this.tab,
      },
      controller: 'FixedRateSMSModalCtrl',
      size,
    });

    this.sendingResult(modalInstance);
  }

  sendingResult(modalInstance) {
    modalInstance.result.then((result) => {
      if (result.success) {
        this.refreshResult();
      }
    });
  }
}
