export default class ExistingInsuranceCtrl {
  constructor(helloPackService) {
    'ngInject';

    this.helloPackService = helloPackService;
  }

  onInvite() {
    const props = {
      familyId: this.familyId,
      onlyFactFind: true,
      inviteInsuranceOnlineFactFind: true,
    };
    this.helloPackService.launchHelloPackModal(props);
  }
}
