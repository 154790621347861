// TODO: move to serviceabilityService
import {
  productPdfBuilderForUI,
  putProductFinderSettingsBuilderForMyCRM,
  assessmentDetailCalculationsBuilderForUI,
  calculationBuilderForMyUI,
  selectedProductsMapperForUI,
} from 'Common/mappers/serviceability';

class LoanProfilerService {
  constructor($q, httpClient) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.apiBaseUrl = 'Serviceability';
  }

  getFrequencyType() {
    // TODO: move to selectOptionsService
    return this.httpClient.get('SelectOptions/FrequencyType');
  }

  getCalculation(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/calculation`, params)
      .then(({ data }) => data.map(calculationBuilderForMyUI) || []);
  }

  LenderInfoSpreadsheetDataGet(ProviderID, brokerEventID) {
    // TODO: move to serviceabilityService
    return this.httpClient.get(
      `${this.apiBaseUrl}/LenderInfoSpreadsheetDataGet`,
      { ProviderID, brokerEventID },
    );
  }

  getServiceabilityIncome(brokerEventId, loanScenarioId, familyId, clientIds) {
    return this.httpClient.get(`${this.apiBaseUrl}/${brokerEventId}/Incomes`, {
      loanScenarioId,
      familyId,
      clientIds,
    });
  }

  setServiceabilityIncome(brokerEventId, data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${brokerEventId}/income`,
      data,
    );
  }

  deleteServiceabilityIncome(incomeID) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/ServiceabilityIncomeDelete`,
      { incomeID },
    );
  }

  getExpenseType() {
    // TODO: move to optionsService
    return this.httpClient.get('Options/Financial/ExpenseTypeGet');
  }

  getServiceabilityExpense(brokerEventID, LoanScenarioID, familyId, clientIds) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityExpenseListGet`,
      { brokerEventID, LoanScenarioID, familyId, clientIds },
    );
  }

  setServiceabilityExpense(brokerEventId, data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${brokerEventId}/expense`,
      data,
    );
  }

  deleteServiceabilityExpense(expenseID) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/ServiceabilityExpenseDelete`,
      { expenseID },
    );
  }

  getServiceabilityFamilyMembers(
    brokerEventID,
    loanScenarioID,
    familyId,
    clientIds,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/AssessmentDetail`, {
      brokerEventID,
      loanScenarioID,
      familyId,
      clientIds,
    });
  }

  setServiceabilityFamilyMembers(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/AssessmentDetail`, data);
  }

  getServiceabilityIncomeDetails(
    brokerEventID,
    loanScenarioID,
    familyId,
    clientIds,
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityIncomeDetailsGet`,
      { brokerEventID, loanScenarioID, familyId, clientIds },
    );
  }

  getServiceabilityLendersSummary(brokerEventID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityLendersSummaryGet`,
      { brokerEventID },
    );
  }

  getServiceabilityFiltersParamters() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityFiltersParamtersGet`,
    );
  }

  getServiceabilityNewBrokerEvent() {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityNewBrokerEventGet`,
    );
  }

  getServiceabilityFiltersParamtersCustomerSelection(
    brokerEventID,
    loanStructureID,
    fromLoanApp,
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityFiltersParamtersCustomerSelectionGet`,
      { brokerEventID, loanStructureID, fromLoanApp },
    );
  }

  getServiceabilityLendersList(brokerEventID, loanScenarioId, accreditedOnly) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityLendersListGet`,
      { brokerEventID, loanScenarioId, accreditedOnly },
    );
  }

  getServiceabilityProductFinder(
    brokerEventID,
    pageNumber,
    sortColumn,
    sortType,
    PageSize,
    loanStructureID,
    lenderId,
    fromLenderRates,
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityProductFinderGet`,
      {
        brokerEventID,
        pageNumber,
        sortColumn,
        sortType,
        PageSize,
        loanStructureID,
        lenderId,
        fromLenderRates,
      },
    );
  }

  setServiceabilitySelectedProduct(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ServiceabilitySelectedProductSet`,
      data,
    );
  }

  getServiceabilitySelectedProduct(brokerEventID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilitySelectedProductGet`,
      { brokerEventID },
    );
  }

  deleteServiceabilitySelectedProduct(brokerEventID, productID) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/ServiceabilitySelectedProductDelete`,
      { brokerEventID, productID },
    );
  }

  setServiceabilityProductValue(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ServiceabilityProductValueSet`,
      data,
    );
  }

  getServiceabilityNextGenAccessment(
    brokerEventID,
    loanScenarioId,
    familyId = null,
    clientIds = null,
    accreditedOnly = false,
  ) {
    return this.httpClient.get(`${this.apiBaseUrl}/NextGenAssessment`, {
      brokerEventID,
      loanScenarioId,
      familyId,
      clientIds,
      accreditedOnly,
    });
  }

  setServiceabilitySettings(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ServiceabilitySettingsSet`,
      data,
    );
  }

  getServiceabilitySelectedProductDetails(productIDs) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilitySelectedProductDetailsGet`,
      { productIDs },
    );
  }

  getServiceabilityFilteringProductsGeneralSettings(brokerEventID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityFilteringProductsGeneralSettingsGet`,
      { brokerEventID },
    );
  }

  updateServiceabilityFilteringProductsSettings(id, data) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/ProductFinder/${id}/Settings`,
      data,
    );
  }

  getServiceabilityStandaloneProductFinder(params) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/serviceabilityStandaloneProductFinderGet`,
      params,
    );
  }

  setNewProductLoanStructure(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/ProductFinderNewLoanStructureSet`,
      data,
    );
  }

  getProductFinderLoanStructureList(brokerEventID) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ProductFinderLoanStructureListGet`,
      { brokerEventID },
    );
  }

  deleteProductFinderLoanStructure(brokerEventID, loanStructureID) {
    return this.httpClient.delete(
      `${this.apiBaseUrl}/ProductFinderLoanStructureDelete`,
      { brokerEventID, loanStructureID },
    );
  }

  setFavouriteProduct(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/FavouriteProductSet`, data);
  }

  getFavouriteProduct(brokerEventID, loanStructureID) {
    const params = {
      brokerEventID,
      loanStructureID,
    };
    return this.httpClient.get(
      `${this.apiBaseUrl}/FavouriteProductGet`,
      params,
    );
  }

  getProductType() {
    return this.httpClient.get(`${this.apiBaseUrl}/ProductTypeGet`);
  }

  getRateTypes() {
    return this.httpClient.get(`${this.apiBaseUrl}/RateTypes`);
  }

  getSelectedProductDetailsGetByLoanStructure(brokerEventID) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/SelectedProductDetailsGetByLoanStructure`, {
        brokerEventID,
      })
      .then(({ data }) => {
        return { data: (data && data.map(selectedProductsMapperForUI)) || [] };
      });
  }

  getServiceabilityProductSearch(SearchProduct) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityProductSearchGet`,
      { SearchProduct },
    );
  }

  setFavouriteProductSequence(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FavouriteProductSequenceSet`,
      data,
    );
  }

  getProductFullDetails(ProductID) {
    return this.httpClient.get(`${this.apiBaseUrl}/ProductFullDetailsGet`, {
      ProductID,
    });
  }

  setFavouriteProductRecommendation(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FavouriteProductRecommendationSet`,
      data,
    );
  }

  setFavouriteProductRates(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/FavouriteProductRatesSet`,
      data,
    );
  }

  getSearchClient(searchString, partyType) {
    // TODO: move to generalService
    return this.httpClient.get(`SearchClientGet`, { searchString, partyType });
  }

  getScenarioList(familyId) {
    // TODO: move to loanScenarioService
    return this.httpClient.get(`LoanScenario/ScenarioListGet`, { familyId });
  }

  setScenario($postData) {
    // TODO: move to loanScenarioService
    return this.httpClient.post('LoanScenario/ScenarioSet', $postData);
  }

  setScenarioApplicant(data) {
    // TODO: move to loanScenarioService
    return this.httpClient.post('LoanScenario/ScenarioApplicantSet', data);
  }

  getProductComparisonPDFReport(brokerEventID, familyId, clientId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ProductComparisonPDFReport`,
      { brokerEventID, familyId, clientId },
    );
  }

  getLiabilityTypeList(LiabilityCategoryID) {
    return this.httpClient.get(`${this.apiBaseUrl}/LiabilityTypeListGet`, {
      LiabilityCategoryID,
    });
  }

  getLiabilityList(BrokerEventID, loanScenarioID, familyId) {
    return this.httpClient.get(`${this.apiBaseUrl}/LiabilityListGet`, {
      BrokerEventID,
      loanScenarioID,
      familyId,
    });
  }

  setLiability(brokerEventId, data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/${brokerEventId}/liability`,
      data,
    );
  }

  deleteLiability(LiabilityID) {
    return this.httpClient.delete(`${this.apiBaseUrl}/LiabilityDelete`, {
      LiabilityID,
    });
  }

  newBrokerEventForScenarioGet(loanScenarioID) {
    // TODO: move to serviceabilityService
    return this.httpClient.get(`Serviceability/NewBrokerEventForScenarioGet`, {
      loanScenarioID,
    });
  }

  lenderListGet() {
    // TODO: move to serviceabilityService
    return this.httpClient.get('Serviceability/LenderListGet');
  }

  lenderListWithProductCountGet(
    brokerEventID,
    loanStructureID,
    pageNumber,
    PageSize,
    sortType,
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/LenderListWithProductCount`,
      {
        brokerEventID,
        loanStructureID,
        pageNumber,
        PageSize,
        sortType,
      },
    );
  }

  productPdf(id) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/pdf/product/${id}`)
      .then((response) => productPdfBuilderForUI(response.data));
  }

  ServiceabilityProviderWorksheetGet(
    brokerEventID,
    providerId,
    loanAmount,
    loanApplicationId,
  ) {
    return this.httpClient.get(
      `${this.apiBaseUrl}/ServiceabilityProviderWorksheetGet`,
      {
        brokerEventID,
        providerId,
        loanAmount,
        loanApplicationId,
      },
    );
  }

  putProductFinderSettings(brokerEventId, filter) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/ProductFinder/${brokerEventId}/Settings`,
      putProductFinderSettingsBuilderForMyCRM(filter),
    );
  }

  getAssessmentDetailCalculations(brokerEventId) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}/assessment-detail/calculations?brokerEventId=${brokerEventId}`,
      )
      .then((response) =>
        response.data
          ? response.data.map(assessmentDetailCalculationsBuilderForUI)
          : [],
      );
  }

  setFamilyCalculation(familyId, brokerEventId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/family-calculation`,
      {},
      { familyId, brokerEventId },
    );
  }

  setFavouriteProductDiscountRates(urlParams, data) {
    const { brokerEventId, loanStructureId, productId } = urlParams;
    return this.httpClient.post(
      `${this.apiBaseUrl}/${brokerEventId}/loan-structure/${loanStructureId}/product/${productId}/discount`,
      data,
    );
  }

  setFavouriteProductFees(urlParams, data) {
    const { brokerEventId, loanStructureId, productId } = urlParams;
    return this.httpClient.post(
      `${this.apiBaseUrl}/${brokerEventId}/loan-structure/${loanStructureId}/product/${productId}/fee`,
      data,
    );
  }

  setLoanModalFavouriteProduct(payload) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/loan-modal/favourite-product`,
      payload,
    );
  }
}

export default LoanProfilerService;
