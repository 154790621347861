export const INTERVIEW_OPTION = {
  FACE_TO_FACE: 1,
  VIDEO_CONFERENCE: 2,
  PHONE_CALL: 3,
  OTHER: 4,
};

export const CONFIRM_DECLARATION_AU = {
  FIRST_HEADING: 'Please confirm:',
  CONFIRM_LIST: [
    'A Hello Pack (inclusive of the Credit Guide) was issued before credit assistance',
    'Online fact find has been sent, accurately completed and information is consistent with the supporting documents provided',
    'All clients on the loan application have completed the Loan Market Consent (either via one touch privacy or Privacy Consent form)',
    'The Goal Setter has been completed and provides an accurate record of conversations with your client and their needs and objectives',
    'Where the clients declared living expenses are lower than HEM, there is specific commentary in MyCRM providing explanation',
    'A minimum of 3 lender/product comparisons have been recorded in the Game Plan (if less than 3 product/lender comparisons, there is specific commentary providing explanation in the Game Plan)',
    'A Game Plan was generated, signed by the client and dated prior to loan lodgement',
    'The application is being lodged within 90 days of generating the Game Plan',
    'There are sufficient, detailed notes recorded that capture all interactions with the client(s) and lender(s) throughout the home loan process',
    'All Tax File and Customer Reference numbers from government documents have been removed',
  ],
  SECOND_HEADING:
    'As per the <a class="bcc-link" rel="noopener noreferrer" target="_blank" href="https://s3-ap-southeast-2.amazonaws.com/prod-mycrm-static-documents/importantdocument-au/Loan%20Market%20Supporting%20Documents%20Minimum%20Requirements%20-%20v280820_07-16-31-56961.pdf">Loan Market Minimum Supporting documents requirements</a>, you’ve uploaded:',
  UPLOADED_DOCUMENTS: [
    'Current ID (click <a class="bcc-link" rel="noopener noreferrer" target="_blank" href="https://www.loanmarket.com.au/state-of-play/news/3918">here</a> to view the Loan Market Verification of Identity (VOI) policy)',
    'Income supporting documents',
    'Liability statements (if applicable)',
    'Bank account and credit card statements',
    'Serviceability calculations',
    'Remaining supporting documents applicable to this application',
  ],
};

export const CONFIRM_DECLARATION_NZ = {
  FIRST_HEADING: 'Please confirm:',
  CONFIRM_LIST: [
    'A Disclosure Guide was issued prior to lending advice being given.',
    'A Declaration Form has been completed signed and dated by the applicant(s).',
    'Customer objectives and future goals have been completed with correct, relevant information.',
    'The application is being lodged within 30 days of generating a Game Plan.',
    'There are detailed sufficient notes recorded that capture all interviews with the applicant(s) and lender(s) throughout the loan process.',
  ],
  SECOND_HEADING: 'Is there <b>at least one of each:<b/>',
  UPLOADED_DOCUMENTS: [
    'Signed Declaration Form',
    'Current certified photo ID',
    'Income documents',
    'Liability documents',
    'Bank statements with evidence of living expenses',
    'Security documentation (if applicable)',
    'Servicing calculator (if applicable)',
  ],
};

export const DECLARATION_CONFIRM_WARNING = {
  title:
    'Oops! Please complete your loan submission declarations before submitting.',
  body: ' ',
  customClass: 'declarations-warning-alert',
};
