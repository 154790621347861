import angular from 'angular';
import _ from 'lodash';

export const loadModule = () =>
  angular
    .module('app')
    .factory('corporateModel', function corporateModel(
      httpRequest,
      httpCacheService,
    ) {
      var apiBaseUrl = 'corporate/';

      function corporateModel() {}

      corporateModel.prototype = {
        ContactListGet(
          startWith,
          contactType,
          page,
          searchCriteria,
          adviserStatus,
          reportingState,
          reportingRegion,
          businessUnitId,
          adviserFamilyId,
          callback,
        ) {
          const adviserStatusId =
            typeof adviserStatus != 'undefined' ? adviserStatus : null;
          const reportingStateId =
            typeof reportingState != 'undefined' ? reportingState : null;
          const reportingRegionId =
            typeof reportingRegion != 'undefined' ? reportingRegion : null;
          const businessUnitID =
            typeof businessUnitId != 'undefined' ? businessUnitId : null;
          const adviserFamilyID =
            typeof adviserFamilyId != 'undefined' ? adviserFamilyId : null;

          httpRequest.get(
            `${apiBaseUrl}ContactListGet?startWith=${startWith}&count=20&contactTypes=${contactType}&page=${page}&searchCriteria=${searchCriteria}&adviserStatusId=${adviserStatusId}&reportingStateId=${reportingStateId}&reportingRegionId=${reportingRegionId}&businessUnitId=${businessUnitID}&adviserFamilyId=${adviserFamilyID}`,
            (response) => {
              return callback(response);
            },
          );
        },
        HelpSupportContactListGet(startWith, contactType, callback) {
          httpRequest.get(
            `${apiBaseUrl}ContactListGet?startWith=${startWith}&count=100&contactTypes=${contactType}`,
            (response) => {
              return callback(response);
            },
          );
        },

        assignAssistantToClientSet(
          assistantId,
          adviserId,
          clientFamilyId,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}AssignAssistantToClientSet?assistantFamilyId=${assistantId}&assigntoAdviserFamilyId=${adviserId}&clientfamilyId=${clientFamilyId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },

        assignAssistantToClientGet(adviserId, clientFamilyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}AssignAssistantToClientGet?assigntoAdviserFamilyId=${adviserId}&clientfamilyId=${clientFamilyId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        AdviserOrganizationSet(saveType, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}AdviserOrganizationSet?saveType=${saveType}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },

        AdviserOrganizationGet(
          adviserOrgComplianceId,
          adviserOrganizationId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}AdviserOrganizationGet?adviserOrgComplianceId=${adviserOrgComplianceId}&adviserOrganizationId=${adviserOrganizationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        AdviserOrgTypesGet(callback) {
          httpRequest.get('AdviserOrgTypesGet', (response) => {
            return callback(response);
          });
        },

        AdviserOrganizationEntityTypesGet(callback) {
          httpRequest.get(
            `${apiBaseUrl}AdviserOrganizationEntityTypesGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        ClientUTMDetailsGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ClientUTMDetailsGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ClientUTMDetailsSet(familyId, clientId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}ClientUTMDetailsSet?familyId=${familyId}&clientId=${clientId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        ContactsUnderOrganizationGet(
          adviserOrganizationId,
          page = 1,
          size = 10,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}ContactsUnderOrganizationGet?adviserOrganizationId=${adviserOrganizationId}&page=${page}&size=${size}`,
            (response) => {
              return callback(response);
            },
          );
        },
        BrandingCategoryListGet(countryId, callback) {
          httpRequest.get(
            `${apiBaseUrl}BrandingCategoryListGet?countryId=${countryId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        AdviserOrganizationStatusesGet(callback) {
          httpRequest.get(
            `${apiBaseUrl}AdviserOrganizationStatusesGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        AdvisersGet(
          familyId,
          searchSaveAdviserOrg,
          excludeNoAddress,
          getAdviserDetail,
          withGeoCode,
          leadReceiveStatusIds,
          leadPriorities,
          corpLeadCampaignIDs,
          businessUnitIds,
          callback,
        ) {
          var _getAdviserDetail =
            typeof getAdviserDetail == 'undefined' ? 0 : getAdviserDetail;
          var _withGeoCode =
            typeof withGeoCode == 'undefined' ? 0 : withGeoCode;
          var _leadReceiveStatusIds =
            typeof leadReceiveStatusIds == 'undefined'
              ? ''
              : leadReceiveStatusIds;
          var _leadPriorities =
            typeof leadPriorities == 'undefined' ? '' : leadPriorities;
          var _corpLeadCampaignIDs =
            typeof corpLeadCampaignIDs == 'undefined'
              ? ''
              : corpLeadCampaignIDs;
          var _businessUnitIds =
            typeof businessUnitIds == 'undefined' ? '' : businessUnitIds;
          httpRequest.get(
            `${apiBaseUrl}AdvisersGet?familyId=${familyId}&searchSaveAdviserOrg=${searchSaveAdviserOrg}&excludeNoAddress=${excludeNoAddress}&getAdviserDetail=${_getAdviserDetail}&withGeocode=${_withGeoCode}&leadReceiveStatusIds=${_leadReceiveStatusIds}&leadPriorities=${_leadPriorities}&corpLeadCampaignIDs=${_corpLeadCampaignIDs}&businessUnitIds=${_businessUnitIds}`,
            (response) => {
              return callback(response);
            },
          );
        },
        AssignClientToAdviserSet(
          adviserFamilyId,
          familyId,
          clientId,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}AssignClientToAdviserSet?adviserFamilyId=${adviserFamilyId}&familyId=${familyId}&clientId=${clientId}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        CorporateContactGet(clientId, familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CorporateContactGet?clientId=${clientId}&familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        AdviserOrganizationListGet(callback) {
          // remove temp
          // var url = "AdviserOrganizationListGet";
          // if (orgTypeId) {
          //     url = "AdviserOrganizationListGet?orgTypeId=" + orgTypeId;
          // }
          httpRequest.get(
            `${apiBaseUrl}AdviserOrganizationListGet`,
            (response) => {
              return callback(response);
            },
          );
        },

        BrokerStatusListGet(callback) {
          httpRequest.get(`${apiBaseUrl}BrokerStatusListGet`, (response) => {
            return callback(response);
          });
        },
        OperatingCountriesGet(callback) {
          httpRequest.get(`${apiBaseUrl}OperatingCountriesGet`, (response) => {
            return callback(response);
          });
        },
        reportingRegionsGet(countryId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReportingRegionsGet?countryId=${countryId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ReportingStatesGet(callback) {
          httpRequest.get(`${apiBaseUrl}ReportingStatesGet`, (response) => {
            return callback(response);
          });
        },
        CommissionTiersGet(countryId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CommissionTiersGet?countryId=${countryId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        AdviserAchievementsGet(fiscalYear, callback) {
          httpRequest.get(
            `${apiBaseUrl}AdviserAchievementsGet?fiscalYear=${fiscalYear}`,
            (response) => {
              return callback(response);
            },
          );
        },
        EnquirySourcesGet(callback) {
          httpRequest.get(`${apiBaseUrl}EnquirySourcesGet`, (response) => {
            return callback(response);
          });
        },
        FamilyAddressGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyAddressGet?familyId=${familyId}&forceGeocode=1`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyAddressSet(
          id,
          clientId,
          addressId,
          isMailing,
          isBusiness,
          overrideFlag,
          isCustomAddress = false,
          callback,
        ) {
          httpRequest.post(
            `/corporate/FamilyAddressSet?familyId=${id}&clientId=${clientId}&addressId=${addressId}&isMailing=${isMailing}&isBusiness=${isBusiness}&overrideFlag=${overrideFlag}&isCustomAddress=${isCustomAddress}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        AdviserPreviousNameGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}AdviserPreviousNameGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        AdviserPreviousNameSet(previousName, familyId, clientId, callback) {
          httpRequest.post(
            `${apiBaseUrl}AdviserPreviousNameSet?previousName=${previousName}&familyId=${familyId}&clientId=${clientId}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        OrganizationAddressGet(organizationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}OrganizationAddressGet?organizationId=${organizationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        OrganizationAddressSet(
          organizationId,
          orgComplianceId,
          addressId,
          isMailing,
          overrideFlag,
          isCustomAddress = false,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}OrganizationAddressSet?organizationId=${organizationId}&orgComplianceId=${orgComplianceId}&addressId=${addressId}&isMailing=${isMailing}&overrideFlag=${overrideFlag}&isCustomAddress=${isCustomAddress}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        ContactTypeRolesGet(callback) {
          httpRequest.get(`${apiBaseUrl}ContactTypeRolesGet`, (response) => {
            return callback(response);
          });
        },
        FamilyMarketingDetailGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyMarketingDetailGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyNpsGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyNpsGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyMarketingDetailSet(familyId, clientId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}FamilyMarketingDetailSet?familyId=${familyId}&clientId=${clientId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyBrokerSupportGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyBrokerSupportGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyBrokerSupportSet(familyId, clientId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}FamilyBrokerSupportSet?familyId=${familyId}&clientId=${clientId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyComplianceGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyComplianceGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyComplianceSet(familyId, clientId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}FamilyComplianceSet?familyId=${familyId}&clientId=${clientId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyWebManagementGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyWebManagementGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyWebManagementSet(familyId, clientId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}FamilyWebManagementSet?familyId=${familyId}&clientId=${clientId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        ContactSet(saveType, data, callback, errorCallback) {
          httpRequest.post(
            `${apiBaseUrl}ContactSet?saveType=${saveType}`,
            data,
            (response) => {
              return callback(response);
            },
            (error) => {
              return errorCallback(error);
            },
          );
        },
        SendPIExpiryNotifications(date, callback) {
          httpRequest.get(
            `${apiBaseUrl}SendPIExpiryNotifications?date=${date}`,
            (response) => {
              return callback(response);
            },
          );
        },
        SendEDRSchemeExpiryNotifications(date, callback) {
          httpRequest.get(
            `${apiBaseUrl}SendEDRSchemeExpiryNotifications?date=${date}`,
            (response) => {
              return callback(response);
            },
          );
        },
        SendExpiryNotifications(date, callback) {
          httpRequest.get(
            `${apiBaseUrl}SendExpiryNotifications?date=${date}`,
            (response) => {
              return callback(response);
            },
          );
        },
        SendProfSchemeNotifications(date, callback) {
          httpRequest.get(
            `${apiBaseUrl}SendProfSchemeNotifications?date=${date}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LeadsProfileGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LeadsProfileGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LeadsProfileSet(data, callback) {
          httpRequest.post(`${apiBaseUrl}LeadsProfileSet`, data, (response) => {
            return callback(response);
          });
        },
        ReferrerOrgGet(referrerOrgComplianceId, referrerOrgId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrgGet?referrerOrgComplianceId=${referrerOrgComplianceId}&referrerOrgId=${referrerOrgId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        OrganizationTypesGet(callback) {
          httpRequest.get(`${apiBaseUrl}OrganizationTypesGet`, (response) => {
            return callback(response);
          });
        },
        ReferrerOrgSet(data, callback) {
          httpRequest.post(`${apiBaseUrl}ReferrerOrgSet`, data, (response) => {
            return callback(response);
          });
        },
        ReferrerOrgDirectorSet(organizationId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReferrerOrgDirectorSet?organizationId=${organizationId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        ReferrerOrgDirectorDelete(organizationId, directorId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReferrerOrgDirectorDelete?organizationId=${organizationId}&directorId=${directorId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        ReferrerOrganizationMembershipsByOrganizationGet(
          organizationId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrganizationMembershipsByOrganizationGet?referrerOrganizationId=${organizationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ReferrerOrganizationRelationshipsGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrganizationRelationshipsGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ReferrerOrganizationMembershipsByReferrerGet(
          familyId,
          clientId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrganizationMembershipsByReferrerGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        AdvisersUnderOrganizationGet(
          adviserOrgid,
          fromLMWMAgreement,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}AdvisersUnderOrganizationGet?adviserOrganizationId=${adviserOrgid}&fromLMWMAgreement=${fromLMWMAgreement}`,
            (response) => {
              return callback(response);
            },
          );
        },
        OrganizationAgreementGet(
          agreementId,
          referrerOrgId,
          adviserOrgId,
          adviserFamilyId,
          agreementTypeId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}OrganizationAgreementGet?agreementId=${agreementId}&referrerOrgId=${referrerOrgId}&adviserOrgId=${adviserOrgId}&adviserFamilyId=${adviserFamilyId}&agreementTypeId=${agreementTypeId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyRecruitmentDetailsGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyRecruitmentDetailsGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        InquirySourceListGet(callback) {
          httpRequest.get(`${apiBaseUrl}InquirySourceListGet`, (response) => {
            return callback(response);
          });
        },
        PerceivedQualityListGet(callback) {
          httpRequest.get(
            `${apiBaseUrl}PerceivedQualityListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        ProspectiveRatingListGet(callback) {
          httpRequest.get(
            `${apiBaseUrl}ProspectiveRatingListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        RecruitmentStatusListGet(callback) {
          httpRequest.get(
            `${apiBaseUrl}RecruitmentStatusListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        RecruitmentNotProceedingReasonListGet(callback) {
          httpRequest.get(
            `${apiBaseUrl}RecruitmentNotProceedingReasonListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        RecruitmentOnboardingStatusListGet(callback) {
          httpRequest.get(
            `${apiBaseUrl}RecruitmentOnboardingStatusListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyRecruitmentDetailsSet(familyId, clientId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}FamilyRecruitmentDetailsSet?familyId=${familyId}&clientId=${clientId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        CheckIfFamilyHasValidEmailGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CheckIfFamilyHasValidEmailGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LeadsReceiveStatusDropdownGet(callback) {
          httpRequest.get(
            `${apiBaseUrl}LeadsReceiveStatusDropdownGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        // DatzMe101 Service
        getReferrerOrganization(callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrganizationsGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        getMembershipRoles(callback) {
          httpRequest.get('/MembershipRolesGet', (response) => {
            return callback(response);
          });
        },
        getMembershipByReferrerOrg(referrerOrganizationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReferrerOrganizationMembershipsByOrganizationGet?referrerOrganizationId=${referrerOrganizationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getOrganizationWithAgreement(
          referrerOrganizationId,
          referrerFamilyId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}OrganizationsWithAgreementGet?referrerOrganizationIDs=${referrerOrganizationId}&referrerFamilyId=${referrerFamilyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setReferrerOrgMembership(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReferrerOrganizationMembershipSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        setReferrerOrgRelationship(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReferrerOrganizationRelationshipSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        getAgreementType(callback) {
          httpRequest.get(`${apiBaseUrl}AgreementTypesGet`, (response) => {
            return callback(response);
          });
        },
        getReferrer(callback) {
          httpRequest.get(`${apiBaseUrl}ReferrersGet`, (response) => {
            return callback(response);
          });
        },
        setOrganizationAgreement(complianceId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}OrganizationAgreementSet?orgComplianceId=${complianceId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        getPerceivedQualityList(callback) {
          httpRequest.get(
            `${apiBaseUrl}PerceivedQualityListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        getProspectiveRatingList(callback) {
          httpRequest.get(
            `${apiBaseUrl}ProspectiveRatingListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        getRecruitmentStatusList(callback) {
          httpRequest.get(
            `${apiBaseUrl}RecruitmentStatusListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        getRecruitmentNotProceedingReasonList(callback) {
          httpRequest.get(
            `${apiBaseUrl}RecruitmentNotProceedingReasonListGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        setFamilyRecruitmentDetails(familyId, clientId, data, callback) {
          httpRequest.post(
            `${apiBaseUrl}FamilyRecruitmentDetailsSet?familyId=${familyId}&clientId=${clientId}`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        getAccreditationFormPdf(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}AccreditationFormPdfGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
            { responseType: 'arraybuffer' },
          );
        },
        getBrandingCategoryList(countryId, callback) {
          httpRequest.get(
            `${apiBaseUrl}BrandingCategoryListGet?countryId=${countryId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAgreementDocument(agreementId, callback) {
          httpRequest.get(
            `${apiBaseUrl}AgreementDocumentGet?agreementId=${agreementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setSendAccreditationEmail(familyId, callback) {
          httpRequest.post(
            `${apiBaseUrl}SendAccreditationEmailSet?familyId=${familyId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        getEmailTemplates(userTemplateId, callback) {
          httpRequest.get(
            `${apiBaseUrl}EmailTemplatesGet?userTemplateId=${userTemplateId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setEmailTemplates(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}EmailTemplateSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        setEmailTemplateFolder(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}EmailTemplateFolderSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        setTemplateSorting(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}TemplateSortingSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        getRayWhiteAdvisers(size, from, orgId, callback) {
          httpRequest.get(
            `${apiBaseUrl}RayWhiteAdvisersGet?size=${size}&from=${from}&orgId=${orgId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getRayWhiteOrganisations(size, from, searchKey, callback) {
          var _searchKey = typeof searchKey == 'undefined' ? '' : searchKey;
          httpRequest.get(
            `${apiBaseUrl}RayWhiteOrganisationsGet?size=${size}&from=${from}&searchKey=${_searchKey}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getMergeFields(isSMS, callback) {
          httpRequest.get(
            `MergeFieldsGet?isSMS=${isSMS ? 'true' : 'false'}`,
            (response) => {
              return callback(response);
            },
          );
        },
        MergeFieldsGet(
          familyId,
          clientId,
          loanId,
          policyId,
          isSMS,
          loanStructureId,
          callback,
        ) {
          httpRequest.get(
            `MergeFieldsGet?familyId=${familyId}&clientId=${clientId}&loanId=${loanId}&policyId=${
              policyId ? policyId : 0
            }&isSMS=${
              isSMS ? 'true' : 'false'
            }&loanStructureId=${loanStructureId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        loanMergeFields(familyId, loanId, callback) {
          httpRequest.get(
            `MergeFieldsGet?familyId=${familyId}&loanId=${loanId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        CurrentOnboardingTabGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CurrentOnboardingTabGet?familyID=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        CurrentOnboardingTabSet(
          familyId,
          currentOnboardingTab,
          onboardingStatusID,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}CurrentOnboardingTabSet?familyID=${familyId}&currentOnboardingTab=${currentOnboardingTab}&onboardingStatusID=${onboardingStatusID}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getTargetManagement(
          targetManagementID,
          targetMonth,
          targetYear,
          teamId,
          targetOwnerID,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}TargetManagementGet?targetManagementID=${targetManagementID}&targetMonth=${targetMonth}&targetYear=${targetYear}&teamId=${teamId}&targetOwnerID=${targetOwnerID}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAdminUsers(callback) {
          httpRequest.get(`${apiBaseUrl}AdminUsersGet`, (response) => {
            return callback(response);
          });
        },
        getRelatedProductGroup(callback) {
          httpRequest.get(`${apiBaseUrl}RelatedProductGroupGet`, (response) => {
            return callback(response);
          });
        },
        getActiveCRMCountryCurrency(callback) {
          httpRequest.get(
            `${apiBaseUrl}ActiveCRMCountryCurrencyGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        getRelatedLoanStatus(callback) {
          httpRequest.get(`${apiBaseUrl}RelatedLoanStatusGet`, (response) => {
            return callback(response);
          });
        },
        setTargetManagement(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}TargetManagementSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        getTargetTracking(targetManagementId, callback) {
          httpRequest.get(
            `${apiBaseUrl}TargetTrackingGet?targetManagementId=${targetManagementId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getTargetLoanSummaryStatus(
          relatedLoanStatusId,
          teamId,
          valueType,
          targetMonth,
          targetYear,
          widgetUserID,
          businessUnitId = 0,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}TargetLoanSummaryStatusGet?relatedLoanStatusId=${relatedLoanStatusId}&teamId=${teamId}&valueType=${valueType}&targetMonth=${targetMonth}&targetYear=${targetYear}&widgetUserID=${widgetUserID}&businessUnitId=${businessUnitId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getWidgetOptionTimePeriodList6(callback) {
          httpRequest.get(
            `${apiBaseUrl}WidgetOptionTimePeriodList6Get`,
            (response) => {
              return callback(response);
            },
          );
        },
        LoansSummaryLenderSplitGet(
          relatedLoanStatusId,
          teamId,
          targetMonth,
          targetYear,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}LoansSummaryLenderSplitGet?relatedLoanStatusId=${relatedLoanStatusId}&teamId=${teamId}&targetMonth=${targetMonth}&targetYear=${targetYear}`,
            (response) => {
              return callback(response);
            },
          );
        },
        AdviserLeaderBoardLoanSettlementGet(
          relatedLoanStatusId,
          valueType,
          teamId,
          referralSourceId,
          targetMonth,
          targetYear,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}AdviserLeaderBoardLoanSettlementGet?relatedLoanStatusId=${relatedLoanStatusId}&valueType=${valueType}&teamId=${teamId}&referralSourceId=${referralSourceId}&targetMonth=${targetMonth}&targetYear=${targetYear}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LoansSummaryReferralSplitGet(
          relatedLoanStatusId,
          teamId,
          targetMonth,
          targetYear,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}LoansSummaryReferralSplitGet?relatedLoanStatusId=${relatedLoanStatusId}&teamId=${teamId}&targetMonth=${targetMonth}&targetYear=${targetYear}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LendersListPerAdviserGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LendersListPerAdviserGet?familyID=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAdvisersDropdown(
          familyId,
          searchSaveAdviserOrg,
          excludeNoAddress,
          loanWritersOnly = false,
          callback,
        ) {
          var _excludeNoAddress =
            typeof excludeNoAddress === 'undefined' ? 0 : excludeNoAddress;
          httpRequest.get(
            `${apiBaseUrl}AdvisersDropdownGet?familyID=${familyId}&searchSameAdviserOrg=${searchSaveAdviserOrg}&excludeNoAddress=${_excludeNoAddress}&loanWritersOnly=${loanWritersOnly}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAgreementStatusLookup(callback) {
          httpRequest.get(
            `${apiBaseUrl}AgreementStatusLookupGet`,
            (response) => {
              return callback(response);
            },
          );
        },
        RecruitmentPreferencesGet(familyId, clientId, callback) {
          httpRequest.get(
            `${apiBaseUrl}RecruitmentPreferencesGet?familyId=${familyId}&clientId=${clientId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        RecruitmentPreferencesSet(familyId, clientId, keepACL, callback) {
          httpRequest.post(
            `${apiBaseUrl}RecruitmentPreferencesSet?familyId=${familyId}&clientId=${clientId}&keepACL=${keepACL}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },
        PartnerOnboardingAdviserGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}PartnerOnboardingAdviserGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAssistantList(familyId, searchSameAdviserOrg, callback) {
          httpRequest.get(
            `GetAssistantList?familyId=${familyId}&searchSameAdviserOrg=${searchSameAdviserOrg}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getClientType(callback) {
          httpRequest.get(`${apiBaseUrl}ClientTypeGet`, (response) => {
            return callback(response);
          });
        },
        ClientTypeSet(callback) {
          httpRequest.get(`${apiBaseUrl}ClientTypeSet`, (response) => {
            return callback(response);
          });
        },
        ConfSponsorship2017Get(callback) {
          httpRequest.get(`${apiBaseUrl}ConfSponsorship2017Get`, (response) => {
            return callback(response);
          });
        },
        BusinessUnitGet(callback) {
          httpRequest.get(`${apiBaseUrl}BusinessUnitGet`, (response) => {
            return callback(response);
          });
        },
        assignAssistantToAdvisersSet(assistId, advId, familyId, callback) {
          var opt = familyId !== 0 ? `&clientFamilyId=${familyId}` : '';
          httpRequest.post(
            `${apiBaseUrl}AssignAssistantToAdvisersSet?assistantFamilyId=${assistId}&assignToAdvisersFamilyId=${advId}${opt}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        adviserAssistantsGet(advId, familyId, callback) {
          var opt = familyId ? `&clienFamilytId=${familyId}` : '';
          httpCacheService.get(
            `${apiBaseUrl}AdviserAssistantsGet?familyId=${advId}${opt}`,
            (response) => {
              return callback(response);
            },
          );
        },
        CessationReasonGet(callback) {
          httpRequest.get(`${apiBaseUrl}CessationReasonGet`, (response) => {
            return callback(response);
          });
        },
        setRecruitmentReadyToProceedEmail(familyId, callback) {
          httpRequest.post(
            `${apiBaseUrl}RecruitmentReadyToProceedEmailSet?familyId=${familyId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        CorpLeadCampaignGet(callback) {
          httpRequest.get(`${apiBaseUrl}CorpLeadCampaignGet`, (response) => {
            return callback(response);
          });
        },
        getGoogleGeoCodedAdress(address, callback) {
          httpRequest.post(
            `GoogleGeoCodedAdressGet?address=${address}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        googleGeoCodedAddressGet(address, callback) {
          httpRequest.post(
            `GoogleGeoCodedAddressGet?address=${address}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyAchievementGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyAchievementGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyAchievementSet(
          familyId,
          achievementIdParam,
          fiscalYear,
          isDeleted,
          familyAchievementsId = 0,
          callback,
        ) {
          /**
           * Temporary fixes for FamilyAchievementSet error.
           * This should be fixed in controller by the refactor code made by Ellen.
           * For now this is a temporary fixes until Ellen Done the with her refactor code.
           */
          var achievementId = _.isUndefined(achievementIdParam)
            ? 0
            : achievementIdParam;
          httpRequest.post(
            `${apiBaseUrl}FamilyAchievementSet?familyId=${familyId}&achievementID=${achievementId}&fiscalYear=${fiscalYear}&isDeleted=${isDeleted}&familyAchievementsId=${familyAchievementsId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        deleteFamilyLenderAccreditation(familyId, lenderId, callback) {
          httpRequest.post(
            `${apiBaseUrl}FamilyLenderAccreditationDelete?familyId=${familyId}&lenderId=${lenderId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        checkLoanPipelineGet(familyId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CheckLoanPipelineGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        reallocateLoanPipelineSet(familyId, clientId, loanId, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReallocateLoanPipelineSet?familyId=${familyId}&clientFamilyID=${clientId}&loanId=${loanId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        importantDocumentSet(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}ImportantDocumentSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        resetLoginTotalFailedAttemptsSet(familyId, callback) {
          httpRequest.post(
            `${apiBaseUrl}ResetLoginTotalFailedAttemptsSet?familyId=${familyId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        activeLoanWritersGet(familyId = 0, callback) {
          httpRequest.get(
            `${apiBaseUrl}ActiveLoanWritersGet?familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getFeatures(callback) {
          httpRequest.get(`${apiBaseUrl}features`, (response) => {
            return callback(response);
          });
        },
        postFeatureAccess(accessModel = {}, callback) {
          httpRequest.post(`${apiBaseUrl}features`, accessModel, (response) => {
            return callback(response);
          });
        },
        deleteFeatureAccess(id, callback) {
          httpRequest.delete(
            `${apiBaseUrl}features/feature-manager/${id}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getAdviserOrganizationList({ searchText = '' }, callback) {
          httpRequest.get(
            `${apiBaseUrl}AdviserOrganizationListGet?searchText=${searchText}`,
            (response) => {
              return callback(response);
            },
          );
        },
      };
      return corporateModel;
    });
