import { get } from 'lodash';
import {
  LOAN_STRUCTURE_TYPE,
  SHORT_NAME,
  RATE_TYPE,
} from 'Common/constants/loanAppDiscount';

export const getFrequencyAndTerm = (num = 0) => {
  const isMonth = num % 1 !== 0;
  return isMonth
    ? {
        frequency: 'Month',
        terms: num * 12,
      }
    : {
        frequency: 'Year',
        terms: num,
      };
};

export const getTermLabel = ({ initialRateTerm, loanTerm }) => {
  const loanTermLabels = getFrequencyAndTerm(loanTerm);
  const initialTermLabels = getFrequencyAndTerm(initialRateTerm);
  const termAfterInitial = initialTermLabels.terms + 1;
  const isSameFrequency =
    initialTermLabels.frequency === loanTermLabels.frequency;
  const isSameTerms = initialTermLabels.terms === loanTermLabels.terms;
  if (!initialRateTerm) {
    return `${loanTermLabels.frequency}s 1 to ${loanTermLabels.terms}`;
  } else if (isSameFrequency && isSameTerms) {
    return `${loanTermLabels.frequency} ${loanTermLabels.terms}`;
  } else if (isSameFrequency && !isSameTerms) {
    return `${initialTermLabels.frequency}s ${termAfterInitial} to ${loanTermLabels.terms}`;
  } else if (!isSameFrequency && !isSameTerms) {
    return `${initialTermLabels.frequency} ${termAfterInitial} to ${loanTermLabels.frequency} ${loanTermLabels.terms}`;
  }
};

export const getInitialLabel = ({
  interestRateTypeLabel,
  isPandI,
  introRate,
  initialRateTerm,
}) => {
  const initialLabel = `${interestRateTypeLabel} ${getLoanStructureTypeLabel(
    isPandI,
  )} ${introRate}`;
  if (!initialRateTerm) {
    return initialLabel;
  }
  const { frequency, terms } = getFrequencyAndTerm(initialRateTerm);
  const termLabel =
    terms === 1 ? `${frequency} ${terms}` : `${frequency}s 1 to ${terms}`;
  return `${initialLabel} (${termLabel})`;
};

export const getOngoingLabel = ({ loanTerm, initialRateTerm }) => {
  const ongoingLabel = `${RATE_TYPE.VARIABLE} ${SHORT_NAME.PRINCIPAL_AND_INTEREST} Rate`;
  if (!loanTerm) {
    return ongoingLabel;
  }
  return `${ongoingLabel} (${getTermLabel({ initialRateTerm, loanTerm })})`;
};

export const getLoanStructureTypeLabel = (isPandI) =>
  isPandI
    ? SHORT_NAME.PRINCIPAL_AND_INTEREST
    : LOAN_STRUCTURE_TYPE.INTEREST_ONLY;

export const getProductName = (referenceProductName, productName) =>
  referenceProductName || productName;

export const updateOnSaveRate = (
  rateData,
  referenceRate,
  referenceRates,
  discountRate,
) => {
  const { baseRate: productBaseRate, isOverrideBaseRate } = rateData;
  const rateValue = +get(discountRate, 'rateValue', 0);
  const { referenceProductId } = discountRate;
  const selectedReferenceRate = referenceRates.find(
    (product) => product.referenceProductId === referenceProductId,
  );
  const baseRate =
    selectedReferenceRate && !isOverrideBaseRate
      ? selectedReferenceRate.rate
      : productBaseRate;
  return {
    referenceRate: {
      ...referenceRate,
      ...selectedReferenceRate,
      isActive: !!selectedReferenceRate,
    },
    rateData: {
      ...rateData,
      baseRate,
      actualRate: rateValue,
      discountRate: +(baseRate - rateValue).toFixed(2),
      showDiscountBox: false,
      isDetailShow: !!rateValue,
      productName: getProductName(
        get(selectedReferenceRate, 'productName'),
        rateData.recommendedProductName,
      ),
    },
  };
};

export const updateOnDeleteRate = (rateData, referenceRate) => {
  const { productRate, recommendedProductName } = rateData;
  return {
    rateData: {
      ...rateData,
      actualRate: productRate,
      baseRate: productRate,
      discountRate: 0,
      editDiscountBox: false,
      isDetailShow: false,
      showReferenceRate: false,
      showDiscountBox: false,
      isOverrideBaseRate: false,
      productName: recommendedProductName,
    },
    referenceRate: {
      selectedProductId: referenceRate.selectedProductId,
      isActive: false,
    },
  };
};

export const onBaseRateChange = (rateData, baseRate = 0) => {
  const { actualRate, discountRate } = rateData;
  return {
    ...rateData,
    baseRate,
    actualRate: discountRate ? actualRate : baseRate,
    discountRate: +(discountRate ? baseRate - actualRate : 0).toFixed(2),
  };
};
