import {
  getAccountCheckBuilderForUI,
  getSignDocumentBuilderForUI,
  getDocumentsBuilderForUI,
  getPackagesBuilderForUI,
} from 'Common/mappers/eSign';

class ESignService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'esign/';
  }

  getAccountCheck(familyId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}${familyId}/account-check`)
      .then((response) => getAccountCheckBuilderForUI(response.data));
  }

  getRecentPackage(loanId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}${loanId}/recent-package`)
      .then((response) => response.data.map(getDocumentsBuilderForUI));
  }

  getPackages(loanId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}${loanId}/packages`)
      .then((response) => response.data.map(getPackagesBuilderForUI));
  }

  setIncludeDocument(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/include-document`, data);
  }

  getSignDocument(loanId, param) {
    return this.httpClient
      .get(
        `${this.apiBaseUrl}${loanId}/sign-document`,
        param,
        false,
        true,
        false,
      )
      .then((response) => getSignDocumentBuilderForUI(response.data));
  }

  getNotifySigners(packageId, loanId) {
    return this.httpClient.get(
      `${this.apiBaseUrl}${packageId}/notify-signers/${loanId}`,
    );
  }

  deletePackage(packageId) {
    return this.httpClient.get(`${this.apiBaseUrl}${packageId}/trash`);
  }

  postSendSmsLink(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/send-sms-link`, data);
  }

  postDetachDocument(packageId, documentId) {
    return this.httpClient.post(
      `${this.apiBaseUrl}${packageId}/detach/${documentId}`,
    );
  }

  postGamePlanSettings(loanApplicationId, data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}${loanApplicationId}/game-plan-settings`,
      data,
    );
  }
}

export default ESignService;
