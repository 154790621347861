import { objectLength } from 'Common/utilities/objectValidation';
import { toastError } from 'Common/utilities/alert';
import { SEND_TYPE } from 'Common/constants/eSign';

import {
  getInvolvePartyUtil,
  checkWarningsUtil,
  onContactSavedUtil,
  getRecentPackageUtil,
  checkRequiredParameterUtil,
  checkEmailValidUtil,
  checkDuplicateUtil,
  mapApplicantUtil,
  updateWarningLimitUtil,
  sendEsignInitUtil,
} from 'Common/utilities/sendViaEsign';

class SendGamePlanToEsign {
  constructor(
    eSignService,
    loanScenarioService,
    crmConfirmation,
    configService,
    contactService,
    gamePlanGeneratorService,
    gamePlanSharedDataService,
    uiService,
    countryCodeService,
    loanAppSharedData,
  ) {
    'ngInject';

    this.eSignService = eSignService;
    this.loanScenarioService = loanScenarioService;
    this.crmConfirmation = crmConfirmation;
    this.configService = configService;
    this.contactService = contactService;
    this.gamePlanGeneratorService = gamePlanGeneratorService;
    this.gamePlanSharedDataService = gamePlanSharedDataService;
    this.uiService = uiService;
    this.countryCodeService = countryCodeService;
    this.loanAppSharedData = loanAppSharedData;
  }

  $onInit() {
    sendEsignInitUtil({ vm: this });
  }

  getInvolveParty() {
    getInvolvePartyUtil({ vm: this });
  }

  updateWarningLimit() {
    updateWarningLimitUtil({ vm: this });
  }

  checkWarnings() {
    checkWarningsUtil({ vm: this });
  }

  mapApplicant(data) {
    return mapApplicantUtil({ vm: this, data });
  }

  checkDuplicate() {
    checkDuplicateUtil({ vm: this });
  }

  checkEmailValid(signee) {
    checkEmailValidUtil({ vm: this, signee });
  }

  getRecentPackage() {
    getRecentPackageUtil({ vm: this });
  }

  getMergedNames(isApplicant = true) {
    return isApplicant
      ? this.applicants.reduce((accum, applicant) => {
          return `${accum}${applicant.FirstName} ${applicant.LastName}`;
        }, '')
      : this.guarantors.reduce((accum, guarantor) => {
          return `${accum}${guarantor.FirstName} ${guarantor.LastName}`;
        }, '');
  }

  getRecipientEmails(isApplicant = true) {
    return isApplicant
      ? this.applicants.map((applicant) => applicant.emailAddress)
      : this.guarantors.map((guarantor) => guarantor.emailAddress);
  }

  onDownloadGamePlan(isApplicant = true) {
    this.gamePlanSharedDataService.oldMergedNames = this.gamePlanSharedDataService.getApplicantNames();
    const mergedNames = this.getMergedNames(isApplicant);
    if (isApplicant) {
      this.isDownloading = true;
    }
    if (!isApplicant) {
      this.isDownloadingGuarantorPdf = true;
    }

    const recipients = isApplicant ? this.applicants : this.guarantors;
    const emailMessage = this.gamePlanSharedDataService.updateEmailNameParam({
      recipients,
      isEditEmailMessage: true,
    });
    const brokerMessage = this.gamePlanSharedDataService.updateEmailNameParam({
      recipients,
      isEditEmailMessage: false,
    });

    const payload = this.gamePlanSharedDataService.getPayload({
      isApplicant,
      sections: this.gamePlanSharedDataService.sections,
      emailRecipients: this.getRecipientEmails(isApplicant),
      emailMessage,
      brokerMessage,
    });

    this.gamePlanGeneratorService
      .download({
        loanAppId: this.loanId,
        fileName: mergedNames,
        payload,
      })
      .then(() => {
        this.isDownloading = false;
        this.isDownloadingGuarantorPdf = false;
        if (isApplicant) {
          this.reviewedApplicantGamePlan();
        }
        if (!isApplicant || !this.guarantors.length) {
          this.reviewedGuarantorGamePlan();
        }
        this.disabledSendToEsign();
      })
      .catch(() => {
        this.isDownloading = false;
        this.isDownloadingGuarantorPdf = false;
        toastError('Game Plan download failed. Please try again.');
      });
  }

  checkRequiredParameter() {
    checkRequiredParameterUtil({ vm: this });
  }

  reviewedApplicantGamePlan() {
    this.isApplicantReviewed = true;
  }

  reviewedGuarantorGamePlan() {
    this.isGuarantorReviewed = true;
  }

  disabledSendToEsign() {
    this.isSendToEsignDisabled =
      (!this.isSendCqp && !this.isSendCpa) ||
      this.isWaitSubmitResponse ||
      (this.warnings && this.warnings.length) ||
      !this.isCheckedTerms ||
      (this.isSendCqp && !this.isGuarantorReviewed) ||
      (this.isSendCpa && !this.isApplicantReviewed);
  }

  proceedToSendingEsign() {
    const {
      LoanApplicationDetails: loanApplicationDetails,
    } = this.loanAppSharedData;
    const { AdviserId: brokerId } = loanApplicationDetails;
    const param = {
      type: this.type,
      brokerId,
      arbitraryDataId: this.arbitraryDataId,
    };

    this.eSignService.getSignDocument(this.loanId, param).then((response) => {
      this.isWaitSubmitResponse = false;
      if (!response || !objectLength(response)) {
        return;
      }
      this.closeAllModals();
      this.crmConfirmation.open({
        type: 'success',
        title: 'Game Plan Sent!',
        description: `Your client will receive an email and SMS notification.`,
        buttonText: 'Okay got it',
        modalSize: 'md',
      });
    });
  }

  postGamePlanSettings() {
    const payload = this.gamePlanSharedDataService.getPayload({
      isApplicant: this.gamePlanSharedDataService.isApplicantActive,
      sections: this.gamePlanSharedDataService.sections,
      emailRecipients: this.signeeList.map((signee) => signee.email),
    });
    this.eSignService
      .postGamePlanSettings(this.loanId, payload)
      .then(({ data }) => {
        this.arbitraryDataId = data;
        this.proceedToSendingEsign();
      });
  }

  sendToEsign() {
    if (this.isSendToEsignDisabled) {
      return;
    }
    this.isWaitSubmitResponse = true;
    this.sendButtonText = `Sending ...`;
    this.type = this.gamePlanSharedDataService.isApplicantActive
      ? SEND_TYPE.APPLICANT
      : SEND_TYPE.GUARANTOR;
    this.postGamePlanSettings();
  }

  onContactSaved({ succeeded }) {
    onContactSavedUtil({ vm: this, succeeded });
  }

  closeModal() {
    this.modalInstance.dismiss('cancel');
  }

  closeAllModals() {
    this.modalInstance.close({
      closeAll: true,
      contactUpdated: this.contactUpdated,
    });
  }
}
export default SendGamePlanToEsign;
