import template from './commissionUnmatchedSummary.html';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    isExporting: '<',
    exportIcon: '<',
    unmatchedCommissions: '<',
    totalUnmatchedCommissions: '<',
    unmatchedCommissionsHeader: '<',
    isLoadingUnmatchedCommissions: '<',
    tableParams: '<',
    onRowItemClick: '&',
    loadUnmatchedCommissions: '&',
    adviserList: '<',
    lenderList: '<',
    commissionTypeList: '<',
    filterSettings: '<',
    onFilterChange: '&',
    onSortTableData: '&',
    onExportTableData: '&',
  },
};
