const ANNOUNCEMENT_TIME = 300000;

class AnnouncementReminderService {
  constructor($interval, modalRenderService, announcementService) {
    'ngInject';

    this.$interval = $interval;
    this.modalRenderService = modalRenderService;
    this.announcementService = announcementService;
    this.getAnnouncements = this.getAnnouncements.bind(this);
    this.announcementTimer = {};
  }

  getAnnouncements() {
    this.announcementService.announcementGet().then((data) => {
      if (!data || !data.length) {
        return;
      }
      this.modalRenderService.openAnnouncementModal(data);
    });
  }

  setAnnouncementTimer() {
    this.announcementTimer = this.$interval(
      this.getAnnouncements,
      ANNOUNCEMENT_TIME,
    );
  }

  announcementTimerCancel() {
    this.$interval.cancel(this.announcementTimer);
  }
}

export default AnnouncementReminderService;
