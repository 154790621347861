import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { COLOR } from 'Common/constants/colors';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { toastError, toastSuccess } from 'Common/utilities/alert';
import {
  getSaveToOpportunityBtnName,
  initFundCalcTooltipMessage,
} from 'Common/utilities/addCalculationUtility';
import {
  generateProductComparisonPDFReportGamePlanFormat,
  isTabHeaderForFamilyServiceability,
} from './util/loanProfilerCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanProfilerCtrl', function LoanProfilerCtrl(
      $scope,
      $uibModal,
      SweetAlert,
      loanProfilerService,
      selectOptionsService,
      loanScenarioService,
      productFinderModelService,
      downloadDataService,
      contactService,
      $window,
      $location,
      $state,
      toaster,
      cfpLoadingBar,
      $timeout,
      $rootScope,
      $filter,
      uiService,
      configService,
      lendingScenarioService,
      $localStorage,
      DEMO_VIDEO,
      commonFnService,
      fundingCalculatorService,
      contactSharedDataService,
      loanProfilerSharedData,
      loanCalculatorLinking,
      loanOpportunityService,
      bouncingTooltipService,
      currentUserService,
      lenderRatesService,
      loanAppSharedData,
      stateService,
      leadOpportunitiesService,
    ) {
      $scope.routeContactList = stateService.routeToContactList;
      $scope.loanAppSharedData = loanAppSharedData;
      $scope.familyIdParams = $state.params.familyId;

      initFundCalcTooltipMessage({ $scope, leadOpportunitiesService });

      $scope.gradienBannerObject = {
        text: `You are running this Serviceability Calculator on ${$scope.loanAppSharedData.serviceabilityFamilyName}`,
        startColor: COLOR.DARKER_BLUE,
        endColor: COLOR.BLUE_VIOLET,
        paddingLeftRight: '49px',
      };
      $scope.fromLenderRateObject = {
        ...lenderRatesService.getLenderRateInfoForProductFinder(),
        isFromLenderRates:
          lenderRatesService.getLenderRateInfoForProductFinder() &&
          !!Object.keys(lenderRatesService.getLenderRateInfoForProductFinder())
            .length,
      };
      $scope.currentlyViewing = '';
      if (
        $scope.fromLenderRateObject &&
        $scope.fromLenderRateObject.lenderName &&
        $scope.fromLenderRateObject.year
      ) {
        $scope.currentlyViewing = `Currently viewing ${$scope.fromLenderRateObject.lenderName} ${$scope.fromLenderRateObject.year} Fixed Rates. `;
      }
      $scope.gradientBannerObject = {
        startColor: COLOR.DARKER_BLUE,
        endColor: COLOR.BLUE_VIOLET,
        text: `${$scope.currentlyViewing}Click here to return to all Lender Rates.`,
        paddingLeftRight: '3%',
      };
      $scope.brokerEventId = $state.params.brokerEventId;
      $scope.loanScenarioId = $state.params.loanScenarioId;
      $scope.activeTab = $state.params.tab;
      $scope.currentSourceLinker = loanCalculatorLinking.currentSourceLinker;
      $rootScope.disableSaveContactBtn = true;
      $scope.isFromContact = false;

      const opportunityLabel = uiService.viewOfAfileTurnedOn
        ? 'a deal'
        : 'an opportunity';
      $scope.savingForLabel = $scope.currentSourceLinker.isOpportunityOrLoanapp
        ? opportunityLabel
        : 'a loan app';

      const { previousRouteStateParams } = $scope.currentSourceLinker;
      $scope.activeProductCategory =
        previousRouteStateParams &&
        previousRouteStateParams.lendingCategory ===
          LENDING_CATEGORY.ASSET_FINANCE
          ? 'Asset Finance'
          : 'Residential';

      $scope.isFromOpportunity =
        $scope.currentSourceLinker.linkerId ===
          loanCalculatorLinking.LINKERS.OPPORTUNITY &&
        $scope.currentSourceLinker.previousRouteStateParams.loanId ===
          $state.params.loanId;
      bouncingTooltipService.resetTooltipVisibility();
      $scope.bouncingTooltipService = bouncingTooltipService;
      $scope.isTooltipVisible = bouncingTooltipService.isTooltipVisible;

      $scope.contactLinker = $localStorage.contactLoanCalculatorLinker || {};
      $scope.linkerFamilyId =
        $scope.contactLinker &&
        $scope.contactLinker.linkerId === loanCalculatorLinking.LINKERS.CONTACT
          ? parseInt($scope.contactLinker.familyId, 10)
          : 0;

      $scope.opportunityIdForProductFinder =
        $scope.currentSourceLinker.linkerId ===
          loanCalculatorLinking.LINKERS.OPPORTUNITY &&
        $scope.currentSourceLinker.previousRouteStateParams.loanId ===
          $state.params.loanId
          ? parseInt($scope.currentSourceLinker.id, 10)
          : 0;

      $scope.saveOpportunitiesDefaultBtnText = getSaveToOpportunityBtnName(
        leadOpportunitiesService,
      );

      $scope.checkQuickLMIBroker = () => {
        fundingCalculatorService
          .getFundingCalculatorQuickLMIProductFinderBrokerEventId(
            null,
            $scope.brokerEventId,
          )
          .then((response) => {
            if (response && response.data) {
              $scope.QuickLMIBrokerEventId =
                response.data.QuickLMIBrokerEventId;
              $scope.isQuickLMILinkShow = !!$scope.QuickLMIBrokerEventId;
            }
          });
      };

      $scope.enableSaveContactBtn = () => {
        $rootScope.disableSaveContactBtn = false;
      };

      $scope.assetFinanceComparisonMode = () => {
        $rootScope.selectedTab = 'productComparison';
        $rootScope.selectedTabHeader = 'Product Comparison';
      };

      $scope.assetFinanceActive =
        configService.feature &&
        configService.feature.assetFinanceProductFinder &&
        currentUserService.isAU;

      $scope.goBackToQuickLMICalc = () => {
        $state.go('app.quickLMI', {
          brokerEventId: $scope.QuickLMIBrokerEventId,
        });
      };

      $scope.goBackToClientContact = (familyId) => {
        const activeTab = $scope.currentSourceLinker.isBusiness
          ? 'loans'
          : 'lending';
        contactSharedDataService.redirectToContactDetails(
          familyId || $scope.linkerFamilyId,
          activeTab,
        );
        loanCalculatorLinking.resetLinker();
      };
      $scope.gobackToLenderRates = () => {
        lenderRatesService.setLenderRateInfoForProductFinder({});
        if (uiService.showProviderInformation) {
          $state.go('app.providerInformation');
        } else {
          $state.go('app.loanTools', { activeTab: 'lender-rates' });
        }
      };
      $scope.checkLinkerIsFromContact = () => {
        $scope.isFromContact =
          $scope.contactLinker.linkerId ===
          loanCalculatorLinking.LINKERS.CONTACT;
        if (!$scope.brokerEventId) {
          return;
        }
        loanProfilerService
          .getAssessmentDetailCalculations($scope.brokerEventId)
          .then((response) => {
            const linkedData =
              response &&
              response.length &&
              $state.params.tab &&
              _.find(
                response,
                (item) =>
                  item.typeOfCalculation.toLowerCase() ===
                  $state.params.tab.toLowerCase(),
              );
            $scope.isSavedCalcIsEdit = !!linkedData;
            $scope.linkerFamilyId = linkedData
              ? linkedData.familyId
              : $scope.contactLinker.familyId;
            if ($scope.linkerFamilyId) {
              loanCalculatorLinking
                .getcontactFullName($scope.linkerFamilyId)
                .then((contactResponse) => {
                  $scope.contactFullName =
                    (contactResponse && contactResponse.data) || '';
                });
            }
          });
      };

      // Init Function
      $scope.init = function () {
        if ($state.params.tab === 'serviceability') {
          $timeout(() => {
            $rootScope.selectedTab = $state.params.tab;
            $rootScope.selectedTabHeader = 'Serviceability';
            const el = $window.document.querySelectorAll(
              `#${$state.params.tab} .nav-link`,
            );
            angular.element(el).triggerHandler('click');
          }, 0);
        } else if ($state.params.tab === 'productFinder') {
          $timeout(() => {
            $rootScope.selectedTab = 'product-Finder';
            $rootScope.selectedTabHeader = 'Product Finder';
            const el = $window.document.querySelectorAll(
              '#product-Finder .nav-link',
            );
            angular.element(el).triggerHandler('click');
          }, 0);
        }
        $scope.checkQuickLMIBroker();
      };

      $scope.init();

      // Tab Selection
      $scope.selectTabUpdate = function (tab) {
        $rootScope.selectedTab = tab;
      };

      // For Open Tab
      // -----------------------------------------------------------------------------
      $scope.selectTab = (selectedTab) => uiService.selectTab(selectedTab);

      // Save To Contact
      // ----------------------------------------------------------------------------------
      $scope.showCreateNewContact = true;
      $scope.searchClientList = [];
      $scope.mainSearchObject = {};
      $scope.isShowContactNotFoundMessage = false;

      $scope.resetSaveToContact = function () {
        $scope.selectedClientName = '';
        $scope.innerResetObject();
      };
      $scope.innerResetObject = function () {
        $scope.showCreateNewContact = true;
        $scope.searchClientList = [];
        $scope.mainSearchObject = {};
        $scope.clientScenarioList = [];
        $scope.selectedClient = {};
        $scope.isShowContactNotFoundMessage = false;
      };

      $scope.checkIsShowNewContact = (
        searchString,
        searchClientList,
        showCreateNewContact,
      ) => {
        const selectedClientName = searchString || '';
        return (
          showCreateNewContact ||
          selectedClientName.length <= 3 ||
          searchClientList.length === 0
        );
      };
      $scope.checkIsShowSaveContact = (
        searchString,
        searchClientList,
        showCreateNewContact,
      ) => {
        const selectedClientName = searchString || '';
        return (
          selectedClientName.length >= 3 &&
          searchClientList.length !== 0 &&
          !showCreateNewContact
        );
      };
      // select client
      $scope.selectClient = loanProfilerSharedData.selectClient.bind($scope);

      $scope.hideShowSearch = () => {
        $scope.showSelectClientDropown = !$scope.showSelectClientDropown;
        $scope.resetSaveToContact();
      };

      // Save to contact
      $scope.saveToContact = function () {
        let tabName = '';
        if ($state.params.tab === 'serviceability') {
          tabName = 'Serviceability';
        } else if ($state.params.tab === 'productFinder') {
          tabName = 'ProductFinder';
        }
        const postData = {
          BrokerEventID: $scope.brokerEventId,
          DateOfCalculation: moment().format(),
          TypeOfCalculation: tabName,
        };
        contactService
          .calculationsSet($scope.selectedClient.FamilyID, postData)
          .then((response) => {
            if (response.data) {
              toastError('Yikes! Something is wrong');
              return;
            }
            // eslint-disable-next-line sonarjs/no-duplicate-string
            toastSuccess('Loan Calculator successfully saved to contact');
            $scope.showSelectClientDropown = false;
            $scope.checkLinkerIsFromContact();
          });
      };

      // Save to contact From Family
      $scope.saveToContactFromFamily = function () {
        let tabName = '';
        if ($state.params.tab === 'serviceability') {
          tabName = 'Serviceability';
        } else if ($state.params.tab === 'productFinder') {
          tabName = 'ProductFinder';
        }

        const postData = {
          BrokerEventID: $scope.brokerEventId,
          DateOfCalculation: moment().format(),
          TypeOfCalculation: tabName,
        };
        contactService
          .calculationsSet($scope.linkerFamilyId, postData)
          .then(() => {
            toaster.pop(
              'success',
              'Saved',
              'Loan Calculator successfully saved to contact',
            );
            $scope.showSelectClientDropown = false;
            contactSharedDataService.redirectToContactDetails(
              $scope.linkerFamilyId,
            );
            $localStorage.contactLoanCalculatorLinker = {};
            $scope.checkLinkerIsFromContact();
            loanCalculatorLinking.resetLinker();
          });
      };
      // eslint-disable-next-line unicorn/consistent-function-scoping
      const getTypeOfCalculation = (tab) => {
        switch (tab) {
          case 'productFinder':
            return 'ProductFinder';
          case 'serviceability':
            return 'Serviceability';
          default:
            return '';
        }
      };
      const validContactData =
        $localStorage.loanProfilerCreateNewContact &&
        $localStorage.loanProfilerFamilyId;
      if (validContactData) {
        const postData = {
          BrokerEventID: $scope.brokerEventId,
          DateOfCalculation: moment().format(),
          TypeOfCalculation: getTypeOfCalculation($state.params.tab),
        };

        contactService
          .calculationsSet($localStorage.loanProfilerFamilyId, postData)
          .then((response) => {
            if (!response || response.data) {
              toaster.pop('error', 'Error', 'Yikes! Something is wrong');
              return;
            }
            $scope.checkLinkerIsFromContact();
            toaster.pop(
              'success',
              'Saved',
              'Loan Calculator successfully saved to contact',
            );
            $scope.showSelectClientDropown = false;
            $scope.resetSaveToContact();
            $localStorage.loanProfilerFamilyId = '';
            $localStorage.loanProfilerCreateNewContact = '';
          });
      } else {
        $scope.checkLinkerIsFromContact();
        $localStorage.loanProfilerCreateNewContact = '';
      }

      if ($localStorage.loanProfilerCreateNewContact) {
        $scope.isContactMsgDisplay = false;
      } else {
        $scope.isContactMsgDisplay = true;
      }

      // Create New Loan Scenario
      $scope.createNewLoanScenario = function () {
        const postData = {
          LoanScenarioId: '0',
          Title: $scope.mainSearchObject.newLoanScenarioName,
          FamilyId: $scope.selectedClient.FamilyID,
        };
        loanProfilerService.setScenario(postData).then((response) => {
          if (response.data !== 0) {
            $scope.mainSearchObject.clientLoanScenarioId = response.data;
            $scope.saveToContact();
          }
        });
      };

      // Get Serviceability Product Search
      $scope.getSearchClient = loanProfilerSharedData.getSearchClient;

      // Create new contact
      $scope.createNewContact = function () {
        $localStorage.loanProfilerCreateNewContact = true;
        $localStorage.previousState = 'app.loanProfiler';
        $localStorage.previousStateParams = {
          brokerEventId: $scope.brokerEventId,
          loanScenarioId: $scope.loanScenarioId,
          tab: $state.params.tab,
        };

        $state.go('app.createContact');
      };

      // Open Modal For Save Contact In Mobile
      $scope.openModalForSaveContact = function () {
        $uibModal.open({
          templateUrl:
            '/assets/views/loanProfiler/partials/modal/save_to_contact_modal.html',
          controller: 'SaveContactModalCtrl',
          size: 'sm',
          resolve: {
            createNewLoanScenario() {
              return $scope.createNewLoanScenario;
            },
            saveToContact() {
              return $scope.saveToContact;
            },
            resetSaveToContact() {
              return $scope.resetSaveToContact;
            },
            innerResetObject() {
              return $scope.innerResetObject;
            },
            createNewContact() {
              return $scope.createNewContact;
            },
            showCreateNewContact() {
              return $scope.showCreateNewContact;
            },
            searchClientList() {
              return $scope.searchClientList;
            },
            checkIsShowNewContact() {
              return $scope.checkIsShowNewContact;
            },
            checkIsShowSaveContact() {
              return $scope.checkIsShowSaveContact;
            },
            mainSearchObject() {
              return $scope.mainSearchObject;
            },
          },
        });
      };

      // Open Model For Demo Video
      $scope.openModalForDemoVideo = function (size) {
        $uibModal.open({
          templateUrl: '/assets/views/partials/demo_video_modal.html',
          controller: 'DemoVideoModalCtrl',
          resolve: {
            videoId() {
              if ($state.params.tab === 'serviceability') {
                return DEMO_VIDEO.SERVICEABILITY.Url;
              } else if ($state.params.tab === 'productFinder') {
                return DEMO_VIDEO.PRODUCT_FINDER.Url;
              }
            },
          },
          size,
          windowClass: 'demo-video-modal-window-class',
        });
      };

      $scope.goToProductFinder = function () {
        $timeout(() => {
          $rootScope.selectedTab = 'product-Finder';
          $rootScope.selectedTabHeader = 'Product Finder';
          const el = $window.document.querySelectorAll(
            '#product-Finder .nav-link',
          );
          angular.element(el).triggerHandler('click');
          $scope.isCompareDetail = false;
        }, 0);
      };

      // Save Email as Word Document
      $scope.generateProductComparisonPDFReport = function () {
        cfpLoadingBar.start();
        cfpLoadingBar.inc();

        const fromLoanAppContactFamilyId = $window.sessionStorage.getItem(
          'loanApplicationContact',
        );
        const fromContactFamilyId = $scope.linkerFamilyId;

        const familyId = fromLoanAppContactFamilyId || fromContactFamilyId || 0;
        if (!$scope.brokerEventId) {
          return;
        }

        if (
          configService &&
          configService.feature.productFinderEditEnhancements
        ) {
          const params = {
            brokerEventId: $scope.brokerEventId,
            familyId: familyId || currentUserService.familyId,
            clientId: 0,
          };

          generateProductComparisonPDFReportGamePlanFormat({
            productFinderModelService,
            downloadDataService,
            params,
          });

          return;
        }

        loanProfilerService
          .getProductComparisonPDFReport($scope.brokerEventId, familyId, 0)
          .then((response) => {
            const data = response.data;
            const currentDate = $filter('date')(new Date(), 'MM-dd-yy');

            if ($window.navigator && $window.navigator.msSaveOrOpenBlob) {
              // IE workaround
              const byteCharacters = $window.atob(data.DocumentContent);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new $window.Blob([byteArray], {
                type: data.ContentType,
              });
              $window.navigator.msSaveOrOpenBlob(
                blob,
                `productComparison_${currentDate}.pdf`,
              );
            } else {
              const element = $window.document.createElement('a');
              element.setAttribute(
                'href',
                `data:${data.ContentType};base64,${data.DocumentContent}`,
              );
              element.download = `productComparison_${currentDate}.pdf`;

              element.style.display = 'none';
              $window.document.body.append(element);

              const clickDelay = $timeout(() => {
                element.click();
              }, 100);

              clickDelay.then(() => {
                element.remove();
              });

              $scope.$on('$destroy', () => {
                $timeout.cancel(clickDelay);
              });
            }

            $timeout(() => {
              cfpLoadingBar.complete();
            }, 750);
          });
      };

      /**
       * Start Lending Scenario
       * */

      $scope.backToLendingScenario = function () {
        toaster.pop('info', 'Redirecting', 'Going back to Lending Scenario');

        $timeout(() => {
          $state.go('app.LendingScenarioSingle', {
            familyId: $window.sessionStorage.getItem('lendingScenarioContact'),
            lendingScenarioId: $window.sessionStorage.getItem(
              'lendingScenario',
            ),
          });

          $window.sessionStorage.removeItem('editServiceabilityName');
          $window.sessionStorage.removeItem('lendingScenarioContact');
          $window.sessionStorage.removeItem('lendingScenario');
        }, 1000);
      };

      $scope.productFinderLinkSet = (val) => {
        lendingScenarioService
          .productFinderLinkSet({
            lendingScenarioId: $scope.lendingScenarioId,
            productId: val,
          })
          .then(() => {
            toaster.pop(
              'success',
              'Success',
              'Product are saved to Lending Scenario',
            );
          });
      };

      $scope.saveProductFinderToLendingScenario = function () {
        /*
         * TODO: remove all products link as initial action
         * */

        let count = 1;
        if ($scope.compareProductIdList.length < 1) {
          toaster.pop('warning', 'Warning', 'You need to select a product');
        } else {
          angular.forEach($scope.compareProductIdList, (val) => {
            if (count < $scope.compareProductIdList.length) {
              $scope.productFinderLinkSet(val);
              count++;
            } else {
              toaster.pop(
                'success',
                'Success',
                'Products are added to your Lending Scenario. Redirecting lending scenario.',
              );

              $scope.backToLendingScenario();
            }
          });
        }
      };

      $scope.saveSearchLendersResultToLendingScenario = function () {
        /*
         * TODO: remove all products link as initial action
         * */

        if ($scope.MultiSelctLenderList.length < 1) {
          toaster.pop('warning', 'Warning', 'You need to select a product');
        } else {
          angular.forEach($scope.MultiSelctLenderList, (val) => {
            if ($scope.MultiSelctLenderList.length > 0) {
              $scope.productFinderLinkSet(val);
              _.remove($scope.MultiSelctLenderList, val);
            } else {
              toaster.pop(
                'success',
                'Success',
                'Products are added to your Lending Scenario. Redirecting lending scenario.',
              );
              $scope.backToLendingScenario();
            }
          });
        }
      };

      $scope.editServiceabilityName = $window.sessionStorage.getItem(
        'editServiceabilityName',
      );

      // Open Modal For Save Contact In Mobile
      $scope.openAddServiceability = function () {
        $uibModal.open({
          templateUrl:
            '/assets/views/contacts/client/lending/lendingScenario/addServiceability.html',
          controller: 'ServiceabilityModalCtrl',
          size: 'md',
          resolve: {
            backToLendingScenario() {
              return $scope.backToLendingScenario;
            },
            saveSearchLendersResultToLendingScenario() {
              return $scope.saveSearchLendersResultToLendingScenario;
            },
            lendingScenarioId() {
              return $scope.lendingScenarioId;
            },
            title() {
              return $scope.Title;
            },
            isEdit() {
              return false;
            },
          },
        });
      };

      $scope.openEditServiceability = function () {
        $uibModal.open({
          templateUrl:
            '/assets/views/contacts/client/lending/lendingScenario/editServiceability.html',
          size: 'md',
          controller: 'ServiceabilityModalCtrl',
          resolve: {
            backToLendingScenario() {
              return $scope.backToLendingScenario;
            },
            saveSearchLendersResultToLendingScenario() {
              return $scope.saveSearchLendersResultToLendingScenario;
            },
            lendingScenarioId() {
              return $scope.lendingScenarioId;
            },
            title() {
              return $scope.Title;
            },
            isEdit() {
              return true;
            },
          },
        });
      };

      $scope.saveToLendingScenario = function () {
        if ($scope.editServiceabilityName) {
          $scope.openEditServiceability();
        } else {
          $scope.openAddServiceability();
        }
      };

      $scope.lendingScenarioId = $window.sessionStorage.getItem(
        'lendingScenario',
      );

      /**
       * END Lending Scenario
       * */

      /**
       * Loan Application ID
       * */
      $scope.loanAppId = $window.sessionStorage.getItem('loanApplication');

      $scope.backToLoanApplication = function () {
        toaster.pop('info', 'Redirecting', 'Going back to Loan Application');

        $timeout(() => {
          $window.sessionStorage.setItem(
            'loanApplicationSection',
            'loandetails',
          );
          $window.sessionStorage.setItem(
            'loanApplicationPanel',
            'productSelection',
          );

          $state.go('app.loanApplicationDetailsV3', {
            familyId: $window.sessionStorage.getItem('loanApplicationContact'),
            loanAppId: $window.sessionStorage.getItem('loanApplication'),
          });

          $window.sessionStorage.removeItem('editFundsCalcLA');
          $window.sessionStorage.removeItem('loanApplicationContact');
          $window.sessionStorage.removeItem('loanApplication');
        }, 1000);
      };

      $scope.disableSaving = false;
      $scope.saveToOpportunity = () => {
        $scope.disableSaving = true;
        const loanScenarioId = $scope.currentSourceLinker.id;
        const params = {
          brokerEventID: $scope.brokerEventId,
          typeOfCalculation:
            $state.params.tab === 'serviceability'
              ? 'Serviceability'
              : 'ProductFinder',
          familyId: $scope.currentSourceLinker.familyId,
        };
        loanOpportunityService
          .setLoanOpportunityCalculation(loanScenarioId, params)
          .then(
            () => {
              $state.go(
                `app.opportunity`,
                $scope.currentSourceLinker.previousRouteStateParams,
              );
              $scope.disableSaving = false;
            },
            () => {
              $scope.disableSaving = false;
            },
          );
      };

      $scope.isShowSaveToContactButton = () => {
        return (
          !$scope.isSavedCalcIsEdit &&
          !$scope.loanAppId &&
          !$scope.opportunityIdForProductFinder &&
          !$scope.fromLenderRateObject.isFromLenderRates
        );
      };
      $scope.goToOpportunityOrLoanapp = () => {
        if (!$scope.currentSourceLinker.isOpportunityOrLoanapp) {
          $scope.goBackToClientContact($scope.currentSourceLinker.familyId);
        } else {
          $state.go(
            `app.opportunity`,
            $scope.currentSourceLinker.previousRouteStateParams,
          );
        }
      };

      /** NZ Requirements By Elmer Datolayta */
      $scope.hiddenClientForNz = false;
      commonFnService.hiddenClientForNz().then((response) => {
        $scope.hiddenClientForNz = response;
      });

      $scope.showProviderInformation = uiService.showProviderInformation;

      $scope.isTabHeaderForFamilyServiceability = isTabHeaderForFamilyServiceability;
      $scope.$on('$destroy', () => {
        lenderRatesService.setLenderRateInfoForProductFinder({});
        $window.sessionStorage.removeItem('loanApplicationContact');
        $window.sessionStorage.removeItem('loanApplication');
      });
    });
