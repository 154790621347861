import angular from 'angular';

class WorkbenchModalCtrl {
  constructor(
    $uibModalInstance,
    cLoanId,
    cFamilyId,
    CLoanStatusId,
    CPipelineCardsID,
    cLoanScenarioId,
    cEnquirySource,
  ) {
    'ngInject';

    this.cLoanId = cLoanId;
    this.CLoanStatusId = CLoanStatusId;
    this.CPipelineCardsID = CPipelineCardsID;
    this.cFamilyId = cFamilyId;
    this.cLoanScenarioId = cLoanScenarioId;
    this.cEnquirySource = cEnquirySource;
    this.modalInstance = $uibModalInstance;
  }
}

export const loadModule = () =>
  angular.module('app').controller('WorkbenchModalCtrl', WorkbenchModalCtrl);
