import get from 'lodash/get';
import { OpportunityNotes } from 'Common/services/opportunityNotes';
import { getTextCharacterLimit } from 'Common/utilities/smsCharacterLimit';
import { toastError, displayError, toastSuccess } from 'Common/utilities/alert';
import { NOTES_EDITOR_OPTIONS } from 'Common/config/wysiwigEditor';
import {
  formatNewlineToHtmlBreak,
  getPlainValueFromHtml,
} from 'Common/utilities/string';

export default class OpportunityNotesModalCtrl {
  constructor(
    loanOpportunityService,
    contactModelService,
    referralsService,
    contactService,
    crmConfirmation,
    configService,
    uiService,
    ckEditorConfigService,
  ) {
    'ngInject';

    this.loanOpportunityService = loanOpportunityService;
    this.contactModelService = contactModelService;
    this.referralsService = referralsService;
    this.contactService = contactService;
    this.crmConfirmation = crmConfirmation;
    this.configService = configService;
    this.uiService = uiService;
    this.ckEditorConfigService = ckEditorConfigService;
  }

  $onInit() {
    this.opportunityNotes = new OpportunityNotes(this.noteObj);
    this.noteObj = this.opportunityNotes.note;
    this.characterCount = `0 / 140`;
    this.onLoadCheckSharingOptions();
    const isRemoveThirdGridTrack =
      this.disableShareSms && this.disableShareEmail;
    this.isNotesRedesignEnabled = this.configService.feature.redesignedExpandedNotesDisplay;
    this.containerStyle = {
      height: this.isNotesRedesignEnabled && '84vh',
    };
    this.gridStyle = this.isNotesRedesignEnabled && {
      display: 'grid',
      'grid-template-rows': `63px 9fr ${isRemoveThirdGridTrack ? '' : '133px'}`,
      margin: '0',
    };

    this.isViewOfAFileTurnedOn = this.uiService.viewOfAfileTurnedOn;
    this.involvePartiesOptionInstance = {
      getSelectedValues: () => {
        return this.viewOfAFileData.sharedToContactFamilyId.length
          ? this.viewOfAFileData.sharedToContactFamilyId
          : [this.noteObj.familyId];
      },
    };
    this.getNotesLinkToLoan();
    if (this.uiService.viewOfAFileTurnedOn) {
      require('./override.scss');
    }
    this.notesEditorOptions = NOTES_EDITOR_OPTIONS;
  }

  $onChanges(changes) {
    if (
      get(changes.noteObj, 'currentValue') &&
      this.configService.feature.redesignedExpandedNotesDisplay
    ) {
      this.noteObj.activityDetailsHtml = formatNewlineToHtmlBreak(
        this.noteObj.activityDetailsHtml,
      );
    }
  }

  getNoteContent() {
    return this.isNotesRedesignEnabled
      ? formatNewlineToHtmlBreak(this.noteObj.activityDetailsHtml)
      : this.noteObj.activityDetails;
  }

  setViewOfAFileModel(note = {}) {
    this.viewOfAFileData = {
      notesLoanLinkId: 0,
      loanId: note.loanId,
      communicationId: note.communicationId || 0,
      isActive: false,
      sharedToContactFamilyId: [],
      sharedToContactFamilyIdCsv: '',
    };
  }

  getNotesLinkToLoan() {
    const { communicationId = 0 } = this.noteObj || {};
    if (!this.isViewOfAFileTurnedOn || !communicationId) {
      return this.setViewOfAFileModel(this.noteObj);
    }
    const params = {
      communicationId,
    };
    return this.contactModelService
      .getNotesLinkToLoan(params)
      .then((data = []) => {
        if (!data.length) {
          return this.setViewOfAFileModel(this.noteObj);
        }
        const [viewOfAFile = {}] = data;
        const sharedToContactFamilyId = data.map((contact) =>
          contact.sharedToContactFamilyId.toString(),
        );
        this.viewOfAFileData = {
          ...viewOfAFile,
          loanIdRaw: viewOfAFile.loanId,
          sharedToContactFamilyId,
          sharedToContactFamilyIdCsv: '',
        };
      });
  }

  setNotesLinkToLoan(data = {}) {
    const loanId =
      this.viewOfAFileData.loanId || this.viewOfAFileData.loanIdRaw;
    const communicationId = data.communicationId || data.CommunicationID || 0;
    const invalidLinkToLoan =
      !this.isViewOfAFileTurnedOn || !communicationId || !loanId;
    if (invalidLinkToLoan) {
      return Promise.resolve({ data });
    }
    const sharedToContactFamilyIdCsv = this.involvePartiesOptionInstance
      .getSelectedValues()
      .join();
    const params = {
      notesLoanLinkId: this.viewOfAFileData.notesLoanLinkId,
      loanId,
      communicationId,
      sharedToContactFamilyIdCsv,
      isActive: !!this.viewOfAFileData.loanId,
    };
    return this.contactModelService.setNotesLinkToLoan(params).then(() => {
      return { data };
    });
  }

  onContactFilesOptionChange({ value }) {
    this.viewOfAFileData.loanId = value;
  }

  onLoadCheckSharingOptions() {
    const minimumNumberLength = 8;
    const isNumberValid =
      this.noteObj &&
      this.noteObj.smsObj &&
      this.noteObj.smsObj.mobile &&
      this.noteObj.smsObj.mobile.length > minimumNumberLength;
    const isEmailValid =
      this.noteObj &&
      this.noteObj.emailObj &&
      this.noteObj.emailObj.referrerEmail &&
      this.noteObj.emailObj.referrerEmail.length;

    this.disableShareSms = !(
      this.referralId &&
      this.isSmsEnabled &&
      isNumberValid
    );
    this.disableShareEmail = !(this.referralId && isEmailValid);
  }

  back() {
    this.modalInstance.dismiss('cancel');
  }

  noteAPICall(communicationId) {
    const noteObject = {
      ...this.noteObj,
      activityDetails: this.getNoteContent(),
    };
    if (communicationId) {
      return this.loanOpportunityService.updateLoanOpportunityNote(
        noteObject,
        communicationId,
      );
    }
    return this.loanOpportunityService.setLoanOpportunityNote(
      noteObject,
      communicationId,
    );
  }

  saveNote() {
    this.isSaving = true;
    const { communicationId } = this.noteObj;
    if (this.isNotOpportunityModule || !this.isViewOfAFileTurnedOn) {
      this.saveNoteContacts(communicationId);
    } else {
      this.isNotOpportunityModule = false;
      this.noteAPICall(communicationId)
        .then((data) => {
          return this.setNotesLinkToLoan(data);
        })
        .then(({ data }) => {
          const { communicationId: resCommunicationId } = data;
          if (resCommunicationId) {
            if (this.noteObj.referrerShareSms) {
              this.shareToReferrerViaSms(resCommunicationId);
            }
            if (this.noteObj.referrerShareEmail) {
              this.shareToReferrerViaEmail(resCommunicationId);
            }
            this.modalInstance.close();
          } else {
            toastError();
            this.isSaving = false;
          }
        })
        .catch((error) => {
          displayError(error);
        });
    }
  }

  saveNoteActivityDetailsFormat() {
    const { smsObj, emailObj } = this.noteObj;
    const activityDetails = this.getNoteContent();
    const smsBody = smsObj.body ? `[smsBody]${smsObj.body}` : '';
    const emailBody = emailObj.body ? `[emailBody]${emailObj.body}` : '';
    return `${activityDetails}${smsBody}${emailBody}`;
  }

  saveNoteContacts(communicationId) {
    const { referrerShareSms, referrerShareEmail } = this.noteObj;
    const isSharedWithReferrer = referrerShareSms || referrerShareEmail;
    const activityDetails = isSharedWithReferrer
      ? this.saveNoteActivityDetailsFormat()
      : this.getNoteContent();
    const notePayload = {
      ...this.noteObj,
      isSharedWithReferrer,
      activityDetails,
    };
    this.contactService
      .noteSet(notePayload, communicationId)
      .then(({ data }) => {
        return this.setNotesLinkToLoan(data);
      })
      .then((response) => {
        toastSuccess('Note saved successfully!');
        if (!response.data) {
          this.modalInstance.close();
        }
        const { CommunicationID: responseCommunicationId } =
          response.data || {};
        if (this.noteObj.referrerShareSms) {
          this.shareToReferrerViaSms(responseCommunicationId);
        }
        if (this.noteObj.referrerShareEmail) {
          this.shareToReferrerViaEmail(responseCommunicationId);
        }
        this.modalInstance.close(response.data);
      })
      .catch((error) => {
        displayError(error);
      });
  }

  setEmailAndSMSBody(data, isReferrer, isHtml = false) {
    const {
      activityDetails,
      activityDetailsHtml,
      clientName,
      brokerName,
    } = this.noteObj;
    const { body } = data;
    const htmlDetails = isHtml
      ? activityDetailsHtml
      : getPlainValueFromHtml(activityDetailsHtml);
    const details = this.isNotesRedesignEnabled ? htmlDetails : activityDetails;
    const bodyContent = details
      ? `Update on ${clientName} from ${brokerName}: ${details}`
      : '';
    return isReferrer ? bodyContent : body;
  }

  getNoteCopySmsToShare() {
    const { smsObj, referrerShareSms } = this.noteObj;
    smsObj.body = this.setEmailAndSMSBody(smsObj, referrerShareSms);
  }

  getNoteCopyEmailToShare() {
    const { emailObj, referrerShareEmail } = this.noteObj;
    emailObj.body = this.setEmailAndSMSBody(emailObj, referrerShareEmail, true);
  }

  shareToReferrerViaSms(communicationId) {
    const { smsObj } = this.noteObj;
    this.loanOpportunityService
      .shareReferrerViaSms(communicationId, smsObj)
      .then(({ Succeeded: succeeded }) => {
        if (succeeded) {
          toastSuccess(`Successfully sent SMS to Referrer`);
        }
      });
  }

  shareToReferrerViaEmail(communicationId) {
    const { emailObj } = this.noteObj;
    this.loanOpportunityService
      .shareReferrerViaEmail(communicationId, emailObj)
      .then(({ Succeeded: succeeded }) => {
        if (succeeded) {
          toastSuccess('Successfully sent Email to Referrer');
        }
      });
  }

  changeSmsBody(textAreaModel) {
    this.noteObj.smsObj.body = textAreaModel;
    this.getCharacterCount();
  }

  getCharacterCount() {
    const currentCharacterCount =
      (this.noteObj &&
        this.noteObj.smsObj.body &&
        this.noteObj.smsObj.body.length) ||
      0;
    const baseCharacterLimit = 140;
    const characterLimit = getTextCharacterLimit(
      baseCharacterLimit,
      currentCharacterCount,
    );
    this.characterCount = `${currentCharacterCount} / ${characterLimit}`;
  }

  changeEmailObj(emailModel) {
    this.noteObj.emailObj = { ...emailModel };
  }

  onNoteTypeChange({ noteType = {} }) {
    this.noteObj.NoteTypeID = noteType.id || null;
  }

  submitDisabled() {
    const hasContent = !!this.getNoteContent();
    return !!(!this.noteObj.activityTitle || !hasContent || this.isSaving);
  }
}
