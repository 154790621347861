class ContactsNotesService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'contacts-notes';
  }

  getDisplayCount() {
    return this.httpClient.get(`${this.apiBaseUrl}/display-count`);
  }

  setDisplayCount(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/display-count`, data);
  }
}

export default ContactsNotesService;
