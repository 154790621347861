import { viewOfAFileTheme } from 'Common/default/modal';
import { toastError } from 'Common/utilities/alert';

class CreateNewFileModalCtrl {
  constructor(
    $state,
    optionsService,
    loanFileService,
    contactService,
    loanAppDetailsService,
  ) {
    'ngInject';

    this.$state = $state;
    this.optionsService = optionsService;
    this.loanFileService = loanFileService;
    this.contactService = contactService;
    this.loanAppDetailsService = loanAppDetailsService;
  }

  $onInit() {
    this.defaultTheme = viewOfAFileTheme;
    this.confirmationText = this.familyId ? 'Save' : 'Next';
    this.modalTitle = this.familyId ? 'Create new deal' : 'New deal';
    this.newFile = {
      FamilyID: this.familyId || 0,
      AdviserID: this.allocatedAdviserId || 0,
      FileName: null,
      LendingCategoryID: null,
      ClientEntityIDs: this.personIds || [],
      PipelineStatusID: null,
      LoanStatusID: null,
      ConversionStatusID: null,
      PurposeAnswerInt: null,
      IsClient: !!this.isClient,
    };
    this.getLendingCategories();
    this.getPipelineStatuses();
    this.getLoanPurpose();
  }

  getLendingCategories() {
    return this.optionsService.getLendingCategories().then((data = []) => {
      this.lendingCategories = data;
    });
  }

  getPipelineStatuses() {
    return this.loanFileService.getStatusList().then((data = []) => {
      this.pipelineStatuses = data;
    });
  }

  getLoanPurpose() {
    return this.optionsService.loanpurpose().then(({ data = [] }) => {
      this.loanPurposes = data;
    });
  }

  onPipelineStatusChange(status = {}) {
    const {
      PipelineStatusId: PipelineStatusID,
      LoanStatusId: LoanStatusID,
      ConversionStatusId: ConversionStatusID,
      IsConversionStatus,
    } = status;
    const pipelineStatus = IsConversionStatus
      ? { ConversionStatusID, PipelineStatusID: null, LoanStatusID: null }
      : { PipelineStatusID, LoanStatusID, ConversionStatusID: null };
    this.newFile = {
      ...this.newFile,
      ...pipelineStatus,
    };
  }

  onCancel() {
    this.modalInstance.dismiss();
  }

  onSave() {
    this.isSaving = true;
    return this.loanFileService
      .setNewFile(this.newFile)
      .then(({ data = {} }) => {
        const { LoanID, LoanApplicationID } = data;
        if (!LoanID || !LoanApplicationID) {
          return toastError();
        }
        if (this.disableRouting) {
          this.modalInstance.close({
            loanApplicationId: LoanApplicationID,
            fileName: this.newFile.FileName,
          });
          return;
        }
        this.modalInstance.close({ loanApplicationId: LoanApplicationID });
        const params = {
          opportunityId: LoanApplicationID,
          loanId: LoanID,
          sourceFamilyId: this.newFile.FamilyID,
          sourceFamilyName: '',
          isContactOpener: false,
          tabName: 'details',
          activeTab: 'summary',
        };
        this.loanAppDetailsService.setInsuranceDefault(
          LoanApplicationID,
          this.newFile.FamilyID,
          this.newFile.AdviserID,
        );
        this.$state.go('app.opportunity', params);
      })
      .finally(() => {
        this.isSaving = false;
      });
  }

  onSelectClient({ client }) {
    const {
      ClientFamilyId: FamilyID,
      AllocatedAdviserId: AdviserID,
      IsClient,
      ClientEntityId,
    } = client;

    this.newFile = {
      ...this.newFile,
      FamilyID,
      AdviserID,
      IsClient,
      ClientEntityIDs: (ClientEntityId && ClientEntityId.split(',')) || [],
    };
  }
}

export default CreateNewFileModalCtrl;
