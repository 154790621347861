import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';
import { COLOR } from 'Common/constants/colors';
import { getLoanSecurityMarkUp, getFirstSecurityWithAddress } from './util';

export default class ExpandableTableCtrl {
  constructor(currentUserService, uiService) {
    'ngInject';

    this.currentUserService = currentUserService;
    this.uiService = uiService;
  }

  $onInit() {
    this.headerList = [
      {
        label: 'Status',
        key: 'status',
      },
      {
        label: 'Amount',
        key: 'amount',
      },
      {
        label: 'Purpose',
        key: 'purpose',
      },
      {
        label: 'Lender',
        key: 'lender',
      },
      {
        label: 'Sec. Address',
        key: 'security',
      },
      {
        label: 'Involved Parties',
        key: 'involved-parties',
      },
    ];
    this.DATE_TEXT_FORMAT = DATE_TEXT_FORMAT;
    this.getLoanSecurityMarkUp = getLoanSecurityMarkUp;
    this.COLOR = COLOR;
  }

  $onChanges(changes) {
    changes.loanList && this.setLoanDetailsFirstSecurityAddress();
  }

  setLoanDetailsFirstSecurityAddress() {
    this.loanList = this.loanList.map((loanDetails) => {
      return {
        ...loanDetails,
        firstSecurityWithAddress: getFirstSecurityWithAddress(
          loanDetails.SecurityInfo,
        ),
      };
    });
  }

  allowOpenDeal() {
    return (
      !this.options.isSettledTable &&
      this.currentUserService.isCorporate &&
      this.uiService.viewOfAfileTurnedOn
    );
  }
}
