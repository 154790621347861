const ADD_NOTE_OPTION_ID = -1;
const ADD_OPTION = { id: ADD_NOTE_OPTION_ID, type: '+ add or edit note type' };
const ALL_OPTION = { id: 0, type: 'All note types' };

export default class NoteTypeSelectorCtrl {
  constructor(
    configService,
    contactModelService,
    uiService,
    modalRenderService,
  ) {
    'ngInject';

    this.configService = configService;
    this.contactModelService = contactModelService;
    this.uiService = uiService;
    this.modalRenderService = modalRenderService;
  }

  $onInit() {
    this.displayNoteType = this.configService.feature.noteType;
    if (!this.displayNoteType) {
      return;
    }
    this.getNoteTypes();
  }

  getNoteTypes() {
    this.contactModelService
      .getNoteTypes(this.communicationId)
      .then((noteTypes) => {
        const allValue = this.useAsFilter ? [ALL_OPTION] : [];
        const addValue = this.showAddOption ? [ADD_OPTION] : [];
        this.noteTypes = [...allValue, ...addValue, ...(noteTypes || [])];
      });
  }

  setPreviousSelectState(id) {
    this.previousSelectState = id;
  }

  onSelectorChange(id) {
    if (id === ADD_NOTE_OPTION_ID) {
      this.value = this.previousSelectState;
      this.openNoteTypeModal();
      this.onChange({ noteType: { id: this.value } });
      return;
    }

    this.previousSelectState = id;
    const noteType =
      this.noteTypes && this.noteTypes.find((type) => type.id === id);
    this.onChange({ noteType });
  }

  onCustomSelectChange({ item = {} }) {
    this.onSelectorChange(item.id);
  }

  openNoteTypeModal() {
    const modalInstance = this.modalRenderService.openNoteTypeModal();
    modalInstance.result.then(() => {
      this.getNoteTypes();
    });
  }
}
