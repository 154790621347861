import angular from 'angular';
import { saveProfilerReportAndPDF } from './util/insuranceProfilerPdf';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('downloadProfilerReport', function downloadProfilerReport(
      $window,
      $timeout,
      $state,
      insuranceProfilerService,
      insuranceReportService,
      toaster,
      crmConfirmation,
    ) {
      return {
        scope: {
          insurerProviderId: '=',
          familyId: '<',
          profilerData: '<',
          onReportGenerated: '&',
          onGeneratingReport: '&',
        },
        template: `
      <a class="insurance-gear-settings generate-report-pdf">
        <preloader-dot id="loading" class="hidden preloader" ng-show="isGenerating"></preloader-dot>
        <icon-pdf class="icon-pdf-svg"></icon-pdf>
        <span class="icon-pdf" ng-hide="isGenerating">Generate Report</span>
        <span id="loadingText" class="loading-text hidden" ng-show="isGenerating">Generating PDF...(will take up to 30 seconds)</span>
      </a>
    `,
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link: (scope, element) => {
          const a = $window.document.createElement('a');
          const generatePdf = (saveToClientDocuments) => {
            angular
              .element('.insurance-gear-settings .icon-pdf')
              .addClass('hidden');
            angular.element('#loading, #loadingText').removeClass('hidden');
            if (scope.onGeneratingReport) {
              scope.onGeneratingReport();
            }

            scope.isGenerating = true;
            insuranceProfilerService
              .profilerInsuranceProfilePdfRpt(
                scope.insurerProviderId,
                scope.familyId,
                saveToClientDocuments,
              )
              .then((response) => {
                if (!response || !response.data) {
                  return;
                }
                const { data } = response;
                data.Name = 'Insurance Profiler Report';

                a.setAttribute(
                  `href`,
                  `data:${data.ContentType};base64,${data.DocumentContent}`,
                );
                a.setAttribute(`download`, data.Name);
                a.style.display = `none`;
                $window.document.body.append(a);

                scope.timeOut = $timeout(() => {
                  a.click();
                });

                angular.element('#loading, #loadingText').addClass('hidden');
                angular
                  .element('.insurance-gear-settings .icon-pdf')
                  .removeClass('hidden');
                if (saveToClientDocuments) {
                  toaster.pop(
                    'success',
                    'Saved',
                    'Document successfully saved to client.',
                  );
                  const isNewClient = scope.familyId && scope.profilerId;
                  isNewClient &&
                    $state.go('app.insuranceProfilerDetails', {
                      insProfilerId: scope.profilerId,
                      familyId: scope.familyId,
                    });
                }

                if (scope.onReportGenerated) {
                  scope.onReportGenerated();
                }
              })
              .finally(() => {
                scope.isGenerating = false;
              });
          };

          const saveProfilerReport = saveProfilerReportAndPDF({
            scope,
            insuranceReportService,
            generatePdf,
            insuranceProfilerService,
          });

          const clickListener = () => {
            if (!scope.insurerProviderId) {
              toaster.pop(
                'warning',
                'Warning',
                'Please calculate first before generating PDF Report.',
              );
              return;
            }

            const modalInstance = crmConfirmation.open({
              type: 'info',
              title: `Would you like to save this report to this client's records tab?`,
              description: ``,
              buttonText: `Yes please!`,
              showCloseButton: true,
              showCancelButton: true,
              modalSize: 'md',
              cancelButtonClass: 'colored',
            });
            modalInstance.result.then(
              () => {
                saveProfilerReport(true);
              },
              () => {
                saveProfilerReport(false);
              },
            );
          };

          element.on('click', clickListener);
          scope.$on('$destroy', () => {
            if (scope.timeOut) {
              $timeout.cancel(scope.timeOut);
            }
            a && a.remove();
            element.off('click', clickListener);
          });
        },
      };
    });
