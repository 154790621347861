export function pdfBuilderForUI(result) {
  return {
    name: result.Name,
    contentType: result.ContentType,
    documentContent: result.DocumentContent,
    documentExists: result.DocumentExists,
    imageUploadType: result.ImageUploadType,
    isSvg: result.IsSvg,
  };
}

export function documentPresignedUrlBuilderForUI(data) {
  return (
    data && {
      documentId: data.DocumentId,
      url: data.Url,
    }
  );
}
