import angular from 'angular';
import _ from 'lodash';
import { lenderSubmissionEmailBuilderForUI } from 'Common/mappers/loanScenario';

export const loadModule = () =>
  angular.module('app').factory('LoanScenarioModel', [
    'httpRequest',
    'httpCacheService',
    'configService',
    function (httpRequest, httpCacheService, configService) {
      var apiBaseUrl = 'LoanScenario/';
      function LoanScenario() {}
      LoanScenario.prototype = {
        LoanAppProviderWorksheetGet(
          loanApplicationId,
          callback,
          errorCallback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}LoanAppProviderWorksheetGet?loanScenarioId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
            (err) => {
              errorCallback(err);
            },
          );
        },
        FactFindDocumentGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FactFindDocumentGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        RecommendationReportStatusGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}RecommendationReportStatusGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        RecommendationReportStatus(data, callback) {
          httpRequest.put(
            `${apiBaseUrl}RecommendationReportStatus`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        loanInsuranceGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanInsuranceGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        loanInsuranceSet(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanInsuranceSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        loanDeclarationGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}BrokerDeclarationGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        loanDeclarationSet(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}BrokerDeclarationSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        cpaPageStatusGet(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CPAPageStatusGet?loanApplicationId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        cpaPageStatus(data, callback) {
          httpRequest.put(`${apiBaseUrl}CPAPageStatus`, data, (response) => {
            return callback(response);
          });
        },
        cpaDocumentGet(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CPADocumentGet?loanApplicationId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        cqpDocumentGet(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CQPDocumentGet?loanApplicationId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        loanDetailsGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanDetailsGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        loanDetailsSet($postData, callback, errorCallback) {
          httpRequest.post(
            `${apiBaseUrl}LoanDetailsSet`,
            $postData,
            (response) => {
              return callback(response);
            },
            (err) => {
              errorCallback(err);
            },
          );
        },

        loanDepositGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanDepositGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        loanDepositSet(data, callback) {
          httpRequest.post(`${apiBaseUrl}LoanDepositSet`, data, (response) => {
            return callback(response);
          });
        },

        loanFacilityGet(loanApplicationId, loanFacilityId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanFacilityGet?loanApplicationId=${loanApplicationId}&loanFacilityId=${loanFacilityId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        loanFacilitySet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanFacilitySet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        loanFacilityDelete(
          loanScenarioId,
          LoanFacilityId,
          callback,
          errorCallback,
        ) {
          httpRequest.delete(
            `${apiBaseUrl}LoanFacilityDelete?loanScenarioId=${loanScenarioId}&LoanFacilityId=${LoanFacilityId}`,
            (response) => {
              return callback(response);
            },
            (err) => {
              errorCallback(err);
            },
          );
        },

        contactLoanClientDelete(loandSecurityInfoID, callback) {
          httpRequest.delete(
            `${apiBaseUrl}ContactLoanClientDelete?loandSecurityInfoID=${loandSecurityInfoID}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getLoanApplicationCQPFeeStructure() {
          // API has been removed
          // httpRequest.get(apiBaseUrl + 'LoanApplicationCQPFeeStructureGet?loanApplicationId=' + loanApplicationId, function (response) {
          //     return callback(response);
          // });
        },

        setLoanApplicationCQPFeeStructure(structure, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanApplicationCQPFeeStructureSet`,
            structure,
            (response) => {
              return callback(response);
            },
          );
        },

        deleteLoanApplicationCQPFeeStructure(
          loanApplicationId,
          LoanApplicationCQPFeeId,
          callback,
        ) {
          httpRequest.delete(
            `${apiBaseUrl}LoanApplicationCQPFeeStructureDelete?loanApplicationId=${loanApplicationId}&LoanApplicationCQPFeeId=${LoanApplicationCQPFeeId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getLoanApplicationCQPReferralFeeStructure(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanApplicationCQPReferralFeeStructureGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        deleteLoanApplicationCQPReferralFeeStructure(
          loanApplicationId,
          LoanApplicationCQPReferralFeeId,
          callback,
        ) {
          httpRequest.delete(
            `${apiBaseUrl}LoanApplicationCQPReferralFeeStructureDelete?loanApplicationId=${loanApplicationId}&LoanApplicationCQPReferralFeeId=${LoanApplicationCQPReferralFeeId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        setLoanApplicationCQPReferralFeeStructure(structure, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanApplicationCQPReferralFeeStructureSet`,
            structure,
            (response) => {
              return callback(response);
            },
          );
        },

        getLoanApplicationCQP(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanApplicationCQPGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        setLoanApplicationCQP(loanApplicationId, lenderId, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanApplicationCQPSet?loanApplicationId=${loanApplicationId}&lenderId=${lenderId}`,
            { header: 'Accept: application/json' },
            (response) => {
              return callback(response);
            },
          );
        },

        CQPCommissionInfoGet() {
          // API has been removed
          // httpRequest.get(apiBaseUrl + 'CQPCommissionInfoGet?loanApplicationId=' + loanApplicationId, function (response) {
          //     return callback(response);
          // });
        },

        familyDeclarationsGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FamilyDeclarationsGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        familyDeclarationsSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}FamilyDeclarationsSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        loanSubmissionBrokerNotesGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanSubmissionBrokerNotesGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        loanSubmissionBrokerNotesSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanSubmissionBrokerNotesSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        brokerNotesGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}BrokerNotesGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        brokerNotesSet(loanAppId, sectId, content, callback) {
          const notes = { content: content };
          httpRequest.put(
            `${apiBaseUrl}BrokerNotesSet?loanApplicationId=${loanAppId}&subsectionId=${sectId}`,
            notes,
            (response) => {
              return callback(response);
            },
          );
        },

        CPADeclarationsGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}CPADeclarationsGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        CPADeclarationsSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}CPADeclarationsSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        referrerInfoGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanScenarioEnquiry?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        referrerInfoSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReferrerInfoSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        lenderSubmissionGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LenderSubmissionGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        lenderSubmissionSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}LenderSubmissionSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        lenderBranchDetailGet(loanApplicationId, lenderBranchId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LenderBranchDetailGet?loanApplicationId=${loanApplicationId}&lenderBranchId=${lenderBranchId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioListGet(familyID, searchString, callback) {
          var queryAdded = '';
          if (typeof searchString !== 'undefined') {
            queryAdded = `&searchString=${searchString}`;
          }
          httpCacheService.get(
            `${apiBaseUrl}ScenarioListGet?familyId=${familyID}${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioCompletionStatus(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ScenarioCompletionStatus?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        FinancialsSummaryGet(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FinancialsSummaryGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioDetailsGet(loanScenarioId, callback) {
          const httpService = configService.feature.loanAppCaching
            ? httpCacheService
            : httpRequest;
          httpService.get(
            `${apiBaseUrl}ScenarioDetailsGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioSet(data, callback) {
          httpRequest.post(`${apiBaseUrl}ScenarioSet`, data, (response) => {
            return callback(response);
          });
        },

        scenarioDelete(loanScenarioId, callback) {
          httpRequest.delete(
            `${apiBaseUrl}ScenarioDelete?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioClientListGet(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ScenarioClientListGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioApplicantListGet(loanScenarioId, callback) {
          const httpService = configService.feature.loanAppCaching
            ? httpCacheService
            : httpRequest;
          httpService.get(
            `${apiBaseUrl}ScenarioApplicantListGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioSearchClientGet(
          searchString,
          partyType,
          loanApplicationID,
          loanID,
          callback,
        ) {
          var partyTypeQuery =
            typeof partyType !== 'undefined' ? `&partyType=${partyType}` : '';
          var loanApplicationID =
            !_.isNull(loanApplicationID) && !_.isUndefined(loanApplicationID)
              ? `&loanApplicationID=${loanApplicationID}`
              : '';
          var loanID =
            !_.isNull(loanID) && !_.isUndefined(loanID)
              ? `&loanID=${loanID}`
              : '';

          httpRequest.get(
            `SearchClientGet?searchString=${searchString}${partyTypeQuery}${loanApplicationID}${loanID}`,
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioApplicantSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}ScenarioApplicantSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        contactLoanClientSet(
          loanId,
          clientEntityId,
          IsClient,
          IsGuarantor,
          IsAddKid,
          IsApplicant,
          IsSolicitor,
          IsAccountant,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}ContactLoanClientSet/?loanid=${loanId}&ClientEntityID=${clientEntityId}&IsClient=${
              IsClient != undefined ? IsClient : false
            }&IsGuarantor=${
              IsGuarantor != undefined ? IsGuarantor : false
            }&IsAddKid=${
              IsAddKid != undefined ? IsAddKid : false
            }&IsApplicant=${
              IsApplicant != undefined ? IsApplicant : false
            }&IsSolicitor=${
              IsSolicitor != undefined ? IsSolicitor : false
            }&IsAccountant=${IsAccountant != undefined ? IsAccountant : false}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },

        scenarioClientDelete(
          loanScenarioId,
          clientEntityId,
          isClient,
          callback,
        ) {
          httpRequest.delete(
            `${apiBaseUrl}ScenarioClientDelete?loanScenarioId=${loanScenarioId}&clientEntityId=${clientEntityId}&isClient=${isClient}`,
            (response) => {
              return callback(response);
            },
          );
        },

        availableDocumentListGet(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}AvailableDocumentListGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        documentListGet(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}Documents?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        documentListGetLenderWorksheet(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}Documents?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        documentPageCountGet(documentId, callback) {
          httpRequest.get(
            `${apiBaseUrl}DocumentPageCountGet?documentId=${documentId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        documentDetailsGet(loanScenarioId, documentId, thumbWidth, callback) {
          var thumbWidthDetails = '';
          if (typeof thumbWidth !== 'undefined') {
            thumbWidthDetails = `&thumbWidth=${thumbWidth}`;
          }
          httpRequest.get(
            `${apiBaseUrl}DocumentDetailsGet?loanScenarioId=${loanScenarioId}&documentId=${documentId}${thumbWidthDetails}`,
            (response) => {
              return callback(response);
            },
          );
        },

        documentDelete(
          loanScenarioId,
          documentId,
          loanScenarioDocumentId,
          callback,
        ) {
          httpRequest.delete(
            `${apiBaseUrl}Documents/${loanScenarioDocumentId}?loanScenarioId=${loanScenarioId}&documentId=${documentId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        documentPageSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}DocumentPageSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        documentUpload($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}DocumentUpload`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        documentSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}DocumentSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        // Needs Analysis Tab
        getNeedsAnalysis(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}NeedsAnalysisGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        setNeedsAnalysis($postdata, callback) {
          httpRequest.post(
            `${apiBaseUrl}NeedsAnalysisSet`,
            $postdata,
            (response) => {
              return callback(response);
            },
          );
        },

        getNewBrokerEventForScenario(loanScenarioId, callback) {
          httpRequest.get(
            `Serviceability/NewBrokerEventForScenarioGet?loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getFundingCalculatorNewBrokerEventForScenario(
          loanScenarioId,
          callback,
        ) {
          httpRequest.get(
            `Serviceability/FundingCalculatorNewBrokerEventForScenarioGet?loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getLoanScenarioNeedsAnalysisLoanPurpose(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanScenarioNeedsAnalysisLoanPurposeGet?loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        BorrowersDetailsGet(familyID, callback) {
          httpRequest.get(
            `contacts/BorrowersDetailsGet?loanID=0&familyID=${familyID}`,
            (response) => {
              return callback(response);
            },
          );
        },

        AssetInfoGet(loanScenarioId, callback) {
          httpRequest.get(
            `LoanScenario/AssetInfoGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        AssetDetailsGet(familyId, loanScenarioId, assetId, callback) {
          var queryAdded = `familyId=${familyId}`;
          if (typeof loanScenarioId !== 'undefined') {
            queryAdded += `&loanScenarioId=${loanScenarioId}`;
          }
          if (typeof assetId !== 'undefined') {
            queryAdded += `&assetId=${assetId}`;
          }
          httpRequest.get(
            `LoanScenario/AssetDetailsGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },
        AssetClientGet(familyId, loanScenarioId, assetId, callback) {
          var queryAdded = `familyId=${familyId}`;
          if (typeof loanScenarioId !== 'undefined') {
            queryAdded += `&loanScenarioId=${loanScenarioId}`;
          }
          if (typeof assetId !== 'undefined') {
            queryAdded += `&assetId=${assetId}`;
          }
          httpRequest.get(
            `LoanScenario/AssetClientGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },

        AssetTypeGet(callback) {
          httpRequest.get('Options/Financial/AssetTypeGet', (response) => {
            return callback(response);
          });
        },

        AssetInfoDelete(loanScenarioId, assetId, callback) {
          httpRequest.delete(
            `LoanScenario/AssetInfoDelete?loanScenarioId=${loanScenarioId}&assetId=${assetId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        AssetDetailsSet(data, callback) {
          httpRequest.post('LoanScenario/AssetDetailsSet', data, (response) => {
            return callback(response);
          });
        },

        LiabilityInfoGet(loanScenarioId, callback) {
          httpRequest.get(
            `LoanScenario/LiabilityInfoGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LiabilityDetailsGet(familyId, loanScenarioId, liabilityId, callback) {
          var queryAdded = `familyId=${familyId}`;
          if (typeof loanScenarioId !== 'undefined') {
            queryAdded += `&loanScenarioId=${loanScenarioId}`;
          }
          if (typeof liabilityId !== 'undefined') {
            queryAdded += `&liabilityId=${liabilityId}`;
          }
          httpRequest.get(
            `LoanScenario/LiabilityDetailsGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LiabilityClientGet(familyId, loanScenarioId, liabilityId, callback) {
          var queryAdded = `familyId=${familyId}`;
          if (typeof loanScenarioId !== 'undefined') {
            queryAdded += `&loanScenarioId=${loanScenarioId}`;
          }
          if (typeof liabilityId !== 'undefined') {
            queryAdded += `&liabilityId=${liabilityId}`;
          }
          httpRequest.get(
            `LoanScenario/LiabilityClientGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },

        LiabilityTypeGet(callback) {
          httpRequest.get('Options/Financial/LiabilityTypeGet', (response) => {
            return callback(response);
          });
        },

        subLiabilityTypeGet(liabilityId, callback) {
          httpRequest.get(
            `Options/Financial/SubLiabilityTypeGet?liabilityId=${liabilityId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        LiabilityInfoDelete(loanScenarioId, liabilityId, callback) {
          httpRequest.delete(
            `LoanScenario/LiabilityInfoDelete?loanScenarioId=${loanScenarioId}&liabilityId=${liabilityId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        LiabilityDetailsSet(data, callback) {
          httpRequest.post(
            'LoanScenario/LiabilityDetailsSet',
            data,
            (response) => {
              return callback(response);
            },
          );
        },

        IncomeInfoGet(loanScenarioId, callback) {
          httpRequest.get(
            `LoanScenario/IncomeInfoGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        IncomeDetailsGet(familyId, loanScenarioId, incomeId, callback) {
          var queryAdded = `familyId=${familyId}`;
          if (typeof loanScenarioId !== 'undefined') {
            queryAdded += `&loanScenarioId=${loanScenarioId}`;
          }
          if (typeof incomeId !== 'undefined') {
            queryAdded += `&incomeId=${incomeId}`;
          }
          httpRequest.get(
            `LoanScenario/IncomeDetailsGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },
        IncomeClientGet(familyId, loanScenarioId, incomeId, callback) {
          var queryAdded = `familyId=${familyId}`;
          if (typeof loanScenarioId !== 'undefined') {
            queryAdded += `&loanScenarioId=${loanScenarioId}`;
          }
          if (typeof incomeId !== 'undefined') {
            queryAdded += `&IncomeId=${incomeId}`;
          }
          httpRequest.get(
            `LoanScenario/IncomeClientGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },

        IncomeTypeGet(includeSubtypes = false, callback) {
          httpRequest.get(
            `Options/Financial/IncomeTypeGet?includeSubTypes=${includeSubtypes}`,
            (response) => {
              return callback(response);
            },
          );
        },

        IncomeInfoDelete(loanScenarioId, incomeId, callback) {
          httpRequest.delete(
            `LoanScenario/IncomeInfoDelete?loanScenarioId=${loanScenarioId}&incomeId=${incomeId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        IncomeDetailsSet(data, callback) {
          httpRequest.post(
            'LoanScenario/IncomeDetailsSet',
            data,
            (response) => {
              return callback(response);
            },
          );
        },

        ExpenseInfoGet(loanScenarioId, callback) {
          httpRequest.get(
            `LoanScenario/ExpenseInfoGet?loanScenarioId=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        ExpenseDetailsGet(familyId, loanScenarioId, expenseId, callback) {
          var queryAdded = `familyId=${familyId}`;
          if (typeof loanScenarioId !== 'undefined') {
            queryAdded += `&loanScenarioId=${loanScenarioId}`;
          }
          if (typeof expenseId !== 'undefined') {
            queryAdded += `&expenseId=${expenseId}`;
          }
          httpRequest.get(
            `LoanScenario/ExpenseDetailsGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ExpenseClientGet(familyId, loanScenarioId, expenseId, callback) {
          var queryAdded = `familyId=${familyId}`;
          if (typeof loanScenarioId !== 'undefined') {
            queryAdded += `&loanScenarioId=${loanScenarioId}`;
          }
          if (typeof expenseId !== 'undefined') {
            queryAdded += `&expenseId=${expenseId}`;
          }
          httpRequest.get(
            `LoanScenario/ExpenseClientGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },

        ExpenseTypeGet(callback) {
          httpRequest.get('Options/Financial/ExpenseTypeGet', (response) => {
            return callback(response);
          });
        },

        ExpenseInfoDelete(loanScenarioId, expenseId, callback) {
          httpRequest.delete(
            `LoanScenario/ExpenseInfoDelete?loanScenarioId=${loanScenarioId}&expenseId=${expenseId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        ExpenseDetailsSet(data, callback) {
          httpRequest.post(
            'LoanScenario/ExpenseDetailsSet',
            data,
            (response) => {
              return callback(response);
            },
          );
        },

        // Employment tab
        LoanScenarioEmploymentGet(loanScenarioId, callback) {
          httpRequest.get(
            `LoanScenario/LoanScenarioEmploymentGet?loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        EmploymentGet(loanScenarioId, employmentId, callback) {
          var employmentIdQuery =
            typeof employmentId !== 'undefined'
              ? `&employmentId=${employmentId}`
              : '';
          httpRequest.get(
            `LoanScenario/EmploymentGet?loanApplicationId=${loanScenarioId}${employmentIdQuery}`,
            (response) => {
              return callback(response);
            },
          );
        },
        EmploymentDelete(employmentId, callback) {
          httpRequest.delete(
            `LoanScenario/EmploymentDelete?employmentId=${employmentId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        CountryListGet(callback) {
          httpRequest.get('LoanScenario/CountryListGet', (response) => {
            return callback(response);
          });
        },

        LoanScenarioIdentificationDocumentsSet(data, callback) {
          httpRequest.post(
            'LoanScenario/LoanScenarioIdentificationDocumentsSet',
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        LoanScenarioEmploymentSet(data, callback) {
          httpRequest.post(
            'LoanScenario/LoanScenarioEmploymentSet',
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        EmploymentSet(data, callback) {
          httpRequest.post(
            `LoanScenario/EmploymentSet?employmentId=${data.employmentID}&clientId=${data.clientId}&entityId=${data.entityId}&employmentType=${data.employmentType}&employmentCategoryId=${data.employmentCategoryID}&employmentStatusId=${data.employmentStatusID}&EmploymentRollId=${data.EmploymentRollID}&employerType=${data.employerType}&isBenefits=${data.isBenefits}&isProbation=${data.isProbation}&dateStarted=${data.dateStarted}&dateEnded=${data.dateEnded}&employerFirstName=${data.employerFirstName}&employerLastName=${data.employerLastName}&addressId=${data.AddressID}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        LoanScenarioIdentificationDocumentsGet(loanScenarioId, callback) {
          httpRequest.get(
            `LoanScenario/LoanScenarioIdentificationDocumentsGet?loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LoanScenarioIdentificationDocumentsTypeget(callback) {
          httpRequest.get(
            'LoanScenario/LoanScenarioIdentificationDocumentsTypeget',
            (response) => {
              return callback(response);
            },
          );
        },
        EmploymentTypesGet(callback) {
          httpRequest.get('LoanScenario/EmploymentTypesGet', (response) => {
            return callback(response);
          });
        },
        IdentificationSet(data, callback) {
          httpRequest.post(
            'LoanScenario/IdentificationSet',
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        IdentificationGet(loanApplicationId, identificationId, callback) {
          var queryAdded = '';
          if (typeof loanApplicationId !== 'undefined') {
            queryAdded += `loanApplicationId=${loanApplicationId}`;
          }
          if (typeof identificationId !== 'undefined') {
            queryAdded += `&identificationId=${identificationId}`;
          }
          httpRequest.get(
            `LoanScenario/IdentificationGet?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },
        IdentificationDelete(identificationId, callback) {
          var queryAdded = '';
          if (typeof identificationId !== 'undefined') {
            queryAdded += `identificationId=${identificationId}`;
          }
          httpRequest.get(
            `LoanScenario/IdentificationDelete?${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },

        FamilyIdentityStatusSet(data, callback) {
          httpRequest.post(
            'LoanScenario/FamilyIdentityStatusSet',
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        FamilyIdentityStatusGet(loanApplicationId, familyId, callback) {
          httpRequest.get(
            `LoanScenario/FamilyIdentityStatusGet?loanApplicationId=${loanApplicationId}&familyId=${familyId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        // EmploymentRoleRoleName: function (criteria,callback) {
        //     httpRequest.get("LoanScenario/EmploymentRoleRoleName?criteria="+criteria,function (response) {
        //         return callback(response);
        //     })
        // },
        /* InsuranceSet: function (data, callback) {
            httpRequest.post("LoanScenario/InsuranceSet", data, function (response) {
                return callback(response);
            })
        },InsuranceGet: function (loanScenarioId,callback) {
            httpRequest.get("LoanScenario/InsuranceGet?loanScenarioID="+loanScenarioId, function (response) {
                return callback(response);
            });
        }, */ EmploymentTypeGet(
          callback,
        ) {
          httpRequest.get('corporate/EmploymentTypeGet', (response) => {
            return callback(response);
          });
        },
        EmployerTypeGet(callback) {
          httpRequest.get('LoanScenario/EmployerTypeGet', (response) => {
            return callback(response);
          });
        },
        // Address/Security Tab
        getLoanScenarioSecurityInfo(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanScenarioSecurityInfoGet?loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getAddress(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ApplicantAddress?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        setAddress(data, callback) {
          httpRequest.post(`${apiBaseUrl}AddressSet`, data, (response) => {
            return callback(response);
          });
        },

        getPropertyStatus(callback) {
          httpRequest.get(`${apiBaseUrl}PropertyStatus`, (response) => {
            return callback(response);
          });
        },

        getPropertyType(callback) {
          httpRequest.get(`${apiBaseUrl}PropertyType`, (response) => {
            return callback(response);
          });
        },

        getProperty(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}PropertyGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getUsage(callback) {
          httpRequest.get(`${apiBaseUrl}Usage`, (response) => {
            return callback(response);
          });
        },

        getZoning(callback) {
          httpRequest.get(`${apiBaseUrl}Zoning`, (response) => {
            return callback(response);
          });
        },

        getTitleType(callback) {
          httpRequest.get(`${apiBaseUrl}TitleType`, (response) => {
            return callback(response);
          });
        },

        getTenureType(callback) {
          httpRequest.get(`${apiBaseUrl}TenureType`, (response) => {
            return callback(response);
          });
        },

        setLoanScenarioSecurityInfo($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanScenarioSecurityInfoSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        getLoanScenarioSecurityClientInfo(
          loanScenarioId,
          loanSercurityId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}LoanScenarioSecurityClientInfoGet?LoanScenarioID=${loanScenarioId}&LoanSecurityID=${loanSercurityId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getLoanScenarioSingleSecurityInfo(loanSercurityId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanScenarioSingleSecurityInfoGet?loanSercurityID=${loanSercurityId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        deleteLoanScenarioSecurityInfo($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanScenarioSecurityInfoDelete`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        getAssetType(callback) {
          httpRequest.get(`${apiBaseUrl}AssetTypeGet`, (response) => {
            return callback(response);
          });
        },
        getCTRLEquitySource(callback) {
          httpRequest.get(`${apiBaseUrl}CTRLEquitySourceGet`, (response) => {
            return callback(response);
          });
        },
        getDepositEquity(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}DepositEquityGet?loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setDepositEquity($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}DepositEquitySet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        deleteDepositEquity(depositEquityId, callback) {
          httpRequest.delete(
            `${apiBaseUrl}DepositEquityDelete?depositEquityID=${depositEquityId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        /**
         * Loan Application: Funding Details
         * */
        linkedFundingCalculatorSet(loanApplicationId, brokerEventId, callback) {
          httpRequest.post(
            `${apiBaseUrl}LinkedFundingCalculatorSet?loanApplicationId=${loanApplicationId}&brokerEventId=${brokerEventId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },

        linkedFundingCalculatorGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LinkedFundingCalculatorGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        fundingCalculatorDetailsGet(brokerEventId, callback) {
          httpRequest.get(
            `${apiBaseUrl}FundingCalculatorDetailsGet?brokerEventId=${brokerEventId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        // Product Selection Tab
        getServiceabilityProductSearch(searchProduct, callback) {
          httpRequest.get(
            `Serviceability/ServiceabilityProductSearchGet?SearchProduct=${searchProduct}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getRecentlyViewedProduct(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}RecentlyViewedProductGet?loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        getPopularSearchedProducts(callback) {
          httpRequest.get(
            `${apiBaseUrl}PopularSearchedProducts`,
            (response) => {
              return callback(response);
            },
          );
        },

        setSearchedProduct($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}SearchedProductSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        getLoanScenarioSelectedProduct(loanScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanScenarioSelectedProductGet?LoanscenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        deleteSelectedProduct(productId, loanScenarioId, callback) {
          httpRequest.delete(
            `${apiBaseUrl}SelectedProductDelete?productID=${productId}&loanScenarioID=${loanScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        getScenarioProviderList(applicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ScenarioProviderListGet?applicationId=${applicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setScenarioProvider(loanApplicationId, providerId, callback) {
          httpRequest.post(
            `${apiBaseUrl}ScenarioProviderSet?loanApplicationId=${loanApplicationId}&providerId=${providerId}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        setScenarioImportantDate($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}ScenarioImportantDateSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        getRelatedDocumentHeadsup(familyid, callback) {
          httpRequest.get(
            `${apiBaseUrl}RelatedDocumentHeadsupShowGet?familyId=${familyid}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setRelatedDocumentHeadsup(familyid, isRelatedDocumentShow, callback) {
          httpRequest.put(
            `${apiBaseUrl}RelatedDocumentHeadsupShow?familyId=${familyid}&isRelatedDocumentShow=${isRelatedDocumentShow}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        getAdviserComment(loanApplicationId, commentId, callback) {
          var queryAdded = '';
          if (typeof commentId !== 'undefined') {
            queryAdded = `&commentId=${commentId}`;
          }
          httpRequest.get(
            `${apiBaseUrl}AdviserCommentGet?loanApplicationId=${loanApplicationId}${queryAdded}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setAdviserComment($postdata, callback) {
          httpRequest.post(
            `${apiBaseUrl}AdviserCommentSet`,
            $postdata,
            (response) => {
              return callback(response);
            },
          );
        },
        deleteAdviserComment(commentId, callback) {
          httpRequest.delete(
            `${apiBaseUrl}AdviserCommentDelete?commentId=${commentId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        setProposedLendingProduct(data, callback) {
          var qString = [];
          angular.forEach(data, (val, key) => {
            qString.push(`${key}=${val}`);
          });
          qString = qString.join('&');
          var query = `?${qString}`;

          httpRequest.post(
            `${apiBaseUrl}ProposedLendingProductSet${query}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },

        ReportSettingsServiceabilityGet(lendingScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReportSettingsServiceabilityGet?lendingScenarioID=${lendingScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ReportSettingsFundingGet(lendingScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReportSettingsFundingGet?lendingScenarioID=${lendingScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ReportSettingsGet(lendingScenarioId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ReportSettingsGet?lendingScenarioID=${lendingScenarioId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ReportSettingsSet(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}ReportSettingsSet`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },

        documentCheckListGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}DocumentCheckListGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        documentCheckListPut(loanApplicationId, isTicked, callback) {
          httpRequest.put(
            `${apiBaseUrl}DocumentCheckList?loanApplicationId=${loanApplicationId}&isTicked=${isTicked}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },

        preApprovalCheckPut(loanApplicationId, isTicked, callback) {
          httpRequest.put(
            `${apiBaseUrl}PreApprovalCheck?loanApplicationId=${loanApplicationId}&isTicked=${isTicked}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },

        getLoanSecurity(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanSecurityGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        contactLoanSecurityInfoSet($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}ContactLoanSecurityInfoSet`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },

        contactLoanSecurityInfoGet(loanId, loanSecurityInfoId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ContactLoanSecurityInfoGet?loanId=${loanId}&loanSecurityInfoId=${loanSecurityInfoId}`,
            (response) => {
              return callback(response);
            },
          );
        },

        LenderFeesGet(
          productId,
          brokerEventId,
          productLoanStructureId,
          loanApplicationId,
          callback,
        ) {
          httpRequest.get(
            `${apiBaseUrl}LenderFees?productId=${productId}&BrokerEventId=${brokerEventId}&productLoanStructureId=${productLoanStructureId}&loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LenderFeesSet($postData, callback) {
          httpRequest.post(`${apiBaseUrl}LenderFees`, $postData, (response) => {
            return callback(response);
          });
        },
        getLoanScenarioEnquiry(id, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanScenarioEnquiry?loanApplicationId=${id}`,
            (response) => {
              return callback(response);
            },
          );
        },
        setLoanScenarioEnquiry(post, callback) {
          httpRequest.put(
            `${apiBaseUrl}LoanScenarioEnquiry`,
            post,
            (response) => {
              return callback(response);
            },
          );
        },
        share(
          loanApplicationId,
          clientId,
          familyId,
          eMail,
          sms,
          clientMessage,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}Share`,
            {
              loanApplicationId,
              clientId,
              familyId,
              eMail,
              sms,
              clientMessage,
              userInitiated: true,
            },
            (response) => {
              return callback(response);
            },
          );
        },
        loanApplicationLockStatus(loanApplicationId, isLocked, callback) {
          httpRequest.put(
            `${apiBaseUrl}loanApplicationLockStatus?loanApplicationId=${loanApplicationId}&isLocked=${isLocked}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        setLoanApplicationShowCPACQPRecommendation(
          loanAppId,
          isShow,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}LoanApplicationShowCPACQPRecommendationSet?loanApplicationId=${loanAppId}&IsShowCPACQPRecommendation=${isShow}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        LoanFacilityTBASet(loanApplicationId, loanFacilityId, isTBA, callback) {
          httpRequest.post(
            `${apiBaseUrl}LoanFacilityTBASet?loanApplicationId=${loanApplicationId}&loanFacilityId=${loanFacilityId}&isTBA=${isTBA}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        NZLoanAppDocumentGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}NZLoanAppDocumentGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        ExpenseDetailsSetMultiple($postData, callback) {
          httpRequest.post(
            `${apiBaseUrl}ExpenseDetailsSetMultiple`,
            $postData,
            (response) => {
              return callback(response);
            },
          );
        },
        LoanAppProviderEmailGet(loanApplicationId, providerId, callback) {
          httpRequest.get(
            `${apiBaseUrl}LoanAppProviderEmail?loanScenarioId=${loanApplicationId}&providerId=${providerId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        LoanAppProviderEmailSet(
          loanApplicationId,
          providerId,
          lenderEmail,
          callback,
        ) {
          httpRequest.post(
            `${apiBaseUrl}LoanAppProviderEmail?loanScenarioId=${loanApplicationId}&providerId=${providerId}&lenderEmail=${lenderEmail}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        emailLoanAppToLenderNZ(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}emailLoanAppToLenderNZ`,
            data,
            (response) => {
              return callback(response);
            },
          );
        },
        LoanAppIsFirstHomeBuyerSet(
          clientId,
          loanApplicationId,
          isFirstHomeBuyer,
          callback,
        ) {
          httpRequest.put(
            `${apiBaseUrl}${loanApplicationId}/clients/${clientId}/isFirstHomeBuyer?isFirstHomeBuyer=${isFirstHomeBuyer}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        LoanAppIsFirstHomeBuyerGet(clientId, loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}${loanApplicationId}/clients/${clientId}/isFirstHomeBuyer`,
            (response) => {
              return callback(response);
            },
          );
        },
        workbenchRequirementGet(loanId, callback) {
          httpRequest.get(
            `${apiBaseUrl}WorkbenchRequirement?loanId=${loanId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        workbenchRequirementSet(data, callback) {
          let qString = [];
          angular.forEach(data, (val, key) => {
            if (val) {
              qString.push(`${key}=${val}`);
            }
          });
          qString = qString.join('&');
          const query = `?${qString}`;

          httpRequest.post(
            `${apiBaseUrl}WorkbenchRequirement${query}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        workbenchRequirementCompletion(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}WorkbenchRequirementCompletion?loanId=${data.loanId}&isCompleted=${data.isCompleted}&requirementID=${data.requirementID}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        workbenchRequirementDelete(data, callback) {
          httpRequest.delete(
            `${apiBaseUrl}WorkbenchRequirement?loanId=${data.loanId}&requirementID=${data.requirementID}`,
            (response) => {
              return callback(response);
            },
          );
        },
        importantDateGet(loanApplicationId, callback) {
          httpRequest.get(
            `${apiBaseUrl}ImportantDateGet?loanApplicationId=${loanApplicationId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        workbenchNextWorkDate(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}WorkbenchNextWorkDate?loanId=${data.loanId}&nextWorkDate=${data.nextWorkDate}&description=${data.description}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },
        workbenchRequirementLog(loanId, callback) {
          httpRequest.get(
            `${apiBaseUrl}WorkbenchRequirementLog?loanId=${loanId}`,
            (response) => {
              return callback(response);
            },
          );
        },
        workbenchRequirementLogDelete(data, callback) {
          httpRequest.delete(
            `${apiBaseUrl}WorkbenchRequirementLog?loanId=${data.loanId}&workbenchLogID=${data.workbenchLogID}`,
            (response) => {
              return callback(response);
            },
          );
        },

        linkLoanAppDocsToContact(data, callback, callbackOnError) {
          httpRequest.post(
            `${apiBaseUrl}LinkLoanAppDocsToContact?loanAppId=${data.loanAppId}`,
            {},
            (response) => {
              return callback(response);
            },
            (error) => {
              if (callbackOnError) {
                return callbackOnError(error);
              }
            },
          );
        },

        completionStatusSet(data, callback) {
          httpRequest.post(
            `${apiBaseUrl}CompletionStatus?loanApplicationId=${data.loanApplicationId}&sectionID=${data.sectionID}&isCompleted=${data.isCompleted}`,
            {},
            (response) => {
              return callback(response);
            },
          );
        },

        assignAssistant(assistantId, loanScenarioId, callback) {
          return httpRequest.put(
            `${apiBaseUrl}${loanScenarioId}/assign-assistant`,
            assistantId,
            (response) => {
              return callback(response);
            },
          );
        },

        availableDocuments(loanScenarioId, callback) {
          return httpRequest.get(
            `${apiBaseUrl}${loanScenarioId}/available-documents`,
            (response) => {
              return callback(response);
            },
          );
        },
        getLenderSubmissionEmails(loanScenarioId, callback) {
          return httpRequest.get(
            `${apiBaseUrl}${loanScenarioId}/lender-emails`,
            (response) => {
              response.data &&
                (response.data = response.data.map(
                  lenderSubmissionEmailBuilderForUI,
                ));
              return callback(response);
            },
            (error) => console.error(error.message),
          );
        },
      };

      return LoanScenario;
    },
  ]);
