import angular from 'angular';
import swal from 'sweetalert';
import _ from 'lodash';
import { clientTitles } from 'Common/utilities/client';
import {
  CONSTRUCTION_TYPES,
  TRANSACTION_TYPE,
  DEFAULT_VALUES,
} from 'Common/constants/securityValues';
import { TITLES } from 'Common/constants/titles';
import { TITLE_TYPES_AU, TITLE_TYPES_NZ } from 'Common/constants/titleTypes';
import { AU_STATES } from 'Common/constants/addressState';
import {
  assetFinanceIgnore,
  checkIfInvestment,
} from 'Common/utilities/security';
import { openDate } from 'Common/utilities/address';
import { toastError } from 'Common/utilities/alert';
import { formatCustomAddress } from 'Common/utilities/addressHistory';
import { checkIfRemoveIncome } from 'Common/utilities/income';
import { countryListBuilderForUI } from 'Common/mappers/options';
import { addressLookup } from 'Common/mappers/address';
import {
  populateCountryList,
  initializeModal,
  addIncome,
  saveAddress,
  setupConstructionLoans,
  isStateVictoria,
} from './util/securityModal';
import {
  setUpNZNewServiceability,
  liabilityDatesMapper,
} from 'Common/utilities/financialLiabilities';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('SecurityModalCtrl', function SecurityModalCtrl(
      $scope,
      $uibModalInstance,
      optionsService,
      loanScenarioService,
      loanApplicationServices,
      dashboardService,
      $timeout,
      generalService,
      LoanSecurityInfoId,
      TransactionTypeList,
      loanAppSharedData,
      uiService,
      currentUserService,
      elementService,
      configService,
      addressLookupService,
      loanStructureService,
      $rootScope,
    ) {
      const {
        addressOverhaul,
        constructionLoanApps,
        nzNewServiceability,
      } = configService.feature;
      $scope.isAddressOverhaulEnabled = addressOverhaul;
      $scope.isConstructionLoanApps = constructionLoanApps;
      $scope.nzNewServiceability = nzNewServiceability;
      $scope.crmCountry = $rootScope.crmCountry;
      const action = LoanSecurityInfoId ? 'edit' : 'add';
      const lenderPlace = 1;
      initializeModal($scope);
      $scope.transactionTypeList = TransactionTypeList;
      setupConstructionLoans({ $scope, optionsService });
      $scope.isStateVictoria = isStateVictoria;
      $scope.auStates = AU_STATES;
      nzNewServiceability &&
        setUpNZNewServiceability({
          $scope,
          loanStructureService,
          optionsService,
        });

      /* Lender place for loanapp */
      angular.extend($scope, {
        cancel: () => {
          $uibModalInstance.close();
          $scope.validateChanges && $scope.validateChanges();
        },

        assetFinanceIgnore,

        uiService,

        loadSecurityModel: () => {
          $scope.security = {
            SecurityTypeId: 1,
            TransactionId: 1,
            ConstructionTypeId: currentUserService.isAU
              ? CONSTRUCTION_TYPES.HOUSE_AND_LAND_PACKAGE
              : CONSTRUCTION_TYPES.FULL_CONSTRUCTION,
            FutureValue: 0,
            BuildAmount: 0,
            IsPrimary: true,
            IsRefinance: true,
            PropertyDetails: {
              LoanSecurityId: 0,
              StatusId: DEFAULT_VALUES.ESTABLISHED_STATUSID,
              PropertyPrimaryPurposeId:
                DEFAULT_VALUES.OWNEROCCUPIED_PRIMARYPURPOSE,
              HoldingId:
                loanAppSharedData.applicantsLength(
                  loanAppSharedData.listApplicants,
                ) === 1
                  ? DEFAULT_VALUES.SOLE_HOLDINGID
                  : DEFAULT_VALUES.JOINT_HOLDINGID,
              ZoningId: DEFAULT_VALUES.RESIDENTIAL_ZONINGID,
              IsPreApproved: true,
              LicensedRealEstateAgentContract: true,
              IdentifierList: [],
              AddressId: 0,
              TitleTypeId: currentUserService.isAU
                ? TITLE_TYPES_AU.FREEHOLD
                : TITLE_TYPES_NZ.FREEHOLD,
              TitleId: TITLES.TORRENS,
              ContactForAccess: {
                ContactForAccess: 0,
              },
              IsElectronicTransaction: true,
            },
            MortgageList: [],
            IncomeList: [],
            Ownership: [],
            countryList: [],
            titles: clientTitles(),
          };
        },
        openDate: (dateField) => openDate($scope.datePopUp, dateField),
        dateFormat: 'dd MMM yyyy',
        altInputFormats: ['d!/M!/yyyy', 'd!M!yyyy'],
        dateOptions: {
          formatYear: 'yy',
          showWeeks: false,
        },
        datePopUp: {
          ContractDate: false,
        },
        getFormattedAddress: () => {
          $timeout.cancel($scope.searchTimeout);
          $scope.searchTimeout = $timeout(() => {
            generalService
              .placeSearch($scope.security.Address.formatted_address)
              .then((response) => {
                $scope.security.addressSuggestions = response.data;
                $scope.security.showSuggestionModal = true;
              })
              .catch(toastError);
          }, 500);
        },

        closeAutoHomeAddress: () => {
          $scope.security.addressSuggestions = [];
        },

        pickAddressSuggestion: (homeAddress) => {
          $scope.security.Address = homeAddress;
          $scope.security.ContactAddress = homeAddress;

          $scope.closeAutoHomeAddress();
          $scope.security.showSuggestionModal = false;
          $scope.saveAddress();
        },

        addNewIdentifier: () => {
          $scope.security.PropertyDetails.IdentifierList.push({
            Security_TitleIdentityId: 0, // TitleTenureType
            IdentityTypeId: '', // TitleIdentificationType
            Value: '',
          });
          elementService.setFocusLastElementWithTimeout('.identification-type');
        },

        removeIdentifier: (theIndex) => {
          _.remove(
            $scope.security.PropertyDetails.IdentifierList,
            (object, key) => {
              return key === theIndex;
            },
          );
        },

        checkIdentifierNumber: () =>
          $scope.security.PropertyDetails.IdentifierList.length > 1,

        saveAddress: (cb) => {
          let callback = cb;
          if (!cb) {
            callback = () => {};
          }
          saveAddress({
            $scope,
            dashboardService,
            addressLookupService,
            callback,
          });
        },

        incomeSettings: {
          showAddIncome: false,
        },

        IncomeList: [],

        addNewIncome: (LoanSecurityInfoID) => {
          addIncome({
            $scope,
            elementService,
            currentUserService,
            LoanSecurityInfoID,
          });
        },

        deleteIncome: (obj, index) => {
          if (action === 'add') {
            $scope.IncomeList.splice(index, 1);
          } else if (obj.Id) {
            swal(
              {
                title: 'Are you sure?',
                text: 'This income will be removed',
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#F68F8F',
                confirmButtonText: 'Yes, remove it!',
                closeOnConfirm: false,
              },
              (confirm) => {
                if (!confirm) {
                  return;
                }
                loanScenarioService
                  .IncomeInfoDelete($scope.loanAppId, obj.Id)
                  .then(({ data }) => {
                    if (!data) {
                      return toastError();
                    }
                    $scope.IncomeList.splice(index, 1);
                    swal({
                      title: 'Success',
                      text: 'Income has been been successfully deleted.',
                      type: 'success',
                      timer: 2000,
                      showConfirmButton: false,
                    });
                  })
                  .catch(toastError);
              },
            );
          } else {
            $scope.IncomeListEditMode.splice(index, 1);
          }
        },

        showSecurityList: () => {
          if (action === 'add') {
            if (
              $scope.LoanSecurityList &&
              !$scope.LoanSecurityList.LoanSecurityId
            ) {
              loanApplicationServices
                .setLoanSecurity({
                  LoanApplicationId: $scope.loanAppId,
                  LoanSecurityId: 0,
                })
                .then(() => {
                  $scope.getLoanSecurity();

                  $scope.securityList = $scope.LoanSecurityList.SecurityList;
                });
            }
          } else {
            const objectToPass = {
              LoanApplicationId: $scope.loanAppId,
              LoanSecurityId: 0,
            };

            if (
              $scope.LoanSecurityList &&
              !$scope.LoanSecurityList.LoanSecurityId
            ) {
              loanApplicationServices.setLoanSecurity(objectToPass).then(() => {
                $scope.getLoanSecurity();
                $scope.securityList = $scope.LoanSecurityList.SecurityList;
              });
            }
          }
        },

        validateSecurityForm: (form, isSaving = false) => {
          $scope.hasError = false;

          const isCustomAddress =
            $scope.security &&
            $scope.security.Address &&
            $scope.security.Address.isCustomAddress;
          const isPostcodeInvalid =
            form && form.postcode && form.postcode.$error.pattern;
          const showPostcodeMessage =
            isSaving && isCustomAddress && isPostcodeInvalid;
          if (showPostcodeMessage) {
            toastError(
              'Please check data entered into Postcode field, only numeric characters are allowed.',
            );
            $scope.security.sending = false;
            return $scope.security.sending;
          }

          _.each($scope.formAreas, (area) => {
            area.error = false;
            return area;
          });

          _.each(form, (field, key) => {
            if (field && key[0] !== '$' && field.$name !== 'addressForm') {
              $scope.formAreas = $scope.validateAddressData(
                $scope.formAreas,
                form,
              );
              if (!field.$valid) {
                if (!field.$viewValue) {
                  field.$setValidity('required', false);
                  $scope.hasError = true;

                  const nameToSearch = field.$name
                    ? field.$name.split('-')[0]
                    : '';
                  _.each($scope.formAreas, (area) => {
                    if (
                      !area.error &&
                      _.findIndex(area.data, (o) => {
                        return o === nameToSearch;
                      }) > -1
                    ) {
                      area.error = true;
                    }
                    return area;
                  });
                } else {
                  field.$setValidity('required', true);
                  $scope.hasError = false;

                  field.$valid = true;
                  field.$invalid = false;
                }
              } else if (field.$name === 'owners') {
                // validate owners 100%
                let countPercentages = 0;
                let checkPercentage = true;
                $scope.ownersError = false;
                $scope.formAreas.info.error = false;
                $scope.security.Ownership.forEach((obj) => {
                  countPercentages += parseFloat(obj.Percentage);
                  if (checkPercentage && countPercentages > 100) {
                    field.$setValidity('required', true);
                    $scope.hasError = true;
                    $scope.ownersError = true;
                    checkPercentage = false;

                    $scope.formAreas.info.error = true;
                  }
                });
              } else {
                field.$setValidity('required', true);
              }

              if (field.$pristine) {
                field.$dirty = true;
              }
            }
          });

          if ($scope.hasError) {
            $scope.security.sending = false;
            return false;
          }
          form.$setValidity('required', true);

          return true;
        },
        proceedSaveSecurity: () => {
          if (assetFinanceIgnore($scope.security.SecurityTypeId)) {
            $scope.security.PropertyDetails.StatusId = 0;
            $scope.security.PropertyDetails.PropertyPrimaryPurposeId = 0;
            $scope.security.PropertyDetails.HoldingId = 0;
            $scope.security.PropertyDetails.ZoningId = 0;
            $scope.security.PropertyDetails.PropertyTypeId = 0;
          }
          const securityData = $scope.security;
          {
            const removeIncomeObject = {
              incomeList: $scope.IncomeList,
              primaryPurposeId:
                $scope.security.PropertyDetails.PropertyPrimaryPurposeId,
              loanAppId: $scope.loanAppId,
            };
            const removeIncomeParam = {
              object: removeIncomeObject,
              isNZ: currentUserService.isNZ,
              incomeInfoDeleteFunction: loanScenarioService.IncomeInfoDelete,
            };
            const isIncomeRemoved = checkIfRemoveIncome(removeIncomeParam);
            $scope.IncomeList = isIncomeRemoved ? [] : $scope.IncomeList;
          }

          const setData = {
            LoanApplicationId: $scope.loanAppId,
            SecurityInfoId: LoanSecurityInfoId,
            FundingCalParametersId: $scope.security.FundingCalParametersId || 0,
            IsPrimary: securityData.IsPrimary,
            SecurityTypeId: parseInt(securityData.SecurityTypeId, 10),
            LoanSecurityId: parseInt(
              $scope.LoanSecurityList.LoanSecurityId,
              10,
            ),
            TransactionId: parseInt(securityData.TransactionId, 10),
            MortgagePriority: securityData.MortgagePriority,
            IsRefinance: securityData.IsRefinance,
            ConstructionTypeId: parseInt(securityData.ConstructionTypeId, 10),
            FutureValue: $scope.isConstructionLoanApps
              ? securityData.FutureValue
              : '',
            BuildAmount: $scope.isConstructionLoanApps
              ? securityData.BuildAmount
              : '',
            PropertyDetails: {
              LoanSecurityId: securityData.PropertyDetails.LoanSecurityId,
              StatusId: parseInt(securityData.PropertyDetails.StatusId, 10),
              PropertyPrimaryPurposeId: parseInt(
                securityData.PropertyDetails.PropertyPrimaryPurposeId,
                10,
              ),
              HoldingId: parseInt(securityData.PropertyDetails.HoldingId, 10),
              ZoningId: parseInt(securityData.PropertyDetails.ZoningId, 10),
              PropertyTypeId: parseInt(
                securityData.PropertyDetails.PropertyTypeId,
                10,
              ),
              Suburb: securityData.PropertyDetails.IsPreApproved
                ? securityData.PropertyDetails.Suburb
                : '',
              country: securityData.PropertyDetails.country,
              PropertyState: securityData.PropertyDetails.IsPreApproved
                ? securityData.PropertyDetails.PropertyState
                : '',
              CurrentValue: $scope.isSaveProperty(securityData)
                ? securityData.PropertyDetails.CurrentValue
                : '',
              LandValue: $scope.isSaveProperty(securityData)
                ? securityData.PropertyDetails.LandValue
                : '',
              EstimatedPrice: securityData.PropertyDetails.IsPreApproved
                ? securityData.PropertyDetails.EstimatedPrice
                : '',
              EstimatedBasesId: parseInt(
                securityData.PropertyDetails.EstimatedBasesId,
                10,
              ),
              ContratctPrice: $scope.isSaveProperty(securityData)
                ? securityData.PropertyDetails.ContratctPrice
                : '',
              ContractDate: securityData.PropertyDetails.ContractDate,
              LicensedRealEstateAgentContract:
                securityData.PropertyDetails.LicensedRealEstateAgentContract,
              IsPreApproved: securityData.PropertyDetails.IsPreApproved,
              IdentifierList: securityData.PropertyDetails.IdentifierList,
              AddressId: !securityData.PropertyDetails.IsPreApproved
                ? securityData.PropertyDetails.AddressId
                : 0,
              TitleTypeId: parseInt(
                securityData.PropertyDetails.TitleTypeId,
                10,
              ), // TitleTenureType
              TitleId: parseInt(securityData.PropertyDetails.TitleId, 10), // TitleType
              ContactForAccess: {
                ...securityData.PropertyDetails.ContactForAccess,
              },
              IsElectronicTransaction:
                securityData.PropertyDetails.IsElectronicTransaction,
            },
            MortgageList: securityData.MortgageList,
            IncomeList: $scope.IncomeList,
            Ownership: securityData.Ownership,
            AssetId: securityData.AssetId || 0,
          };
          loanApplicationServices
            .setLoanSecurityInfo(setData)
            .then(({ data }) => {
              if (!data) {
                return toastError();
              }
              $scope.getLoanSecurity();
              $scope.financialsSharedService.AssetInfoGet();
              $scope.financialsSharedService.LiabilityInfoGet();
              $scope.financialsSharedService.IncomeInfoGet();
              $scope.loanAppSharedData.REFRESH_FUNDS_AVAILABLE_SECTION = {
                isNew: action === 'add',
              };
              $scope.loanAppSharedData.refreshLoanDetailsSet = 1;
              $scope.cancel();
            })
            .catch(toastError);
        },

        validateAddressData: (areas, form) => {
          const formAreas = { ...areas };
          const isAddressOverhaul =
            $scope.isAddressOverhaulEnabled &&
            !$scope.security.PropertyDetails.IsPreApproved;
          const invalidAddress = !!(
            isAddressOverhaul &&
            form &&
            form.addressForm &&
            form.addressForm.$invalid
          );
          Object.keys(formAreas).forEach((key) => {
            const isFormattedAddress = formAreas[key].data.length
              ? formAreas[key].data.findIndex((o) => {
                  return o === 'formatted_address';
                }) > -1
              : false;

            let isAddressEmpty =
              !isAddressOverhaul &&
              $scope.security.Address &&
              !$scope.security.Address.formatted_address &&
              isFormattedAddress &&
              !$scope.security.PropertyDetails.IsPreApproved;

            if (
              !isAddressOverhaul &&
              $scope.security.Address &&
              $scope.security.Address.isCustomAddress
            ) {
              formatCustomAddress($scope.security);
              isAddressEmpty =
                $scope.security.Address &&
                !$scope.security.Address.street_address &&
                !$scope.security.PropertyDetails.IsPreApproved;
              if (!$scope.security.Address.street_address) {
                $scope.security.Address.street_address = '';
              }
            }
            if (
              !formAreas.location.error &&
              (invalidAddress || isAddressEmpty)
            ) {
              formAreas.location.error = true;
              $scope.hasError = true;
            }
            return formAreas[key];
          });

          return formAreas;
        },

        saveSecurity: (formData) => {
          if (!$scope.security || !$scope.security.PropertyDetails) {
            return;
          }
          $scope.security.sending = true;

          if ($scope.validateSecurityForm(formData, $scope.security.sending)) {
            if ($scope.mortgageSettings.showAddMortgage) {
              $scope.saveNewMortgage();
            }

            const validSecurity =
              assetFinanceIgnore($scope.security.SecurityTypeId) ||
              $scope.security.PropertyDetails.IsPreApproved;
            if (validSecurity) {
              $scope.proceedSaveSecurity();
            } else {
              $scope.saveAddress($scope.proceedSaveSecurity);
            }
          }
        },

        checkIfInvestment: () => {
          $scope.isInvestment = checkIfInvestment(
            $scope.security.PropertyDetails.PropertyPrimaryPurposeId,
          );
        },

        checkTransactionType: () => {
          return (
            parseInt($scope.security.TransactionId, 10) !==
            TRANSACTION_TYPE.REFINANCE
          );
        },

        mortgageSettings: {},

        addNewMortgage: () => {
          $scope.OwnershipList = [];
          angular.copy($scope.OwnershipListInit, $scope.OwnershipList);

          $scope.mortgageTemp = {
            LoanApplicationId: $scope.loanAppId,
            LoanSecurityInfoId: 0, // reassign
            SecurityMortgageId: 0,
            edit: 0,
            MortgagePriorityId: '',
            CreditorId: '',
            Amount: '',
            IsRefinance: false,
            PrincipalInterest: 2, // 2:Principal and interest; 1: Interest Only
            InterestTaxDeductible: true,
            LoanStartDate: null,
            InterestOnlyEndDate: null,
            IsRevolvingCredit: false,
            InterestOnlyStartDate: null,
          };
          $scope.mortgageSettings.showAddMortgage = true;
        },

        saveNewMortgage: () => {
          $scope.security.MortgageList.push($scope.mortgageTemp); // add to array
          $scope.mortgageTemp = {}; // reset
          $scope.mortgageSettings.showAddMortgage = false;
        },

        editMortgage: () => {},

        deleteMortgage: (index) => {
          swal(
            {
              title: 'Are you sure?',
              text: 'This mortgage will be removed from your list',
              type: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#F68F8F',
              confirmButtonText: 'Yes, remove it!',
              closeOnConfirm: true,
            },
            (confirm) => {
              if (!confirm) {
                return;
              }
              const mortgage = $scope.security.MortgageList[index];
              const { Id } = mortgage;
              loanApplicationServices
                .deleteLoanSecurityMortgage(
                  $scope.security.LoanApplicationId,
                  Id,
                )
                .then(({ data }) => {
                  if (!data) {
                    return toastError();
                  }
                  $scope.security.MortgageList.splice(index, 1);
                })
                .catch(toastError);
            },
          );
        },
      });

      const getLenderList = () => {
        optionsService.getLenderList(lenderPlace).then((response) => {
          $scope.LenderListList = response.data;
          $scope.LenderListList.unshift({
            ProviderName: 'Please select',
            ProviderId: '',
          });
        });
      };

      $scope.populateCountryListGet = populateCountryList({
        $scope,
        optionsService,
      });

      const getPropertyType = (value) => {
        if ($scope.security.PropertyDetails) {
          optionsService.getPropertyType(value).then((response) => {
            $scope.PropertyTypeList = response.data;
            $scope.PropertyTypeList.unshift({
              AddressPropertyType: 'Please select',
              AddressPropertyTypeID: '',
            });
            if (action === 'add') {
              if (
                value === DEFAULT_VALUES.RESIDENTIAL_ZONINGID &&
                !$scope.security.PropertyDetails.PropertyTypeId
              ) {
                $scope.security.PropertyDetails.PropertyTypeId =
                  DEFAULT_VALUES.FULLY_DETACHED_HOUSE;
              } else {
                $scope.security.PropertyDetails.PropertyTypeId = '';
              }
            } else {
              const searchPropertyTypeId = _.find(
                $scope.PropertyTypeList,
                (o) =>
                  parseInt(o.AddressPropertyTypeID, 10) ===
                  parseInt(
                    $scope.security.PropertyDetails.PropertyTypeId_original,
                    10,
                  ),
              );

              if (!searchPropertyTypeId) {
                $scope.security.PropertyDetails.PropertyTypeId = '';
              } else {
                $scope.security.PropertyDetails.PropertyTypeId =
                  $scope.security.PropertyDetails &&
                  $scope.security.PropertyDetails.PropertyTypeId_original;
              }
            }
          });
        }
      };

      const listeners = {
        transactionId: () => {
          $scope.$watch('security.TransactionId', (nv) => {
            $scope.security.IsRefinance =
              parseInt(nv, 10) === TRANSACTION_TYPE.REFINANCE;
            $scope.setExistingMortgagesVisibility();
          });
        },
        incomeList: () => {
          $scope.$watch(
            'IncomeList',
            (nv) => {
              $scope.incomeSettings.showAddIncome = _.size(nv) > 0;

              if ($scope.securityForm && $scope.securityForm.$submitted) {
                $scope.validateSecurityForm($scope.securityForm);
              }
            },
            true,
          );
        },
        zoningId: () => {
          $scope.$watch('security.PropertyDetails.ZoningId', (nv) => {
            if (nv === '') {
              return;
            }
            getPropertyType(nv);
          });
        },
        security: () => {
          $scope.$watch(
            'security',
            () => {
              if ($scope.securityForm && $scope.securityForm.$submitted) {
                $scope.validateSecurityForm($scope.securityForm);
              }
            },
            true,
          );
        },
      };

      if (action === 'add') {
        $scope.getApplicants(true);
        $scope.loadSecurityModel();
        $scope.setExistingMortgagesVisibility();
      } else {
        const loadSecurity = () => {
          loanApplicationServices
            .getLoanSecurityInfo($scope.loanAppId, LoanSecurityInfoId)
            .then((response) => {
              const { data: security } = response;

              if (security.PropertyDetails.IdentifierList) {
                security.PropertyDetails.IdentifierList = security.PropertyDetails.IdentifierList.map(
                  (type) => {
                    return {
                      ...type,
                      IdentityTypeId:
                        type.IdentityTypeId &&
                        parseInt(type.IdentityTypeId, 10),
                    };
                  },
                );
              }
              $scope.setExistingMortgagesVisibility();
              if (security.MortgageList) {
                security.MortgageList = security.MortgageList.map((o) => {
                  o.CreditorId = o.CreditorId ? o.CreditorId.toString() : '';
                  o.FinancialId = o.FinancialId ? o.FinancialId.toString() : '';
                  o.InterestRate = Number(o.InterestRate);
                  o.InterestRateFrequencyId = o.InterestRateFrequencyId
                    ? o.InterestRateFrequencyId.toString()
                    : '';
                  o.MortgagePriorityId = o.MortgagePriorityId || '';
                  return {
                    ...o,
                    ...liabilityDatesMapper(o),
                  };
                });
              }

              if (security.IncomeList) {
                security.IncomeList.forEach((obj) => {
                  obj.IncomeSubTypeId = obj.IncomeSubTypeId
                    ? String(obj.IncomeSubTypeId)
                    : null;
                  return obj;
                });
              }

              if (security.IncomeList && _.size(security.IncomeList) > 0) {
                $scope.IncomeList = angular.copy(security.IncomeList);
                $scope.incomeSettings.showAddIncome = true;
              } else {
                $scope.IncomeList = [];
              }

              const securityData = {
                TransactionTypeList: security.TransactionTypeList,
                refinanceType: _.find(
                  security.TransactionTypeList,
                  (o) => o.Name === 'Refinance',
                ),
                LoanApplicationId: $scope.loanAppId,
                IsPrimary: security.IsPrimary,
                IsREA: security.IsREA,
                SecurityTypeId: parseInt(security.SecurityTypeId, 10), // SecurityType
                FundingCalParametersId: security.FundingCalParametersId,
                TransactionId: parseInt(security.TransactionId, 10), // TransactionType
                LoanSecurityId: LoanSecurityInfoId,
                ConstructionTypeId: parseInt(security.ConstructionTypeId, 10),
                FutureValue: security.FutureValue,
                BuildAmount: security.BuildAmount,
                PropertyDetails: {
                  StatusId: security.PropertyDetails.StatusId || '', // StatusType
                  PropertyPrimaryPurposeId:
                    security.PropertyDetails.PropertyPrimaryPurposeId || '', // PrimaryPurposeType
                  HoldingId: security.PropertyDetails.HoldingId || '', // HoldingType
                  ZoningId: security.PropertyDetails.ZoningId || '', // ZoningType
                  PropertyTypeId: security.PropertyDetails.PropertyTypeId || '', // PropertyType
                  PropertyTypeId_original:
                    security.PropertyDetails.PropertyTypeId || '', // PropertyType
                  CurrentValue: $scope.isSaveProperty(security)
                    ? security.PropertyDetails.CurrentValue
                    : '',
                  LandValue: security.PropertyDetails.LandValue,
                  EstimatedPrice: security.PropertyDetails.EstimatedPrice,
                  EstimatedBasesId: parseInt(
                    security.PropertyDetails.EstimatedBasesId,
                    10,
                  ), // AssetValueEstimatedBasis
                  ContratctPrice: security.PropertyDetails.ContratctPrice,
                  ContractDate: Object.prototype.hasOwnProperty.call(
                    security.PropertyDetails,
                    'ContractDate',
                  )
                    ? new Date(security.PropertyDetails.ContractDate)
                    : '',
                  LicensedRealEstateAgentContract:
                    security.PropertyDetails.LicensedRealEstateAgentContract,
                  IsPreApproved: security.PropertyDetails.IsPreApproved,
                  AddressId: security.PropertyDetails.AddressId || 0,
                  TitleTypeId: parseInt(
                    security.PropertyDetails.TitleTypeId,
                    10,
                  ), // TitleTenureType
                  TitleId: parseInt(security.PropertyDetails.TitleId, 10), // TitleType
                  IdentifierList: security.PropertyDetails.IdentifierList,
                  SecurityInfoId: security.SecurityInfoId, // go back
                  Suburb: security.PropertyDetails.Suburb,
                  country: security.PropertyDetails.Country,
                  PropertyState: security.PropertyDetails.PropertyState,
                  ContactForAccess: {
                    ...security.PropertyDetails.ContactForAccess,
                  },
                  IsElectronicTransaction:
                    security.PropertyDetails.IsElectronicTransaction,
                },
                Ownership: security.Ownership,
                MortgageList: security.MortgageList,
                AssetId: security.AssetId,
                titles: clientTitles(),
                PledgeAmount: security.PledgeAmount || 0,
                PledgePercentage: security.PledgePercentage || 0,
              };
              $scope.security = { ...securityData };
              $scope.checkIfInvestment();

              // will fallback for invalid date
              if (
                String($scope.security.PropertyDetails.ContractDate) ===
                'Invalid Date'
              ) {
                $scope.security.PropertyDetails.ContractDate = new Date();
              }

              const collectionBorrowerID = [];

              // This is to present the ownership being selected on Edit
              $scope.security.Ownership.forEach((obj) => {
                collectionBorrowerID.push(obj.BorrowerID);
              });

              $scope.scenarioApplicantList = _.map(
                $scope.scenarioApplicantList,
                (obj) => {
                  if (collectionBorrowerID.includes(obj.BorrowerID)) {
                    obj.IsTicked = true;
                    obj.ticked = true;
                    const findOwner = $scope.security.Ownership.find(
                      (owner) => owner.BorrowerID === obj.BorrowerID,
                    );
                    obj.Percentage = findOwner
                      ? findOwner.Percentage
                      : obj.Percentage;
                  } else {
                    obj.Percentage = 0;
                    obj.IsTicked = false;
                    obj.ticked = false;
                  }
                  return obj;
                },
              );
              // Get the address details for the first instance
              if ($scope.security.PropertyDetails.AddressId) {
                dashboardService
                  .addressDetailsGet($scope.security.PropertyDetails.AddressId)
                  .then(({ data }) => {
                    if ($scope.isAddressOverhaulEnabled) {
                      $scope.security.Address = {
                        ...addressLookup(data),
                        displayDetails: true,
                      };
                      $scope.isExistingAddress = true;
                    } else {
                      $scope.security.Address = data;
                    }
                    $scope.security.Address.isEditSecurityInfo = false;
                  });
              }
            });
        };

        $scope.getApplicants().then(() => {
          loadSecurity();
        });

        $scope.OwnershipList = [];
        angular.copy($scope.OwnershipListInit, $scope.OwnershipList);
      }

      $scope.handleUpdateAddress = ({ address }) => {
        $scope.security.Address = address;
      };
      const init = () => {
        getLenderList();
        $scope.populateCountryListGet(countryListBuilderForUI);
        listeners.transactionId();
        listeners.incomeList();
        listeners.security();
        listeners.zoningId();
        $scope.getOwners();
        $scope.getEmptySecurityInfo();
        $scope.getSecurityOptions();
      };
      init();
      $scope.isSearchAgainConfirmationOpen = false;
      $scope.toggleSearchAgainPopover = () => {
        $scope.isSearchAgainConfirmationOpen = !$scope.isSearchAgainConfirmationOpen;
      };
      $scope.onClearAddress = () => {
        $scope.isExistingAddress = false;
        _.set($scope, 'security.Address.displayDetails', false);
        _.set($scope, 'security.Address.searchString', '');
      };
    });
