import { textLimit } from 'Common/utilities/string';

export const appendAllOption = (multiselectObj, ticked = true) => {
  const allOption = { name: 'ALL', shortName: 'ALL', ticked, value: 0 };
  const validMultiselectObj = multiselectObj && multiselectObj.length;
  return validMultiselectObj ? [allOption, ...multiselectObj] : [allOption];
};

export const appendClearOption = (multiselectObj, text = 'PLEASE SELECT') => {
  const clearOpt = { name: text, shortName: text, ticked: false, value: null };
  const validMultiselectObj = multiselectObj && multiselectObj.length;
  return validMultiselectObj ? [clearOpt, ...multiselectObj] : [clearOpt];
};

export const defaultMultiselectItemValues = (name, value) => {
  const MULTISELECT_CHAR_LIMIT = 22;
  return {
    name,
    shortName: textLimit(name, MULTISELECT_CHAR_LIMIT, true),
    value,
    ticked: false,
  };
};

export const tickAllOptions = (list, tick = true) => {
  if (!list || !list.length) {
    return [];
  }
  return list.map((option) => ({ ...option, ticked: tick }));
};

export const getArrayOfValues = (list) => {
  if (!list || !list.length) {
    return [];
  }
  const values = list.map((option) => {
    return option.value;
  });
  return values.filter((value) => typeof value !== 'undefined');
};

export const tickSavedOptions = (optionsList, savedValuesList) => {
  const invalidOptionsList = !optionsList || !optionsList.length;
  if (invalidOptionsList) {
    return [];
  }

  const savedList =
    typeof savedValuesList === 'string'
      ? savedValuesList.split(',').map((val) => Number(val))
      : savedValuesList;

  const invalidSavedList = !savedList || !savedList.length;
  const isAllSaved = !invalidSavedList && savedList.includes(0);
  if (invalidSavedList || isAllSaved) {
    optionsList[0].ticked = true;
    return optionsList;
  }

  return optionsList.map((option) => {
    option.ticked = !!savedList.includes(option.value);
    return option;
  });
};

export const getTickValues = (selection) => (propName, allValue) => {
  const invalidArgs = !selection || !selection.length || !propName;
  if (invalidArgs) {
    return [];
  }

  return selection.reduce((accum, item) => {
    if (item[propName] !== allValue) {
      accum.push(item[propName]);
    }
    return accum;
  }, []);
};

export const isMultiSelectDefault = (data = {}, fieldName) => {
  const defaultValues = [0, -1];
  return !!defaultValues.includes(data[fieldName]);
};

export const invalidHeaderSelection = (data, fieldName, selection = []) => {
  const hasDefaultSelection = !!selection.find((item) =>
    isMultiSelectDefault(item, fieldName),
  );
  const onlyDefaultSelection = selection.length === 1 && hasDefaultSelection;
  return !data[fieldName] && onlyDefaultSelection;
};

export const getAllTicked = (list = [], checkKey = 'ticked') => {
  return list.filter((item) => item[checkKey]);
};

export const getSelectedValues = (list = [], key = 'value') => {
  const selectedList = getAllTicked(list);
  return selectedList.map((item) => item[key]);
};
