import {
  generalBuilderForUI,
  notesBuilderForUI,
  notesBuilderForMyCRM,
  calculationsBuilderForUI,
  calculationsBuilderForMyCRM,
  totalLoanAmountsBuilderForUI,
  shareReferrerNotesViaSmsBuilderForMyCRM,
  shareReferrerNotesViaEmailBuilderForMyCRM,
  communicationBuilderForUI,
  opportunityServiceabilityIncomeBuilderForMyCRM,
  opportunityServiceabilityIncomeBuilderForUI,
  opportunityServiceabilityLiabilityBuilderForUI,
  opportunityServiceabilityLiabilityBuilderForMyCRM,
  opportunityServiceabilityFinancialsBuilderForUI,
  opportunityServiceabilityBuilderForMyCRM,
  opportunityServiceabilityBuilderForUI,
  financialClientBuilderForMyCRM,
  loanBuilderForUI,
} from 'Common/mappers/loanOpportunity';

class LoanOpportunityService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.baseUrl = 'loan-opportunity';
  }

  setLoanOpportunity(data) {
    return this.httpClient.post(`${this.baseUrl}`, data);
  }

  getLoanOpportunityGeneral(id) {
    return this.httpClient
      .get(`${this.baseUrl}/${id}/general-info`)
      .then((response) => response.data && generalBuilderForUI(response.data));
  }

  setLoanOpportunityGeneral(id, data) {
    return this.httpClient
      .put(`${this.baseUrl}/${id}/general-info`, data)
      .then((response) => generalBuilderForUI(response.data));
  }

  getLoanOpportunityDetails(id) {
    return this.httpClient.get(`${this.baseUrl}/${id}/details`);
  }

  setInvolvedParties(id, data) {
    return this.httpClient.put(`${this.baseUrl}/${id}/involved-parties`, data);
  }

  setLoanOpportunitySummary(id, data) {
    return this.httpClient.put(`${this.baseUrl}/${id}/summary`, data);
  }

  setLoanOpportunityStatus(loanId, pipelineStatusId) {
    return this.httpClient.put(
      `${this.baseUrl}/loan/${loanId}/status/${pipelineStatusId}`,
    );
  }

  getLoanOpportunityNotes(id, params = {}) {
    return this.httpClient
      .get(`${this.baseUrl}/loan/${id}/notes`, params)
      .then((response) => response.data.map(notesBuilderForUI));
  }

  setLoanOpportunityNote(data) {
    return this.httpClient
      .post(`${this.baseUrl}/notes`, notesBuilderForMyCRM(data))
      .then((response) => notesBuilderForUI(response.data));
  }

  updateLoanOpportunityNote(data, communicationId) {
    return this.httpClient
      .put(
        `${this.baseUrl}/notes/${communicationId}`,
        notesBuilderForMyCRM(data),
      )
      .then((response) => notesBuilderForUI(response.data));
  }

  deleteLoanOpportunityNote(communicationId) {
    return this.httpClient.delete(`${this.baseUrl}/notes/${communicationId}`);
  }

  setLoanOpportunityCalculation(opportunityId, data) {
    return this.httpClient.put(
      `${this.baseUrl}/${opportunityId}/calculations`,
      calculationsBuilderForMyCRM(data),
    );
  }

  getLoanOpportunityCalculation(opportunityId) {
    return this.httpClient
      .get(`${this.baseUrl}/${opportunityId}/calculations`)
      .then((response) => response.data.map(calculationsBuilderForUI));
  }

  getLoanOpportunityTotalLoanAmounts() {
    return this.httpClient
      .get(`${this.baseUrl}/status/total-amounts`)
      .then((response) => response.data.map(totalLoanAmountsBuilderForUI));
  }

  setConversion(id, conversionStatus) {
    return this.httpClient.put(
      `${this.baseUrl}/${id}/conversion-status`,
      conversionStatus,
    );
  }

  getReasonSubstatus() {
    return this.httpClient.get(`${this.baseUrl}/reason-substatus`, {}, true);
  }

  shareReferrerViaEmail(loanId, emailObj) {
    return this.httpClient
      .post(
        `${this.baseUrl}/notes/${loanId}/email`,
        shareReferrerNotesViaEmailBuilderForMyCRM(emailObj),
      )
      .then((response) => response.data);
  }

  shareReferrerViaSms(loanId, smsObj) {
    return this.httpClient
      .post(
        `${this.baseUrl}/notes/${loanId}/sms`,
        shareReferrerNotesViaSmsBuilderForMyCRM(smsObj),
      )
      .then((response) => response.data);
  }

  sendEmail(loanId, emailObj) {
    return this.httpClient.post(`${this.baseUrl}/${loanId}/email`, emailObj);
  }

  sendSms(loanId, smsObj) {
    return this.httpClient.post(`${this.baseUrl}/${loanId}/sms`, smsObj);
  }

  getCommunicationsList(loanId, params) {
    return this.httpClient
      .get(`${this.baseUrl}/${loanId}/communications`, params)
      .then((response) => communicationBuilderForUI(response.data));
  }

  getFinancials(opportunityId) {
    return this.httpClient
      .get(`${this.baseUrl}/${opportunityId}/financials`)
      .then(
        ({ data }) =>
          data && opportunityServiceabilityFinancialsBuilderForUI(data),
      );
  }

  saveServiceabilityFinancials(opportunityId, brokerEventId, model) {
    return this.httpClient
      .post(
        `${this.baseUrl}/${opportunityId}/serviceability/${brokerEventId}/financials`,
        opportunityServiceabilityBuilderForMyCRM(model),
      )
      .then(({ data }) => data && opportunityServiceabilityBuilderForUI(data));
  }

  deleteFinancials(opportunityId, data) {
    return this.httpClient.delete(
      `${this.baseUrl}/${opportunityId}/financial`,
      financialClientBuilderForMyCRM(data),
    );
  }

  addFamilyLiability(opportunityId, familyId, model) {
    return this.httpClient
      .post(
        `${this.baseUrl}/${opportunityId}/liabilities/family/${familyId}`,
        opportunityServiceabilityLiabilityBuilderForMyCRM(model),
      )
      .then(
        ({ data }) =>
          data && opportunityServiceabilityLiabilityBuilderForUI(data),
      );
  }

  updateFamilyLiability(opportunityId, opportunityLiabilityId, model) {
    return this.httpClient
      .put(
        `${this.baseUrl}/${opportunityId}/liabilities/${opportunityLiabilityId}`,
        opportunityServiceabilityLiabilityBuilderForMyCRM(model),
      )
      .then(
        ({ data }) =>
          data && opportunityServiceabilityLiabilityBuilderForUI(data),
      );
  }

  addClientIncome(opportunityId, familyId, model) {
    return this.httpClient
      .post(
        `${this.baseUrl}/${opportunityId}/income/client`,
        opportunityServiceabilityIncomeBuilderForMyCRM(model),
      )
      .then(
        ({ data }) => data && opportunityServiceabilityIncomeBuilderForUI(data),
      );
  }

  updateClientIncome(opportunityId, incomeId, model) {
    return this.httpClient
      .put(
        `${this.baseUrl}/${opportunityId}/income/${incomeId}`,
        opportunityServiceabilityIncomeBuilderForMyCRM(model),
      )
      .then(
        ({ data }) => data && opportunityServiceabilityIncomeBuilderForUI(data),
      );
  }

  // handles conversion and pipeline status updates
  moveLoanPipelineItem(payload) {
    return this.httpClient.post(`pipeline-mergedlead/move-card`, payload);
  }

  getNotProceededReasons() {
    return this.httpClient.get(
      `pipeline-mergedlead/not-proceed-with-reasons`,
      {},
    );
  }

  getLoanList(familyId) {
    return this.httpClient
      .get(`/pipeline-mergedlead/loan-list/${familyId}`)
      .then(({ data }) => data.map(loanBuilderForUI) || []);
  }
}

export default LoanOpportunityService;
