export const ACCREDITATION = {
  TAB_TYPES: {
    LENDER: 1,
    INSURER: 2,
    REFERRER: 3,
    PRODUCTS: 4,
    RESIDENTIAL: 5,
    ASSET: 6,
    COMMERCIAL: 7,
    DEPOSIT_BONDS: 8,
    PERSONAL_LOANS: 9,
    SMALL_BUSINESS_LENDING: 10,
  },
  TABS: [
    { typeId: 1, label: 'Lender', type: 'LENDER', isActive: true },
    { typeId: 2, label: 'Insurer', type: 'INSURER', isActive: false },
    { typeId: 3, label: 'Referrer', type: 'REFERRER', isActive: false },
    { typeId: 4, label: 'Products', type: 'PRODUCTS', isActive: false },
    { typeId: 5, label: 'Residential', type: 'RESIDENTIAL', isActive: true },
    { typeId: 6, label: 'Asset', type: 'ASSET', isActive: false },
    { typeId: 7, label: 'Commercial', type: 'COMMERCIAL', isActive: false },
    {
      typeId: 8,
      label: 'Deposit Bonds',
      type: 'DEPOSIT_BONDS',
      isActive: false,
    },
    {
      typeId: 9,
      label: 'Personal Loans',
      type: 'PERSONAL_LOANS',
      isActive: false,
    },
    {
      typeId: 10,
      label: 'Small Business Lending',
      type: 'SMALL_BUSINESS_LENDING',
      isActive: false,
    },
  ],
};
