import get from 'lodash/get';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';

export const emailValidation = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z\-]+\.)+[A-Za-z]{2,}))$/;
  return re.test(email);
};

export const getDefaultEmailFromDetail = (detail = {}) => {
  const emails = get(detail, 'Email', []);
  const { EmailAddress = '' } =
    emails.find((email) => email.Type === EMAIL_CTYPES.CRM_EMAIL) ||
    get(emails, '[0]', {});
  return EmailAddress;
};
