import angular from 'angular';

class AnnouncementModalCtrl {
  constructor($uibModalInstance, featuresList) {
    'ngInject';

    this.modalInstance = $uibModalInstance;
    this.featuresList = featuresList;
  }
}

export const loadModule = () =>
  angular
    .module('app')
    .controller('AnnouncementModalCtrl', AnnouncementModalCtrl);
