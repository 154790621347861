import {
  loadingExclusionListBuilderForUI,
  issuedFollowupListBuilderForUI,
  inArrearsListBuilderForUI,
  currentClaimListBuilderForUI,
  deferralReviewListBuilderForUI,
  sendMailDocBuilderForUI,
  annualReviewsListBuilderForUI,
} from 'Common/mappers/customerCare';
import { INSURANCE_TABS } from 'Common/constants/customerCareModules';

class CustomerCareInsuranceService {
  constructor($q, httpClient) {
    'ngInject';

    this.$q = $q;
    this.httpClient = httpClient;
    this.apiBaseUrl = 'insurance-customercare';
    this.loanCustomerCare = 'customercare';
  }

  getLoadingExclusionList(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/loading-exclusion-list`, params)
      .then(
        ({ data }) =>
          (data && data.map(loadingExclusionListBuilderForUI)) || [],
      );
  }

  getIssuedFollowupList(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/issued-followup-list`, params)
      .then(
        ({ data }) => (data && data.map(issuedFollowupListBuilderForUI)) || [],
      );
  }

  getinArrearsList(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/in-arrears-list`, params)
      .then(({ data }) => (data && data.map(inArrearsListBuilderForUI)) || []);
  }

  getCurrentClaimList(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/current-claim-list`, params)
      .then(
        ({ data }) => (data && data.map(currentClaimListBuilderForUI)) || [],
      );
  }

  getDeferralList(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/deferral-list`, params)
      .then(
        ({ data }) => (data && data.map(deferralReviewListBuilderForUI)) || [],
      );
  }

  getAnnaulReviews(params) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/annual-review`, params)
      .then(
        ({ data }) => (data && data.map(annualReviewsListBuilderForUI)) || [],
      );
  }

  postCompletion(params) {
    return this.httpClient.post(`${this.apiBaseUrl}/completion`, params);
  }

  sendEmail(data, params = {}) {
    return this.httpClient.post(`${this.apiBaseUrl}/send-email`, data, params);
  }

  sendSms(data, params = {}) {
    return this.httpClient.post(`${this.apiBaseUrl}/send-sms`, data, params);
  }

  sendMail(data, params = {}) {
    return this.httpClient
      .post(`${this.apiBaseUrl}/send-mail`, data, params)
      .then((response) => sendMailDocBuilderForUI(response.data));
  }

  postCompletionIssuedfollowup(params) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/completion-issuedfollowup`,
      params,
    );
  }

  getCustomerCareInsuranceTabs() {
    return this.$q.resolve(INSURANCE_TABS);
  }

  isAnnualReviewLoanAllowed() {
    return this.httpClient.get(
      `${this.loanCustomerCare}/is-annualreview-allowed`,
    );
  }

  getAnnualDateRangeFilter() {
    return this.$q.resolve([
      {
        id: 0,
        title: 'Previous month',
        type: 'Previous',
        value: 1,
      },
      {
        id: 1,
        title: 'Current month',
        type: 'Current',
        value: 0,
      },
      {
        id: 2,
        title: 'Next 2 months',
        type: 'Next',
        value: 2,
      },
      {
        id: 3,
        title: 'Next 3 months',
        type: 'Next',
        value: 3,
      },
      {
        id: 4,
        title: 'Show all',
        type: 'All',
        value: 4,
      },
    ]);
  }

  getDateRangeFilter() {
    return this.$q.resolve([
      {
        id: 0,
        title: 'This month',
        type: 'Next',
        value: 0,
      },
      {
        id: 1,
        title: 'Last month',
        type: 'Previous',
        value: 1,
      },
      {
        id: 2,
        title: '2 months ago',
        type: 'Previous',
        value: 2,
      },
      {
        id: 3,
        title: '3 months ago',
        type: 'Previous',
        value: 3,
      },
      {
        id: 4,
        title: '4 months ago',
        type: 'Previous',
        value: 4,
      },
      {
        id: 5,
        title: '5 months ago',
        type: 'Previous',
        value: 5,
      },
      {
        id: 6,
        title: '6 months ago',
        type: 'Previous',
        value: 6,
      },
      {
        id: 7,
        title: '7 months ago',
        type: 'Previous',
        value: 7,
      },
      {
        id: 8,
        title: '8 months ago',
        type: 'Previous',
        value: 8,
      },
      {
        id: 9,
        title: '9 months ago',
        type: 'Previous',
        value: 9,
      },
      {
        id: 10,
        title: '10 months ago',
        type: 'Previous',
        value: 10,
      },
      {
        id: 11,
        title: '11 months ago',
        type: 'Previous',
        value: 11,
      },
      {
        id: 12,
        title: '12 months ago',
        type: 'Previous',
        value: 12,
      },
    ]);
  }

  isAnnualReviewAllowed() {
    return this.httpClient.get(`${this.apiBaseUrl}/is-annualreview-allowed`);
  }
}

export default CustomerCareInsuranceService;
