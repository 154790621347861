import template from './loanFacilityFees.html';
import './style.scss';

export default {
  template,
  controllerAs: 'vm',
  bindings: {
    feesList: '<',
    facility: '<',
    onFeeChanges: '&',
    onToggleFee: '&',
  },
};
