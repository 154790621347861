import template from './helloPackViewer.html';
import controller from './helloPackViewerCtrl';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    familyId: '<',
    activePackMode: '<',
    model: '<',
    adviserInfo: '<',
    onLoadTheme: '&',
    packMode: '<?',
  },
};
