import angular from 'angular';
import _ from 'lodash';
import moment from 'moment';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import { REQUESTER } from 'Common/constants/addNewContactRequesters';
import {
  ENTITY_PARTY_TYPES,
  PERSON_PARTY_TYPES,
} from 'Common/constants/partyTypes';
import {
  setAssistantsList,
  setAllocatedIds,
  onAdviserAutocompleteSelect,
  onAdviserSelect,
  setAllocatedAdviser,
  getAssistantId,
} from 'Common/utilities/allocation';
import { toastError } from 'Common/utilities/alert';
import {
  setFamilyDetails,
  completeSave,
  setDependantParent,
} from 'Assets/js/controllers/contacts/client/util/personCompanyModalCtrl.js';
import { parseToInt10 } from 'Common/utilities/parse';

export const loadModule = () =>
  angular.module('app').controller(
    'AddCompanyRelatedModalCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function AddCompanyRelatedModalCtrl(
      $scope,
      $uibModalInstance,
      utilitiesService,
      toaster,
      contactService,
      selectedPartyType,
      guardianList,
      person,
      generalService,
      $timeout,
      $stateParams,
      requester,
      applicationSharedService,
      $rootScope,
      partyTypeService,
      genderService,
      contactSharedDataService,
      corporateService,
      currentUserService,
      optionsService,
      configService,
      dependantModelService,
    ) {
      const {
        dependentUplift,
        dependentsWithOneClientAsParent,
      } = configService.feature;
      $scope.completeSave = completeSave({ $scope, $uibModalInstance });
      $scope.Settings = {
        isEdit: false,
      };
      $scope.ContactSet = {};
      $scope.showParentDropdown =
        PERSON_PARTY_TYPES.DEPENDENT === parseToInt10(selectedPartyType) &&
        dependentUplift &&
        dependentsWithOneClientAsParent;
      $scope.guardianList = guardianList;
      const currentSelectedParentId = _.get(person, 'ParentGuardianId');
      $scope.person = person;
      $scope.defaultTypeToCompany = requester === REQUESTER.NEW_LOAN_APP_MODAL;
      $scope.modalObj = {
        isCorporate: false,
        selectedAdviser: {},
      };
      $scope.ENTITY_PARTY_TYPES = ENTITY_PARTY_TYPES;
      const adviserAccessList = [
        ACCESS_TYPE.PRINCIPAL_ADVISER,
        ACCESS_TYPE.ADVISER,
      ];
      $scope.assistantsList = [];
      $scope.getAssistantsList = setAssistantsList({
        $scope,
        optionsService,
      });
      $scope.onAdviserAutocompleteSelect = onAdviserAutocompleteSelect({
        $scope,
      });
      $scope.onAdviserSelect = onAdviserSelect({ $scope });

      const getAdviserList = () => {
        const userFamilyId = $scope.userInfo.FamilyId || 0;
        $scope.adviserList = [];
        const isLoanWritersOnly =
          currentUserService.isAssistant || currentUserService.isAdminAssistant;

        corporateService
          .getAdvisersDropdown(userFamilyId, 0, 0, isLoanWritersOnly)
          .then((response) => {
            if (!response.data || !response.data.length) {
              return;
            }
            $scope.adviserList = response.data;
            if (
              adviserAccessList.includes($scope.userInfo.AccessType) &&
              !$scope.ContactSet.AllocatedAdviserID
            ) {
              $scope.ContactSet.AllocatedAdviserID = $scope.userInfo.FamilyId;
              $scope.getAssistantsList($scope.ContactSet.AllocatedAdviserID);
            }
          });
      };
      contactService.getUserInfo().then((response) => {
        $scope.accessType = response.data.AccessType;
        $scope.modalObj.isCorporate =
          $scope.accessType === ACCESS_TYPE.CORPORATE;
        $scope.userInfo = response.data;
        getAdviserList();
      });

      const allocatedAdviser = (obj) => {
        $scope.getAssistantsList(obj.AllocatedAdviserID);
        setAllocatedAdviser({ $scope })(obj);
      };
      // Address
      $scope.addressObject = {};
      $scope.addressObject.getFormattedAddress = () => {
        $timeout.cancel($scope.searchTimeout);
        $scope.searchTimeout = $timeout(() => {
          generalService
            .placeSearch($scope.ContactSet.Address[0].formatted_address)
            .then((response) => {
              $scope.addressObject.addressSuggestions = response.data;
              $scope.addressObject.showSuggestionModal = true;
            });
        }, 500);
      };

      $scope.addressObject.closeAutoHomeAddress = () => {
        $scope.addressObject.addressSuggestions = [];
      };

      $scope.addressObject.pickAddressSuggestion = (homeAddress) => {
        const validContactSetAddress =
          $scope.ContactSet &&
          $scope.ContactSet.Address &&
          $scope.ContactSet.Address.length;
        const hasAddressValuationExtendedDetailsId =
          $scope.ContactSet.Address &&
          $scope.ContactSet.Address[0] &&
          $scope.ContactSet.Address[0].AddressValuationExtendedDetailsId;
        const tempAddressValuationExtendedDetailsId =
          hasAddressValuationExtendedDetailsId || 0;

        $scope.ContactSet.Address = validContactSetAddress
          ? [...$scope.ContactSet.Address]
          : [{}];

        $scope.ContactSet.Address[0] = homeAddress;
        $scope.ContactSet.Address[0].AddressValuationExtendedDetailsId = tempAddressValuationExtendedDetailsId;
        $scope.ContactSet.Address[0].Type = 'Home';

        $scope.addressObject.closeAutoHomeAddress();
        $scope.addressObject.showSuggestionModal = false;
      };

      if (person && person.PersonId) {
        contactService
          .clientInformGet(person.FamilyId, person.PersonId)
          .then((response) => {
            $scope.ContactSet = $scope.getNewContactModel('Adult');
            angular.extend($scope.ContactSet, response.data[0]);
            $scope.ContactSet.AllocatedAssistantID = getAssistantId(
              $scope.ContactSet,
            );
            if (_.size($scope.ContactSet.Phone) > 0) {
              const mobileNumber = _.find($scope.ContactSet.Phone, (obj) => {
                return obj.Type === 'Mobile';
              }) || { Type: 'Mobile', Number: '' };
              $scope.ContactSet.MobileNumber = mobileNumber.Number;

              const faxNumber = _.find($scope.ContactSet.Phone, (obj) => {
                return obj.Type === 'Fax';
              }) || { Type: 'Fax', Number: '' };
              $scope.ContactSet.FaxNumber = faxNumber.Number;

              const officeNumber = _.find($scope.ContactSet.Phone, (obj) => {
                return obj.Type === 'Work';
              }) || { Type: 'Work', Number: '' };
              $scope.ContactSet.OfficeNumber = officeNumber.Number;
            }

            if (_.size($scope.ContactSet.Email) > 0) {
              $scope.ContactSet.EmailAddress =
                $scope.ContactSet.Email[0].EmailAddress;
            }

            if (_.isUndefined($scope.ContactSet.IsResident)) {
              $scope.ContactSet.IsResident = false;
            }
            // find country codes
            let CitizenshipObj = _.find($scope.countryList, (o) => {
              return o.CountryID === $scope.ContactSet.CitizenshipId;
            });

            let CountryOfResidencyObj = _.find($scope.countryList, (o) => {
              return o.CountryID === $scope.ContactSet.CountryOfResidencyId;
            });
            let CountryOfBirthObj = _.find($scope.countryList, (o) => {
              return o.CountryID === $scope.ContactSet.CountryOfBirthId;
            });
            if (!CitizenshipObj) {
              CitizenshipObj = {
                CountryCode: '',
              };
            }
            if (!CountryOfResidencyObj) {
              CountryOfResidencyObj = {
                CountryCode: '',
              };
            }
            if (!CountryOfBirthObj) {
              CountryOfBirthObj = {
                CountryCode: '',
              };
            }

            angular.extend($scope.ContactSet, {
              FamilyId: person.FamilyId,
              CountryCode: CitizenshipObj.CountryCode,
              ResidencyCountryCode: CountryOfResidencyObj.CountryCode,
              CountryOfBirth: CountryOfBirthObj.CountryCode,
              ResidencyId: String($scope.ContactSet.ResidencyId),
              MaritalStatusId: String($scope.ContactSet.MaritalStatusId),
              DOBGet: moment($scope.ContactSet.DOB).format('DD/MM/YYYY'),
              PartyType: selectedPartyType,
            });
            if ($scope.showParentDropdown) {
              $scope.ContactSet.ParentGuardianId =
                currentSelectedParentId ||
                _.get($scope.guardianList, '[0].PersonId', null);
            }
            allocatedAdviser($scope.ContactSet);
          });
        $scope.Settings.isEdit = true;
      } else if (person && person.OrganisationId) {
        contactService.organisationInfoGet(person.FamilyId).then((response) => {
          $scope.BusinessSet = response.data;
          $scope.BusinessSet.OrganisationId = person.OrganisationId;
          $scope.BusinessSet.FamilyId = person.FamilyId;
          const { formatted_address: formattedAddress } =
            $scope.BusinessSet.Address[0] || {};

          // fix added: address uses array on its data, but ng-model text was set already just as plain object and used on other functions
          $scope.BusinessSet.Address.formatted_address = formattedAddress;

          if (_.size($scope.BusinessSet.Phone) > 0) {
            $scope.BusinessSet.Phone = $scope.BusinessSet.Phone[0].Number;
          }

          if (_.size($scope.BusinessSet.Email) > 0) {
            $scope.BusinessSet.EmailAddress =
              $scope.BusinessSet.Email[0].EmailAddress;
          }

          allocatedAdviser($scope.BusinessSet);

          // this will be for the dropdown
          $scope.ContactSet = {};
          $scope.ContactSet.PartyType =
            $scope.BusinessSet.OrganisationType === 'Partnership' ? '7' : '2';
        });
        $scope.Settings.isEdit = true;
      } else {
        const { NEW_LOAN_APP_MODAL, VIEW_OF_FILE } = REQUESTER;
        const otherRequesters = [NEW_LOAN_APP_MODAL, VIEW_OF_FILE];
        if (otherRequesters.includes(requester)) {
          const countryCode = applicationSharedService.countryCodeAndBirthday(
            $rootScope.crmCountry,
          );
          const countryBirth = applicationSharedService.countryCodeAndBirthday(
            $rootScope.crmCountry,
          );
          $scope.ContactSet = contactSharedDataService.getContactDataModel(
            'Adult',
            countryCode,
            countryBirth,
          );
        } else {
          $scope.ContactSet = $scope.getNewContactModel('Adult');
        }
        $scope.BusinessSet = {};
        $scope.BusinessSet.OrganisationId = 0;
        $scope.BusinessSet.FamilyId = 0;
        $scope.BusinessSet.Address = {};
        $scope.ContactSet.PartyType = selectedPartyType;
        $scope.Settings.isEdit = false;
      }

      // Default
      $scope.saveText = 'Applicant';
      $scope.$watch('ContactSet.PartyType', (newVal) => {
        $scope.saveText = partyTypeService.getPartyType(newVal);
      });

      $scope.titles = [
        { Value: 'Mr', Name: 'Mr' },
        { Value: 'Mrs', Name: 'Mrs' },
        { Value: 'Ms', Name: 'Ms' },
        { Value: 'Miss', Name: 'Miss' },
        { Value: 'Master', Name: 'Master' },
        { Value: 'Dr', Name: 'Dr' },
      ];

      $scope.$watch('ContactSet.Title', (newVal) => {
        if ($scope.ContactSet && $scope.ContactSet.Gender) {
          genderService.changeGender(newVal, $scope.ContactSet.Gender);
        }
      });

      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };
      $scope.datePopUp = {
        DOB: false,
      };
      // Open Date Popup
      $scope.openDate = (dateField) => {
        $scope.datePopUp[dateField] = !$scope.datePopUp[dateField];
      };

      $scope.format = 'dd MMM yyyy';
      $scope.altInputFormats = ['d!/M!/yyyy', 'd!M!yyyy'];
      $scope.dateOptions = {
        formatYear: 'yy',
        showWeeks: false,
      };

      // Save Client
      $scope.saveClientAccount = () => {
        $scope.ContactSet.Phone = [];
        $scope.ContactSet.Phone.push({
          Type: 'Mobile',
          Number: $scope.ContactSet.MobileNumber,
        });
        $scope.ContactSet.Email = [
          { Type: 'Email', EmailAddress: $scope.ContactSet.EmailAddress },
        ];
        $scope.ContactSet.DOB = utilitiesService.formatDate(
          $scope.ContactSet.DOBGet,
          true,
        );
        if ($scope.ContactSet.OfficeNumber) {
          $scope.ContactSet.Phone.push({
            Type: 'Work',
            Number: $scope.ContactSet.OfficeNumber,
          });
        }
        if ($scope.ContactSet.FaxNumber) {
          $scope.ContactSet.Phone.push({
            Type: 'Fax',
            Number: $scope.ContactSet.FaxNumber,
          });
        }

        let CitizenshipObj = _.find($scope.countryList, (o) => {
          return o.CountryCode === $scope.ContactSet.CountryCode;
        });

        let CountryOfResidencyObj = _.find($scope.countryList, (o) => {
          return o.CountryCode === $scope.ContactSet.ResidencyCountryCode;
        });
        let CountryOfBirthObj = _.find($scope.countryList, (o) => {
          return o.CountryCode === $scope.ContactSet.CountryOfBirth;
        });
        if (!CitizenshipObj) {
          CitizenshipObj = {
            CountryID: 0,
          };
        }
        if (!CountryOfResidencyObj) {
          CountryOfResidencyObj = {
            CountryID: 0,
          };
        }
        if (!CountryOfBirthObj) {
          CountryOfBirthObj = {
            CountryID: 0,
          };
        }

        angular.extend($scope.ContactSet, {
          CitizenshipId: parseInt(CitizenshipObj.CountryID, 10),
          CountryOfResidencyId: parseInt(CountryOfResidencyObj.CountryID, 10),
          CountryOfBirthId: parseInt(CountryOfBirthObj.CountryID, 10),
          ResidencyId: parseInt($scope.ContactSet.ResidencyId, 10),
        });
        if (!_.isUndefined($scope.ContactSet.Address)) {
          const temporatyContactAddress = $scope.ContactSet.Address[0];
          $scope.ContactSet.Address = [];
          if (temporatyContactAddress) {
            temporatyContactAddress.Type = 'Home';
            $scope.ContactSet.Address.push(temporatyContactAddress);
          }
        }
        // checking of company name if it is blank, company name is a required field
        if (
          ($scope.ContactSet.PartyType === 5 ||
            $scope.ContactSet.PartyType === 6) &&
          (_.isNull($scope.ContactSet.CompanyName) ||
            _.isEmpty($scope.ContactSet.CompanyName))
        ) {
          return false;
        }
        contactService
          .addContact([$scope.ContactSet])
          .then(({ data }) => {
            !$scope.showParentDropdown &&
              setFamilyDetails({ $scope, contactService })(
                data,
                $scope.Settings.isEdit,
              );
            const { PersonId, ParentGuardianId } = $scope.ContactSet;
            const payload = {
              dependantClientID: PersonId,
              parentGuardianClientID: ParentGuardianId,
            };
            $scope.showParentDropdown &&
              setDependantParent({
                $scope,
                contactService,
                setFamilyDetailsPayData: data,
                isEdit: $scope.Settings.isEdit,
                dependantModelService,
                payload,
              });
          })
          .catch((error) => {
            $scope.ContactSet.isSaving = false;
            toastError(error.data.Message);
          });
      };

      $scope.saveBusinessAccount = (form) => {
        if (form.$invalid) {
          $scope.prevStep();
          toaster.pop(
            'error',
            'Invalid Form',
            'Please fill up the form correctly',
          );
          $scope.canSave = true;
          return false;
        }

        // return true;
        let orgType;
        if ($scope.ContactSet.PartyType === '7') {
          orgType = 'Partnership';
        } else if ($scope.ContactSet.PartyType === '2') {
          orgType = 'Company';
        } else {
          orgType = '';
        }
        const org = {
          SourceFamilyID: $scope.accessType === 5 ? $stateParams.familyId : '',
          OrganisationId: $scope.BusinessSet.OrganisationId,
          Name: $scope.BusinessSet.Name,
          OrganisationTaxNumber: $scope.BusinessSet.OrganisationTaxNumber,
          OrganisationCompanyNumber:
            $scope.BusinessSet.OrganisationCompanyNumber,
          FamilyId: String($scope.BusinessSet.FamilyId),
          AllocatedAdviserID: $scope.ContactSet.AllocatedAdviserID,
          Phone: [
            {
              Type: 'Work',
              Number:
                _.size($scope.BusinessSet.Phone) > 0
                  ? $scope.BusinessSet.Phone
                  : '',
            },
          ],
          Address: [$scope.BusinessSet.Address],
          Email: [
            {
              Type: 'Email',
              EmailAddress: $scope.BusinessSet.EmailAddress
                ? $scope.BusinessSet.EmailAddress
                : '',
            },
          ],
          OrganisationType: orgType,
          Directors: $scope.BusinessSet.Directors,
        };

        contactService.organisationInfoSet(org).then((response) => {
          const FamilyId = response.data;

          if (typeof response.data.Message !== 'undefined') {
            toaster.pop('info', 'Information', response.data.Message);
          } else {
            $uibModalInstance.close({
              FamilyId,
              PartyType: $scope.ContactSet.PartyType,
              isEdit: $scope.Settings.isEdit,
            });
          }
        });
      };
      $scope.requiredAllocatedAdviser = false;
      $scope.saveAccount = (form) => {
        $scope.ContactSet.AllocatedAdviserID = $scope.modalObj.isCorporate
          ? 0
          : $scope.ContactSet.AllocatedAdviserID;
        setAllocatedIds({ $scope });
        if (!$scope.ContactSet.AllocatedAdviserID) {
          $scope.requiredAllocatedAdviser = true;
          return;
        }
        $scope.ContactSet.isSaving = true;
        if (
          $scope.ContactSet.PartyType === '1' ||
          $scope.ContactSet.PartyType === '3' ||
          $scope.ContactSet.PartyType === '5' ||
          $scope.ContactSet.PartyType === '6' ||
          $scope.ContactSet.PartyType === '4'
        ) {
          $scope.saveClientAccount(form);
        } else if (
          $scope.ContactSet.PartyType === '2' ||
          $scope.ContactSet.PartyType === '7'
        ) {
          $scope.saveBusinessAccount(form);
        }
      };
      $scope.isCompany = () => {
        return (
          parseInt($scope.ContactSet.PartyType, 10) ===
          ENTITY_PARTY_TYPES.COMPANY
        );
      };
    },
  );
