import { getToken } from 'Common/utilities/token';

export const buildCommonHttpHeader = (token, isAuthorizationOnly = false) => {
  const xSourcePlatform = isAuthorizationOnly
    ? {}
    : { 'X-SourcePlatform': 'MyCRM' };
  return {
    Authorization: token,
    ...xSourcePlatform,
  };
};

export const httpHeaders = {
  common: () => buildCommonHttpHeader(getToken()),
};
