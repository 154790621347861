export function appendEmptySelect(data, placeHolderCustom = {}) {
  const defaults = { name: 'Please select', value: 0 };
  const placeHolder = Object.keys(placeHolderCustom).length
    ? placeHolderCustom
    : defaults;

  return [placeHolder, ...data];
}

export function appendAnyOption(data, option) {
  return [...option, ...data];
}

export const getOptionName = (list, value) => {
  const invalidParameter = !list || !list.length || !value;
  if (invalidParameter) {
    return;
  }
  const item = list.find((opt) => opt.Value === value.toString());
  return item && item.Name;
};
