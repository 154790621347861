import { formatToCurrency } from 'Common/utilities/currency';

export const loanVariationTypeBuilderForUI = (type) => {
  return {
    id: type.TopUpOrVariationTypeId,
    name: type.TopUpOrVariationTypeName,
  };
};

export const loanVariationBuilderForUI = (variation) => {
  return {
    loanId: variation.LoanId,
    loanAppId: variation.LoanAppId,
  };
};

export const loanVariationSummaryBuilderForUI = (variation) => {
  if (!variation) {
    return {};
  }

  const summaryList = [
    variation.PrincipalIncrease &&
      loanVariationPrincipalIncreaseFormat(variation),
    variation.PrincipalDecrease &&
      loanVariationPrincipalDecreaseFormat(variation),
    variation.AddedSecurities && loanVariationAddSecurityFormat(variation),
    variation.DischargedSecurities &&
      loanVariationSecurityDischargeFormat(variation),
    variation.SecuritySwap && loanVariationSecuritySwapFormat(variation),
    variation.AddedNewProducts && loanVariationAddNewProductFormat(variation),
    variation.ProductSplit && loanVariationProductSplitFormat(variation),
  ].filter((summary) => !!summary);

  return (
    variation && {
      primaryTopUpOrVariationTypeId: variation.PrimaryTopUpOrVariationTypeId,
      secondaryTopUpOrVariationTypeIds:
        variation.SecondaryTopUpOrVariationTypeIds,
      summaryList,
    }
  );
};

export const loanVariationPrincipalIncreaseFormat = ({ PrincipalIncrease }) => {
  if (
    !PrincipalIncrease ||
    PrincipalIncrease.OldPrincipalAmount ===
      PrincipalIncrease.NewPrincipalAmount
  ) {
    return '';
  }
  return `<strong>Top Up</strong> Current Principal $${formatToCurrency(
    PrincipalIncrease.OldPrincipalAmount,
  )} - New Principal $${formatToCurrency(
    PrincipalIncrease.NewPrincipalAmount,
  )} - Increase of $${formatToCurrency(
    PrincipalIncrease.NewPrincipalAmount - PrincipalIncrease.OldPrincipalAmount,
  )}`;
};

export const loanVariationPrincipalDecreaseFormat = ({ PrincipalDecrease }) => {
  if (
    !PrincipalDecrease ||
    PrincipalDecrease.OldPrincipalAmount ===
      PrincipalDecrease.NewPrincipalAmount
  ) {
    return '';
  }
  return `<strong>Principal Decrease</strong> Current Principal $${formatToCurrency(
    PrincipalDecrease.OldPrincipalAmount,
  )} - New Principal $${formatToCurrency(
    PrincipalDecrease.NewPrincipalAmount,
  )} - Decrease of $${formatToCurrency(
    PrincipalDecrease.OldPrincipalAmount - PrincipalDecrease.NewPrincipalAmount,
  )}`;
};

export const loanVariationSecurityFormat = (security) => {
  return `${security.Address}, $${formatToCurrency(security.SecurityValue)}`;
};

export const loanVariationSecurityLabelPluralize = (securityList = []) => {
  return securityList.length > 1 ? 'securities' : 'security';
};

export const loanVariationSecuritiesMap = (securities) =>
  securities
    .map((security) => loanVariationSecurityFormat(security))
    .join('; ');

export const loanVariationAddSecurityFormat = ({ AddedSecurities }) => {
  if (!AddedSecurities || !AddedSecurities.length) {
    return '';
  }
  return `<strong>Add Security</strong> Added ${
    AddedSecurities.length > 1 ? 'securities' : 'security'
  }: ${loanVariationSecuritiesMap(AddedSecurities)}`;
};

export const loanVariationSecurityDischargeFormat = ({
  DischargedSecurities,
}) => {
  if (!DischargedSecurities || !DischargedSecurities.length) {
    return '';
  }
  return `<strong>Security Discharge</strong> Discharged ${loanVariationSecurityLabelPluralize(
    DischargedSecurities,
  )}: ${loanVariationSecuritiesMap(DischargedSecurities)}`;
};

export const loanVariationSecuritySwapFormat = ({ SecuritySwap }) => {
  if (!SecuritySwap) {
    return '';
  }

  const { DischargedSecurities, AddedSecurities } = SecuritySwap;

  if (
    !DischargedSecurities ||
    !AddedSecurities ||
    (!DischargedSecurities.length && !AddedSecurities.length)
  ) {
    return '';
  }

  return `<strong>Security Swap</strong> Discharged ${loanVariationSecurityLabelPluralize(
    DischargedSecurities,
  )}: ${loanVariationSecuritiesMap(
    DischargedSecurities,
  )} - Added ${loanVariationSecurityLabelPluralize(
    AddedSecurities,
  )}: ${loanVariationSecuritiesMap(AddedSecurities)}`;
};

export const loanVariationAddNewProductFormat = ({ AddedNewProducts }) => {
  if (!AddedNewProducts) {
    return '';
  }

  const { OldProducts, NewProducts } = AddedNewProducts;
  if (!OldProducts || !NewProducts || !NewProducts.length) {
    return '';
  }

  return `<strong>Add New Product</strong> Old ${
    OldProducts.length > 1 ? 'products' : 'product'
  }: ${OldProducts.join('; ')} - New ${
    NewProducts.length > 1 ? 'products' : 'product'
  }: ${NewProducts.join('; ')}`;
};

export const interestOnlyTermFormat = (interestOnlyTerm) => {
  if (!interestOnlyTerm) {
    return '';
  }
  return ` for ${interestOnlyTerm} ${interestOnlyTerm > 1 ? 'years' : 'year'}`;
};

export const loanVariationSplitFormat = (split, index) => {
  return `Split ${index + 1} $${formatToCurrency(split.TotalLoanAmount)}${
    split.RateType ? ` ${split.RateType}` : ''
  } ${split.RepaymentType}${interestOnlyTermFormat(split.InterestOnlyTerm)}`;
};

export const loanVariationSplitMap = (splits) => {
  return splits
    .map((split, index) => loanVariationSplitFormat(split, index))
    .join(', ');
};

export const loanVariationProductSplitFormat = ({ ProductSplit }) => {
  if (!ProductSplit) {
    return '';
  }

  const { OldLoanSplits, NewLoanSplits } = ProductSplit;
  return `<strong>Product Split</strong> Current split structure: ${loanVariationSplitMap(
    OldLoanSplits,
  )} - New split structure: ${loanVariationSplitMap(NewLoanSplits)}`;
};
