import { COVER_TYPE } from 'Common/constants/coverType';

export const showRecalculationModal = (
  isCalculated,
  crmConfirmation,
  onConfirm,
) => {
  if (!isCalculated) {
    return false;
  }
  return crmConfirmation.open({
    type: 'warning',
    title: 'Settings have changed!',
    description: `You have adjusted the settings - you should consider re-calculating to ensure the correct information is displayed.`,
    buttonText: `Re-Calculate`,
    cancelButtonText: 'Ignore',
    onConfirm,
    showCloseButton: true,
    showCancelButton: true,
    modalSize: 'md',
    cancelButtonClass: 'colored',
  });
};
export const onIPFrequencyChange = ({ $scope, coverType, index }) => {
  const frequency = $scope.IPIncomeProtectionDataList[index].IPFrequency;
  $scope.calculateCoverLevel(index, frequency);
  $scope.refreshPremiums(index, coverType);
};
export const onIPLevelSuggestionChange = ({
  $scope,
  value,
  index,
  coverType,
}) => {
  if (COVER_TYPE.MORTGAGE_REPAYMENT === coverType) {
    $scope.IPMortgageRepaymentDataList[index].Suggested_LevelOfCover = value;
    $scope.IPMortgageRepaymentDataList[index].defaultSuggested = value;
  } else {
    $scope.IPIncomeProtectionDataList[index].Suggested_LevelOfCover = value;
    $scope.IPIncomeProtectionDataList[index].defaultSuggested = value;
  }
  $scope.refreshPremiums(index, coverType, {}, false, true);
};

export const settingsModal = ({
  $uibModal,
  isCalculated,
  crmConfirmation,
  recalculateWithChanges,
}) => {
  return $uibModal
    .open({
      templateUrl: 'assets/views/insuranceTools/modals/profilerSettings.html',
      controller: 'InsuranceProfilerSettingsCtrl as vm',
      windowClass: 'insuranceProfiler-settings-modal',
      backdrop: false,
      keyboard: false,
    })
    .result.then((isShowRecalculationModal) => {
      if (!isShowRecalculationModal) {
        return false;
      }
      return showRecalculationModal(
        isCalculated,
        crmConfirmation,
        recalculateWithChanges,
      );
    });
};

export const resetDefaultProfileValue = ({ $scope }) => {
  $scope.profilerFormData.PeopleEntity = $scope.profilerFormData.PeopleEntity.map(
    (item) => {
      item.HasHealthInsurance = true;
      item.HasIncomeProtection = true;
      item.HasLifeInsurance = true;
      item.HasTPD = true;
      item.HasTraumaInsurance = true;
      if ($scope.showdMortgageInsurance) {
        item.HasMortgageInsurance = true;
      }
      return item;
    },
  );
};

export const sendOldEmailReport = ({ $scope, $uibModal }) => {
  $uibModal.open({
    templateUrl:
      '/assets/views/insuranceTools/modals/insuranceProfilerEmailReport.html',
    controller: 'InsuranceProfilerEmailReportModalCtrl',
    controllerAs: 'vm',
    backdrop: 'static',
    size: 'lg',
    windowClass: '',
    resolve: {
      profilerData: () => $scope.profilerData,
      familyId: () => $scope.profilerData.familyId,
    },
  });
};

export const sendNewEmailReport = ({ $scope, helloPackService }) => {
  const props = {
    insuranceProfilerId: $scope.profilerData.profilerId,
    familyId: $scope.profilerData.familyId,
  };
  helloPackService.launchHelloPackModal(props);
};
