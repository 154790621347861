import angular from 'angular';
import _ from 'lodash';
import { toastError } from 'Common/utilities/alert';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('GeneralSettingModalCtrl', function GeneralSettingModalCtrl(
      $scope,
      $uibModalInstance,
      $stateParams,
      toaster,
      getLoanStructure,
      getDefaultFilterSelection,
      setDefaultFilterSelection,
      updateOverlaySearch,
      loanProfilerService,
    ) {
      $scope.brokerEventId = $stateParams.brokerEventId;
      $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.filtersParamtersList = {};
      $scope.loanProviderLendersListGetAll = [];
      $scope.loanProviderLendersListSet = [];
      // eslint-disable-next-line sonarjs/cognitive-complexity
      loanProfilerService
        .getServiceabilityFiltersParamters()
        .then((response) => {
          $scope.filtersParamtersList = response.data;
          _.each(response.data.Lenders, (item) => {
            const length = 12;
            const trimmedString =
              item.Name && item.Name.length > length
                ? `${item.Name.slice(0, Math.max(0, length - 3))}...`
                : item.Name;
            if (item.Value !== 'FavouriteLenders') {
              $scope.loanProviderLendersListGetAll.push({
                value: item.Value,
                ticked: false,
                name: item.Name,
                shortName: trimmedString,
              });
            }
          });
          $scope.updateLVR = function () {
            if (
              $scope.defaultSelection &&
              $scope.defaultSelection.loanAmount &&
              $scope.defaultSelection.security
            ) {
              $scope.lvrDropDownVale =
                ($scope.defaultSelection.loanAmount /
                  $scope.defaultSelection.security) *
                100;
              if (
                $scope.lvrDropDownVale > 100 ||
                Number($scope.lvrDropDownVale, 2).toFixed(2) === 0
              ) {
                $scope.lvrDropDownVale = 0;
              }

              if ($scope.lvrDropDownVale !== 0) {
                if ($scope.lvrDropDownVale === 80) {
                  $scope.defaultSelection.LVR = '80.01';
                } else {
                  const lvrDropDownVale = Number(
                    $scope.lvrDropDownVale,
                    2,
                  ).toFixed(2);
                  $scope.defaultSelection.LVR = `+${lvrDropDownVale}`;
                }
              }
            }
          };
          $scope.defaultSelection = {};
          loanProfilerService
            .getServiceabilityFilteringProductsGeneralSettings(
              $scope.brokerEventId,
            )
            .then((generalSettingResponse) => {
              $scope.defaultSelection = generalSettingResponse.data;
              if ($scope.defaultSelection) {
                if ($scope.defaultSelection.loanAmount === 0) {
                  $scope.defaultSelection.loanAmount = '';
                } else if ($scope.defaultSelection.loanAmount !== '') {
                  $scope.defaultSelection.loanAmount = Number(
                    $scope.defaultSelection.loanAmount,
                  );
                }
                if (
                  !$scope.defaultSelection.security ||
                  $scope.defaultSelection.security === 0
                ) {
                  $scope.defaultSelection.security = '';
                } else {
                  $scope.defaultSelection.security = Number(
                    $scope.defaultSelection.security,
                  );
                }
                $scope.lenderSelection = $scope.defaultSelection.Lender;
                $scope.loanProviderLendersListSet = [];
                if (
                  $scope.defaultSelection.Lender &&
                  $scope.defaultSelection.Lender.length !== 0
                ) {
                  _.each($scope.defaultSelection.Lender, (item) => {
                    const data = _.find(
                      $scope.loanProviderLendersListGetAll,
                      (itemGet) => {
                        if (itemGet.value === item) {
                          itemGet.ticked = true;
                        }
                        return itemGet.value === item;
                      },
                    );
                    if (data) {
                      $scope.loanProviderLendersListSet.push(data);
                    }
                  });
                }
                $scope.updateLVR();
              }
            });
        });

      $scope.setDefaultFilterSelection = () => {
        const lenders = _.map($scope.loanProviderLendersListSet, 'value');
        if ($scope.defaultSelection && $scope.defaultSelection.Lender) {
          $scope.defaultSelection.Lender = [];
          $scope.defaultSelection.Lender = lenders;
        }
        loanProfilerService
          .updateServiceabilityFilteringProductsSettings(
            $scope.defaultSelection.brokerEventID,
            { GeneralSetting: $scope.defaultSelection },
          )
          .then(({ data }) => {
            if (data) {
              $scope.updateLVR();
              updateOverlaySearch();
              getLoanStructure(true);
              getDefaultFilterSelection();
              $scope.cancel();
            } else {
              toastError();
            }
          });
      };
      let multiSelectDefaultListGet = [];
      $scope.DefaultMultiSelectOpen = (LendersListSet) => {
        multiSelectDefaultListGet = LendersListSet;
      };

      // Update Default Lenders List
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.updateDefaultLendersListSet = () => {
        const lendersListData = _.find(multiSelectDefaultListGet, (item) => {
          return item.value === 'ALL';
        });
        const loanProviderLendersListData = _.find(
          $scope.loanProviderLendersListSet,
          (item) => {
            return item.value === 'ALL';
          },
        );

        if (!lendersListData && loanProviderLendersListData) {
          _.each($scope.loanProviderLendersListSet, (item) => {
            if (item.value !== 'ALL') {
              _.map($scope.loanProviderLendersListGetAll, (itemGet) => {
                if (itemGet.value === item.value) {
                  itemGet.ticked = false;
                }
                return itemGet;
              });
            }
          });
          $scope.loanProviderLendersListSet = [];
          $scope.loanProviderLendersListSet.push(loanProviderLendersListData);
        } else if (
          (lendersListData && !loanProviderLendersListData) ||
          (lendersListData && loanProviderLendersListData)
        ) {
          _.map($scope.loanProviderLendersListGetAll, (itemGet) => {
            if (itemGet.value === 'ALL') {
              itemGet.ticked = false;
            }
            return itemGet;
          });
          const index = $scope.loanProviderLendersListSet.indexOf(
            loanProviderLendersListData,
          );
          if (index > -1) {
            $scope.loanProviderLendersListSet.splice(index, 1);
          }
        }

        multiSelectDefaultListGet = [];
        multiSelectDefaultListGet = $scope.loanProviderLendersListSet;
      };

      // Select Default All Lender
      $scope.selectDefaultAllLender = () => {
        _.map($scope.loanProviderLendersListGetAll, (itemGet) => {
          if (itemGet.value === 'ALL') {
            $scope.loanProviderLendersListSet = [];
            $scope.loanProviderLendersListSet.push(itemGet);
          } else {
            itemGet.ticked = false;
          }
          return itemGet;
        });
      };

      // Select Default None Lender
      $scope.selectDefaultNoneLender = () => {
        _.map($scope.loanProviderLendersListGetAll, (itemGet) => {
          itemGet.ticked = false;
          return itemGet;
        });
        $scope.loanProviderLendersListSet = [];
      };
    });
