export const PAGE_SOURCE = {
  CUSTOMER_CARE: 'CUSTOMER_CARE',
  COMMISSIONS: 'COMMISSIONS',
  LOAN_APP_1: 'LOAN_APP_1',
  INSURANCE_QUOTE: 'INSURANCE_QUOTE',
  MY_CONTACTS: 'MY_CONTACTS',
  INSURANCE_PROFILER: 'INSURANCE_PROFILER',
  CUSTOMER_CARE_INSURANCE: 'CUSTOMER_CARE_INSURANCE',
  LOAN_PIPELINE: 'LOAN_PIPELINE',
};

export const YOUTUBE_LINKS = {
  [PAGE_SOURCE.CUSTOMER_CARE]: 'https://www.youtube.com/embed/YtidGLXcrgE',
  [PAGE_SOURCE.COMMISSIONS]: 'https://www.youtube.com/embed/8LUwewZRRRc',
  [PAGE_SOURCE.LOAN_APP_1]: 'https://www.youtube.com/embed/-yHrp8NuR-M',
  [PAGE_SOURCE.INSURANCE_QUOTE]: 'https://www.youtube.com/embed/1MqobwvEApA',
  [PAGE_SOURCE.MY_CONTACTS]: 'https://www.youtube.com/embed/OfxmcXnX3Sk',
  [PAGE_SOURCE.INSURANCE_PROFILER]: 'https://www.youtube.com/embed/3ThLihULEnc',
  [PAGE_SOURCE.LOAN_PIPELINE]: 'https://www.youtube.com/embed/XCQ82lZiKlo',
  [PAGE_SOURCE.CUSTOMER_CARE_INSURANCE]:
    'https://www.youtube.com/embed/axihI-1ft08',
};
