import template from './discountRateInput.html';
import controller from './discountRateInputCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    rateData: '=',
    referenceRates: '<',
    referenceRate: '=',
    isOnLoanDetailsModal: '<',
    isVariable: '<',
    initialRate: '=',
    initialReferenceRate: '=',
    loanId: '<',
    productLoanStructureId: '<',
    productId: '<',
    brokerEventId: '<',
    updateRepayment: '&',
  },
};
