import { parseIntValue } from 'Common/utilities/parse';
import { lengthOfTime } from 'Common/utilities/lengthOfTime';
import { ADDRESS_TYPE } from 'Common/constants/addressModules';
import {
  getDatePickerConfig,
  isStartEndDateDropdownsInvalid,
} from 'Common/utilities/date';
import { getMonths, getYears } from 'Common/utilities/calendar';
import { OWNERSHIP_TYPE } from 'Common/constants/addressOwnership';
import { FINANCIAL_FREQUENCY } from 'Common/constants/financialFrequency';

import {
  formatCustomAddress,
  getContactLivingIn,
  addIstevenLabelMap,
} from 'Common/utilities/addressHistory';
import { toastError } from 'Common/utilities/alert';
import moment from 'moment';
import toastr from 'toastr';

const defaultLengthOfTime = '0 months';
const defaultOwnerId = -1;

export default class AddressHistoryModalCtrl {
  constructor(
    $q,
    optionsService,
    contactService,
    dashboardService,
    broadcastDataService,
    addressHistoryService,
    currentUserService,
    configService,
  ) {
    'ngInject';

    this.$q = $q;
    this.configService = configService;
    this.currentUserService = currentUserService;
    this.optionsService = optionsService;
    this.contactService = contactService;
    this.dashboardService = dashboardService;
    this.broadcastDataService = broadcastDataService;
    this.addressHistoryService = addressHistoryService;
    this.months = getMonths();
    this.years = getYears();
  }

  $onInit() {
    this.isLoanApp = !!this.loanAppId;
    this.useAsPostalAddressFlag = this.configService.feature.useAsPostalAddress;
    this.isNew = this.modalType === 'new';
    this.modalTitle = `${this.isNew ? 'Add' : 'Edit'} an Address`;
    this.ADDRESS_TYPE = ADDRESS_TYPE;
    this.isSaveDisabled = true;
    this.dateConfig = getDatePickerConfig();
    this.occupancyLengthOfTime = defaultLengthOfTime;
    this.datePopUp = {
      StartDate: false,
      EndDate: false,
    };
    this.OWNERSHIP_TYPE = OWNERSHIP_TYPE;
    this.FINANCIAL_FREQUENCY = FINANCIAL_FREQUENCY;

    if (!this.propertySet && this.isNew) {
      this.propertySet = {
        EstimatedValue: 0,
        Address: { AddressID: '0' },
        PropertyName: '',
        LocationDesc: '',
        Ownership: [],
        OwnershipTypeId: 0,
        ContactLivingIn: [],
        FloorNumber: '',
        DischargeDate: new Date(),
        PurchaseDate: new Date(),
        StartDate: null,
        EndDate: null,
        FullAddress: { formatted_address: '' },
        isUsePostalAddress: false,
      };
    } else if (this.propertySet && !this.isNew) {
      this.propertySet.Address = {
        formatted_address: this.propertySet.formatted_address,
        geoCoded: this.propertySet.geoCoded,
        latitude: this.propertySet.latitude,
        longitude: this.propertySet.longitude,
        isCustomAddress: this.propertySet.isCustomAddress,
        street_address: this.propertySet.street_address,
        locality: this.propertySet.locality,
        postal_code: this.propertySet.postal_code,
        stateShort_Name: this.propertySet.stateShort_Name,
      };
      this.propertySet.FullAddress = { ...this.propertySet.Address };
      this.propertySet.isUsePostalAddress = this.propertySet.isPostalAddress;

      this.propertySet.StartDate = this.propertySet.StartDate
        ? { ...this.propertySet.StartDate, Day: 1 }
        : null;
      this.propertySet.EndDate = this.propertySet.EndDate
        ? { ...this.propertySet.EndDate, Day: 1 }
        : null;
      this.onAddressTypeChange();
      this.onOwnershipTypeChange();
    }

    this.$q
      .all([
        this.getAddressTypes(),
        this.getOwnershipType(),
        this.getOwners(),
        this.getFinancialFrequency(),
      ])
      .then(() => {
        this.isSaveDisabled = false;
      });
  }

  close() {
    const closeParam = this.isLoanApp ? { FamilyId: this.familyId } : null;
    this.modalInstance.close(closeParam);
  }

  dismiss() {
    if (this.isAddAnotherTriggered) {
      this.close();
    } else {
      this.modalInstance.dismiss();
    }
  }

  getAddressTypes() {
    return this.optionsService.addressTypeGet().then(({ data }) => {
      this.addressTypeList = data || [];
      if (!this.isNew && !this.propertySet.TypeId) {
        this.propertySet.TypeId = (data && data[0] && data[0].Value) || null;
      }
    });
  }

  getOwners() {
    this.localLang = {
      selectAll: '<span>Select All</span>',
      selectNone: '<span>Deselect All </span>',
      reset: "<i class='fa fa-refresh'></i>",
      search: 'Search',
      nothingSelected: "<div class='buttonLabel'>No Clients Selected</div>",
    };
    this.ownersList = [];
    this.ownersListSelected = [];

    this.contactService.clientInformGet(this.familyId).then(({ data }) => {
      const dataContent =
        (data &&
          data.filter((o) => o.Role === 'Adult' || o.Role === 'Guarantor')) ||
        [];
      const defaultOption = {
        ClientEntityId: defaultOwnerId,
        PersonId: defaultOwnerId,
        FirstName: 'All',
        LastName: 'Clients',
        Role: 'Adult',
        ticked: this.isNew,
        PreferredName: 'All Clients',
      };
      const ownersList = [{ ...defaultOption }, ...dataContent];

      if (!this.isNew) {
        this.propertySet.ContactLivingIn.forEach((value) => {
          const selectedOwners = {
            ClientEntityId: value.BorrowerID,
            FirstName: value.FirstName,
            LastName: value.LastName,
            PersonId: value.BorrowerID,
            PreferredName:
              (`${value.FirstName} ` || '') + (value.LastName || ''),
            Role: 'Adult',
            ticked: true,
          };
          this.ownersListSelected.push(selectedOwners);
        });

        ownersList.map((value) => {
          value.ClientEntityId = value.PersonId;
          value.ticked = !!this.ownersListSelected.find(
            (obj) => +obj.ClientEntityId === +value.ClientEntityId,
          );
          return value;
        });
      } else {
        ownersList.map((value) => {
          value.ClientEntityId = value.PersonId;
          value.ticked = !!value.ticked;
          return value;
        });
        this.ownersListSelected.push(defaultOption);
      }
      this.ownersList = ownersList.map(addIstevenLabelMap);
    });
  }

  getOwnershipType() {
    return this.optionsService.ownershipTypeGet().then(({ data }) => {
      this.ownershipTypeList = parseIntValue(data) || [];
    });
  }

  getFinancialFrequency() {
    return this.optionsService.FinancialFrequency().then(({ data }) => {
      this.listRentalFrequency = parseIntValue(data) || [];
    });
  }

  onOwnerSelect(client) {
    if (!client.ticked) {
      return;
    }
    if (client.PersonId === defaultOwnerId) {
      this.ownersList.map((value) => {
        value.ticked = value.PersonId === defaultOwnerId;
        return value;
      });
    } else {
      this.ownersList.map((value) => {
        value.ticked = value.PersonId !== defaultOwnerId ? value.ticked : false;
        return value;
      });
    }
  }

  onSelectAllOwners() {
    this.ownersList.map((owner) => {
      owner.ticked = owner.ClientEntityId === defaultOwnerId;
      return owner;
    });
  }

  onOwnershipTypeChange() {
    if (!this.propertySet.OwnershipTypeId) {
      this.isWithRental = false;
      return;
    }

    this.isWithRental = this.broadcastDataService.addressWithRentalIds.includes(
      this.propertySet.OwnershipTypeId.toString(),
    );
    this.propertySet.FrequencyTypeId =
      this.OWNERSHIP_TYPE.RENT === this.propertySet.OwnershipTypeId
        ? this.FINANCIAL_FREQUENCY.WEEKLY
        : this.FINANCIAL_FREQUENCY.MONTHLY;
  }

  onAddressTypeChange() {
    this.getLengthOfTime();
  }

  onOpenDate(dateField) {
    this.datePopUp[dateField] = !this.datePopUp[dateField];
  }

  checkAddressType() {
    this.isCurrentAddress =
      parseInt(this.propertySet.TypeId, 10) ===
      parseInt(ADDRESS_TYPE.CURRENT_ADDRESS, 10);

    this.isPreviousAddress =
      parseInt(this.propertySet.TypeId, 10) ===
      parseInt(ADDRESS_TYPE.PREVIOUS_ADDRESS, 10);

    this.isCurrentOrPreviousAddress =
      this.isCurrentAddress || this.isPreviousAddress;
  }

  getLengthOfTime() {
    this.checkAddressType();
    if (
      this.propertySet &&
      !this.propertySet.StartDate &&
      this.isCurrentOrPreviousAddress
    ) {
      this.propertySet.StartDate = { Year: 0, Month: 0, Day: 1 };
      this.propertySet.EndDate = { Year: 0, Month: 0, Day: 1 };

      if (this.isCurrentAddress) {
        this.propertySet.EndDate = null;
      }
    }

    if (!this.isCurrentOrPreviousAddress) {
      this.propertySet.StartDate = null;
      this.propertySet.EndDate = null;
    }

    if (this.propertySet.EndDate && this.isPreviousAddress) {
      this.DateEnded = moment(
        `${this.propertySet.EndDate.Year}/${this.propertySet.EndDate.Month}/${this.propertySet.EndDate.Day}`,
      );
    }
    if (this.isCurrentOrPreviousAddress) {
      this.DateStarted = moment(
        `${this.propertySet.StartDate.Year}/${this.propertySet.StartDate.Month}/${this.propertySet.StartDate.Day}`,
      );
    }

    if (!this.DateEnded && !this.DateStarted) {
      this.occupancyLengthOfTime = defaultLengthOfTime;
      return;
    }
    const isCurrentAddressWithEndDate =
      this.isCurrentAddress || !this.DateEnded;
    const start = isCurrentAddressWithEndDate
      ? moment(Date.now())
      : moment(this.DateEnded);
    const end = moment(this.DateStarted);

    this.occupancyLengthOfTime = lengthOfTime(start, end);
    return this.occupancyLengthOfTime;
  }

  setSaveAndAddAnotherTitle() {
    this.modalTitle = `Add Another Address`;
  }

  saveSuccess(isSaveOnly) {
    if (isSaveOnly) {
      this.close();
    } else {
      this.propertySet.Address = {};
      this.propertySet.StartDate = null;
      this.propertySet.EndDate = null;
      this.propertySet.TypeId = '';
      this.propertySet.OwnershipTypeId = '';
      this.propertySet.RentalAmount = '';
      this.isSaveDisabled = false;
      this.isAddAnotherTriggered = true;
      this.setSaveAndAddAnotherTitle();
    }
  }

  checkIsUsePostalAddress() {
    const { TypeId } = this.propertySet;
    this.propertySet.isUsePostalAddress =
      parseInt(TypeId, 10) !== ADDRESS_TYPE.CURRENT_ADDRESS
        ? false
        : this.propertySet.isUsePostalAddress;
  }

  checkEndDate() {
    if (this.isPreviousAddress) {
      this.propertySet.EndDate.Day = 1;
      return;
    }
    this.propertySet.EndDate = null;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  save(form) {
    if (this.isSaveDisabled) {
      return;
    }
    this.checkIsUsePostalAddress();
    this.checkAddressType();

    if (
      this.isPreviousAddress &&
      isStartEndDateDropdownsInvalid(
        this.propertySet.StartDate,
        this.propertySet.EndDate,
      )
    ) {
      return false;
    }

    const isSaveOnly = this.isSaveOnly;
    const isCustomAddress =
      this.propertySet &&
      this.propertySet.Address &&
      this.propertySet.Address.isCustomAddress;
    const isInvalidPostal =
      form && form.postcode && form.postcode.$error.pattern;
    if (isCustomAddress && isInvalidPostal) {
      toastError(
        'Please check data entered into Postcode field, only numeric characters are allowed.',
      );
      return;
    }
    if (!form.$valid) {
      toastr.warning('Please fill in all fields', 'Required fields');
      return;
    }

    if (
      !this.ownersListSelected ||
      !this.ownersListSelected.length ||
      !this.ownersListSelected[0]
    ) {
      toastr.error('No clients found', 'Clients');
      return;
    }
    this.checkEndDate();

    this.isSaveDisabled = true;
    if (this.ownersListSelected[0].ClientEntityId === defaultOwnerId) {
      this.ownersList.forEach((data) => {
        if (data.ClientEntityId === defaultOwnerId) {
          return;
        }

        this.propertySet.Ownership && this.propertySet.Ownership.push(data);
        this.propertySet.ContactLivingIn.push(getContactLivingIn(data));
      });
    } else {
      const contactsLivingIn = [];
      this.ownersListSelected.forEach((data) => {
        this.propertySet.Ownership && this.propertySet.Ownership.push(data);
        contactsLivingIn.push(getContactLivingIn(data));
      });
      this.propertySet.ContactLivingIn = contactsLivingIn;
    }

    this.propertySet.FullAddress = {};
    this.propertySet.FullAddress.OwnershipTypeId =
      this.propertySet.OwnershipTypeId || '';

    const setAddressMethod = this.isLoanApp
      ? 'setLoanAppAddress'
      : 'setContactAddress';
    if (this.isNew) {
      this.propertySet = formatCustomAddress(this.propertySet);
      /**
       * the user might be adding a po box address
       * */
      if (
        !Object.prototype.hasOwnProperty.call(
          this.propertySet.FullAddress,
          'formatted_address',
        )
      ) {
        const fullAddressObject = {
          OwnershipTypeId: this.propertySet.OwnershipTypeId,
          formatted_address: this.propertySet.Address
            ? this.propertySet.Address.formatted_address
            : '',
          geoCoded: false,
        };
        this.propertySet.FullAddress = {
          ...this.propertySet.FullAddress,
          ...fullAddressObject,
        };
      }
      this.addressHistoryService[setAddressMethod](
        this.propertySet,
        this.familyId,
      )
        .then(() => {
          toastr.success('New address successfully added', 'Added');

          this.saveSuccess(isSaveOnly);
        })
        .catch((error) => toastError(error));
    } else {
      this.propertySet = formatCustomAddress(this.propertySet);
      this.propertySet.FullAddress = { ...this.propertySet.Address };
      this.propertySet.FullAddress.AddressValuationExtendedDetailsId = this.propertySet.AddressValuationExtendedDetailsId;
      this.addressHistoryService[setAddressMethod](
        this.propertySet,
        this.familyId,
      )
        .then(() => {
          toastr.success('Address successfully edited', 'Edited');
          this.close();
        })
        .catch((error) => toastError(error));
    }
  }
}
