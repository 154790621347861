import template from './conflictInterestDeclarations.html';
import controller from './conflictInterestDeclarationsCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    loanAppId: '<',
    familyId: '<',
    questionList: '<',
  },
};
