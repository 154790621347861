import angular from 'angular';
import existingInsurance from './existingInsurance';
import benefitListTable from './benefitListTable';
import insuranceOnlineFactFind from './insuranceOnlineFactFind';

export default angular
  .module('clientInsurance.scenes.insuranceList.scenes', [])
  .component('benefitListTable', benefitListTable)
  .component('existingInsurance', existingInsurance)
  .component('insuranceOnlineFactFind', insuranceOnlineFactFind).name;
