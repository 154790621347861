import angular from 'angular';
import { INVALID_WORKSHEET_LOAN_AMOUNT } from 'Common/constants/promptMessages';
import { toastError } from 'Common/utilities/alert';
import { validateDocumentData } from 'Common/utilities/downloadDataHelper';
import { LOAN_APP_CPA, LOAN_APP_CQP } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { performanceTimingData } from 'Common/utilities/dataLayer';
import {
  getFilenameExtension,
  isInvalidServicabilityWorksheetLoanAmount,
} from '../../../src/common/utilities/downloadHelper';

/**
 * Downloads a document using directive
 * @name downloadDocumentDir
 * @isDownloading {boolean} bind this to your controller, will check if the file is being downloaded, will turn to FALSE if finished downloading.
 * @documentIdBeingDownloaded {int} bind this to your controller, this will return an ID of the document being downloaded to be used by your controller.
 * @documentId {int} pass the documentId from your controller to this directive.
 */
export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .directive('downloadDocumentDir', function downloadDocumentDir(
      generalService,
      loanScenarioService,
      loanScenarioModelService,
      $window,
      $compile,
      toaster,
      downloadDataService,
      loanProfilerService,
      nextGenLoanAppService,
      uiService,
      $sce,
      $timeout,
    ) {
      return {
        scope: {
          isDownloading: '=',
          documentIdBeingDownloaded: '=',
          documentId: '@',
          downloadDocumentDirType: '@',
          loanAppId: '@',
          multiLoanCpaIds: '@',
          servProvWorksheetObject: '=',
          downloadFile: '=', // direct document object to be downloaded required properties: @ContentType, @DocumentContent, @Name, @extension
        },
        // eslint-disable-next-line sonarjs/cognitive-complexity
        link(scope, element) {
          element.on('click', () => {
            if (scope.isDownloading) {
              // makes sure it won't download multiple times
              return false;
            } else if (scope.documentId) {
              scope.documentIdBeingDownloaded = parseInt(scope.documentId, 10);
              scope.isDownloading = true;
              generalService
                .documentGet(scope.documentIdBeingDownloaded)
                .then((response) => {
                  const data = response.data;
                  if (data.DocumentContent) {
                    try {
                      const timeOut = $timeout(() => {
                        if (validateDocumentData(data)) {
                          const {
                            ContentType,
                            DocumentContent,
                            DocumentKey,
                          } = validateDocumentData(data);

                          downloadDataService.download(
                            `data:${ContentType};base64,${DocumentContent}`,
                            `${DocumentKey}`,
                            ContentType,
                          );
                          scope.isDownloading = false;
                        }
                      });
                      scope.$on('$destroy', () => {
                        $timeout.cancel(timeOut);
                      });
                    } catch (error) {
                      scope.isDownloading = false;
                    }
                  } else {
                    scope.isDownloading = false;
                  }
                });
            } else if (scope.downloadDocumentDirType === 'Fact Find Download') {
              scope.isDownloading = true;
              loanScenarioService.FactFindDocumentGet(scope.loanAppId).then(
                (response) => {
                  scope.isDownloading = false;
                  const docFile = response.data;
                  downloadDataService.download(
                    `data:${docFile.ContentType};base64,${docFile.DocumentContent}`,
                    'factfind.pdf',
                    docFile.ContentType,
                  );
                },
                () => {
                  scope.isDownloading = false;
                  toaster.pop(
                    'error',
                    'There seems to be an issue downloading the pdf file. Please try again.',
                    'Unable to download the file',
                  );
                },
              );
            } else if (
              scope.downloadDocumentDirType === 'cpa' ||
              scope.downloadDocumentDirType === 'cqp'
            ) {
              const PERFORMANCE_TYPE =
                scope.downloadDocumentDirType === 'cpa'
                  ? LOAN_APP_CPA
                  : LOAN_APP_CQP;
              performance.start(PERFORMANCE_TYPE);
              const service = scope.multiLoanCpaIds
                ? loanScenarioModelService
                : loanScenarioService;
              const endPointMethod = scope.multiLoanCpaIds
                ? 'cpaDocument'
                : `${scope.downloadDocumentDirType}DocumentGet`;
              const param = scope.multiLoanCpaIds
                ? { loanApplicationIds: scope.multiLoanCpaIds }
                : scope.loanAppId;

              scope.isDownloading = true;
              if (typeof service[endPointMethod] === 'function') {
                service[endPointMethod](param).then(
                  (response) => {
                    const docFile = response.data;
                    downloadDataService.download(
                      `data:${docFile.ContentType};base64,${docFile.DocumentContent}`,
                      `${scope.downloadDocumentDirType}report.pdf`,
                      docFile.ContentType,
                    );
                    scope.isDownloading = false;
                  },
                  // eslint-disable-next-line sonarjs/no-identical-functions
                  () => {
                    scope.isDownloading = false;
                    toaster.pop(
                      'error',
                      'There seems to be an issue downloading the pdf file. Please try again.',
                      'Unable to download the file',
                    );
                  },
                );
              }
              const duration = performance.endPaint(PERFORMANCE_TYPE);
              performanceTimingData({
                action: PERFORMANCE_TYPE,
                value: duration,
              });
            } else if (
              scope.downloadDocumentDirType ===
              'serviceabilityProviderWorksheet'
            ) {
              if (
                isInvalidServicabilityWorksheetLoanAmount(
                  scope.servProvWorksheetObject.loanAmount,
                )
              ) {
                toastError(INVALID_WORKSHEET_LOAN_AMOUNT);
                return;
              }
              scope.isDownloading = true;
              loanProfilerService
                .ServiceabilityProviderWorksheetGet(
                  scope.servProvWorksheetObject.brokerEventId,
                  scope.servProvWorksheetObject.providerId,
                  scope.servProvWorksheetObject.loanAmount,
                  scope.servProvWorksheetObject.loanScenarioId,
                )
                .then((response) => {
                  const docFile = response.data;
                  const extension = getFilenameExtension(docFile.ContentType);
                  downloadDataService.download(
                    `data:${docFile.ContentType};base64,${docFile.DocumentContent}`,
                    `${docFile.Name} - Lender Spreadsheet${extension}`,
                    docFile.ContentType,
                  );
                  scope.isDownloading = false;
                });
            } else if (scope.downloadDocumentDirType === 'applyOnlinePdf') {
              scope.isDownloading = true;
              loanScenarioService
                .NZLoanAppDocumentGet(scope.loanAppId)
                .then((response) => {
                  const docFile = response.data;
                  downloadDataService.download(
                    `data:${docFile.ContentType};base64,${docFile.DocumentContent}`,
                    `${docFile.Name}`,
                    docFile.ContentType,
                  );
                  scope.isDownloading = false;
                });
            } else if (scope.downloadDocumentDirType === 'auViewSubmission') {
              scope.isDownloading = true;
              if (uiService.isCountry('Australia')) {
                const loan = {
                  LoanScenarioID: scope.loanAppId,
                };
                nextGenLoanAppService.nextGenAOLView(loan).then((response) => {
                  const nextGenObj = {
                    ...response.data,
                    https: $sce.trustAsResourceUrl(response.data.https),
                  };
                  const guid = nextGenObj.GUID || '';
                  const startPage = nextGenObj.StartPage || '';
                  const applicationViewURL = `${nextGenObj.https}?GUID=${guid}&StartPage=${startPage}`;
                  $window.open(applicationViewURL);
                  scope.isDownloading = false;
                });
              }
            } else if (
              scope.downloadFile &&
              scope.downloadFile.ContentType &&
              scope.downloadFile.DocumentContent &&
              scope.downloadFile.Name &&
              scope.downloadFile.extension
            ) {
              scope.isDownloading = true;
              downloadDataService.download(
                `data:${scope.downloadFile.ContentType};base64,${scope.downloadFile.DocumentContent}`,
                `${scope.downloadFile.Name}.${scope.downloadFile.extension}`,
                scope.downloadFile.ContentType,
              );
              scope.isDownloading = false;
            }
          });
        },
      };
    });
