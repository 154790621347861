import angular from 'angular';
import swal from 'sweetalert';
import _ from 'lodash';
import dotProp from 'dot-prop-immutable';
import { contactLoanListGet } from './util/summaryDetailCtrl';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('SummaryDetailCtrl', function SummaryDetailCtrl(
      $scope,
      contactService,
      corporateService,
      userService,
      mapService,
      $uibModal,
      $timeout,
      $compile,
      toaster,
      generalService,
      optionsService,
      $rootScope,
      $stateParams,
      $interval,
      broadcastDataService,
      apiRefactorService,
      commonFnService,
      insuranceSharedData,
      $window,
      contactSharedDataService,
      loadAllocationModalService,
      configService,
      uiService,
    ) {
      $scope.status = false;
      $scope.uiService = uiService;

      const defaultLimit = 2;

      $scope.lenderList = [];
      $scope.loanList = [];
      $scope.loanLimitTo = defaultLimit;
      $scope.listLimitTo = defaultLimit;
      $scope.markers = [];
      $scope.distance = [];
      $scope.clientDetailsLocation = [];
      $scope.brokerStatusList = [];
      $scope.contactAdvisers = {
        AssistantAdvID: 0,
      };
      $scope.currentAdviser = {
        AdviserFullName: '',
      };
      $scope.selAssistant = false;
      $scope.isAdviserListLoaded = false;
      $scope.compiled = '';
      $scope.contactSharedDataService = contactSharedDataService;
      $scope.clientAddressPreviewFlag =
        configService.feature.clientAddressPreview;

      optionsService.countryListGet().then(
        (response) => {
          $scope.countryList = response.data;
        },
        () => {
          $scope.countryList = [];
        },
      );

      contactSharedDataService.reloadContactSummary = () => {
        $scope.getClientByFamilyIdSummary();
      };

      $scope.getLenderList = () => {
        const lenderPlace = 0;
        contactService
          .lenderListGet(undefined, lenderPlace)
          .then((response) => {
            $scope.lenderList = response.data;
          });
      };

      $scope.getLenderList();

      $scope.getLenderId = function (lenderName) {
        const lenderObj = _.find($scope.lenderList, (obj) => {
          return obj.ProviderName === lenderName;
        });
        if (!_.isUndefined(lenderObj)) {
          return lenderObj.ProviderId;
        }
      };

      generalService.getBrokerAll().then((response) => {
        $scope.brokersList = response.data;
      });

      $scope.putAssignAdviser = function () {
        corporateService
          .assignClientToAdviserSet(
            $scope.getContactFamilyInfo.BrokerID,
            $scope.familyId,
            0,
          )
          .then(() => {
            toaster.pop(
              'success',
              'Successfully',
              'Assigned Adviser to Client',
            );
            $scope.editAdviserName = false;
            $scope.getClientByFamilyIdSummary();
          });
      };

      function getAssignedAssistant(advId, familyId) {
        corporateService
          .assignAssistantToClientGet(advId, parseInt(familyId, 10))
          .then(({ data }) => {
            const assistantId = dotProp.get(data, '0.AssistantFamilyID');
            $scope.contactAdvisers = {
              ...$scope.contactAdvisers,
              AssistantAdvID: assistantId,
              AssistantFamilyId: assistantId,
            };
          });
      }

      $scope.resetAssistantOnAdviserChange = (adviserId) => {
        if (!adviserId) {
          return;
        }
        getAssignedAssistant(adviserId, $scope.familyId);
      };

      $scope.getClientByFamilyIdSummary = (getAssistant = true) => {
        contactService
          .clientInformGet($scope.familyId)
          .then((response) => {
            $scope.clientsDataObject = response.data;

            // Summary Data;
            $scope.getSummaryData($scope.clientsDataObject);
          })
          .then(() => {
            apiRefactorService
              .contactFamilyInfoGet($scope.familyId)
              .then((response) => {
                $scope.currentAdviser = response.data;

                getAssistant &&
                  getAssignedAssistant(
                    $scope.currentAdviser.BrokerID,
                    $scope.familyId,
                  );
              });

            corporateService.brokerStatusListGet().then((response) => {
              angular.forEach(response.data, (value) => {
                $scope.brokerStatusList.push(value);
              });
            });
          });
      };

      $scope.$watch('selectedContacts', (newValue) => {
        if (newValue === 'summary') {
          contactLoanListGet({
            $scope,
            contactService,
            familyId: $scope.familyId,
          });

          $scope.getClientByFamilyIdSummary();
        }
      });

      $scope.separateExistingFromInProgressLoan = function (loanList) {
        $scope.existingLoans = [];
        $scope.inProgressLoans = [];
        _.forEach(loanList, (value) => {
          if (value.LoanStatus.LoanStatusCategory === 'Existing') {
            $scope.existingLoans.push(value);
          } else if (value.LoanStatus.LoanStatusCategory === 'InProgress') {
            $scope.inProgressLoans.push(value);
          }
        });
      };

      // Insurance
      // -------------------------------------------------------
      $scope.insuranceList = [];
      const insurances = insuranceSharedData;
      insurances.summaryInsuranceListGet($scope.familyId);

      $scope.$watch(
        () => {
          return insurances.sharedData.summaryInsuranceList;
        },
        (newVal) => {
          if (newVal) {
            $scope.insuranceList = newVal;
          }
        },
      );

      $scope.displayMore = () => {
        $scope.listLimitTo =
          $scope.insuranceList && $scope.insuranceList.length;
      };

      // save Assigned Assistant Adviser
      $scope.assignAssistantAdv = function (assistantId) {
        corporateService
          .assignAssistantToClientSet(
            assistantId,
            $scope.getContactFamilyInfo.BrokerID,
            parseInt($scope.familyId, 10),
          )
          .then((response) => {
            getAssignedAssistant(
              $scope.getContactFamilyInfo.BrokerID,
              $scope.familyId,
            );
            if (response.status === 200) {
              toaster.pop(
                'success',
                'Assistant adviser successfully assigned.',
              );
            } else {
              toaster.pop(
                'error',
                'There was error setting assistant adviser.',
              );
            }
            $scope.selAssistant = false;
          });
      };

      const successAllocation = (familyId, name) => {
        toaster.pop('success', 'Successfully', 'Assigned Adviser to Client');
        $scope.contactFamilyAddress();
        $scope.mapModel.map.isCancelledTriggered = true;
        $scope.getClientByFamilyIdSummary(false);
        commonFnService.tranferLoanApp(
          familyId,
          $scope.familyId,
          name,
          $scope.clientHeaderName,
          0,
        );
      };
      const loadModal = (size, postingCode) => {
        const showAllocationButton = true;
        const modalInstance = loadAllocationModalService.launchBrokerAllocationModal(
          showAllocationButton,
          postingCode,
        );
        modalInstance.result.then(
          (adviserInfo) => {
            if (adviserInfo) {
              const familyId = adviserInfo.FamilyId || 0;
              const name = adviserInfo.FullName || '';
              if ($scope.contactModel.isCorporateUser) {
                corporateService
                  .assignClientToAdviserSet(familyId, $scope.familyId, 0)
                  .then(() => successAllocation(familyId, name));
              } else {
                contactService
                  .putAssignAdviser($scope.familyId, familyId)
                  .then(() => successAllocation(familyId, name));
              }
            }
          },
          () => {
            if ($scope.allocation) {
              $scope.allocation.isAllocationShow = true;
            }
          },
        );
      };
      $scope.openModalAdviserMapNearby = function (size) {
        corporateService
          .clientUTMDetailsGet($scope.familyId, 0)
          .then((response) => {
            $scope.loadingBroker = true;
            if (
              response.data[0] &&
              response.data[0].PostingCode &&
              parseInt(response.data[0].PostingCode, 10) > 0
            ) {
              loadModal(size, response.data[0].PostingCode);
            } else {
              swal(
                'No Allocation Post Code Set',
                'Please set the allocation post code before re-allocating',
                'error',
              );
            }
          });
      };

      // equalize code provided by Edward
      function equalize(summaryDetailTarget) {
        // manifest
        let columns = 0;
        // manifest values
        const summaryColumnHeights = [];
        columns = $window.document.querySelectorAll(summaryDetailTarget);

        // loop through targets and fetch heights
        for (const [j, element] of columns.entries()) {
          element.style.removeProperty('height');
          // fetch column heights
          const colHeight = element.offsetHeight;
          // append values into array
          summaryColumnHeights[j] = colHeight;
        }
        // sort heights from tallest to shortest
        summaryColumnHeights.sort((a, b) => {
          return a > b ? -1 : 1;
        });

        if (_.size(columns) > 0) {
          // set a hard code height to equalize
          _.forEach(columns, (el) => {
            el.style.setProperty('height', `${summaryColumnHeights[0]}px`);
          });
        }
      }

      $scope.reevaluateEqualize = function () {
        equalize('[data-equalize]');
      };
      function equalizeCaller() {
        equalize('[data-equalize]');
      }
      $interval(equalizeCaller, 3000);
    });
