import {
  loanVariationTypeBuilderForUI,
  loanVariationBuilderForUI,
  loanVariationSummaryBuilderForUI,
} from 'Common/mappers/loanVariations';

class LoanVariationsService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'loan-topup-variation';
  }

  getTypes() {
    return this.httpClient
      .get(`${this.apiBaseUrl}/types`)
      .then(({ data }) => data && data.map(loanVariationTypeBuilderForUI));
  }

  createVariation(payload) {
    return this.httpClient
      .post(`${this.apiBaseUrl}`, payload)
      .then(({ data }) => loanVariationBuilderForUI(data));
  }

  getLoanVariationDetails(loanId) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/${loanId}/details`)
      .then(({ data }) => data && loanVariationSummaryBuilderForUI(data));
  }

  savePrimaryLoanVariationType(data) {
    return this.httpClient.put(
      `${this.apiBaseUrl}/primary-variation-type`,
      data,
    );
  }

  saveSecondaryLoanVariationType(data) {
    return this.httpClient.post(
      `${this.apiBaseUrl}/secondary-variation-type`,
      data,
    );
  }
}

export default LoanVariationsService;
