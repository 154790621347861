import angular from 'angular';
import _ from 'lodash';
import swal from 'sweetalert';
import { ACCESS_TYPE } from 'Common/constants/accessTypes';
import {
  LOAN_STATUS,
  LOAN_STATUS_CATEGORY,
} from 'Common/constants/loanStatusCategory';
import { nzCorporate } from 'Common/default/email';
import { showEmailSignature } from 'Common/utilities/showFeature';
import {
  redirectToContact,
  mapAssistantsToDropdown,
} from './util/sendEmailComponentCtrl';
import workbenchSuccess from '../workbench/successModal';

function SendEmailComponentCtrl(
  $localStorage,
  broadcastDataService,
  validateEmailService,
  mailTemplateService,
  insuranceQuoteService,
  dashboardService,
  corporateService,
  commonFnService,
  generalService,
  optionsService,
  contactService,
  templateService,
  emailTemplatesSharedData,
  $stateParams,
  SweetAlert,
  $uibModal,
  $document,
  $timeout,
  $window,
  toaster,
  $scope,
  $state,
  uiService,
  currentUserService,
  crmConfirmation,
  configService,
  customerCareTemplateService,
  mycrmLookupService,
) {
  'ngInject';

  /* model */
  $scope.emailSignature = showEmailSignature(
    configService.feature.emailSignature,
    currentUserService,
  );
  $scope.familyId = this.familyId || $state.params.familyId;
  $scope.emailModel = {};
  $scope.countryList = [];
  $scope.insurerListOfPolicy = [];
  $scope.recipients = {};
  $scope.ccs = {};
  $scope.selectEmail = [];
  $scope.loginUseInfo = {};
  $scope.templates = {};
  $scope.emailContactType = $state.params.contactType;
  $scope.isWorkbench = this.isWorkbench;
  const DEFAULT_ADVISER = 'Default adviser';
  $scope.isEnabledFromToFeature = uiService.isSetToFromEmailEnabled;
  $scope.adviserList = [];
  $scope.defaultAdviserEmail = '';
  const defaultAdviserOption = {
    familyId: '',
    email: '',
    fullName: DEFAULT_ADVISER,
  };
  $scope.selectedEmailAdviser = defaultAdviserOption;
  $scope.validation = {
    selectedLoanInvalid: false,
  };
  angular.extend($scope, {
    originalRecipients: [],
    originalRecipientsCount: 0,
    signatureUser: '',
    watchCounter: 0,
  });
  /* methods */
  $scope.mergeFieldsLoanObject = {
    loanList: null,
    selectedLoanList: null,
    mergeLoanForEmailContent: null,
    selectedTemplate: null,
    init: () => {
      $scope.mergeFieldsLoanObject.populateCountryList();
      $scope.mergeFieldsLoanObject.populateLoanList();
      $scope.mergeFieldsLoanObject.getInsurerMergeField();
    },

    dropdown: {
      isOpen: false,
    },
    getInsurerMergeField: () => {
      insuranceQuoteService
        .getInsurerMergeField($scope.familyId)
        .then((response) => {
          $scope.insurerListOfPolicy = response;
        })
        .catch(() => {
          toaster.pop(
            'warning',
            'Oops',
            'Something went wrong fetching Insurance Policies',
          );
        });
    },
    getPrimaryClient: (clientList) => {
      return _.find(clientList, (object) => {
        return object.isPrimary === true;
      });
    },

    validateMergeFieldOption: () => {
      const hasInsurancePolicies =
        $scope.insurerListOfPolicy && $scope.insurerListOfPolicy.length;
      const hasLoans =
        $scope.mergeFieldsLoanObject.loanList &&
        $scope.mergeFieldsLoanObject.loanList.length;
      return hasInsurancePolicies || hasLoans;
    },
    onlyForNz: () => {
      const hasInsurancePolicies =
        $scope.insurerListOfPolicy && $scope.insurerListOfPolicy.length;
      return hasInsurancePolicies && uiService.isCountry('New Zealand');
    },
    populateLoanList: () => {
      contactService.loanListGet($scope.familyId).then((response) => {
        if (currentUserService.isNZ) {
          $scope.mergeFieldsLoanObject.loanList =
            response &&
            response.data.filter((obj) => {
              return (
                parseInt(obj.LoanStatus.LoanStatusID, 10) !==
                parseInt(LOAN_STATUS.PREVIOUS.NOT_PROCEEDED_WITH, 10)
              );
            });
          return;
        }
        const { EXISTING, IN_PROGRESS } = LOAN_STATUS_CATEGORY;
        const includeLoanStatusCategories = [EXISTING, IN_PROGRESS];
        $scope.mergeFieldsLoanObject.loanList = _.filter(
          response.data,
          (object) =>
            includeLoanStatusCategories.includes(
              object.LoanStatus.LoanStatusCategory,
            ),
        );
      });
    },

    populateCountryList: () => {
      optionsService.countryListGet().then((response) => {
        $scope.countryList = response.data;
      });
    },

    changeSelectedLoan: (loanId) => {
      $scope.mergeFieldsLoanObject.selectedLoanList = _.find(
        $scope.mergeFieldsLoanObject.loanList,
        (object) => {
          return object.LoanID === loanId;
        },
      );
      $scope.mergeFieldsLoanObject.changeLoanMergeFieldContent(loanId);
    },
    changeInsurancPolicy: (policyId) => {
      $scope.selectedLoanPolicyId = policyId;
      if ($scope.mergeFieldsLoanObject.selectedTemplate) {
        $scope.mergeFieldsLoanObject.translateTemplate(policyId);
      }
    },
    changeLoanMergeFieldContent: (loanId) => {
      $scope.mergeFieldsLoanObject.selectedLoan = _.find(
        $scope.mergeFieldsLoanObject.loanList,
        (object) => {
          return object.LoanID === loanId;
        },
      );
      if ($scope.mergeFieldsLoanObject.selectedTemplate) {
        $scope.mergeFieldsLoanObject.translateTemplate();
      }
    },

    translateTemplate: (policyId = 0) => {
      const PrimaryClient = $scope.mergeFieldsLoanObject.getPrimaryClient(
        $scope.clients,
      );
      const PersonId = PrimaryClient ? PrimaryClient.PersonId : 0;
      const loanStructureId =
        $scope.mergeFieldsLoanStructureObject &&
        $scope.mergeFieldsLoanStructureObject.selectedLoanStructure
          ? $scope.mergeFieldsLoanStructureObject.selectedLoanStructure
              .LoanStructureId
          : null;
      const {
        selectedLoan: loan,
        selectedTemplate: templateContent,
        selectedSubject: templateSubject,
      } = $scope.mergeFieldsLoanObject;
      const mergeFieldData = {
        familyId: $scope.familyId,
        client: PersonId,
        loan,
        templateContent,
        templateSubject,
        loanStructureId,
        policyId,
      };
      mailTemplateService.translateMailTemplate(mergeFieldData).then(
        (response) => {
          if (_.has(response, 'templateSubject')) {
            $scope.email.Subject = response.templateSubject;
            $scope.email.Content = `${response.templateContent}<br>${broadcastDataService.userSignature}`;
          } else {
            $scope.email.Content = `${response}<br>${broadcastDataService.userSignature}`;
          }

          $scope.validation.selectedLoanInvalid = false;
        },
        (error) => {
          $scope.email.Subject = error.templateSubject;
          $scope.email.Content = `${error.templateContent}<br>${broadcastDataService.userSignature}`;
          $scope.validation.selectedLoanInvalid =
            error.data === 'No loan selected';
        },
      );
    },
  };

  $scope.mergeFieldsLoanStructureObject = {
    changeSelectedLoanStructure: (loanStructure) => {
      if (!$scope.mergeFieldsLoanStructureObject) {
        return;
      }
      $scope.mergeFieldsLoanStructureObject.selectedLoanStructure = loanStructure;
      if (
        $scope.mergeFieldsLoanObject &&
        $scope.mergeFieldsLoanObject.selectedTemplate
      ) {
        $scope.mergeFieldsLoanObject.translateTemplate();
      }
    },
  };

  angular.extend($scope, {
    openSignatureManagementModal() {
      if ($scope.emailSignature) {
        crmConfirmation
          .open({
            type: 'warning',
            title: 'Hold your horses there partner!',
            description: `It looks like you've been writing up an email, ready to send, but if you update your signature, this  will be lost. We recommend you save that email text somewhere before you proceed to the email signature page.`,
            buttonText: 'Yes, I wish to proceed',
            showCancelButton: true,
          })
          .result.then((response) => {
            if (!response) {
              return;
            }
            $localStorage.sendEmailClientLinker = { familyId: $scope.familyId };
            $state.go('app.users', { activeTab: 'email-signature' });
          });
      } else {
        $uibModal.open({
          templateUrl: '/assets/views/modals/manage_signature_modal.html',
          controller: 'EmailSignatureModalCtrl',
          windowClass: 'manage-signature-modal-class',
          resolve: {
            signatureUser() {
              return $scope.signatureUser;
            },
            loginUseInfo() {
              return $scope.loginUseInfo;
            },
            email() {
              return $scope.email;
            },
            options() {
              return $scope.options;
            },
          },
        });
      }
    },

    getUserInfo() {
      contactService.getUserInfo().then((response) => {
        if (!response || !response.data) {
          return;
        }
        $scope.userInfo = response.data;
        $scope.isEmailSignatureRemove = false;
        if ($scope.signatureUser && !$scope.isEmailSignatureRemove) {
          $scope.email.Content = `<p> <br>${$scope.signatureUser}</br>`;
          broadcastDataService.userSignature = $scope.signatureUser;
        }
      });
    },

    resetSignature() {
      if (!$scope.loginUseInfo || !$scope.loginUseInfo.BrokerId) {
        return;
      }
      $scope.isEmailSignatureRemove = true;
      generalService.setEmailSignature({
        familyId: $scope.loginUseInfo.BrokerId,
        emailSignature: '',
      });
    },

    initiateSignature() {
      generalService
        .getEmailSignature($scope.loginUseInfo.BrokerId)
        .then((response) => {
          $scope.getUserInfo();
          if (!response || !response.data) {
            return;
          }
          $scope.signatureUser = response.data;
          const emailSignatureElementTag = '<table';
          $scope.isSignatureSyncToGmail = $scope.signatureUser.includes(
            emailSignatureElementTag,
          );
        });
    },
  });

  dashboardService.getBrokerBasicInfo().then((response) => {
    $scope.loginUseInfo = response.data;

    let sender = {
      EmailAddress: $scope.isEnabledFromToFeature
        ? ''
        : _.get($scope.loginUseInfo, 'Email[0].EmailAddress', ''),
    };
    if ($scope.isEnabledFromToFeature) {
      sender = {
        ...sender,
        Name: '',
      };
    }

    $scope.email = {
      Subject: '',
      Content: "<p id='email-editor'></p>",
      ReplyTo: sender,
      SentDate: new Date(),
      Attachment: [],
      From: sender,
      FamilyID: $scope.familyId,
    };

    $scope.initiateSignature();
    if (
      !currentUserService.isNZ ||
      currentUserService.accessType !== ACCESS_TYPE.CORPORATE
    ) {
      return;
    }
    const defaultNzEmail = {
      Name: nzCorporate.name,
      EmailAddress: nzCorporate.email,
    };
    $scope.email.ReplyTo = defaultNzEmail;
  });

  function getEmailTemplates(familyName, isRefresh) {
    corporateService.getEmailTemplates(0).then((response) => {
      $scope.blankCategory = _.remove(response.data, (cat) =>
        templateService.isBlankFolderOrTemplate(cat),
      )[0];
      $scope.blankTemplate = templateService.getBlankTemplateIn(
        $scope.blankCategory,
      );
      $scope.emailTemplateListing = templateService.filterTemplatesByUserType(
        response.data,
      );

      if (!isRefresh) {
        $scope.setTemplateSelection($scope.blankTemplate);
      }
    });
  }
  function getMergeFields() {
    $scope.preferredName = '';
    $scope.loanId = 0;
    $scope.clientId = 0;
    corporateService
      .MergeFieldsGet($scope.familyId, $scope.clientId, $scope.loanId)
      .then((response) => {
        $scope.emailMergeFieldList = response.data;
        $scope.mergeFieldsObj = response.data[0];
        $scope.mergeFields = [];
        $scope.mergeFields = $scope.mergeFieldsObj.MergeFields;
        Object.keys($scope.mergeFields).forEach((k) => {
          if ($scope.mergeFields[k].Name === 'Full Name') {
            $scope.preferredName = $scope.mergeFields[k].Value;
          }
        });

        getEmailTemplates($scope.preferredName);
      });
  }
  getMergeFields();

  $scope.emailSharedData = broadcastDataService;

  $scope.$watch('emailSharedData.emailSendingPage.isTriggered', () => {
    if ($scope.emailSharedData.emailSendingPage.isTriggered) {
      getEmailTemplates('', true);
      $scope.emailSharedData.emailSendingPage.isTriggered = false;
    }
  });

  $scope.templates.selectedTemplate = [
    {
      UserTemplateID: 0,
      UserTemplateName: 'Blank',
    },
  ];

  $scope.openEmailManagement = function () {
    $uibModal.open({
      templateUrl:
        'assets/views/corporate/emailManagement/emailManagement.html',
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      controller: 'EmailManagementCtrl',
    });
  };
  const fillAttachment = (attachedIds) => {
    if (attachedIds) {
      const ids = attachedIds.split(',');
      ids.forEach((id) => {
        if (id) {
          generalService.documentGet(id).then((response) => {
            const data = response.data;
            data.DocumentId = id;
            if (data) {
              $scope.emailModel.attachments = [
                ...$scope.emailModel.attachments,
                data,
              ];
            }
          });
        }
      });
    }
  };
  $scope.setTemplateSelection = (template) => {
    if (!template) {
      return;
    }

    const id = template.UserTemplateID || 0;
    const name = template.UserTemplateName || '';
    const subject = template.UserTemplateEmailSubject || '';
    const content = template.UserTemplateContent || '';
    const attachedIds = template.AttachmentDocumentIds;
    $scope.emailModel.attachments = [];
    fillAttachment(attachedIds);
    $scope.mergeFieldsLoanObject.selectedTemplate = content;
    $scope.mergeFieldsLoanObject.selectedSubject = subject;
    $scope.templates.selectedTemplate = [
      {
        UserTemplateID: id,
        UserTemplateName: name,
      },
    ];
    $scope.mergeFieldsLoanObject.translateTemplate($scope.selectedLoanPolicyId);
  };

  function getAdviserInfo() {
    corporateService
      .corporateContactGet($scope.paramClientId, $scope.familyId)
      .then((response) => {
        $scope.adviserInfo = response.data;
        $scope.recipients.email = [];
        $scope.recipients.name = [];
        $scope.originalRecipients.name = [];
        $scope.originalRecipients.email = [];
        $scope.ccs.name = [];
        $scope.ccs.email = [];
        const data = response.data;

        if (_.size(data) > 0) {
          const email = data.Email || '';
          const firstName = data.FirstName || '';
          const lastName = data.LastName || '';

          $scope.clientHeaderName = `${firstName} ${lastName}`;
          $scope.recipients.email.push(email);
          $scope.originalRecipients.email.push(email);

          $scope.recipients.name.push({
            name: `${firstName} ${lastName} [ ${email} ] `,
            email,
          });
          $scope.originalRecipients.name.push({
            name: `${firstName} ${lastName} [ ${email} ] `,
            email,
          });
        }
      });
  }
  // eslint-disable-next-line sonarjs/cognitive-complexity
  function getClientInfo() {
    contactService.clientInformGet($scope.familyId).then((response) => {
      // Client Header Name
      let clientHeaderName = '';
      let email = '';

      $scope.clients = response.data;
      $scope.isEmailSendEnable = false;
      $scope.recipients.email = [];
      $scope.recipients.name = [];
      $scope.originalRecipients.name = [];
      $scope.originalRecipients.email = [];
      $scope.ccs.name = [];
      $scope.ccs.email = [];
      $scope.recipientsObjectArray = [];
      angular.forEach($scope.clients, (client) => {
        email = '';
        if (_.size(client.Email) > 0 && client.Email[0].EmailAddress) {
          email = client.Email[0].EmailAddress;
          $scope.recipients.email.push(email);
          if (client.Role.toLowerCase() === 'adult' && !client.Deceased) {
            $scope.originalRecipients.email.push(email);
          }
        }
        if (client.Role.toLowerCase() === 'adult' && !client.Deceased) {
          // will only display header name for adults
          if (clientHeaderName !== '') {
            clientHeaderName += ` & ${client.FirstName} ${client.LastName}`;
          } else if (response.data.length > 1) {
            clientHeaderName += client.FirstName;
          } else {
            clientHeaderName += `${client.FirstName} ${client.LastName}`;
          }
          $scope.recipientsObjectArray.push({
            name: `${client.FirstName} ${client.LastName} [ ${email} ] `,
            email: `${email}`,
          });
          $scope.recipients.name.push({
            name: `${client.FirstName} ${client.LastName} [ ${email} ] `,
            email,
          });
          $scope.originalRecipients.name.push({
            name: `${client.FirstName} ${client.LastName} [ ${email} ] `,
            email,
          });
          $scope.originalRecipientsCount++;
        }
      });
      $scope.disabledEmailBtn();
      $scope.clientHeaderName = clientHeaderName;
    });
  }

  $scope.adviserChanged = (selectedEmail) => {
    $scope.selectedEmailAdviser = selectedEmail;
    const adviserName =
      DEFAULT_ADVISER === selectedEmail.fullName
        ? ''
        : selectedEmail.fullName || $scope.selectedEmailAdviser.fullName;

    $scope.email = {
      ...$scope.email,
      From: {
        Name: adviserName,
        EmailAddress: selectedEmail.email,
      },
      ReplyTo: {
        Name: adviserName,
        EmailAddress: selectedEmail.email,
      },
    };
  };
  $scope.getAdviserList = () => {
    mycrmLookupService
      .getAdvisers({
        clientFamilyId: $scope.familyId,
      })
      .then((response) => {
        $scope.adviserList = [defaultAdviserOption, ...response];

        return optionsService.assistantsWithEmailGet(
          currentUserService.familyId,
        );
      })
      .then((data) => {
        const mappedAssistantList = data.map(mapAssistantsToDropdown);
        $scope.adviserList = [...$scope.adviserList, ...mappedAssistantList];
        return contactService.getContactAssignedAdviser($scope.familyId);
      })
      .then(({ data }) => {
        const adviser = $scope.adviserList.find(
          (adviser) => adviser.familyId === data,
        );
        $scope.defaultAdviserEmail = adviser.email;
      });
  };

  $scope.init = function () {
    // Ckeditor Tollbar Configuration
    $scope.options = {
      language: 'en',
      allowedContent: true,
      uiColor: '#DFD6E4',
      height: 300,
      resize_enabled: false,
      removePlugins: 'elementspath',
      toolbar: [
        { items: ['BGColor'] },
        { items: ['JustifyLeft'] },
        { items: ['BulletedList', '-'] },
        { items: ['Link'] },
        { items: ['Image'] },
        { items: ['Table'] },
        { items: ['Paste', '-'] },
        { items: ['Smiley'] },
        { items: ['-'] },
        '/',
        { items: ['Format'] },
        { items: ['Font'] },
        { items: ['FontSize', '-'] },
        { items: ['Bold'] },
        { items: ['Italic'] },
        { items: ['Underline'] },
        { items: ['Subscript'] },
        { items: ['Superscript'] },
      ],
    };

    // Get Email Template Category
    $scope.emailTemplateCategoryList = [];
    $scope.getemailTemplateCategory = function () {
      $scope.emailTemplateCategoryList = customerCareTemplateService.getEmailTemplateCategory(
        $scope.familyId,
      );
    };
    $scope.getemailTemplateCategory();

    $timeout(() => {
      // Determine if Adviser or Client
      // Client ID is 0 if client
      if ($scope.paramClientId > 0) {
        getAdviserInfo();
      } else {
        getClientInfo();
      }
    }, 1000);

    // List Of Recipient
    $scope.getRecipientAutoSuggestion = function (userInput) {
      contactService.getRecipientAutoSuggestion(userInput).then((response) => {
        return response.data;
      });
    };

    $scope.mergeFieldsLoanObject.init();
    $scope.isEnabledFromToFeature && $scope.getAdviserList();
  };
  $scope.init();

  /* watches */
  $scope.$watch('recipients.name', (nv, ov) => {
    // added watch counter so that I can add a condition on which the recipients.name is first added a value, watchCounter @ 1 is when recipients.name is populated by the controller above, pushed an object
    $scope.watchCounter++;

    if (
      !_.isUndefined(nv) &&
      !_.isEmpty(nv) &&
      $scope.watchCounter > 2 &&
      nv.length > ov.length
    ) {
      const recipientsLength = $scope.recipients.name.length;
      const newEmailFromTagging = $scope.recipients.name[recipientsLength - 1];
      if (
        validateEmailService.validateEmail(newEmailFromTagging) === false &&
        !_.isUndefined(ov)
      ) {
        toaster.pop('warning', 'Oops', 'Email format is invalid.');
        const names = angular.copy($scope.recipients.name);
        names.splice(-1, 1);

        $scope.recipients.name = angular.copy(names);
      } else {
        $scope.recipients.name.pop();
        $scope.recipients.name.push({
          email: newEmailFromTagging,
          name: newEmailFromTagging,
        });
        $scope.recipients.email.push(newEmailFromTagging);
      }
    }
  });

  $scope.$watch('ccs.name', (nv) => {
    if (!_.isUndefined(nv) && $scope.ccs.name.length) {
      const ccsLength = $scope.ccs.name.length;
      const newEmailFromTagging = $scope.ccs.name[ccsLength - 1];

      if (newEmailFromTagging && newEmailFromTagging.email) {
        return;
      }
      if (!validateEmailService.validateEmail(newEmailFromTagging)) {
        toaster.pop('warning', 'Oops', 'Email format is invalid.');
        const names = angular.copy($scope.ccs.name);
        names.splice(-1, 1);

        $scope.ccs.name = angular.copy(names);
      } else {
        $scope.ccs.name.pop();
        $scope.ccs.name.push({
          email: newEmailFromTagging,
          name: newEmailFromTagging,
        });
        $scope.ccs.email.push(newEmailFromTagging);
      }
    }
  });

  $scope.onRemoveCcEmail = (item) => {
    if (!item || !item.email) {
      return;
    }
    $scope.ccs.email =
      $scope.ccs.email && $scope.ccs.email.filter((x) => x !== item.email);
  };

  // Send Email
  // eslint-disable-next-line sonarjs/cognitive-complexity
  $scope.sendEmail = function (form) {
    let firstError = null;
    if (form.$invalid) {
      form &&
        Object.keys(form).forEach((object) => {
          if (object[0] !== '$') {
            if (firstError === null && !form[object].$valid) {
              firstError = form[object].$name;
            }

            if (form[object].$pristine) {
              form[object].$dirty = true;
            }
          }
        });
      angular.element(`.ng-invalid[name=${firstError}]`).focus();
    } else {
      $scope.isEmailSendEnable = true;
      mailTemplateService
        .isLoanForMailContentValid(
          $scope.email.Content,
          $scope.mergeFieldsLoanObject.selectedLoan,
        )
        .then((isValid) => {
          if (isValid) {
            $scope.recipientsEmailArray = [];
            $scope.ccsEmailArray = [];
            angular.forEach($scope.recipientsObjectArray, (item) => {
              $scope.recipientsEmailArray.push({ EmailAddress: item.email });
            });
            angular.forEach($scope.ccs.email, (item) => {
              $scope.ccsEmailArray.push({ EmailAddress: item });
            });
            $scope.email.Recipients = $scope.recipientsEmailArray;
            $scope.email.Attachment = $scope.emailModel.attachments;
            $scope.email.CC = $scope.ccsEmailArray;
            if (this.isWorkbench) {
              $scope.email.WorkbenchLoanId = this.loanId;
              $scope.email.WorkbenchQuoteID = this.quoteId;
              $scope.email.TemplateName =
                $scope.templates.selectedTemplate[0].UserTemplateName || null;
            }

            contactService.sendEmail($scope.email).then(
              () => {
                $scope.recepients = $scope.originalRecipients;
                $scope.ccs = [];
                $scope.email.Subject = '';
                $scope.email.Content = '';
                form.$setPristine(true);

                if (!this.isWorkbench) {
                  redirectToContact({ $window, $scope, $state });
                } else {
                  workbenchSuccess('Email Sent Successfully', () => {
                    this.onBack({ $event: { refresh: true } });
                  });
                }
                $scope.initiateSignature();
              },
              () => {
                $scope.isEmailSendEnable = false;
              },
            );
          } else {
            $scope.isEmailSendEnable = false;
          }
          $scope.validation.selectedLoanInvalid = !isValid;
        });
    }
  }.bind(this);

  // Remove Recepiant
  $scope.removeRecipient = (item) => {
    _.remove($scope.recipientsObjectArray, item);
    $scope.disabledEmailBtn();
  };

  // select Recepiant
  $scope.selectRecipient = (item) => {
    $scope.recipientsObjectArray.push({ name: item, email: item });
    $scope.disabledEmailBtn();
  };

  // Disabled Send Email Button
  $scope.disabledEmailBtn = () => {
    const boolValueData = _.find($scope.recipientsObjectArray, (e) => {
      return e.email === '';
    });

    if ($scope.recipientsObjectArray.length === 0 || boolValueData) {
      $scope.isEmailSendEnable = true;
    } else {
      $scope.isEmailSendEnable = false;
    }
  };

  // Get Email Template Content
  $scope.getEmailTemplateContent = function (template) {
    _.map($scope.emailTemplateCategoryList, (item) => {
      if (!item.HasSubCategory) {
        item.Template.isSelected =
          parseInt(item.Template.UserTemplateID, 10) ===
          parseInt(template.UserTemplateID, 10);
      } else {
        angular.forEach(item.ListOfTemplates, (innerItem) => {
          innerItem.isSelected =
            parseInt(innerItem.UserTemplateID, 10) ===
            parseInt(template.UserTemplateID, 10);
        });
      }
    });
    contactService
      .getEmailTemplateContent(template.UserTemplateID, $scope.familyId)
      .then((response) => {
        $scope.email.Content = response.data.TemplateContent;
        $scope.email.Subject = response.data.UserTemplateEmailSubject;
      });
  };

  // Called when the editor is completely ready.
  $scope.onReady = function () {};

  // Opern Model For Quick Start Video
  $scope.openModalForQuickStartVideo = function (size) {
    $uibModal.open({
      templateUrl:
        '/assets/views/contacts/partials/quick_start_video_modal.html',
      controller: 'QuickStartVideoModalCtrl',
      size,
    });
  };

  $scope.attachedFile = [];

  $scope.tmpAttachedFile = [];
  const attachFile = (arr) => {
    $scope.attachedFile = arr;
    if (!$scope.emailModel.attachments) {
      $scope.emailModel.attachments = [];
    }
    $scope.attachedFile &&
      Object.keys($scope.attachedFile).forEach((x) => {
        $scope.emailModel.attachments = [
          ...$scope.emailModel.attachments,
          $scope.attachedFile[x],
        ];
      });
  };

  $scope.emailModel.attachments = $scope.attachedFile[0];
  $scope.openAttachMent = function () {
    const modalInstance = commonFnService.attachFileMgmt($scope.familyId);
    modalInstance.result.then(attachFile);
  };

  $scope.saveCurrentContentAsTemplate = () => {
    if ($scope.email) {
      emailTemplatesSharedData.setNewTemplateToSave(
        $scope.email.Subject,
        $scope.email.Content,
      );
      $scope.openEmailManagement();
    }
  };
  $scope.removeAttachment = (item) => {
    _.remove($scope.emailModel.attachments, item);
  };

  $scope.clearEditor = () => {
    if (!$scope.email.Content || !$scope.email.Content.length) {
      return;
    }

    swal(
      {
        title: 'Are you sure?',
        text: 'Your content will be removed from the editor.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F68F8F',
        confirmButtonText: 'Yes, remove it!',
        closeOnConfirm: true,
      },
      (isConfirm) => {
        if (isConfirm) {
          $scope.resetEditor();
        }
      },
    );
  };

  $scope.resetEditor = () => {
    $scope.templates.selectedTemplate = {
      UserTemplateID: 0,
      UserTemplateName: 'Blank',
      UserTemplateContent: null,
    };
    $scope.email.Content = null;
    $scope.selectedLoanInvalid = false;
  };
}

export default SendEmailComponentCtrl;
