import controller from './commissionFilterInlineCtrl';
import template from './commissionFilterInline.html';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    adviserList: '<',
    lenderList: '<',
    commissionTypeList: '<',
    filterSettings: '<',
    onFilterChange: '&',
    onExportTableData: '&',
  },
};
