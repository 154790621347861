import { statusListBuilderForUI } from 'Common/mappers/loanFile';

class LoanFileService {
  constructor(httpClient) {
    'ngInject';

    this.httpClient = httpClient;
    this.apiBaseUrl = 'loan-file';
  }

  getStatusList(loanId = 0) {
    return this.httpClient
      .get(`${this.apiBaseUrl}/status-list`, { loanId })
      .then(
        ({ data }) =>
          data && data.map((status) => statusListBuilderForUI(status, loanId)),
      );
  }

  setNewFile(data) {
    return this.httpClient.post(`${this.apiBaseUrl}/new-file`, data);
  }

  searchName({ searchNameString, adviserId = 0, page = 1, count = 20 }) {
    return this.httpClient.get(`${this.apiBaseUrl}/search-name`, {
      searchNameString,
      adviserId,
      page,
      count,
    });
  }
}

export default LoanFileService;
