import angular from 'angular';
import { forEach } from 'lodash';
import { IMPORTANT_DATE_TYPES } from 'Common/constants/importantDateTypes';
import { LOAN_APP_STATUS_TYPES } from 'Common/constants/loanAppStatusTypes';
import { INTERNAL_SERVER_ERROR } from 'Common/constants/httpStatusCodes';
import { LOAN_STATUS } from 'Common/constants/loanStatusCategory';
import { typelessEqual } from 'Common/utilities/math';
import { parseToInt10 } from 'Common/utilities/parse';
import { PIPELINE_TYPES_ID } from 'Common/constants/pipelineType';
import { isMobileView } from 'Common/utilities/clientWindowWatcher';
import { toastInfo } from 'Common/utilities/alert';
import {
  loanDetail,
  openLoanViewer,
  getImportantDates,
  getImportantDateFields,
  updateLoanStatusCallback,
  deleteLoanModal,
  redirectDuplicatedLoanToDealView,
  updateLoanStatusCallToService,
  getBorrowersToolTipValue,
  showLoanVariationModal,
} from './util/loanDetail';

export const loadModule = () =>
  angular
    .module('app')
    // eslint-disable-next-line sonarjs/cognitive-complexity
    .controller('LoanDetailCtrl', function LoanDetailCtrl(
      $scope,
      contactService,
      loanApplicationServices,
      $state,
      $stateParams,
      $uibModal,
      imageDataURI,
      SweetAlert,
      $timeout,
      contactSharedData,
      toaster,
      loanAppSharedData,
      $window,
      modalRenderService,
      pipelineSharedData,
      loanAppDetailsService,
      pipelineService,
      stateService,
      configService,
      timeoutService,
      loanScenarioModelService,
      loanScenarioService,
      crmConfirmation,
      loanFinancialsService,
      contactSharedDataService,
      uiService,
      loanOpportunityService,
      loanFileService,
    ) {
      $scope.newlyClonedLoanApp = {};
      $scope.familyId = $state.params.familyId;
      $scope.openLoanViewer = openLoanViewer({ $scope, $state });
      $scope.getBorrowersToolTipValue = getBorrowersToolTipValue;
      $scope.updateLoanStatusCallToService = updateLoanStatusCallToService({
        uiService,
        contactService,
        loanFileService,
        loanOpportunityService,
      });
      getImportantDates({ $scope })(loanApplicationServices);
      loanDetail({ $scope })(configService, uiService);
      $scope.showLoanVariationModal = showLoanVariationModal(
        $uibModal,
        $scope.familyId,
      );
      const refreshLendingBroadcastHandler = $scope.$on(
        'UPDATE_LENDING_ON_CHANGE_STATUS',
        (isUpdate) => {
          if (isUpdate) {
            $scope.getLoanListGet();
          }
        },
      );
      $scope.$on('$destroy', refreshLendingBroadcastHandler);

      $scope.$watch('defaultTabOnloadAction', (newValue, oldValue) => {
        if (
          newValue === 'loanInformationModal' &&
          oldValue !== 'loanInformationModal'
        ) {
          $scope.loanInformationModal('create', $scope.familyId, 0);
        }
        if (
          newValue === 'addExistingLoansModal' &&
          oldValue !== 'addExistingLoansModal'
        ) {
          $scope.addExistingLoansModal('create', $scope.familyId, 0);
        }
      });

      const loadLoanWatch = $scope.$watch(
        () => {
          return loanAppDetailsService.loadLoanList;
        },
        (nv) => {
          if (nv) {
            $scope.getLoanListGet();
          }
          loanAppDetailsService.loadLoanList = false;
        },
      );

      $scope.$on('$destroy', () => {
        loadLoanWatch();
        timeoutService.cancelTimeouts('LoanDetailCtrl');
      });

      $scope.deleteLoan = ({ loanId, isLockable, isLocked, openTab }) => {
        const deleteLoanModalParams = {
          isLockable,
          isLocked,
          loanDeleteAction: $scope.loanDeleteAction,
          loanId,
          crmConfirmation,
          swal: SweetAlert.swal,
          openTab,
        };

        deleteLoanModal(deleteLoanModalParams);
      };

      $scope.loanDeleteAction = (loanId) => {
        contactService.loanDelete(loanId).then(() => {
          $timeout(() => {
            $scope.getLoanListGet();
            if (typeof $scope.PipelineCardsTimeLineViewGet === 'function') {
              $scope.PipelineCardsTimeLineViewGet();
            }
            contactSharedDataService.reloadContactLoanTimeline();
            SweetAlert.swal({
              title: 'Success',
              text: 'Loan has been been successfully deleted.',
              type: 'success',
              timer: 2000,
              showConfirmButton: false,
            });
          }, 100);

          contactSharedData.lendingSharedData.updateLinkToLoan = true;
        });
      };

      $scope.loanInformationModal = (
        action,
        familyId,
        loanId,
        loanScenarioID,
        loanInformation,
      ) => {
        const params = {
          loanInformationAction: action,
          familyId: parseInt(familyId, 10),
          loanId,
          loanInformation,
          loanScenarioID,
        };

        const modalInstance = $uibModal.open({
          templateUrl: '/assets/views/contacts/partials/loan_modal_v2.html',
          controller: 'LoanInformationCtrl',
          scope: $scope,
          size: 'wide',
          backdrop: 'static',
          keyboard: false,
          windowClass: $scope.additionalLoanImportantDatesFlag
            ? 'loan-information-modal'
            : '',
          resolve: {
            params: () => params,
            loanType: () => loanScenarioID,
          },
        });

        modalInstance.result.then((result) => {
          const { originalLoanStatusId, loanStatusId } = result;
          const isLoanSetToSettled =
            loanStatusId !== originalLoanStatusId &&
            parseToInt10(loanStatusId) === LOAN_STATUS.EXISTING.SETTLED;

          if (isLoanSetToSettled) {
            loanFinancialsService.showAutoCreatedFinancialsOnSettlement({
              params: {
                familyId: params.familyId,
                loanAppId: params.loanScenarioID,
              },
            });
          }
          // TODO: call up lending-details > loan-list-get
          contactSharedData.lendingSharedData.loanDetail = {
            processAction: action,
            processResult: result,
          };
          if (typeof $scope.PipelineCardsTimeLineViewGet === 'function') {
            $scope.PipelineCardsTimeLineViewGet();
          }
          contactSharedDataService.reloadContactLoanTimeline();
          $scope.getLoanListGet();
        });
      };

      $scope.loanDetailsModal = (action, familyId, loanId, loanInfo) => {
        $scope.params = {
          loanInformationAction: action,
          familyId: parseInt(familyId, 10),
          loanId,
        };

        const modalInstance = $uibModal.open({
          templateUrl:
            '/assets/views/contacts/client/lending/detailsModal.html',
          controller: 'LoanDetailsModalCtrl',
          resolve: {
            loanInfo() {
              return loanInfo;
            },
            action() {
              return action;
            },
          },
        });

        modalInstance.result.then(
          () => {
            $scope.getLoanListGet();
          },
          () => {
            $scope.getLoanListGet();
          },
        );
      };

      const refreshLoanList = () => {
        $timeout(() => {
          contactSharedDataService.reloadContactLoanTimeline();
          $scope.getLoanListGet();
        }, 1000);
      };

      $scope.callLoanStatusSet = (
        status,
        inprogressLoan,
        shouldSendNurtureCampaigns,
        reasonSubstatusID,
      ) => {
        angular.extend(inprogressLoan, {
          PreviousLoanStatus: { ...inprogressLoan.LoanStatus },
        });

        angular.extend(inprogressLoan.LoanStatus, {
          LoanStatusName: status.LoanStatusName,
          LoanStatusID: status.LoanStatusID,
        });
        getImportantDateFields({ $scope })(status);

        $scope
          .updateLoanStatusCallToService({
            status,
            loanDetails: inprogressLoan,
            shouldSendNurtureCampaigns,
            reasonSubstatusID,
          })
          .then((response) => {
            if (response && response.status === INTERNAL_SERVER_ERROR) {
              refreshLoanList();
              return;
            }
            toaster.pop('success', 'Loan Status successfully updated!');
            $scope.loanDetailScope.importantDateField =
              $scope.importantDateField;
            if (inprogressLoan.PipelineCardsID) {
              loanAppSharedData.lendingTabPipelineStatusData = {
                PipelineStatusID: inprogressLoan.LoanStatus.LoanStatusID,
              };
            }

            /**
             * Check if status is Approval then save the current date as Approval Date
             * */
            if (
              !$scope.additionalLoanImportantDatesFlag &&
              status.LoanStatusName === 'Unconditional Approval'
            ) {
              // Assign current date
              inprogressLoan.ApprovalDate = new $window.Date();

              // Save the approval date
              $scope.importantDateSave(
                inprogressLoan,
                IMPORTANT_DATE_TYPES.IN_PROGRESS.APPROVAL,
                inprogressLoan.ApprovalDate,
              );
            }

            $scope.importantDatesPopup[inprogressLoan.LoanID] = !!$scope
              .importantDateField.length;
            if (
              $scope.additionalLoanImportantDatesFlag &&
              $scope.importantDatesPopup[inprogressLoan.LoanID]
            ) {
              return;
            }
            refreshLoanList();
          });
      };

      $scope.updateLoanStatus = (status, inprogressLoan) => {
        if (
          typelessEqual(
            status.LoanStatusID,
            LOAN_APP_STATUS_TYPES.NOT_PROCEEDED,
          )
        ) {
          const modalCallback = updateLoanStatusCallback({
            $scope,
            loanScenarioModelService,
            status,
            inprogressLoan,
          });
          modalRenderService
            .renderPipelineStatusMoveConfirmationModal({
              showNurtureCampaignsQuestion: pipelineSharedData.showNurtureCampaignsQuestion(
                $scope.getContactFamilyInfo &&
                  $scope.getContactFamilyInfo.ReferralItem,
              ),
              reasonList: $scope.reasonListView,
              isNotProceeded: true,
            })
            .result.then(modalCallback);
        } else if (
          !$scope.additionalLoanImportantDatesFlag &&
          typelessEqual(status.LoanStatusID, LOAN_STATUS.EXISTING.SETTLED)
        ) {
          $scope.callLoanStatusSet(status, inprogressLoan);
          modalRenderService
            .openMovedToSettledModal({
              size: 'modalLinkLoanToPipeline',
              familyID: $scope.familyId,
              loanId: inprogressLoan.LoanID,
              loanScenarioId: inprogressLoan.LoanScenarioID,
            })
            .then(() => {
              $scope.getLoanListGet();
            });
        } else {
          $scope.callLoanStatusSet(status, inprogressLoan);
        }
      };

      $scope.cloneLoan = (loan, loansSectionLoadingStatus) => {
        $scope[loansSectionLoadingStatus] = true;
        toastInfo('Duplicating application inprogress...', 'Info');
        loanApplicationServices
          .cloneLoan(loan.LoanScenarioID, $scope.familyId)
          .then(
            (response) => {
              $scope[loansSectionLoadingStatus] = false;
              $scope.getLoanListGet();
              $scope.newlyClonedLoanApp.loanAppId = response.data;
              if (uiService.viewOfAfileTurnedOn) {
                return redirectDuplicatedLoanToDealView({
                  loanScenarioService,
                  $scope,
                  loansSectionLoadingStatus,
                });
              }

              $state.go('app.loanApplicationDetailsV3', {
                familyId: $scope.familyId,
                loanAppId: $scope.newlyClonedLoanApp.loanAppId,
              });
            },
            () => {
              $scope[loansSectionLoadingStatus] = false;
            },
          );
      };

      $scope.workbench = (inprogressLoan) => {
        if (!isMobileView($window.innerWidth)) {
          const modalInstance = $uibModal.open({
            templateUrl:
              'assets/views/loanApplication/components/workbench.html',
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
            resolve: {
              cLoanId: () => inprogressLoan.LoanID,
              CLoanStatusId: () => inprogressLoan.LoanStatus.LoanStatusID,
              CPipelineCardsID: () => inprogressLoan.PipelineCardsID,
              cLoanScenarioId: () => inprogressLoan.LoanScenarioID,
              cFamilyId: () => $scope.familyId,
              cEnquirySource: () =>
                $scope.getContactFamilyInfo &&
                $scope.getContactFamilyInfo.ReferralItem,
            },
            controller: 'WorkbenchModalCtrl',
            controllerAs: 'vm',
            windowTopClass: 'workbench-modal',
          });
          modalInstance.result.then(
            () => {},
            (error) => {
              forEach($scope.LoanStatusList, (stat) => {
                if (String(stat.LoanStatusID) === error.loanStatusId) {
                  inprogressLoan.LoanStatus.LoanStatusID = error.loanStatusId;
                  inprogressLoan.LoanStatus.LoanStatusName =
                    stat.LoanStatusName;
                }
              });
              // @TODO: Workbench should return loanId
              contactSharedDataService.reloadContactLoanTimeline();
              $scope.getLoanListGet();
            },
          );
        } else {
          const params = {
            ...inprogressLoan,
            familyId: $scope.familyId,
            enquirySource: $scope.getContactFamilyInfo.ReferralItem,
          };
          stateService.savePrevState(
            $state.$current.self,
            $state.$current.locals.globals.$stateParams,
          );
          $state.go('app.mobileWorkbench', {
            LoanID: params.LoanID,
            LoanStatusID: params.LoanStatus.LoanStatusID,
            PipelineCardsID: params.PipelineCardsID,
            LoanScenarioID: params.LoanScenarioID,
            familyId: params.familyId,
            enquirySource: params.enquirySource,
            clientNames: params.Applictants.trim(),
          });
        }
      };

      $scope.getReasonList = () => {
        pipelineService
          .getNotProceedReasons(PIPELINE_TYPES_ID.APPLICATION)
          .then(({ reasonList }) => {
            $scope.reasonListView = reasonList || null;
          });
      };
      $scope.getReasonList();

      $scope.importantDatesPopup = {};

      $scope.closePopup = (key) => {
        $scope.importantDatesPopup[key] = false;
        refreshLoanList();
      };

      $scope.loanDetailScope = {
        ...$scope,
      };

      $scope.extendChildScopeFromParentScope = (lendingDetailScope) => {
        if (!$scope.getLoanListGet) {
          $scope = { ...$scope, ...lendingDetailScope };
        }
      };
    });
