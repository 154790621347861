import template from './loanAppLendingFee.html';
import controller from './loanAppLendingFeeCtrl';
import './style.scss';

export default {
  template,
  controller,
  controllerAs: 'vm',
  bindings: {
    fee: '<',
    loanStructureId: '<',
    idx: '<',
    rootClass: '<',
    onToggle: '&',
    onFeeChanges: '&',
  },
};
