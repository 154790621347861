export const FIRST_HOME = 1;
export const NEXT_HOME = 2;
export const INVESTMENT_PROPERTY = 3;
export const COMMERCIAL = 4;
export const INDUSTRIAL = 5;
export const ASSET_FINANCE = 25;
export const CAR_FINANCE = 26;
export const GET_A_BETTER_DEAL = 6;
export const RENOVATE = 7;
export const CONSOLIDATE_DEBT = 8;
export const REVERSE_MORTGAGE = 9;
export const BORROW_EXTRA_FUNDS = 10;
export const REFINANCE = 11;
export const PERSONAL_LOAN = 28;
