import moment from 'moment';
import { parseToInt10 } from 'Common/utilities/parse';
import { toDate } from 'Common/utilities/date';
import { DATE_TEXT_FORMAT } from 'Common/constants/dateConfigs';
import { capitalizeFirstLetter } from 'Common/utilities/string';
import { ONE_OFF_TYPES } from 'Common/constants/commissionType';

export function expectedCommissionsQueryBuilderForMyCRM(query) {
  return (
    query && {
      AdviserID: query.adviserId,
      AdviserName: query.adviserName,
      AdviserEmail: query.adviserEmail,
      ClientIDs: query.clientId && query.clientId.toString(),
      ClientName: query.clientName,
      Entities: query.borrowerEntity,
      ProviderID: parseInt(query.lenderId, 10),
      SettlementDate:
        query.settlementDate &&
        moment(query.settlementDate).format('YYYY-MM-DDThh:mm:ss'),
      LoanAmount: parseInt(query.loanAmount, 10),
      ReferenceNumber: query.referenceNumber,
      Notes: query.notes,
    }
  );
}

export const commissionWidgetDatesBuilderForUI = (option) => {
  return {
    name: option.Name,
    value: parseToInt10(option.Value),
  };
};

export function commissionReportBuilderForUI(report) {
  return {
    key: report.Key,
    size: report.Size,
    lastModified: report.LastModified,
  };
}

export function loansOneOffCommissionsBuilderForUI(
  commission,
  isCommissionEnhancementsV3On,
) {
  const varyingDate =
    ONE_OFF_TYPES.REFIX === commission.TypeId
      ? toDate(commission.RefixDate)
      : toDate(commission.DateSettled);
  const date = isCommissionEnhancementsV3On
    ? varyingDate
    : toDate(commission.DateSettled);
  return {
    id: commission.ExpectationID,
    date,
    typeId: commission.TypeId,
    loanStructureID: commission.LoanStructureID,
    expectedAmount: commission.ExpectedCommission,
    paidAmount: commission.AmountPaid,
    isPaid: commission.Paid,
    paidDate: toDate(commission.DatePaid),
    rate: commission.CommissionRatio,
    rateAmount: commission.Amount,
    isActualPayment: commission.IsActualPayment,
    settlementDate: toDate(commission.DateSettled),
    refixDate: toDate(commission.RefixDate),
    dateExpected: toDate(commission.DateExpected),
    isRatio: commission.IsRatio,
    productName: commission.ProductName,
    modifiable: commission.CanModify,
  };
}

export function oneOffCommissionRatesBuilderForUI(commObj) {
  return {
    commissionTypeID: commObj.CommissionTypeID,
    numberOfDays: commObj.NumberOfDays,
    commissionRate: commObj.CommissionRate,
    isPercentage: commObj.IsPercentage,
  };
}

export function expectedCommissionsSummaryBuilderForUI(summary) {
  return {
    amount: summary.TotalAmount || 0,
    percent: summary.TotalPercent,
    type: summary.TotalType,
  };
}

export function loansExpectedCommissionsRecurringBuilderForUI(commission) {
  return {
    id: commission.RecurringID,
    productId: commission.LoanStructureID ? commission.LoanStructureID : 0,
    frequencyId: commission.FrequencyID ? commission.FrequencyID : 0,
    expectedAmount: commission.ExpectedAmount,
    amount: commission.ActualPaidAmount,
    isActive: commission.IsStillRecurring,
    rate: commission.CommissionRatio * 100,
    rateAmount: commission.LoanAmount,
    dateStart: commission.DateStart
      ? moment(commission.DateStart).toDate()
      : null,
    dateCreated: commission.DateCreated,
  };
}

export function commissionLoanStructureBuilderForUI(commissionLoanStructure) {
  return {
    expectationID: commissionLoanStructure.ExpectationID,
    loanID: commissionLoanStructure.LoanID,
    loanStructureID: commissionLoanStructure.LoanStructureID,
    loanAmount: commissionLoanStructure.LoanAmount,
    familyID: commissionLoanStructure.FamilyID,
    clientName: commissionLoanStructure.ClientName,
    providerID: commissionLoanStructure.ProviderID,
    providerName: commissionLoanStructure.ProviderName,
    productName: commissionLoanStructure.ProductName,
    adviserName: commissionLoanStructure.AdviserName,
    expectedCommission: commissionLoanStructure.ExpectedCommission,
    typeName: commissionLoanStructure.TypeName,
    dateExpected: commissionLoanStructure.DateExpected,
    datePaid: commissionLoanStructure.DatePaid,
  };
}

export const unmatchedCommissionBuilderForUI = (data) => {
  return (
    data && {
      id: data.Commission_Spreadsheet_ID,
      spreadSheetId: data.Commission_Spreadsheet_ID,
      client: data.ClientName,
      providerId: data.ProviderId,
      provider: data.ProviderName,
      adviser: data.AdviserName,
      type: (data.TypeName && capitalizeFirstLetter(data.TypeName)) || '',
      isMatched: data.IsMatched,
      totalCount: data.TotalCount,
      commissionAmount: data.AmountPaid || 0,
      unmatchedTotal: data.UnmatchedTotal || 0,
      loanAmount: data.LoanAmount || 0,
      dateProcessed:
        (data.ProcessDate &&
          moment(data.ProcessDate).format(DATE_TEXT_FORMAT)) ||
        '',
      datePaid: (data.DatePaid && moment(data.DatePaid).toDate()) || '',
      referenceNo: data.DebitReference,
      paymentReferenceNo: data.DebitReference
        ? `${moment(data.DatePaid).format(DATE_TEXT_FORMAT)} / ${
            data.DebitReference
          }`
        : '',
    }
  );
};
export const searchCommissionBuilderForUI = (data) => {
  return (
    data && {
      client: data.ClientName,
      providerId: data.LenderId,
      provider: data.LenderName,
      adviser: data.BrokerFullName,
      adviserId: data.BrokerID,
      type: data.CommissionTypeDesc || '',
      totalCount: data.TotalCount,
      commissionAmount: data.Commission || 0,
      loanAmount: data.TransactionAmount || 0,
      dateProcessed:
        (data.TransactionDate &&
          moment(data.TransactionDate).format(DATE_TEXT_FORMAT)) ||
        '',
      referenceNo: data.ReferenceNo,
      paymentReferenceNo: data.PaymentReferenceNo
        ? `${moment(data.ReferenceDate).format(DATE_TEXT_FORMAT)} / ${
            data.PaymentReferenceNo
          }`
        : '',
      totalCommission: data.TotalCommissionAmount,
      totalLoan: data.TotalLoanAmount,
    }
  );
};

export const paymentCommissionBuilderForUI = (data) => {
  return (
    data && {
      adviser: data.BrokerFullName,
      adviserId: data.BrokerId,
      totalCount: data.TotalCount,
      commissionAmount: data.CommissionAmount || 0,
      description: data.Description,
      datePaid:
        (data.DatePaid && moment(data.DatePaid).format(DATE_TEXT_FORMAT)) || '',
    }
  );
};

export const unmatchedCommissionBuilderForAPI = (data) => {
  return (
    data && {
      Commission_Spreadsheet_ID: data.spreadSheetId,
      ProviderId: data.providerId,
      TypeName: data.type,
      AmountPaid: data.commissionAmount,
      LoanAmount: data.loanAmount,
      DatePaid: data.datePaid,
    }
  );
};

export const expectedCommissionBuilderForUI = (data) => {
  return (
    data && {
      id: data.ExpectationID,
      commissionId: data.ExpectationID,
      loanId: data.LoanID,
      type: (data.TypeName && capitalizeFirstLetter(data.TypeName)) || '',
      familyId: data.FamilyID,
      client: data.ClientName,
      adviser: data.AdviserName,
      loanAmount: data.LoanAmount,
      provider: data.ProviderName,
      providerId: data.ProviderID,
      totalCount: data.TotalCount,
      dateSettled:
        (data.DateSettled &&
          moment(data.DateSettled).format(DATE_TEXT_FORMAT)) ||
        '',
      dateExpected:
        (data.DateExpected &&
          moment(data.DateExpected).format(DATE_TEXT_FORMAT)) ||
        '',
      loanStatusId: data.LoanStatusID,
      loanScenarioId: data.LoanScenarioID,
      loanStructureId: data.LoanStructureID,
      expectedAmount: data.ExpectedCommission,
      expectedTotal: data.ExpectedTotal || 0,
      loanStatusCategory: data.LoanStatusCategory,
    }
  );
};

export const commissionEnquiryBuilderForAPI = (data) => {
  return (
    data && {
      AdviserID: data.adviserId,
      AdviserEmail: data.returnEmail,
      ClientIDs: data.clientIds,
      Entities: data.borrowingEntity,
      ProviderID: data.lenderId,
      SettlementDate: data.settlementDate,
      LoanAmount: data.loanAmount,
      ReferenceNumber: data.referenceNumber,
      Notes: data.notes,
    }
  );
};
