import {
  activeFactFindCrmConfirmation,
  inactiveFactFindSweetAlert,
} from 'Common/utilities/contactsDeleteFactFindWarnings';
import {
  crmOptionSettings,
  swalOptionSettings,
} from 'Common/constants/loanDeleteWarningSettings';
import { deleteLoan } from 'Common/utilities/deleteLoanAction';
import { PIPELINE_STATUS } from 'Common/constants/pipelineStatus';

export const deletePipelineItemService = ({
  event,
  loanDetails,
  swal,
  deleteAction,
  loanScenarioService,
  crmConfirmation,
}) => {
  event && event.stopPropagation();

  const activeFactFindCrmConfirmationAction = () => {
    activeFactFindCrmConfirmation({
      crmConfirmation,
      deleteAction,
      crmOptionSettings,
    });
  };
  const inactiveFactFindSweetAlertAction = () => {
    inactiveFactFindSweetAlert({ swal, deleteAction, swalOptionSettings });
  };

  if (loanDetails) {
    const loanScenarioId =
      loanDetails.loanScenarioID || loanDetails.loanScenarioId;

    return deleteLoan({
      loanScenarioId,
      loanScenarioService,
      inactiveFactFindSweetAlertAction,
      activeFactFindCrmConfirmationAction,
    });
  }
  inactiveFactFindSweetAlertAction();
};

export const pipelineStatusFilter = (
  isShowSettled = false,
  isShowNotProceeded = false,
) => (response) => {
  if (response.data && response.data.length) {
    const exclude = [];
    if (!isShowNotProceeded) {
      exclude.push(PIPELINE_STATUS.NOT_PROCEEDED);
    }
    if (!isShowSettled) {
      exclude.push(PIPELINE_STATUS.SETTLED);
    }
    response.data = response.data.filter(
      (s) => !exclude.includes(s.PipelineStatusID) || s.PipelineCategoryID,
    );
  }
  return response;
};
