export function announcementBuilderForUI(announcement) {
  return {
    announcementID: announcement.AnnouncementID,
    attachments: announcement.Attachment.map((obj) => {
      return {
        source: obj.Source,
        type: obj.Type,
      };
    }),
    dateCreated: announcement.DateCreated,
    dateEnd: announcement.DateEnd,
    dateStart: announcement.DateStart,
    details: announcement.Details,
    isDisableTryItOut: announcement.IsDisableTryItOut,
    isNew: announcement.IsNew,
    longTitle: announcement.LongTitle,
    title: announcement.Title,
  };
}
