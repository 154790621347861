import {
  contactEmailBuilderForUI,
  contactEmailBuilderForMyCRM,
  contactPhoneBuilderForUI,
  contactPhoneBuilderForMyCRM,
} from 'Common/mappers/contact';
import { getDefaultEmailFromDetail } from 'Common/utilities/emailUtilities';
import { getDefaultMobileFromDetail } from 'Common/utilities/contact';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';
import { phoneType } from 'Common/constants/phoneType';
import { formatNumberWithComma } from 'Common/utilities/currency';
import { humanizeDate } from 'Common/utilities/date';

export function helloBookClientBuilderForUI(client) {
  return (
    client && {
      familyId: client.FamilyId,
      address: client.Address,
      allocatedAdviserId: client.AllocatedAdviserID,
      allocatedAdviserName: client.AllocatedAdviserName,
      email: client.Email && client.Email.map(contactEmailBuilderForUI),
      firstName: client.FirstName,
      fullName: client.FullName,
      dropdownName: client.isPrimary
        ? `${client.FullName} (primary)`
        : client.FullName,
      isSelected: true,
      isDisabled: false,
      gender: client.Gender,
      isExistingCustomer: client.IsExistingCustomer,
      lastName: client.LastName,
      personId: client.PersonId,
      phone: client.Phone && client.Phone.map(contactPhoneBuilderForUI),
      isEntity: client.isEntity,
      isPrimary: client.isPrimary,
      role: client.Role,
      preferredName: client.PreferredName,
      value: true,
      defaultEmail: getDefaultEmailFromDetail(client),
      phoneName: `phone-${client.PersonId}`,
      ...getDefaultMobileFromDetail(client),
      displayName: client.LegalName || client.FullName || client.FamilyFullName,
    }
  );
}

export function helloBookClientBuilderForMyCRM(client) {
  return (
    client && {
      SourceFamilyID: client.familyId,
      FamilyID: client.familyId,
      Address: client.address,
      AllocatedAdviserID: client.allocatedAdviserId,
      AllocatedAdviserName: client.allocatedAdviserName,
      Email: client.email && client.email.map(contactEmailBuilderForMyCRM),
      FirstName: client.firstName,
      FullName: client.fullName,
      Gender: client.gender,
      IsExistingCustomer: client.isExistingCustomer,
      LastName: client.lastName,
      PersonId: client.personId,
      Phone: client.phone && client.phone.map(contactPhoneBuilderForMyCRM),
      IsEntity: client.isEntity,
      IsPrimary: client.isPrimary,
    }
  );
}

export function clientInfoForMyCRM(client) {
  return {
    Email: client.formattedEmail || client.defaultEmail,
    ClientId: client.personId,
    ClientFamilyId: client.familyId,
    Mobile: client.formattedPhone || client.defaultMobile,
    PreferredName: client.preferredName,
  };
}

export function helloBookSendBuilderForMyCRM(form) {
  return (
    form && {
      FactFind: {
        ...form.factFindSections,
        SelectedHeadingIds: form.SelectedHeadingIds,
        IsPartialFactFind: form.IsPartialFactFind,
        LoanApplicationID: form.selectedApplicationId || 0,
        FamilyId: form.client.familyId,
        ClientId: form.client.personId,
        ClientMessage: form.editorMessage,
      },
      FactFindType: form.model.factFindType,
      IsFactFindEnabled: form.model.sections.onlineFactFind,
      IsHelloBookEnabled: form.model.sections.helloBook,
      IsCreditEnabled: form.model.sections.creditGuide,
      IsDisclosureGuide: form.model.sections.disclosureGuide,
      IsCalendlyInviteEnabled: form.model.sections.calendly,
      Message: form.editorMessage,
      CommunicationAppendMessage: form.communicationAppendMessage,
      AdviserId: form.adviser.personId,
      SendType: form.sendType,
      ClientFamilyId: form.client.familyId,
      Email: form.model.email,
      Mobile: form.model.phone,
      Documents: form.documents,
      IsForLoanWriter: form.isForLoanWriters,
      Clients: form.clients.map(clientInfoForMyCRM),
      LoanID: form.loanId || 0,
    }
  );
}

export function helloPackDocumentsBuilderForMyCRM(docs) {
  return (
    docs && {
      Name: docs.name,
      DocumentKey: docs.documentKey,
    }
  );
}

export function helloPackSendBuilderForMyCRM(form) {
  return (
    form && {
      FactFind: {
        ...form.factFindSections,
        ...form.selectedHeadingIds,
        LoanApplicationID: form.selectedApplicationId || 0,
        FamilyId: form.client.familyId,
        ClientId: form.client.personId,
        ClientMessage: form.editorMessage,
      },
      FactFindType: form.factFindType,
      IsFactFindEnabled: form.isFactFindEnabled,
      IsHelloBookEnabled: form.isHelloBookEnabled,
      IsCreditEnabled: form.isCreditEnabled,
      IsDisclosureGuide: form.isDisclosureEnabled,
      IsCalendlyInviteEnabled: form.isCalendlyInviteEnabled,
      IsUploadedAttachments: form.isUploadedAttachments,
      Message: form.editorMessage,
      CommunicationAppendMessage: form.communicationAppendMessage,
      AdviserId: form.adviserId,
      SendType: form.sendType,
      ClientFamilyId: form.client.familyId,
      Email: form.client.defaultEmail,
      Mobile: form.client.defaultMobile,
      Documents: form.documents.map(helloPackDocumentsBuilderForMyCRM),
      IsForLoanWriter: form.isForLoanWriters,
      Clients: form.clients.map(clientInfoForMyCRM),
      CustomerIoWorkflow: form.customerIoWorkflow,
      LoanID: form.loanId || 0,
    }
  );
}

export function attachmentsMapper(attachment) {
  return {
    documentKey: attachment.DocumentKey,
    name: attachment.Name,
  };
}

export const helloBookPrivacyPolicyForUI = (data) => {
  return {
    clientId: data.ClientId,
    privacyConsent: data.PrivacyConsent || '',
    displayName: data.LegalName || '',
    factFindSentDate: data.FactFindSentDate || '',
  };
};

export function helloBookDefaultSettingsForUI(settings) {
  return (
    settings && {
      factFindType: settings.FactFindType.toLowerCase(),
      isFactFindEnabled: settings.IsFactFindEnabled,
      isHelloBookEnabled: settings.IsHelloBookEnabled,
      isCreditEnabled: settings.IsCreditEnabled,
      isDisclosureEnabled: settings.IsDisclosureEnabled,
      isCalendlyInviteEnabled: settings.IsCalendlyInviteEnabled,
      isCCToAdviser: settings.IsCCToAdviser,
      isUploadedAttachments: settings.IsUploadedAttachments,
      calendlyLink: settings.CalendlyLink,
      message: settings.Message,
      factFindSections: {
        yourhousehold: settings.YourHouseHold,
        yourhome: settings.YourHome,
        topurchase: settings.ToPurchase,
        otherproperties: settings.OtherProperties,
        assets: settings.Assets,
        liabilities: settings.Liabilities,
        income: settings.Income,
        expenses: settings.Expenses,
        yourdetails: settings.YourDetails,
        coapplicants: settings.CoApplicants,
        addresshistory: settings.AddressHistory,
        yourgoals: settings.YourGoals,
        employment: settings.Employment,
        forseeablechanges: settings.ForseeableChanges,
        credit: settings.Credit,
        supportingdocuments: settings.SupportingDocuments,
        lenders: settings.Lenders,
        loanfeatures: settings.LoanFeatures,
        services: settings.Services,
        futureplans: settings.FuturePlans,
      },
      attachments: settings.Attachments.map(attachmentsMapper),
      documentRequestMethod: settings.DocumentRequestMethod,
      fileInvite: JSON.parse(settings.FileInvite || '{}'),
    }
  );
}

export function helloBookDefaultSettingsForMyCRM(settings) {
  return (
    settings && {
      AdviserId: settings.adviserId,
      FactFindType: settings.factFindType,
      IsFactFindEnabled: settings.onlineFactFind,
      IsHelloBookEnabled: settings.helloBook,
      IsCreditEnabled: settings.creditGuide,
      IsDisclosureGuide: settings.disclosureGuide,
      IsCalendlyInviteEnabled: settings.calendly,
      IsCCToAdviser: settings.isCCToAdviser,
      IsUploadedAttachments: settings.isUploadedAttachments,
      Message: settings.message,
      FactFind: { ...settings.factFindSections },
      Documents: [...settings.documents],
      DocumentRequestMethod: settings.documentRequestMethod,
      FileInvite: JSON.stringify(settings.fileInvite),
    }
  );
}

export function helloBookClientUpdateForMyCRM(client) {
  const mobile = client?.phone?.find(
    (phone) => phone.type === phoneType.mobile,
  );
  return (
    client && {
      ClientId: client.personId,
      Email: client.email?.find(
        (email) => email.type === EMAIL_CTYPES.CRM_EMAIL,
      )?.email,
      MobilePhone: mobile?.number,
      MobilePhoneCountryCode: mobile?.countryCode,
    }
  );
}

export function helloBookFamilyLoanForUI({
  loan,
  isMergeLeadAndOpportunities = false,
  userCountryCode = 'AU',
}) {
  const lender = {
    id: loan.lenderId,
    name: loan.lenderName,
    countryCode: userCountryCode,
  };
  const status = {
    name: loan.statusDisplayName || '',
    loanStatusId: loan.loanStatusId || 0,
  };

  const applicants = (loan.clientName && loan.clientName.trim()) || '';

  const updatedOn = `updated ${humanizeDate(
    loan.lastModifiedDate || loan.createdDate,
  )}`;

  const loanDisplayLabel = isMergeLeadAndOpportunities
    ? `${loan.pipelineType} (${loan.statusDisplayName}) ${updatedOn}`
    : `$${formatNumberWithComma(loan.loanAmount)}, ${
        loan.statusDisplayName
      } ${updatedOn}`;
  const dealDisplayName = `${loan.dealName || 'New Deal'} - ${
    loan.loanScenarioId || 0
  } - ${loan.loanAmount || '$0'}`;

  return {
    ...loan,
    applicants,
    lender,
    status,
    loanDisplayLabel,
    dealName: isMergeLeadAndOpportunities ? loan.dealName : dealDisplayName,
  };
}

export function insuranceProfilerDocumentsBuilderForMyCRM(doc) {
  return (
    doc && {
      DocumentID: doc.documentId,
    }
  );
}

export function insuranceProfilerSendBuilderForMyCRM(form) {
  return (
    form && {
      ProfilerId: form.profilerId,
      FamilyId: form.familyId,
      AdviserId: form.adviserId,
      AdviserEmail: form.adviserEmail,
      Message: form.message,
      IsCCtoAdviser: form.isCCToAdviser,
      Clients: form.clients.map(clientInfoForMyCRM),
      Documents: form.documents.map(insuranceProfilerDocumentsBuilderForMyCRM),
    }
  );
}

export function inviteIOFFSendBuilderForMyCRM(form) {
  return (
    form && {
      FamilyId: form.familyId,
      Message: form.message,
      Clients: form.clients.map(clientInfoForMyCRM),
    }
  );
}
