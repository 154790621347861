import angular from 'angular';
import _ from 'lodash';
import { SECTION_ID } from 'Common/constants/loanappSectionIds';
import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { LENDER_FILTER_CATEGORY } from 'Common/constants/lenderConstants';
import {
  LIST_PURPOSE,
  TOP_PURPOSE_LIST,
  BANKING_WITH_LENDERS,
} from 'Common/constants/customerObjectives';
import { parseToInt10 } from 'Common/utilities/parse';
import { LOAN_APP_CUSTOMER_OBJECTIVES } from 'Common/constants/performance';
import { performance } from 'Common/utilities/perfume';
import { loanAppSectionData } from 'Common/utilities/dataLayer';
import { getCustomerObjQuestions } from 'Common/utilities/objectivesQuestion';
import {
  formatNewlineToHtmlBreak,
  formatHtmlBreakToNewline,
} from 'Common/utilities/string';
import {
  setupCurrentlyBankingLenders,
  getReqAndObjValidation,
  mapAdditionalAnswers,
  setAdditionalQuestions,
  deleteLoanObjectiveAnswer,
} from './util/customerObjectives';
import { setCompletionStatus } from './../util/loanAppCtrl';

export const loadModule = () =>
  angular.module('app').controller(
    'LoanAppCustomerObjectivesCtrl',
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function LoanAppCustomerObjectivesCtrl(
      $rootScope,
      $scope,
      $timeout,
      contactService,
      loanScenarioService,
      loanApplicationServices,
      $state,
      $stateParams,
      loanProfilerService,
      optionsService,
      loanAppSharedData,
      fundingCalculatorService,
      configService,
      objectivesQuestionService,
      uiService,
      currentUserService,
      opportunityLoanAppService,
      lenderModelService,
      loanObjectivesService,
    ) {
      // eslint-disable-next-line sonarjs/cognitive-complexity
      $scope.loanObjectivesService = loanObjectivesService;
      const initializeCustomerObjectives = () => {
        $scope.isCustomerObjectivesLoaded = false;
        $scope.clickCounter = 0;
        $scope.loanAppId = $stateParams.loanAppId;
        $scope.familyId = $stateParams.familyId;
        $scope.uiService = uiService;
        $scope.currentUserService = currentUserService;
        $scope.LIST_OF_QUESTIONS = getCustomerObjQuestions(currentUserService);
        const {
          requirementsAndObjectives,
          currentlyBankingWithLenders,
          onlyShowAccreditedLenders,
          financialGoalSummary,
        } = configService.feature;
        $scope.financialGoalSummary = financialGoalSummary;
        $scope.requirementsAndObjectives = requirementsAndObjectives;
        $scope.isCurrentBankingWithLenders = currentlyBankingWithLenders;
        $scope.objectivesQuestionService = objectivesQuestionService;
        $scope.lenderListFilter = '';
        $scope.OTHER_OPTION_ID = BANKING_WITH_LENDERS.OTHER;
        $scope.purposeHasType = (type) => {
          const { listPurpose } = $scope;

          if (!listPurpose) {
            return false;
          }
          return !!listPurpose.find((o) => {
            return (
              o && o.Name && o.Name.toLowerCase().includes(type.toLowerCase())
            );
          });
        };

        $scope.getLoanDepositObject = {
          DepositBreakDown: [],
        };
        /* listeners */
        $scope.$on('isLodged', (event, data) => {
          $scope.isLodged = data;
        });
        /* end of listeners */

        $scope.checkRefinancePurpose = () => {
          loanAppSharedData.isRefinancePrimaryPurpose = !!(
            $scope.listPurpose &&
            $scope.listPurpose.length &&
            $scope.listPurpose.find(
              (item) => item.GroupName === LIST_PURPOSE.REFINANCE_GROUP,
            )
          );
        };

        $scope.fundsAvailable = [];
        const { LoanDetails } = loanAppSharedData.loanAppSummary;
        const { LendingCategoryId: lendingCategoryId } = LoanDetails;
        $scope.isAssetFinance =
          lendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE ||
          lendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN;
        $scope.lenderListFilter =
          (currentUserService.isAU &&
            LENDER_FILTER_CATEGORY[lendingCategoryId]) ||
          '';
        $scope.processCustomerObjectives = () => {
          if (!$scope.isLoanAppLoaded) {
            return;
          }
          performance.start(LOAN_APP_CUSTOMER_OBJECTIVES);
          $scope.isCustomerObjectivesLoaded = true;
          getReqAndObjValidation({
            $scope,
            loanApplicationServices,
            loanAppId: $scope.loanAppId,
          });
          if ($scope.clickCounter !== 0) {
            return;
          }

          $scope.clickCounter++;
          $scope.validateObjectives = () => {
            if (!$scope.listPurpose) {
              $scope.getLoanObjectives();
            }
            const isValidObjectivesQuestion =
              objectivesQuestionService.objectivesQuestionValid() ||
              !$scope.requirementsAndObjectives;
            const isValidImportantDates =
              (($scope.loanAppOptions.dates.settlementDate.active ||
                $scope.loanAppOptions.dates.financeDate.active ||
                $scope.loanAppOptions.dates.auctionDate.active) &&
                $scope.ObjectivesSet.ImportantDates === true) ||
              $scope.ObjectivesSet.ImportantDates === false;
            const isValidSpecificGoals =
              typeof $scope.ObjectivesSet.SpecificGoalsObjectives ===
                'string' && $scope.ObjectivesSet.SpecificGoalsObjectives !== '';
            const isValidBrankruptNote =
              ($scope.ObjectivesSet.Bankrupt === true &&
                !_.isUndefined($scope.ObjectivesSet.BankruptNote) &&
                $scope.ObjectivesSet.BankruptNote !== '') ||
              $scope.ObjectivesSet.Bankrupt === false;
            const isValidCurrentDebtNote =
              ($scope.ObjectivesSet.CurrentDebt === true &&
                !_.isUndefined($scope.ObjectivesSet.CurrentDebtNote) &&
                $scope.ObjectivesSet.CurrentDebtNote !== '') ||
              $scope.ObjectivesSet.CurrentDebt === false;
            const isValidRiskInsurance =
              $scope.ObjectivesSet.RiskInsurance === true ||
              $scope.ObjectivesSet.RiskInsurance === false;
            const isValidHomeInsurance =
              $scope.ObjectivesSet.HomeInsurance === true ||
              $scope.ObjectivesSet.HomeInsurance === false;
            const isValidWhatAreYouWantingToDo =
              typeof $scope.ObjectivesSet.WhatAreYouWantingToDo === 'string' &&
              $scope.ObjectivesSet.WhatAreYouWantingToDo !== '';
            const isValidBankWith =
              ($scope.isCurrentBankingWithLenders &&
                $scope.ObjectivesSet.ListBankingWithLenders &&
                $scope.ObjectivesSet.ListBankingWithLenders.length) ||
              !!$scope.ObjectivesSet.BankingWith;
            const isValidHappyWithCurrentBank =
              ($scope.ObjectivesSet.HappyWithCurrentBank === false &&
                !_.isUndefined($scope.ObjectivesSet.HappyWithCurrentBankNote) &&
                $scope.ObjectivesSet.HappyWithCurrentBankNote !== '') ||
              $scope.ObjectivesSet.HappyWithCurrentBank === true;
            const isValidDirector =
              $rootScope.crmCountry !== 'Australia' ||
              ($rootScope.crmCountry === 'Australia' &&
                !_.isUndefined($scope.ObjectivesSet.directorForYears) &&
                (($scope.ObjectivesSet.directorForYears &&
                  $scope.ObjectivesSet.directorForYearsNote !== '' &&
                  !_.isUndefined($scope.ObjectivesSet.directorForYearsNote)) ||
                  $scope.ObjectivesSet.directorForYears === false));

            const firstConditionIsvalidObjectives =
              $scope.listPurpose.length > 0 &&
              $scope.listLenders.length > 0 &&
              ($scope.listLoanFeatures.length > 0 ||
                $scope.displayPossibleAdverseChanges) &&
              isValidImportantDates &&
              isValidSpecificGoals &&
              isValidBrankruptNote &&
              isValidCurrentDebtNote &&
              isValidRiskInsurance &&
              isValidHomeInsurance &&
              isValidWhatAreYouWantingToDo &&
              isValidBankWith &&
              isValidHappyWithCurrentBank &&
              isValidDirector &&
              isValidObjectivesQuestion;
            const secondConditionIsValidObjectives =
              $rootScope.crmCountry === 'New Zealand' &&
              $scope.listPurpose &&
              $scope.listPurpose.length > 0 &&
              $scope.ObjectivesSet &&
              $scope.ObjectivesSet.SpecificGoalsObjectives;

            $scope.isValidObjectives =
              firstConditionIsvalidObjectives ||
              secondConditionIsValidObjectives;

            if (
              $scope.ObjectivesSet &&
              typeof $scope.ObjectivesSet.depositAmount !== 'undefined' &&
              $scope.ObjectivesSet.depositAmount
            ) {
              $scope.ObjectivesSet.depositAmount = '';
            }
            const lastConditionIsStartedObjectives =
              $rootScope.crmCountry === 'New Zealand' ||
              ($scope.listPurpose && $scope.listPurpose.length > 0) ||
              ($scope.ObjectivesSet &&
                typeof $scope.ObjectivesSet.SpecificGoalsObjectives !==
                  'undefined' &&
                $scope.ObjectivesSet.SpecificGoalsObjectives);

            $scope.isStartedObjectives =
              $scope.listPurpose.length > 0 ||
              $scope.fundsAvailable.length > 0 ||
              $scope.listLenders.length > 0 ||
              $scope.listLoanFeatures.length > 0 ||
              (((($scope.loanAppOptions.dates.settlementDate.active === true ||
                $scope.loanAppOptions.dates.financeDate.active === true) &&
                $scope.ObjectivesSet.ImportantDates === true) ||
                $scope.ObjectivesSet.ImportantDates === false) &&
                $scope.ObjectivesSet.SpecificGoalsObjectives !== '' &&
                typeof $scope.ObjectivesSet.SpecificGoalsObjectives ===
                  'string') ||
              ($scope.ObjectivesSet.Bankrupt === true &&
                !_.isUndefined($scope.ObjectivesSet.BankruptNote)) ||
              $scope.ObjectivesSet.Bankrupt === false ||
              ($scope.ObjectivesSet.CurrentDebt === true &&
                !_.isUndefined($scope.ObjectivesSet.CurrentDebtNote)) ||
              $scope.ObjectivesSet.CurrentDebt === false ||
              $scope.ObjectivesSet.RiskInsurance === true ||
              $scope.ObjectivesSet.RiskInsurance === false ||
              $scope.ObjectivesSet.HomeInsurance === true ||
              $scope.ObjectivesSet.HomeInsurance === false ||
              (typeof $scope.ObjectivesSet.WhatAreYouWantingToDo === 'string' &&
                $scope.ObjectivesSet.WhatAreYouWantingToDo !== '') ||
              ($scope.ObjectivesSet.BankingWith !== '' &&
                $scope.ObjectivesSet.BankingWith !== null) ||
              ($scope.ObjectivesSet.HappyWithCurrentBank === false &&
                !_.isUndefined(
                  $scope.ObjectivesSet.HappyWithCurrentBankNote,
                )) ||
              $scope.ObjectivesSet.HappyWithCurrentBank === true ||
              lastConditionIsStartedObjectives;

            const isValidObjectivesToPass = !!$scope.isValidObjectives;

            setCompletionStatus({
              $scope,
              $timeout,
              sectionId: SECTION_ID.CUSTOMER_OBJECTIVES,
              loanAppSharedData,
              isValidSection: isValidObjectivesToPass,
            });

            $scope.countValids($scope.isValidObjectives, 'objectives');
          };

          $scope.listeners = () => {
            /**
             *  Validates every update on object
             */
            const objectivesSetWatcher = $scope.$watchCollection(
              'ObjectivesSet',
              () => {
                // will set the purpose on service
                loanAppSharedData.loanAppPurpose =
                  $scope.ObjectivesSet.PrimaryPurpose;
                $scope.validateObjectives();
              },
            );

            const listPurposeWatcher = $scope.$watchCollection(
              'listPurpose',
              () => {
                $scope.validateObjectives();
              },
            );

            const auctionDateWatcher = $scope.$watchCollection(
              'loanAppOptions.dates.auctionDate.active',
              () => {
                $scope.validateObjectives();
              },
            );

            const financeDateWatcher = $scope.$watchCollection(
              'loanAppOptions.dates.financeDate.active',
              () => {
                $scope.validateObjectives();
              },
            );

            const settlementDateWatcher = $scope.$watchCollection(
              'loanAppOptions.dates.settlementDate.active',
              () => {
                $scope.validateObjectives();
              },
            );

            const listLendersWatcher = $scope.$watchCollection(
              'listLenders',
              () => {
                $scope.validateObjectives();
              },
            );

            const listLoanFeaturesWatcher = $scope.$watchCollection(
              'listLoanFeatures',
              () => {
                $scope.validateObjectives();
              },
            );

            const fundsAvailableWatcher = $scope.$watchCollection(
              'fundsAvailable',
              () => {
                $scope.validateObjectives();
              },
            );

            const loanAppAdviserFamilyIdWatcher =
              onlyShowAccreditedLenders &&
              $scope.$watchCollection(
                'loanAppSharedData.LoanApplicationDetails.AdviserFamilyId',
                () => {
                  $scope.loadPreferredLenderList();
                },
              );

            $scope.$on('$destroy', () => {
              if (objectivesSetWatcher) {
                objectivesSetWatcher();
              }
              if (listPurposeWatcher) {
                listPurposeWatcher();
              }
              if (listLendersWatcher) {
                listLendersWatcher();
              }
              if (listLoanFeaturesWatcher) {
                listLoanFeaturesWatcher();
              }
              if (fundsAvailableWatcher) {
                fundsAvailableWatcher();
              }
              if (auctionDateWatcher) {
                auctionDateWatcher();
              }
              if (financeDateWatcher) {
                financeDateWatcher();
              }
              if (settlementDateWatcher) {
                settlementDateWatcher();
              }
              if (loanAppAdviserFamilyIdWatcher) {
                loanAppAdviserFamilyIdWatcher();
              }
            });
          };

          /**
*  ff. are question id for each sections
*  Primary Purpose = 5
Deposit Source = 6
Deposit Amount = pending
Preferred Lenders = 7
Loan Features = 8
Specific Goals/Objectives = 9
Any previous impairements/bankruptcy? = 10 / SubQuestion = 11
Arrears in current debt? = 12 / SubQuestion = 13
Any Foreseable changes in circumstance? = 14 / SubQuestion = 15
Guaranteeing any other loan = 16 / SubQuestion = 17
Any Risk Insurance? = 18 / SubQuestion = 19
Any Home/Contents Insurance? = 20 / SubQuestion = 21
*/

          $scope.getQuestion = () => {
            optionsService.question('Objectives').then((response) => {
              $scope.objectiveQuestions = response.data;
              currentUserService.isNZ && setAdditionalQuestions($scope);
            });
          };

          $scope.DisplayOptionList = [
            { Id: 1, Type: 'answerBool', Value: 'YesNoOnly' },
            { Id: 2, Type: 'answerString', Value: 'TextOnly' },
            { Id: 3, Type: 'answerString', Value: 'TextboxOnYes' },
            { Id: 4, Type: 'answerString', Value: 'TextboxOnNo' },
            { Id: 5, Type: 'answerInt', Value: 'SingleDropDown' },
            { Id: 6, Type: 'answerInt', Value: 'MultiDropDown' },
            { Id: 7, Type: 'answerInt', Value: 'SingleDropDownNoFrame' },
            { Id: 8, Type: 'answerBool', Value: 'CheckBox' },
            { Id: 9, Type: 'answerString', Value: 'TextboxWithDropdown' },
            { Id: 10, Type: 'answerDecimal', Value: 'TextFloat' },
          ];

          $scope.getLoanObjectives = () => {
            loanApplicationServices
              .getLoanObjectives($scope.loanAppId)
              .then((response) => {
                if (!response || !response.data) {
                  return;
                }
                const loanObjectivesGet = response.data;
                const listPurpose = [];
                const listLenders = [];
                const listBankingWithLenders = [];
                const listLoanFeatures = [];
                $scope.isStartedObjectives =
                  loanObjectivesGet && loanObjectivesGet.length;
                $scope.loanObjectivesData = loanObjectivesGet;

                currentUserService.isNZ && mapAdditionalAnswers($scope);
                objectivesQuestionService.setObjectives(loanObjectivesGet);

                _.forEach(loanObjectivesGet, (obj) => {
                  const theStringAnswer = formatHtmlBreakToNewline(
                    _.last(obj.answerString),
                  );

                  switch (obj.QuestionId) {
                    case 5:
                      _.forEach($scope.LoanPurposeList, (o) => {
                        const isAnswerFound = _.find(obj.answerInt, (ans) => {
                          return parseInt(ans, 10) === parseInt(o.Value, 10);
                        });
                        if (isAnswerFound) {
                          o.Id = o.Value;
                          listPurpose.push(o);

                          o.show = false;
                        } else {
                          o.show = true;
                        }
                      });
                      $scope.listPurpose = listPurpose;
                      break;
                    // @TODO : Put other cases here
                    case 6:
                      $scope.ObjectivesSet.DepositSource = _.last(
                        obj.answerInt,
                      ).toString();
                      break;
                    case 37:
                      $scope.ObjectivesSet.depositAmount = _.last(
                        obj.answerDecimal,
                      );
                      break;
                    case 7:
                      _.forEach($scope.loanProviderLendersList, (o) => {
                        if (
                          _.find(obj.answerInt, (ans) => {
                            return (
                              parseInt(ans, 10) === parseInt(o.ProviderId, 10)
                            );
                          })
                        ) {
                          o.Id = o.ProviderId;
                          listLenders.push(o);
                          o.show = false;
                        } else {
                          o.show = true;
                        }
                      });
                      $scope.listLenders = listLenders;
                      break;
                    case 8:
                      _.forEach($scope.LoanFeaturesList, (o) => {
                        if (
                          _.find(obj.answerInt, (ans) => {
                            return parseInt(ans, 10) === parseInt(o.Value, 10);
                          })
                        ) {
                          o.Id = o.Value;
                          listLoanFeatures.push(o);

                          o.show = false;
                        } else {
                          o.show = true;
                        }
                      });

                      $scope.listLoanFeatures = listLoanFeatures;
                      break;
                    case 9:
                      $scope.ObjectivesSet.SpecificGoalsObjectives = theStringAnswer;
                      break;

                    // with subquestions
                    case 10:
                      $scope.ObjectivesSet.Bankrupt = _.last(obj.answerBool);
                      break;
                    case 11:
                      $scope.ObjectivesSet.BankruptNote = theStringAnswer;
                      break;
                    case 12:
                      $scope.ObjectivesSet.CurrentDebt = _.last(obj.answerBool);
                      break;
                    case 13:
                      $scope.ObjectivesSet.CurrentDebtNote = theStringAnswer;
                      break;
                    case 14:
                      $scope.ObjectivesSet.Circumstance = _.last(
                        obj.answerBool,
                      );
                      break;
                    case 15:
                      $scope.ObjectivesSet.CircumstanceNote = theStringAnswer;
                      break;
                    case 16:
                      $scope.ObjectivesSet.OtherLoan = _.last(obj.answerBool);
                      break;
                    case 17:
                      $scope.ObjectivesSet.OtherLoanNote = theStringAnswer;
                      break;
                    case 18:
                      $scope.ObjectivesSet.RiskInsurance = _.last(
                        obj.answerBool,
                      );
                      break;
                    case 19:
                      $scope.ObjectivesSet.RiskInsuranceNote = theStringAnswer;
                      break;
                    case 20:
                      $scope.ObjectivesSet.HomeInsurance = _.last(
                        obj.answerBool,
                      );
                      break;
                    case 21:
                      $scope.ObjectivesSet.HomeInsuranceNote = theStringAnswer;
                      break;
                    case $scope.LIST_OF_QUESTIONS.WHAT_ARE_YOU_WANTING_TO_DO:
                      $scope.ObjectivesSet.WhatAreYouWantingToDo = theStringAnswer;
                      break;
                    case 44:
                      $scope.ObjectivesSet.ImportantDates = _.last(
                        obj.answerBool,
                      );
                      break;
                    case 45:
                      $scope.ObjectivesSet.ImportantDatesNote = theStringAnswer;
                      break;
                    case 46:
                      $scope.ObjectivesSet.BankingWith = theStringAnswer;
                      $scope.isOtherBankingAdded();
                      break;
                    case 47:
                      $scope.ObjectivesSet.HappyWithCurrentBank = _.last(
                        obj.answerBool,
                      );
                      break;
                    case 48:
                      $scope.ObjectivesSet.HappyWithCurrentBankNote = theStringAnswer;
                      break;
                    case 109:
                      $scope.ObjectivesSet.BankToRefinanceFrom = String(
                        _.last(obj.answerInt),
                      );
                      break;
                    case 122:
                      $scope.ObjectivesSet.directorForYears = _.last(
                        obj.answerBool,
                      );
                      break;
                    case 123:
                      $scope.ObjectivesSet.directorForYearsNote = theStringAnswer;
                      break;
                    case 224:
                      $scope.ObjectivesSet.WhyTheseLenders = theStringAnswer;
                      break;
                    case 225:
                      $scope.filterSelectedBankingLenders(
                        obj,
                        listBankingWithLenders,
                      );
                      $scope.listBankingWithLenders = listBankingWithLenders;
                      $scope.isOtherBankingAdded();
                      $scope.ObjectivesSet.ListBankingWithLenders =
                        $scope.listBankingWithLenders;
                      break;
                    default:
                      break;
                  }
                });
              });
          };

          // place all the value that you want to turn off on module click
          $scope.eventModuleGlobal = () => {
            $scope.isDisplayAddSource = false;
          };

          $scope.showDepositAddSource = (e) => {
            e.stopPropagation();
            $scope.isDisplayAddSource = true;
          };

          $scope.getFundsAvailable = () => {
            // Let's get the funds first for easier object manipulation
            fundingCalculatorService
              .getFundingCalculatorFunds($scope.securityBrokerEventId)
              .then((fundsResult) => {
                // These funds will be used after parameters, using FUNDING_REQUIRED FundingType
                const fundsData = fundsResult;
                // remove the funds available types
                _.remove(fundsData, (fund) => {
                  return fund.fundingType === 'FUNDING_REQUIRED';
                });

                $scope.fundsAvailable = fundsData;

                loanAppSharedData.fundingAvailableRefresh &&
                  loanAppSharedData.fundingAvailableRefresh(
                    $scope.fundsAvailable,
                  );
                $rootScope.$broadcast('REFRESH_FUNDS_AVAILABLE_SECTION', {
                  fundsAvailable: $scope.fundsAvailable,
                  isNew: false,
                });
              });
          };
          $scope.fundsAvailableInit = () => {
            $scope.depositSourceObj = {
              type: null,
              amount: null,
            };
            loanScenarioService
              .getFundingCalculatorNewBrokerEventForScenario($scope.loanAppId)
              .then((cusObjectivesCalcBrokerEventResponse) => {
                $scope.securityBrokerEventId =
                  cusObjectivesCalcBrokerEventResponse.data;
                loanAppSharedData.securityBrokerEventId =
                  $scope.securityBrokerEventId;
                $scope.getFundsAvailable();
              });
          };
          $scope.fundsAvailableInit();

          const refreshObjectivesDepositSectionWatcher = $rootScope.$on(
            'REFRESH_OBJECTIVES_DEPOSIT_SECTION',
            (e, args) => {
              if (!args.isNew && args.fundsAvailable) {
                $scope.fundsAvailable = args.fundsAvailable;
              } else {
                $scope.getFundsAvailable();
              }
            },
          );
          $scope.$on('$destroy', () => {
            if (refreshObjectivesDepositSectionWatcher) {
              refreshObjectivesDepositSectionWatcher();
            }
          });

          $scope.addDepositSource = () => {
            const fundingSet = {
              brokerEventId: $scope.securityBrokerEventId,
              id: 0,
              fundingType: 'FUNDS_AVAILABLE',
              fundingParticulars: $scope.depositSourceObj.type,
              fundingValue: $scope.depositSourceObj.amount,
              description: $scope.depositSourceObj.description,
              isOnlyValueEditable: false,
            };

            fundingCalculatorService
              .setFundingCalculatorFunds(fundingSet)
              .then(() => {
                $scope.getFundsAvailable();
                $rootScope.$broadcast('getFundingCalculatorFundsSummary');
                $scope.isDisplayAddSource = false;
                $scope.depositSourceObj = {};
              });
          };

          $scope.updateObjective = (
            QuestionId,
            QuestionType,
            QuestSectionId,
            answer,
          ) => {
            let QuestionSectionId = 0;

            if (QuestSectionId) {
              QuestionSectionId = QuestSectionId;
            }

            const selectedOption = _.find($scope.DisplayOptionList, (o) => {
              return o.Value === QuestionType;
            });
            const postObject = {
              LoanScenarioId: $scope.loanAppId,
              FamilyId: $scope.familyId,
              QuestionSectionId,
              QuestionId,
              DisplayOptionId: selectedOption.Id,
            };

            switch (selectedOption.Type) {
              case 'answerString':
                postObject.answerString = [formatNewlineToHtmlBreak(answer)];
                break;
              case 'answerBool':
                postObject.answerBool = [answer];
                break;
              case 'answerInt':
                // Multiple
                postObject.answerInt = [answer];
                break;
              case 'answerDecimal':
                postObject.answerDecimal = [answer];
                break;
              default:
                break;
            }

            loanApplicationServices
              .postLoanObjectives(postObject)
              .then(() => {});
          };

          const onLendersGet = (data) => {
            const lenders = [
              {
                ProviderId: '-2',
                ProviderName: 'No Preferred Lenders',
                Category: $scope.lenderListFilter,
              },
              ...data,
            ];
            $scope.loanProviderLendersList = lenders.map((lender) => {
              lender.show = true;
              return lender;
            });
            const bankingLenderListPromise = $scope.populateBankingLenderList();
            bankingLenderListPromise &&
              bankingLenderListPromise.then(() => {
                $scope.getLoanObjectives();
              });
            $scope.getQuestion();
          };

          $scope.objectivesInit = () => {
            $scope.ObjectivesSet = {};
            $scope.LoanPurposeList = [];
            $scope.loanProviderLendersList = [];
            $scope.listPurpose = [];
            $scope.listLenders = [];
            $scope.listLoanFeatures = [];
            $scope.ObjectivesSet.depositAmount = [];
            $scope.objectiveQuestions = [];
            $scope.listBankingWithLenders = [];
            $scope.bankingLenderList = [];

            setupCurrentlyBankingLenders({
              $scope,
              configService,
              currentUserService,
              contactService,
              loanObjectivesService,
            });

            // eslint-disable-next-line unicorn/consistent-function-scoping
            const moveToplist = (purposeList) => {
              const topPurposeList = Object.values(TOP_PURPOSE_LIST);
              const resultList = purposeList.filter((f) =>
                topPurposeList.includes(f.Value),
              );
              const copyOriginal = purposeList.filter(
                (f) => !topPurposeList.includes(f.Value),
              );
              const sortPurpose = (a, b) => {
                return (
                  topPurposeList.indexOf(a.Value) -
                  topPurposeList.indexOf(b.Value)
                );
              };
              return [...resultList.sort(sortPurpose), ...copyOriginal];
            };

            optionsService.loanpurpose().then((response) => {
              $scope.LoanPurposeList = currentUserService.isNZ
                ? moveToplist(response.data)
                : response.data;

              _.map($scope.LoanPurposeList, (o) => {
                o.show = true;
                return o;
              });
              $scope.getLoanObjectives();
            });

            optionsService.DepositType().then((response) => {
              $scope.DepositSourceList = response.data;
            });

            optionsService.LoanFeaturesGet().then((response) => {
              _.map(response.data, (o) => {
                o.Value = parseInt(o.Value, 10);
                o.show = true;

                return o;
              });

              $scope.LoanFeaturesList = response.data;
              $scope.getLoanObjectives();
            });

            $scope.loadPreferredLenderList();
            $scope.validateObjectives();
          };

          $scope.loadPreferredLenderList = () => {
            const lenderPlace = 1;
            !onlyShowAccreditedLenders &&
              contactService
                .lenderListGet(null, lenderPlace)
                .then(({ data }) => {
                  onLendersGet(data);
                });
            const brokerId = _.get(
              loanAppSharedData,
              'LoanApplicationDetails.AdviserFamilyId',
            );
            onlyShowAccreditedLenders &&
              brokerId &&
              lenderModelService.getAccreditedLender(brokerId).then((data) => {
                onLendersGet(data);
              });
          };

          $scope.objectivesInit();

          $scope.getFilterData = () => {
            $scope.filtersParamtersList = {};
            $scope.loanProviderLendersListGetAll = [];
            $scope.loanProviderLendersListSet = [];

            // This is test, to be removed
            loanProfilerService
              .getServiceabilityFiltersParamters()
              .then((response) => {
                $scope.filtersParamtersList = response.data;
                angular.forEach(response.data.Lenders, (item) => {
                  const length = 7;
                  const trimmedString =
                    item.Name.length > length
                      ? `${item.Name.slice(0, 3)}...`
                      : item.Name;
                  if (item.Value !== 'FavouriteLenders') {
                    $scope.loanProviderLendersListGetAll.push({
                      icon: `<img ng-src="/assets/images/lenderLogos/AU/${item.Value}.png"/>`,
                      name: item.Name,
                      shortName: trimmedString,
                      value: item.Value,
                      ticked: false,
                    });
                  }
                });
              });
          };

          $scope.Settings = {
            noLender: false,
          };
          const listLendersWatcher = $scope.$watch(
            'listLenders',
            (nv) => {
              if (
                _.find(nv, (o) => {
                  return o.ProviderId === '-2';
                })
              ) {
                $scope.Settings.noLender = true;

                _.forEach(nv, (otherLenders) => {
                  if (
                    !_.isUndefined(otherLenders) &&
                    otherLenders.ProviderId !== '-2'
                  ) {
                    $scope.removeLender(otherLenders);
                  }
                });
              } else {
                $scope.Settings.noLender = false;
              }
            },
            true,
          );
          $scope.$on('$destroy', () => {
            if (listLendersWatcher) {
              listLendersWatcher();
            }
          });

          $scope.selectLender = () => {
            $scope.ObjectivesSet.PreferredLender.Id =
              $scope.ObjectivesSet.PreferredLender.ProviderId;
            $scope.listLenders.push($scope.ObjectivesSet.PreferredLender);

            const foundLenderAtList = _.find($scope.loanProviderLendersList, {
              Id: $scope.ObjectivesSet.PreferredLender.Id,
            });
            _.map($scope.loanProviderLendersList, (obj) => {
              if (obj.Id === foundLenderAtList.Id) {
                obj.show = false;
              }
              return obj;
            });

            $scope.updateObjective(
              $scope.LIST_OF_QUESTIONS.PREFERRED_LENDER,
              'MultiDropDown',
              0,
              parseToInt10($scope.ObjectivesSet.PreferredLender.ProviderId),
            );

            $scope.ObjectivesSet.PreferredLender = '';
          };

          $scope.removeLender = (lender) => {
            deleteLoanObjectiveAnswer($scope.loanObjectivesService)({
              questionId: $scope.LIST_OF_QUESTIONS.PREFERRED_LENDER,
              loanAppId: $scope.loanAppId,
              answerInt: lender.Id,
            }).then(() => {
              _.remove($scope.listLenders, (o) => {
                return _.isEqual(o, lender);
              });
              const foundLenderAtList = _.find($scope.loanProviderLendersList, {
                Id: lender.Id,
              });
              _.map($scope.loanProviderLendersList, (obj) => {
                if (obj.Id === foundLenderAtList.Id) {
                  obj.show = true;
                }
                return obj;
              });
            });
          };

          $scope.selectLoan = () => {
            $scope.ObjectivesSet.LoanFeatures.Id =
              $scope.ObjectivesSet.LoanFeatures.Value;

            $scope.listLoanFeatures.push($scope.ObjectivesSet.LoanFeatures);
            const foundObj = _.find($scope.LoanFeaturesList, {
              Id: $scope.ObjectivesSet.LoanFeatures.Id,
            });
            _.map($scope.LoanFeaturesList, (obj) => {
              if (obj.Id === foundObj.Id) {
                obj.show = false;
              }
              return obj;
            });

            $scope.updateObjective(
              $scope.LIST_OF_QUESTIONS.LOAN_FEATURES,
              'MultiDropDown',
              0,
              $scope.ObjectivesSet.LoanFeatures.Id,
            );

            $scope.ObjectivesSet.LoanFeatures = '';
          };

          $scope.removeLoan = (loan) => {
            deleteLoanObjectiveAnswer($scope.loanObjectivesService)({
              questionId: $scope.LIST_OF_QUESTIONS.LOAN_FEATURES,
              loanAppId: $scope.loanAppId,
              answerInt: loan.Id,
            }).then(() => {
              _.remove($scope.listLoanFeatures, (o) => {
                return _.isEqual(o, loan);
              });
              const foundObj = _.find($scope.LoanFeaturesList, {
                Id: loan.Id,
              });
              _.map($scope.LoanFeaturesList, (obj) => {
                if (obj.Id === foundObj.Id) {
                  obj.show = true;
                }
                return obj;
              });
            });
          };

          $scope.selectPurpose = () => {
            $scope.ObjectivesSet.PrimaryPurpose.Id =
              $scope.ObjectivesSet.PrimaryPurpose.Value;
            $scope.listPurpose.push($scope.ObjectivesSet.PrimaryPurpose);
            const foundObj = _.find($scope.LoanPurposeList, {
              Id: $scope.ObjectivesSet.PrimaryPurpose.Id,
            });
            // eslint-disable-next-line sonarjs/no-identical-functions
            _.map($scope.LoanPurposeList, (obj) => {
              if (obj.Id === foundObj.Id) {
                obj.show = false;
              }
              return obj;
            });

            $scope.updateObjective(
              $scope.LIST_OF_QUESTIONS.PURPOSE,
              'MultiDropDown',
              0,
              parseToInt10($scope.ObjectivesSet.PrimaryPurpose.Id),
            );

            $scope.ObjectivesSet.PrimaryPurpose = '';
            /* LoanAppOnOpportunities */
            const refreshTimeout = $timeout(() => {
              opportunityLoanAppService.executeCallback(
                'refreshOpportunityDetails',
              );
            }, 1000);
            $scope.$on('$destroy', () => {
              $timeout.cancel(refreshTimeout);
            });

            $scope.checkRefinancePurpose();
            $scope.validateObjectives();
          };

          $scope.removePurpose = (purpose) => {
            deleteLoanObjectiveAnswer($scope.loanObjectivesService)({
              questionId: $scope.LIST_OF_QUESTIONS.PURPOSE,
              loanAppId: $scope.loanAppId,
              answerInt: purpose.Id,
            }).then(() => {
              _.remove($scope.listPurpose, (o) => {
                return _.isEqual(o, purpose);
              });
              const foundObj = _.find($scope.LoanPurposeList, {
                Id: purpose.Id,
              });
              // eslint-disable-next-line sonarjs/no-identical-functions
              _.map($scope.LoanPurposeList, (obj) => {
                if (obj.Id === foundObj.Id) {
                  obj.show = true;
                }
                return obj;
              });
            });

            /* LoanAppOnOpportunities */
            // eslint-disable-next-line sonarjs/no-identical-functions
            const refreshTimeout = $timeout(() => {
              opportunityLoanAppService.executeCallback(
                'refreshOpportunityDetails',
              );
            }, 1000);
            $scope.$on('$destroy', () => {
              $timeout.cancel(refreshTimeout);
            });

            $scope.checkRefinancePurpose();
            $scope.brokerNotes.getLoanObjectives();
          };

          $scope.deleteDeposit = (fundsId) => {
            const params = {
              FundsID: fundsId,
            };
            fundingCalculatorService
              .deleteFundingCalculatorFunds(params)
              .then(() => {
                $scope.getFundsAvailable();
                $rootScope.$broadcast('getFundingCalculatorFundsSummary');
              });
          };

          $scope.objectivesQuestionUpdate = () => {
            $scope.validateObjectives();
          };

          $scope.listeners();
          const duration = performance.endPaint(LOAN_APP_CUSTOMER_OBJECTIVES);
          loanAppSectionData({
            label: LOAN_APP_CUSTOMER_OBJECTIVES,
            value: duration,
          });
        };

        const sectionObject = loanAppSharedData.getSectionObjectById(
          loanAppSharedData.completionListObject,
          SECTION_ID.CUSTOMER_OBJECTIVES,
        );
        $scope.countValids(sectionObject.IsCompleted, 'objectives');
        $scope.processCustomerObjectives();
      };
      $scope.$watch('isLoanAppLoaded', () => {
        if (!$scope.isLoanAppLoaded) {
          return;
        }
        initializeCustomerObjectives();
      });
    },
  );
