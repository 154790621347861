import { cloneDeep } from 'lodash';
import { setTableSortOrder } from 'Common/utilities/sort';
import { removeElementOnIndex } from 'Common/utilities/array';
import { copyLenderList, copyAdviserList } from 'Common/utilities/commission';

const dateSettings = {
  isOpen: false,
  displayFilterDate: '',
  activeRangeIndex: null,
  filter: {
    DateStart: '',
    DateEnd: '',
  },
};

export default class CommissionLandingCtrl {
  constructor(
    $state,
    $timeout,
    configService,
    commissionService,
    currentUserService,
    modalRenderService,
    commissionHelperService,
  ) {
    'ngInject';

    this.$state = $state;
    this.$timeout = $timeout;
    this.configService = configService;
    this.commissionService = commissionService;
    this.currentUserService = currentUserService;
    this.modalRenderService = modalRenderService;
    this.commissionHelperService = commissionHelperService;
    this.processParentNotification = this.processParentNotification.bind(this);
  }

  $onInit() {
    this.parentToChildNotificationRegistration({
      handler: this.processParentNotification,
    });
    this.isPageLoaded = true;
    this.isExportingUnmatched = false;
    this.isExportingExpected = false;
  }

  processParentNotification(eventHandler) {
    if (eventHandler !== 'initLandingCommissions' && !this.isPageLoaded) {
      return;
    }
    this.isPageLoaded = true;
  }

  $onDestroy() {
    this.loadUnmatchedTimeout &&
      this.$timeout.cancel(this.loadUnmatchedTimeout);
    this.loadExpectedTimeout && this.$timeout.cancel(this.loadExpectedTimeout);
    this.loadingTimeout && this.$timeout.cancel(this.loadingTimeout);
  }

  $onChanges(changes) {
    if (!changes) {
      return;
    }

    if (changes.brokers && changes.brokers.currentValue) {
      this.unmatchedAdviserList = copyAdviserList(this.brokers, this.default);
      this.expectedAdviserList = copyAdviserList(this.brokers, this.default);
      this.initUnmatchedCommissions();
      this.initExpectedCommissions();
    }
    changes.lenders &&
      (this.unmatchedLenderList = copyLenderList(this.lenders));
    changes.lenders && (this.expectedLenderList = copyLenderList(this.lenders));
    changes.types && (this.commissionTypeList = cloneDeep(this.types));
  }

  initUnmatchedCommissions() {
    this.unmatchedCommissionsHeader = this.commissionHelperService.getUnmatchedCommissionColumns();
    this.unmatchedCommissionsTableParams = {
      currentPage: 1,
      countPerPage: 10,
    };
    this.totalUnmatchedCommissions = 0;
    this.initUnmatchedFilterSettings();
    this.loadUnmatchedCommissions();
  }

  initUnmatchedFilterSettings() {
    this.unmatchedFilterSettings = {
      tableSrc: 'unmatched',
      ...cloneDeep(this.default),
      date: { ...dateSettings },
      lenderId: 0,
      commissionTypeId: 0,
      total: {
        displayTitle: 'Unmatched Total',
        value: 0,
      },
      sortColumn: 'ProcessDate',
      filterFields: {
        date: true,
        adviserMultiple: true,
        lender: true,
        commissionType: false,
        totalDisplay: true,
      },
    };
  }

  getUnmatchedRequestParams() {
    return {
      dateStart: this.unmatchedFilterSettings.date.filter.DateStart,
      dateEnd: this.unmatchedFilterSettings.date.filter.DateEnd,
      typeId: this.unmatchedFilterSettings.commissionTypeId,
      adviserIds: this.unmatchedFilterSettings.adviserIds || null,
      providerIds: this.unmatchedFilterSettings.lenderId || null,
      sortColumn: this.unmatchedFilterSettings.sortColumn,
      sortType: this.unmatchedFilterSettings.sortType,
      pageNumber: this.unmatchedCommissionsTableParams.currentPage,
      pageSize: this.unmatchedCommissionsTableParams.countPerPage,
    };
  }

  loadUnmatchedCommissions() {
    this.isLoadingUnmatchedCommissions = true;
    const params = this.getUnmatchedRequestParams();

    return this.commissionService.getUnmatchedCommissions(params).then(
      (response) => {
        if (!response) {
          return;
        }

        this.unmatchedCommissions = response;
        const [firstItem] = this.unmatchedCommissions;
        this.totalUnmatchedCommissions =
          (firstItem && firstItem.totalCount) || 0;
        this.unmatchedFilterSettings.total.value =
          (firstItem && firstItem.unmatchedTotal) || 0;
        this.loadingTimeout = this.$timeout(() => {
          this.isLoadingUnmatchedCommissions = false;
        }, 100);
      },
      () => {
        this.isLoadingUnmatchedCommissions = false;
        return this.isLoadingUnmatchedCommissions;
      },
    );
  }

  onUnmatchFilterChange(filterSettings) {
    this.unmatchedFilterSettings = filterSettings;
    this.loadUnmatchedTimeout &&
      this.$timeout.cancel(this.loadUnmatchedTimeout);

    this.loadUnmatchedTimeout = this.$timeout(() => {
      this.loadUnmatchedCommissions();
    }, 1000);
  }

  initExpectedCommissions() {
    this.expectedCommissionsHeader = this.commissionHelperService.getExpectedCommissionColumns();
    this.expectedCommissionsTableParams = {
      currentPage: 1,
      countPerPage: 10,
    };
    this.totalExpectedCommissions = 0;
    this.initExpectedFilterSettings();
    this.loadExpectedCommissions();
  }

  initExpectedFilterSettings() {
    this.expectedFilterSettings = {
      tableSrc: 'expected',
      ...cloneDeep(this.default),
      date: { ...dateSettings },
      lenderId: 0,
      total: {
        displayTitle: 'Expected Total',
        value: 0,
      },
      sortColumn: 'DateSettled',
      filterFields: {
        date: true,
        adviserMultiple: true,
        lender: true,
        totalDisplay: true,
      },
    };
  }

  getExpectedRequestParams() {
    return {
      typeId: 0,
      dateStart: this.expectedFilterSettings.date.filter.DateStart,
      dateEnd: this.expectedFilterSettings.date.filter.DateEnd,
      adviserIds: this.expectedFilterSettings.adviserIds || null,
      providerIds: this.expectedFilterSettings.lenderId || null,
      sortColumn: this.expectedFilterSettings.sortColumn,
      sortType: this.expectedFilterSettings.sortType,
      pageNumber: this.expectedCommissionsTableParams.currentPage,
      pageSize: this.expectedCommissionsTableParams.countPerPage,
    };
  }

  loadExpectedCommissions() {
    this.isLoadingExpectedCommissions = true;
    const params = this.getExpectedRequestParams();

    return this.commissionService.getExpectedCommissions(params).then(
      (response) => {
        if (!response) {
          return;
        }

        this.expectedCommissions = response;
        const [firstItem] = this.expectedCommissions;
        this.totalExpectedCommissions =
          (firstItem && firstItem.totalCount) || 0;
        this.expectedFilterSettings.total.value =
          (firstItem && firstItem.expectedTotal) || 0;
        this.loadingTimeout = this.$timeout(() => {
          this.isLoadingExpectedCommissions = false;
        }, 100);
      },
      () => {
        this.isLoadingExpectedCommissions = false;
        return this.isLoadingExpectedCommissions;
      },
    );
  }

  onExpectedFilterChange(filterSettings) {
    this.expectedFilterSettings = filterSettings;
    this.loadExpectedTimeout && this.$timeout.cancel(this.loadExpectedTimeout);

    this.loadExpectedTimeout = this.$timeout(() => {
      this.loadExpectedCommissions();
    }, 1000);
  }

  onSortTableData(sortColumn, filterSettings) {
    const filter = setTableSortOrder(sortColumn, filterSettings);

    const isUnmatchedTable = filterSettings.tableSrc === 'unmatched';
    if (isUnmatchedTable) {
      this.unmatchedFilterSettings = filter;
      this.onUnmatchFilterChange(this.unmatchedFilterSettings);
    } else {
      this.expectedFilterSettings = filter;
      this.onExpectedFilterChange(this.expectedFilterSettings);
    }
  }

  onUnmatchedRowItemClick(commission) {
    if (!this.isMobileDevice || !commission) {
      return;
    }
    this.modalRenderService.openCommissionUnmatchedDetails({
      commission,
    });
  }

  onExpectedRowItemClick(commission) {
    if (!this.isMobileDevice || !commission) {
      return;
    }
    const modalInstance = this.modalRenderService.openCommissionExpectedDetails(
      {
        commission,
        onSendEnquiry: () => this.onSendEnquiry(commission),
        onDeleteExpectedCommission: () => {
          this.onDeleteExpectedCommission(commission, modalInstance);
        },
      },
    );
  }

  onDeleteCommissionSuccess(commission, modalInstance) {
    if (!commission) {
      return;
    }
    const index = this.expectedCommissions.findIndex(
      (com) => com.commissionId === commission.commissionId,
    );

    if (index < 0) {
      return;
    }
    this.expectedCommissions = removeElementOnIndex(
      index,
      this.expectedCommissions,
    );

    if (modalInstance) {
      modalInstance.close();
    }
  }

  onDeleteExpectedCommission(commission, modalInstance) {
    if (!commission) {
      return;
    }
    this.commissionHelperService.confirmDeleteCommission({
      id: commission.commissionId,
      onSuccessCallback: () =>
        this.onDeleteCommissionSuccess(commission, modalInstance),
    });
  }

  onSendEnquiry(commission) {
    if (!commission) {
      return;
    }
    this.commissionHelperService.openCommissionEnquiry({
      familyId: commission.familyId,
      loanId: commission.loanId,
    });
  }

  onExportUnmatchedCommission() {
    const fileName = `UnmatchedCommissions.xls`;
    this.isExportingUnmatched = true;
    const params = {
      type: 'Unmatched',
      fileName,
      methodName: 'exportUnmatchedCommissions',
      exportParams: this.getUnmatchedRequestParams(),
    };

    this.commissionHelperService
      .exportCommissionTableData(params)
      .then(() => {
        this.isExportingUnmatched = false;
      })
      .catch(() => {
        this.isExportingUnmatched = false;
      });
  }

  onExportExpectedCommission() {
    const fileName = `ExpectedCommissions.xls`;
    const params = {
      type: 'Expected',
      fileName,
      methodName: 'exportExpectedCommissions',
      exportParams: this.getExpectedRequestParams(),
    };
    this.isExportingExpected = true;
    this.commissionHelperService
      .exportCommissionTableData(params)
      .then(() => {
        this.isExportingExpected = false;
      })
      .catch(() => {
        this.isExportingExpected = false;
      });
  }
}
