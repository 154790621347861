const validateLocation = (location) => (test) => location.includes(test);

export const getApiResourceBasedOnLocation = () => {
  /* eslint-disable angular/window-service */
  const isLocation = validateLocation(window.location.href);
  switch (true) {
    case isLocation('nz'):
      return process.env.NZ_API_RESOURCE;
    case isLocation('au'):
      return process.env.AU_API_RESOURCE;
    case isLocation('id'):
      return process.env.ID_API_RESOURCE;
    default:
      return process.env.DEFAULT_API_RESOURCE;
  }
};
