import { forEach } from 'lodash';
import {
  toastError,
  toastSaveSuccess,
  alertError,
} from 'Common/utilities/alert';
import {
  PACKAGE_STATUS,
  DOCUMENT,
  SEND_TYPE,
  ESIGN_BTN_TEXT,
} from 'Common/constants/eSign';
import { EMAIL_CTYPES } from 'Common/constants/emailTypes';
import { PHONE_TYPE } from 'Common/constants/phoneMobileFormat';
import { getPhoneNumberByType, getEmailByType } from 'Common/utilities/contact';

const MIN_WARNING = 3;

export const checkSelectedParticipants = ({ data, vm }) => {
  const groupName = data && data.IsApplicant ? 'applicants' : 'guarantors';
  const groupNameSource = data && data.IsApplicant ? 'borrowers' : 'guarantors';
  if (!vm.gamePlanSharedDataService) {
    vm[groupName].push(vm.mapApplicant(data));
    vm.checkEmailValid(data);
  }
  if (vm.gamePlanSharedDataService) {
    const isSelected = vm.gamePlanSharedDataService[groupNameSource].some(
      (item) => item.PersonId === data.PersonId,
    );
    if (isSelected) {
      vm[groupName].push(vm.mapApplicant(data));
      vm.checkEmailValid(data);
    }
  }
};

export const getInvolvePartyUtil = ({ vm }) => {
  if (!vm.loanId) {
    return;
  }
  vm.assignedAdviserEmail =
    vm.loanAppSharedData.LoanApplicationDetails.AdviserEmail;
  vm.invalidEmails = [];
  vm.invalidMobiles = [];
  vm.duplicates = [];
  vm.duplicatesMobile = [];
  vm.warnings = [];
  vm.duplicateAdviserEmail = [];
  vm.isFetching = true;
  vm.loanScenarioService
    .scenarioApplicantListGet(vm.loanId)
    .then((response) => {
      vm.isFetching = false;
      if (!response || !response.data || !response.data.InvolvedPartyPerson) {
        return;
      }
      vm.applicants = [];
      vm.signeeList = [];
      vm.guarantors = [];

      const { InvolvedPartyPerson: involvedParty } = response.data;
      forEach(involvedParty, (val) => {
        val.forEach((data) => {
          checkSelectedParticipants({ data, vm });
        });
      });
      vm.checkDuplicate();
    });
};

// eslint-disable-next-line sonarjs/cognitive-complexity
export const checkWarningsUtil = ({ vm }) => {
  if (
    vm.duplicates &&
    !vm.duplicates.length &&
    vm.duplicatesMobile &&
    !vm.duplicatesMobile.length &&
    vm.invalidEmails &&
    !vm.invalidEmails.length &&
    vm.invalidMobiles &&
    !vm.invalidMobiles.length &&
    vm.duplicateAdviserEmail &&
    !vm.duplicateAdviserEmail.length
  ) {
    vm.warnings = [];
    vm.disabledSendToEsign();
    return;
  }

  if (vm.duplicateAdviserEmail && vm.duplicateAdviserEmail.length) {
    vm.warnings.push(
      `The email of <b>${vm.duplicateAdviserEmail.join(
        ', ',
      )}</b> is the same as the assigned adviser.  All signatories must have unique email addresses to proceed.`,
    );
  } else {
    const hasDuplicate =
      (vm.duplicates && vm.duplicates.length) ||
      (vm.duplicatesMobile && vm.duplicatesMobile.length)
        ? `unique `
        : '';
    const emailMessage =
      (vm.invalidEmails && vm.invalidEmails.length) ||
      (vm.duplicates && vm.duplicates.length)
        ? `email address`
        : '';
    const phoneMessage =
      (vm.invalidMobiles && vm.invalidMobiles.length) ||
      (vm.duplicatesMobile && vm.duplicatesMobile.length)
        ? `mobile number`
        : '';
    const theMessage =
      emailMessage && phoneMessage
        ? `${emailMessage} and ${phoneMessage}`
        : `${emailMessage}${phoneMessage}`;
    const updateBelow = vm.isEsign2FA ? ` Please update below.` : ``;
    vm.warnings.push(
      `For e-sign to work, each signatory must have their own valid ${hasDuplicate}${theMessage}.${updateBelow}`,
    );
  }
  vm.showMoreButton = vm.warnings && vm.warnings.length > 3;
  vm.warningLimit = vm.showMoreButton && vm.minWarning;
  vm.disabledSendToEsign();
};

export const sendEsignInitUtil = ({ vm }) => {
  vm.isEsign2FA = vm.configService.feature.eSign2FA;
  vm.minWarning = MIN_WARNING;
  vm.applicants = [];
  vm.guarantors = [];
  vm.signeeList = [];
  vm.sendButtonText = `Checking for existing package..`;
  vm.isCPADownloadPdf = false;
  vm.isCQPDownloadPdf = false;
  vm.isCPAReviewed = false;
  vm.isCQPReviewed = false;
  vm.isApplicantReviewed = false;
  vm.isGuarantorReviewed = false;
  vm.isWaitSubmitResponse = false;
  vm.isCheckedTerms = false;
  vm.isEmailRequired = true;
  vm.isMobileRequired = true;
  vm.isSendToEsignDisabled = false;
  vm.contactUpdated = false;
  vm.eSignAccountType =
    (vm.loanAppSharedData && vm.loanAppSharedData.eSignAccountType) || 0;
  if (vm.isEsignEnabled) {
    vm.isEsignEnabled();
  }
  vm.checkRequiredParameter();
};
export const updateWarningLimitUtil = ({ vm }) => {
  vm.warningLimit =
    vm.warningLimit === vm.minWarning ? vm.warnings.length : vm.minWarning;
};
export const mapApplicantUtil = ({ vm, data }) => {
  vm.currentApplicant = {
    ...data,
    name: `${data.FirstName} ${data.LastName}`,
    emailAddress: getEmailByType(data.Email, EMAIL_CTYPES.CRM_EMAIL),
    mobilePhone: getPhoneNumberByType(data.Phone, PHONE_TYPE.MOBILE),
  };
  return vm.currentApplicant;
};
export const checkDuplicateUtil = ({ vm }) => {
  if (!vm.signeeList) {
    return;
  }
  vm.duplicates = vm.signeeList.reduce((accum, signee, index, array) => {
    if (array.filter((val) => val.email === signee.email).length > 1) {
      return accum.concat(signee);
    }
    return accum;
  }, []);
  vm.duplicatesMobile = vm.signeeList.reduce((accum, signee, index, array) => {
    if (array.filter((val) => val.phone === signee.phone).length > 1) {
      return accum.concat(signee);
    }
    return accum;
  }, []);
  vm.checkWarnings();
};

export const checkEmailValidUtil = ({ vm, signee }) => {
  const userEmail = getEmailByType(signee.Email, EMAIL_CTYPES.CRM_EMAIL);
  if (!userEmail) {
    vm.invalidEmails.push(signee);
  }

  const userMobile = getPhoneNumberByType(signee.Phone, 'Mobile');
  if (!userMobile) {
    vm.invalidMobiles.push(signee);
  }

  if (userEmail) {
    const person = {
      name: `${signee.FirstName} ${signee.LastName}`,
      email: userEmail,
      phone: userMobile,
    };
    vm.signeeList.push(person);
    const isSameAdviserEmail = userEmail === vm.assignedAdviserEmail;
    isSameAdviserEmail &&
      vm.duplicateAdviserEmail.push(`${signee.FirstName} ${signee.LastName}`);
  }
};

export const checkRequiredParameterUtil = ({ vm }) => {
  const isValidToViewContent = vm.loanId && (vm.isSendCqp || vm.isSendCpa);
  if (isValidToViewContent) {
    vm.getInvolveParty();
    vm.getRecentPackage();
    return;
  }
  vm.closeModal();
  alertError('Please contact support', false, 'Not Allowed!');
};

export const setBtnTextForEsign = ({ vm, singlePackage }) => {
  const { status, documents } = singlePackage;
  const isDocumentsSigned = !!(
    documents.length && documents.some((obj) => obj.status === DOCUMENT.SIGNED)
  );
  const isBtnTextToSend =
    status === PACKAGE_STATUS.PACKAGE_COMPLETE ||
    status === PACKAGE_STATUS.PACKAGE_TRASHED ||
    isDocumentsSigned;

  vm.sendButtonText = isBtnTextToSend
    ? ESIGN_BTN_TEXT.SEND
    : ESIGN_BTN_TEXT.UPDATE;
};

export const checkBtnTextForEsign = ({ vm, response }) => {
  const documentType = vm.gamePlanSharedDataService.isApplicantActive
    ? SEND_TYPE.APPLICANT
    : SEND_TYPE.GUARANTOR;

  const matchDocType = response.filter((item) => {
    const isMatch = documentType === item.taggedDocumentType;
    if (isMatch) {
      setBtnTextForEsign({ vm, singlePackage: item });
    }
    return isMatch;
  });

  if (!matchDocType.length) {
    vm.sendButtonText = ESIGN_BTN_TEXT.SEND;
  }
};

export const getRecentPackageUtil = ({ vm }) => {
  if (!vm.loanId) {
    return;
  }
  vm.eSignService.getPackages(vm.loanId).then((response) => {
    if (!response || !response.length) {
      vm.sendButtonText = ESIGN_BTN_TEXT.SEND;
      return;
    }
    setBtnTextForEsign({ vm, singlePackage: response[0] });
    if (vm.gamePlanSharedDataService) {
      checkBtnTextForEsign({ vm, response });
    }
  });
};

export const onContactSavedUtil = ({ vm, succeeded }) => {
  if (succeeded) {
    toastSaveSuccess();
    vm.warnings = [];
    vm.contactUpdated = true;
    vm.getInvolveParty();
    return;
  }
  toastError();
};
