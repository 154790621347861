import angular from 'angular';
import possibleAdverseChanges from './possibleAdverseChanges';
import loanSectionStatus from './loanSectionStatus';
import questionTextAndIcon from './questionTextAndIcon';
import questionYesNo from './questionYesNo';
import questionMultiSelect from './questionMultiSelect';
import questionCheckAndIcon from './questionCheckAndIcon';
import questionCheckRow from './questionCheckRow';
import fieldsAnticipatedChanges from './fieldsAnticipatedChanges';
import fieldsIntendedRepayment from './fieldsIntendedRepayment';
import assetInsuranceSection from './assetInsuranceSection';
import tradeReferenceModal from './tradeReferenceModal';
import futureFinancialGoals from './futureFinancialGoals';
import submissionWarningBanner from './submissionWarningBanner';

export default angular
  .module('loanAppSections.components', [])
  .component('possibleAdverseChanges', possibleAdverseChanges)
  .component('loanSectionStatus', loanSectionStatus)
  .component('questionTextAndIcon', questionTextAndIcon)
  .component('questionYesNo', questionYesNo)
  .component('questionMultiSelect', questionMultiSelect)
  .component('questionCheckAndIcon', questionCheckAndIcon)
  .component('questionCheckRow', questionCheckRow)
  .component('fieldsAnticipatedChanges', fieldsAnticipatedChanges)
  .component('fieldsIntendedRepayment', fieldsIntendedRepayment)
  .component('assetInsuranceSection', assetInsuranceSection)
  .component('tradeReferenceModal', tradeReferenceModal)
  .component('futureFinancialGoals', futureFinancialGoals)
  .component('submissionWarningBanner', submissionWarningBanner).name;
