import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import angular from 'angular';

export function getLoanFacilitiesTitle(isLoanAppAssetFinance) {
  return isLoanAppAssetFinance ? 'Lending' : 'Loan Facilities';
}

export function getInterestOnlyTerm(interestOnlyTerm) {
  return typeof interestOnlyTerm === 'undefined' ? 0 : interestOnlyTerm;
}

export function getLoanStructureSet() {
  return {
    ProductId: 0,
    InterestOnly: false,
  };
}

export function getModalTitle(modalType) {
  return modalType === 'new' ? 'Add' : 'Edit';
}

export function getModalButtonTitle(modalType) {
  return modalType === 'new' ? 'Add' : 'Save';
}

export function getLenderName(loanAppSharedData) {
  return (
    loanAppSharedData.loanAppSummary &&
    loanAppSharedData.loanAppSummary.LoanDetails &&
    loanAppSharedData.loanAppSummary.LoanDetails.LenderName
  );
}

export function getAssetFinanceCategory(loanDetailsSet) {
  return (
    loanDetailsSet &&
    (loanDetailsSet.LendingCategoryId === LENDING_CATEGORY.ASSET_FINANCE ||
      loanDetailsSet.LendingCategoryId === LENDING_CATEGORY.PERSONAL_LOAN)
  );
}

export function getLoanOptions() {
  return [
    {
      name: 'Top-Up',
      value: 'TopUp',
      isCheked: false,
    },
    {
      name: 'First Home Owner',
      value: 'FirstHomeOwner',
      isCheked: false,
    },
    {
      name: 'Unregulated',
      value: 'Unregulated',
      isCheked: false,
    },
    {
      name: 'Low doc',
      value: 'Lowdoc',
      isCheked: false,
    },
    {
      name: 'Non-genuine Savings',
      value: 'NongenuineSavings',
      isCheked: false,
    },
    {
      name: 'Limited Genuine Savings',
      value: 'LimitedGenuineSavings',
      isCheked: false,
    },
    {
      name: 'Interest Only',
      value: 'InterestOnly',
      isCheked: false,
    },
  ];
}

export function getSecurityList(security) {
  return typeof security === 'undefined' ? [] : security;
}
export function getnewLoanAmount(newLoanAmount) {
  return newLoanAmount === 0 ? '' : newLoanAmount;
}

export function getTotalLoanAmount(loanStructureSet) {
  return (
    parseFloat(loanStructureSet.LMIPremium || 0) +
    parseFloat(loanStructureSet.BaseLoanAmount || 0)
  );
}

export function getLaonDetailLmi(loanDetailsSet) {
  return loanDetailsSet && loanDetailsSet.IsLMI ? loanDetailsSet.LMI : 0;
}

export function getSaveFundsObj(modalType, fundingSet) {
  let saveFundsObj = {};
  if (modalType === 'edit') {
    saveFundsObj = {
      brokerEventId: fundingSet.brokerEventId,
      fundsId: fundingSet.id,
      fundingType: fundingSet.fundingType,
      fundingParticulars: fundingSet.fundingParticulars,
      fundingValue: fundingSet.fundingValue,
      isOnlyValueEditable: fundingSet.isOnlyValueEditable,
      description: fundingSet.description,
    };
  } else {
    saveFundsObj = angular.copy(fundingSet);
  }
  return saveFundsObj;
}

export function getFundingDownpayment(fundsData) {
  return fundsData && fundsData.length ? fundsData[0] : {};
}

export function getLoanAppLenderDetailObject() {
  return {
    LenderId: null,
    LenderName: '',
    IsOnlineAvailable: null,
  };
}
