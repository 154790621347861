// Loan app section
export const LOAN_APP_APPLICANTS = 'applicants';
export const LOAN_APP_CUSTOMER_OBJECTIVES = 'customer_objectives';
export const LOAN_APP_FINANCIAL_POSITION = 'financial_position';
export const LOAN_APP_POSSIBLE_ADVERSE_CHANGES = 'possible_adverse_changes';
export const LOAN_APP_LOAN_FUNDING = 'loan_funding';
export const LOAN_APP_REFERRAL_PARTNERS = 'referral_partners';
export const LOAN_APP_BROKER_DECLARATIONS = 'broker_declarations';
export const LOAN_APP_SUPPORTING_DOCS = 'supporting_docs';
export const LOAN_APP_LOAN_SUBMISSION = 'loan_submission';
export const LOAN_APP_APPLY_NOW_SUBMISSION = 'next_gen_submission';
export const LOAN_APP_ASSET_INSURANCE = 'asset_insurance';
export const LOAN_APP_INSURANCE = 'insurance';
export const LOAN_APP_PRODUCT_FINDER = 'loan_app_product_finder';
export const LOAN_APP_CPA = 'loan_app_cpa';
export const LOAN_APP_CQP = 'loan_app_cqp';
export const LOAN_APP_FACT_FIND = 'loan_app_fact_find';
export const GAME_PLAN_SEND_ESIGN = 'game_plan_send_esign';
export const GAME_PLAN_DOWNLOAD = 'game_plan_download';
export const LOAN_APP_ESIGN = 'loan_app_esign';
export const LOAN_APP_INSURANCE_REFERRALS = 'loan_app_insurance_referrals';
export const HELLO_PACK_SEND_INSURANCE = 'hello_pack_send_insurance';
export const HELLO_PACK_SEND_LENDING = 'hello_pack_send_lending';
