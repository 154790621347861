import { LENDING_CATEGORY } from 'Common/constants/securityValues';
import { COLOR } from 'Common/constants/colors';

export const LENDER_ID = {
  OTHERS: 64,
  AU_OTHERS: -1,
};

export const LENDER_FILTER_CATEGORY = {
  [LENDING_CATEGORY.PERSONAL_LOAN]: 'Personal Loan',
  [LENDING_CATEGORY.ASSET_FINANCE]: 'Asset Finance',
};

export const PROVIDER_NAME = {
  DEFAULT: 'Provider',
  AU: 'Lender',
};

export const LOAN_LENDING_CATEGORY = {
  [LENDING_CATEGORY.RESIDENTIAL]: {
    COLOR: COLOR.TRENDY_PINK_DARKER,
    LABEL: 'Residential',
  },
  [LENDING_CATEGORY.COMMERCIAL]: {
    COLOR: COLOR.SHAKESPEARE,
    LABEL: 'Commercial',
  },
  [LENDING_CATEGORY.SMALL_BUSINESS]: {
    COLOR: COLOR.TAN_HIDE,
    LABEL: 'Small Business',
  },
  [LENDING_CATEGORY.ASSET_FINANCE]: {
    COLOR: COLOR.SUNGLO,
    LABEL: 'Asset Finance',
  },
  [LENDING_CATEGORY.PERSONAL_LOAN]: {
    COLOR: COLOR.FUEL_YELLOW,
    LABEL: 'Personal Loan',
  },
  [LENDING_CATEGORY.DEPOSIT_BOND]: {
    COLOR: COLOR.FRUIT_SALAD,
    LABEL: 'Deposit Bond',
  },
};
