/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-extra-arguments */
import { routeDefaultPage } from 'Common/utilities/config';
import layout from '../../../src/components/layout/layout.html';

/**
 * Config for the router
 */

function configRouter(
  $stateProvider,
  $urlRouterProvider,
  $controllerProvider,
  $compileProvider,
  $filterProvider,
  $provide,
  $ocLazyLoadProvider,
  jsRequires,
) {
  // eslint-disable-next-line angular/window-service
  if (window.technology === 'react') {
    // eslint-disable-next-line angular/function-type
    $provide.decorator('$browser', [
      '$delegate',
      '$window',
      function ($delegate, $window) {
        $delegate.onUrlChange = function (e) {
          // do nothing
        };
        $delegate.url = function () {
          return $window.location.href;
        };
        return $delegate;
      },
    ]);
  }

  $ocLazyLoadProvider.config({
    debug: false,
    events: true,
    modules: jsRequires.modules,
  });

  // APPLICATION ROUTES
  // -----------------------------------
  // For any unmatched url, redirect to /app/dashboard
  // $urlRouterProvider.otherwise("/app/dashboard");
  $urlRouterProvider.otherwise(($injector, $location) => {
    // during config windowservice doesn't exist
    // eslint-disable-next-line angular/window-service
    if (window.technology === 'angular') {
      const $state = $injector.get('$state');
      const { feature } = $injector.get('configService');
      const currentUserService = $injector.get('currentUserService');
      return routeDefaultPage({ $state, feature, currentUserService });
    } else {
      const $state = $injector.get('$state');
      $state.go($location.path());
    }
  });

  // Set up the states
  $stateProvider
    .state('app', {
      url: '/app',
      views: {
        '': {
          template: layout,
        },
      },
      abstract: true,
    })
    .state('app.dashboard', {
      url: '/dashboard',
      templateUrl: `assets/views/overviewDashboard.html`,
      title: 'Dashboard',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.dashboardV2', {
      url: '/dashboard-v2/:activeTab',
      template: '<dashboard-container></dashboard-container>',
      title: 'Business Planning Dashboard',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.home', {
      url: '/home',
      template: `<mycrm-home></mycrm-home>`,
      title: 'Home',
    })
    .state('app.contactsSingle', {
      url: '/contacts/:familyId/:activeTab/:loanId/:inprogress',
      templateUrl: `assets/views/contacts_single.html`,
      params: {
        activeTab: { squash: true },
        loanId: { squash: true },
        inprogress: { squash: true },
      },
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactsLoans', {
      url: '/contacts/:familyId/:activeTab/:lendingTab',
      templateUrl: `assets/views/contacts_single.html`,
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactsInsurances', {
      url: '/contacts/:familyId/:activeTab',
      templateUrl: `assets/views/contacts_single.html`,
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.LoanProfilerSingle', {
      url: '/contact/loan-profiler/:familyId/:lendingScenarioId',
      templateUrl: `assets/views/contacts/client/lending/lendingScenario/details.v4.html`,
      title: 'Loan Profiler',
      ncyBreadcrumb: {
        label: 'Loan Profiler',
      },
    })

    .state('app.LendingScenarioSingle', {
      url: '/contact/lending-scenario/:familyId/:lendingScenarioId',
      templateUrl: `assets/views/contacts/client/lending/lendingScenario/details.v4.html`,
      title: 'Lending Scenario',
      ncyBreadcrumb: {
        label: 'Lending Scenario',
      },
    })
    .state('app.LoanProfilerLoanRequirements', {
      url:
        '/contact/loan-profiler/:familyId/:lendingScenarioId/loan-requirements/:loanRequirementId',
      templateUrl: `assets/views/contacts/client/lending/lendingScenario/loanReq/loanReq.html`,
      title: 'Loan Profiler',
      ncyBreadcrumb: {
        label: 'Loan Profiler',
      },
    })
    .state('app.LendingScenarioLoanRequirements', {
      url:
        '/contact/lending-scenario/:familyId/:lendingScenarioId/loan-requirements/:loanRequirementId',
      templateUrl: `assets/views/contacts/client/lending/lendingScenario/loanReq/loanReq.html`,
      title: 'Lending Scenario',
      ncyBreadcrumb: {
        label: 'Lending Scenario',
      },
    })

    .state('app.contacts', {
      url: '/contacts?index&page&search',
      templateUrl: `assets/views/contacts.html`,
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactsAdviserOrg', {
      url: '/contact/adviser_org/:adviserOrgId/:adviserComplianceId/:activeTab',
      templateUrl: `assets/views/corporate/adviser_org.html`,
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })

    /**
     * Account Page for Business
     */
    .state('app.BusinessAccount', {
      url: '/contact/business/:familyId/:activeTab/:loanId/:inprogress',
      templateUrl: `assets/views/contacts/business/index.html`,
      params: {
        activeTab: { squash: true },
        loanId: { squash: true },
        inprogress: { squash: true },
      },
      title: 'Contacts - Business Account',
      ncyBreadcrumb: {
        label: 'Contact - Business Account',
      },
    })

    .state('app.contactsAdviser', {
      url: '/contact/adviser/:clientId/:familyId/:activeTab',
      templateUrl: `assets/views/corporate/adviser.html`,
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      inherit: false,
    })
    .state('app.contactsAssistant', {
      url: '/contact/assistant/:clientId/:familyId/:activeTab',
      templateUrl: `assets/views/corporate/assistant.html`,
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      inherit: false,
    })
    .state('app.contactsReferrerOrg', {
      url:
        '/contact/referrer-org/:referrerComplianceId/:referrerOrgId/:activeTab',
      templateUrl: `assets/views/corporate/referrer_org.html`,
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      inherit: false,
    })
    .state('app.contactsReferrer', {
      url: '/contact/referrer/:clientId/:familyId/:activeTab',
      templateUrl: `assets/views/contacts/corportate_referrer.html`,
      title: 'Contacts',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      inherit: false,
    })
    .state('app.genralSearchInMobile', {
      url: '/search/:searchText',
      template:
        '<general-mobile-search search-text="vm.uiService.generalSearchText"></general-mobile-search>',
      title: 'Search',
      ncyBreadcrumb: {
        label: 'Search',
      },
    })
    .state('app.loanTools', {
      url: '/loantools/:activeTab',
      templateUrl: `assets/views/loanTools/loan_tools.html`,
      title: 'Loan Tools',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.loanRepaymentCalculator', {
      url: '/repayment',
      templateUrl: `assets/views/loanCalculator/loan_repayment_calculator.html`,
      title: 'Repayment',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.incomeCalculator', {
      url: '/incomeCalculator',
      template: '<income-calculator></income-calculator>',
      title: 'Income Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.quickQuote', {
      url: '/quickQuote',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      title: 'Quick Quote',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.quickQuoteDetails', {
      url: '/quickQuote/:familyId/:insQuoteId',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      title: 'Quick Quote Details',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactQuickQuote', {
      url: '/contactQuote/:familyId?isFromInsuranceTab',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      title: 'Family Quick Quote',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
      params: {
        isFromInsuranceTab: {
          value: null,
          inherit: false,
          squash: true,
        },
      },
    })
    .state('app.profilerQuickQuote', {
      url: '/profilerQuickQuote/profileId=:profilerId',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      title: 'Quick Quote Details',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.profilerQuickQuoteFamily', {
      url: '/profilerQuickQuote/:profilerId/:familyId/:insQuoteId',
      templateUrl: `assets/views/insuranceTools/newQoute.html`,
      title: 'Quick Quote Details',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.insuranceProfiler', {
      url: '/insuranceProfiler',
      templateUrl: `assets/views/insuranceTools/insuranceProfiler.html`,
      title: 'Insurance Profiler',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.contactProfiler', {
      url: '/contactProfiler/:familyId',
      templateUrl: `assets/views/insuranceTools/insuranceProfiler.html`,
      title: 'Insurance Profiler',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.insuranceProfilerDetails', {
      url: '/insuranceProfiler/:familyId/:insProfilerId',
      templateUrl: `assets/views/insuranceTools/insuranceProfiler.html`,
      title: 'Insurance Profiler Details',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.insApp', {
      url: '/insuranceApplication/:insAppId/:familyId?',
      templateUrl: `assets/views/insuranceTools/insApp.html`,
      title: 'Insurance Application',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.importantDocuments', {
      url: '/important-documents/:activeTab?s',
      templateUrl: `assets/views/loanTools/importantDocs.html`,
      title: 'Important Documents',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.myDocuments', {
      url: '/my-documents',
      templateUrl: `assets/views/resource/myDocuments.html`,
      title: 'My Documents',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.templates', {
      url: '/templates',
      templateUrl: `assets/views/resource/templates.html`,
      title: 'Templates',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.education', {
      url: '/education',
      templateUrl: `assets/views/resource/education.html`,
      title: 'Education',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.educationQualifications', {
      url: '/education-and-qualifications',
      template: `<education-qualifications></education-qualifications>`,
      title: 'Education and Qualifications',
    })
    .state('app.complaints', {
      url: '/complaints',
      templateUrl: `assets/views/resource/complaints.html`,
      title: 'Complaints',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.corporateComplaints', {
      url: '/corporate-complaints',
      template: '<complaints-register></complaints-register>',
      title: 'Complaints',
    })
    .state('app.onlineTraining', {
      url: '/online-training',
      templateUrl: `assets/views/resource/onlineTraining.html`,
      title: 'Online Training',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.additionalRepayment', {
      url: '/additionalRepayment',
      templateUrl: `assets/views/loanCalculator/additional_repayment_calculator.html`,
      title: 'Additional Repayment',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.compareLoans', {
      url: '/compareLoans',
      templateUrl: `assets/views/loanCalculator/compare_loan_calculator.html`,
      title: 'Compare Loan Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.quickLMI', {
      url: '/quickLMI/:brokerEventId',
      templateUrl: `assets/views/loanCalculator/quick_lmi_calculator.html`,
      title: 'Quick LMI Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.hemCalculator', {
      url: '/hemCalculator',
      templateUrl: 'assets/views/hemCalculator/hem_calculator.html',
      title: 'HEM Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.savingGoal', {
      url: '/savingGoal',
      templateUrl: `assets/views/loanCalculator/saving_goal_calculator.html`,
      title: 'Saving Goal Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.offset', {
      url: '/offset',
      templateUrl: `assets/views/loanCalculator/offset_calculator.html`,
      title: 'Offset Calculator',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.leads', {
      url: '/leads',
      params: {
        createdContact: '',
      },

      templateUrl: `assets/views/pipeline/pipeline.html`,
      title: 'Leads',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.application', {
      url: '/application/:familyId',
      params: {
        createdContact: '',
      },
      templateUrl: `assets/views/pipeline/applications/applications.html`,
      title: 'Applications',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.emailCampaign', {
      url: '/email-campaign',
      templateUrl: `assets/views/communicate/communicate.html`,
      title: 'Communicate',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.newEmailCampaign', {
      url: '/new-email-campaign',
      templateUrl: `assets/views/communicate/sendCampaign.html`,
    })
    .state('app.report', {
      url: '/report?dateFilter&adviser&widgetId&widgetUserId&loanStatus',
      params: {
        dateFilter: null,
        adviser: [],
        widgetId: null,
        widgetUserId: null,
      },
      template: `<jasper-report></jasper-report>`,
      title: 'Report',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.commissionReport', {
      url: '/commissionReport',
      templateUrl: `assets/views/report/commisionReport.html`,
      title: 'Commision Report',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.resources', {
      url: '/resources',
      templateUrl: `assets/views/resources/resources.html`,
      title: 'Resources',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.mobileReferralDetails', {
      url: '/mobile-referral-details?referralId&contact',
      template: `<referral-details></referral-details>`,
      params: {
        referralId: null,
        contact: '',
      },
      title: 'Referral',
      ncyBreadcrumb: {
        label: 'Referral',
      },
    })
    .state('app.referrals', {
      url: '/referrals',
      templateUrl: `assets/views/referrals/referrals.html`,
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.sendReferrals', {
      url: '/sendReferrals?referralId&referralTypeId',
      templateUrl: `assets/views/referrals/sendReferrals.html`,
      params: {
        referralId: null,
        referralTypeId: null,
      },
      title: 'Send Referrals',
      ncyBreadcrumb: {
        label: 'Send Referrals',
      },
    })
    .state('app.newReferral', {
      url: '/Referral/:referralType/:agreementId/:agreementTypeId/:requestId',
      templateUrl: `assets/views/referrals/new_referral.html`,
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.referrerDetail', {
      url: '/referrer/:referrerId/:agreementId',
      templateUrl: `assets/views/referrals/referrer_detail.html`,
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.groupDetail', {
      url: '/group/:agreementId',
      templateUrl: `assets/views/referrals/group_detail.html`,
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.tasks', {
      url:
        '/tasks/:familyId?page&search&dateFilter&assignedToday&assignedToMe&taskId',
      template: '<tasks-page></tasks-page>',
      title: 'tasks',
      ncyBreadcrumb: {
        label: 'Dashboard',
      },
    })
    .state('app.sendEmail', {
      url: '/sendEmail/:contactType/:familyId',
      templateUrl: `assets/views/contacts/send_email.html`,
      title: 'SendEmail',
      ncyBreadcrumb: {
        label: 'Send Email',
      },
    })
    .state('app.sendMail', {
      url: '/sendMail/:contactType/:familyId',
      templateUrl: `assets/views/contacts/send_mail.html`,
      title: 'SendMail',
      ncyBreadcrumb: {
        label: 'Send Mail',
      },
    })
    .state('app.loanProfiler', {
      url:
        '/loanProfiler/:brokerEventId/:loanScenarioId/:tab?familyId&loanId&proposedLoanAmount&securityAmount&clientIds&paramType&paramId',
      templateUrl: `assets/views/loanProfiler/loan_profiler.html`,
      title: 'loanProfiler',
      ncyBreadcrumb: {
        label: 'Loan Profiler',
      },
    })
    .state('app.loanProfilerMobile', {
      url: '/loanProfilerLender/:brokerEventId',
      templateUrl: `assets/views/loanProfiler/loan_profiler_mobile.html`,
      title: 'loanProfilerLender',
      ncyBreadcrumb: {
        label: 'Loan Profiler',
      },
    })
    .state('app.fundingCalculator', {
      url: '/fundingCalculator/:brokerEventId?paramId&paramType',
      templateUrl: `assets/views/fundingCalculator/funding_calculator_setup.html`,
      title: 'fundingCalculator',
      ncyBreadcrumb: {
        label: 'Funding Calculator',
      },
    })
    .state('app.googleAuthCodeCtrl', {
      url: '/googleauthcode',
      templateUrl: `assets/views/googleAuthCode/googleAuthCode.html`,
      title: '',
      ncyBreadcrumb: {
        label: '',
      },
    })
    .state('app.googleauthcodeGmail', {
      url: '/googleauthcode/gmail',
      templateUrl: `assets/views/googleAuthCode/gmail.html`,
      title: '',
      ncyBreadcrumb: {
        label: '',
      },
    })
    .state('app.googleauthcodeGdrive', {
      url: '/googleauthcode/drive',
      templateUrl: `assets/views/googleAuthCode/drive.html`,
      title: '',
      ncyBreadcrumb: {
        label: '',
      },
    })

    /**
     * TODO: apply necessary uri after API is being applied
     * @author: Francis
     */
    .state('app.loanApplicationDetailsV3', {
      url: '/loan-application/:familyId/:loanAppId?isGamePlanCQP',
      templateUrl: `assets/views/loanApplication/index.html`,
      title: 'Loan Application Details - V3',
      ncyBreadcrumb: {
        label: 'Loan Application Details',
      },
    })

    .state('app.myAccount', {
      url: '/user/my-account',
      templateUrl: `assets/views/user/account.html`,
      title: 'My Account',
      ncyBreadcrumb: {
        label: 'My Account',
      },
    })
    .state('app.userPermissions', {
      url: '/user/permissions',
      templateUrl: `assets/views/user/permissions.html`,
      title: 'User Permissions',
      ncyBreadcrumb: {
        label: 'User Permissions',
      },
    })
    .state('app.userLenders', {
      url: '/user/lenders',
      templateUrl: `assets/views/user/lenders.html`,
      title: 'User Lenders',
      ncyBreadcrumb: {
        label: 'User Lenders',
      },
    })

    /**
     * Customize Theme
     */

    .state('app.customTheme', {
      url: '/theme',
      templateUrl: `assets/views/customTheme.html`,
      title: 'Customize Theme',
      ncyBreadcrumb: {
        label: 'Customize Theme',
      },
    })

    .state('app.commission', {
      url: '/commission',
      templateUrl: 'assets/views/commission/commission.html',
    })

    .state('app.commission.transaction', {
      url: '/transaction/:keyword',
      templateUrl: 'assets/views/commission/commissionTrans.html',
    })

    .state('app.expectedCommission', {
      url: '/expected-commission',
      templateUrl: 'assets/views/commission/expectedCommission.html',
    })

    .state('app.unmatchedCommission', {
      url: '/unmatched-commission',
      templateUrl: 'assets/views/commission/unmatchedCommission.html',
    })
    .state('app.commissionManagement', {
      url: '/commission-management/:activeTab',
      template:
        '<commission-management is-mobile-device="isMobileDevice"></commission-management>',
      title: 'Commission Management',
    })
    .state('app.commissionDashboard', {
      url: '/commission-dashboard',
      template:
        '<commission-dashboard is-mobile-device="isMobileDevice"></commission-dashboard>',
      title: 'Commission Dashboard',
    })

    /**
     * @description: separate uri for creating contacts
     * @author: francis
     */
    .state('app.createContact', {
      url: '/contact/create',
      params: {
        fromTool: '',
        urlLocation: '',
      },
      title: 'New Contact',
      ncyBreadcrumb: {
        label: 'New Contact',
      },
      templateUrl: 'assets/views/contacts/create-contact.html',
    })

    .state('app.createContactCorporate', {
      url: '/contact/create/corporate',
      params: {
        fromTool: '',
      },
      templateUrl: 'assets/views/contacts/contacts_new.html',
    })

    .state('app.contactOnboarding', {
      url: '/contact/onboarding/:familyId/:clientId',
      templateUrl: 'assets/views/onboarding/onboarding.html',
    })

    .state('app.accreditation', {
      url: '/contact/accreditation/:familyId/:clientId',
      templateUrl: 'assets/views/corporate/adviser/accreditation.html',
    })

    .state('app.corporateSendEmail', {
      url: '/corporate/sendEmail/:familyId/:clientId',
      templateUrl: `assets/views/corporate/send_email.html`,
      title: 'SendEmail',
      ncyBreadcrumb: {
        label: 'Send Email',
      },
    })
    .state('app.corporateSendSms', {
      url: '/corporate/sendSms/:contactType/:familyId/:isFromContact',
      templateUrl: `assets/views/corporate/send_sms.html`,
      title: 'SendEmail',
      ncyBreadcrumb: {
        label: 'Send SMS',
      },
    })
    .state('app.targetManagement', {
      url: '/corporate/targetmanagement/',
      templateUrl: `assets/views/corporate/targetManagement/target_management.html`,
    })
    .state('app.brokerSupportRequest', {
      url: '/helpsupport/brokersupportrequest/',
      templateUrl: `assets/views/helpSupport/brokerSupportRequest.html`,
    })
    .state('app.submitEnhancements', {
      url: '/submit-enhancements',
      template: `<submit-enhancements base-state="submit-enhancements"></submit-enhancements>`,
      title: 'Submit Enhancements',
      abstract: true,
    })
    .state('app.submitEnhancements.post', {
      url: '/:p/:cannyTitle',
    })
    .state('app.submitEnhancements.list', {
      url: '/',
    })
    .state('app.cannyEnhancements', {
      url: '/canny-enhancements',
      template: `<submit-enhancements base-state="canny-enhancements"></submit-enhancements>`,
      title: 'Canny  / Enhancements',
      abstract: true,
    })
    .state('app.cannyEnhancements.post', {
      url: '/:p/:cannyTitle',
    })
    .state('app.cannyEnhancements.list', {
      url: '/',
    })
    .state('app.newRequest', {
      url: '/helpsupport/new/request',
      templateUrl: `assets/views/helpSupport/new/request.html`,
    })
    .state('app.brokerSupportDetails', {
      url: '/helpsupport/view/:ticketId/request/:create',
      templateUrl: `assets/views/helpSupport/view/brokerSupportdetails.html`,
    })
    .state('app.satisfactionSurvey', {
      url: '/communicate/satisfactionsurvey',
      templateUrl: `assets/views/communicate/satisfactionSurvey.html`,
    })
    .state('app.referral', {
      url: '/referral',
      templateUrl: `assets/views/referrals/referral.html`,
      title: 'Referrals',
      ncyBreadcrumb: {
        label: 'Referrals',
      },
    })
    .state('app.customerCare', {
      url: '/customerCare/:activeTab/:activeSubTab',
      templateUrl: `assets/views/customerCare/customer_care.html`,
      title: 'Customer Care',
      params: {
        activeSubTab: { squash: true, value: '' },
      },
      ncyBreadcrumb: {
        label: 'Customer Care',
      },
    })
    .state('app.customerCareInsurance', {
      url: '/communicate/customer-care-insurance/:activeTab',
      template: '<insurance-customer-care></insurance-customer-care>',
      title: 'Customer Care Insurance',
      ncyBreadcrumb: {
        label: 'Communicate',
      },
    })
    .state('app.business', {
      url: '/business',
      templateUrl: `assets/views/profileManagement/Business/business.html`,
      title: 'Business Settings',
      ncyBreadcrumb: {
        label: 'Business Settings',
      },
    })
    .state('app.users', {
      url: '/users/:activeTab',
      templateUrl: `assets/views/profileManagement/Users/users.html`,
      title: 'Your Settings',
      ncyBreadcrumb: {
        label: 'Your Settings',
      },
      params: {
        isActivateLeadGeneratorSwitch: false,
      },
    })
    .state('app.creditCheck', {
      url: '/creditCheck',
      templateUrl: 'assets/views/creditChecks/credit_checks.html',
      title: 'Credit Check',
      ncyBreadcrumb: {
        label: 'Credit Check',
      },
    })
    .state('connectLogs', {
      url: '/connectLogs',
      templateUrl: 'assets/views/connectLogs.html',
      title: 'Connect Logs',
    })

    .state('app.pipeline', {
      url: '/pipeline/:pipelineType?page&search',
      template: `<pipeline is-small-device="isSmallDevice"></pipeline>`,
      title: 'Pipeline',
      params: {
        createdContact: '',
      },
    })

    .state('app.opportunity', {
      url:
        '/opportunity/:activeTab?opportunityId&loanId&sourceFamilyId&sourceFamilyName&isContactOpener&isBusiness&loanAppId&familyId',
      template: `<opportunity></opportunity>`,
      title: 'Opportunity',
    })

    .state('app.featureManagement', {
      url: '/corporate/feature-management',
      template: `<feature-management></feature-management>`,
      title: 'Feature Management',
    })
    .state('app.mobileWorkbench', {
      url:
        '/mobile-workbench?LoanID&LoanStatusID&PipelineCardsID&LoanScenarioID&familyId&enquirySource&clientNames',
      params: {
        LoanID: null,
        LoanStatusID: null,
        PipelineCardsID: null,
        LoanScenarioID: null,
        familyId: null,
        enquirySource: null,
        state: null,
        clientNames: '',
      },
      template: `<application-workbench></application-workbench>`,
      title: 'Mobile Workbench',
    })
    .state('app.meetTheCorporateTeam', {
      url: '/meet-the-corporate-team',
      template: `<corporate-team></corporate-team>`,
      title: 'Meet the Corporate Team',
    })
    .state('app.cannyEnhancementsNz', {
      url: '/canny-enhancements-nz',
      template: `<canny-enhancements-nz></canny-enhancements-nz>`,
      title: 'Canny / Enhancements',
    })
    .state('app.providerInformation', {
      url: '/resources/provider-information',
      template: '<provider-information-list></provider-information-list>',
      title: 'Provider Information',
    })
    .state('app.insuranceProviderInformation', {
      url: '/resources/providers/insurance/:providerId',
      template: '<provider-info type="insurance"></provider-info>',
      title: 'Insurance Provider Information',
    })
    .state('app.loanProviderInformation', {
      url: '/resources/providers/lender/:providerId',
      template: '<provider-info type="lender"></provider-info>',
      title: 'Lender Information',
    })
    .state('app.releaseNotes', {
      url: '/release-notes',
      template: `<release-notes ng-if="vm.configService.feature.releaseNotes"></release-notes>`,
      title: `What's New in MyCRM`,
    })
    .state('app.insuranceApplicationsPipeline', {
      url: '/insurance-application/pipeline',
      template:
        '<insurance-applications-pipeline></insurance-applications-pipeline>',
      title: 'Pipeline',
    })
    .state('app.insuranceQuoteOpportunities', {
      url: '/insurance-quote/pipeline',
      template: '<insurance-opportunities></insurance-opportunities>',
      title: 'Pipeline',
    })
    .state('app.helloBookPreview', {
      url: '/hello-book/preview/:familyId',
      template: '<hello-book-preview-generator></hello-book-preview-generator>',
      title: 'Hello Book',
      params: {
        familyId: null,
      },
    })
    .state('app.renderDynamicLink', {
      url:
        '/render-dynamic-link/:toState?familyId&isEntity&isClient&loanScenarioId&loanId&contactType&activeTab&isLinker',
      template: '<render-dynamic-link></render-dynamic-link>',
      params: {
        toState: null,
      },
    });
}

configRouter.$inject = [
  '$stateProvider',
  '$urlRouterProvider',
  '$controllerProvider',
  '$compileProvider',
  '$filterProvider',
  '$provide',
  '$ocLazyLoadProvider',
  'JS_REQUIRES',
  '$locationProvider',
];

export default configRouter;
