import { get } from 'lodash';
import { toastWarning } from 'Common/utilities/alert';
import {
  updateOnSaveRate,
  updateOnDeleteRate,
  onBaseRateChange,
} from 'Common/utilities/discountRate';
import { RATE_PERIOD } from 'Common/constants/loanAppDiscount';

export default class DiscountRateInput {
  constructor(loanSplitModelService) {
    'ngInject';

    this.loanSplitModelService = loanSplitModelService;
  }

  $onInit() {
    this.discountRate = {};
    this.RATE_PERIOD = RATE_PERIOD;
    this.setProductName();
  }

  getCurrenBaseRate() {
    const { isActive } = this.referenceRate;
    const rate = get(this.referenceRate, 'rate', 0);
    const productRate = get(this.rateData, 'productRate', 0);
    const readOnlyBaseRate = isActive ? rate : productRate;
    this.rateData = {
      ...this.rateData,
      readOnlyBaseRate,
    };
    this.isVariable && (this.initialRate.readOnlyBaseRate = readOnlyBaseRate);
  }

  overrideHandler() {
    this.rateData.isOverrideBaseRate = true;
    this.isVariable && (this.initialRate.isOverrideBaseRate = true);
    this.getCurrenBaseRate();
  }

  resetOverrideHandler() {
    this.rateData = {
      ...this.rateData,
      baseRate: this.rateData.readOnlyBaseRate,
      isOverrideBaseRate: false,
    };
    !this.isVariable && this.onBaseRateChange();
    this.isVariable && this.onResetOverrideForInitial();
  }

  onResetOverrideForInitial() {
    this.initialRate = {
      ...this.initialRate,
      baseRate: this.initialRate.readOnlyBaseRate,
      isOverrideBaseRate: false,
    };
    this.onBaseRateChange();
  }

  onBaseRateChange() {
    const baseRate = +get(this.rateData, 'baseRate', 0);
    if (!baseRate) {
      return;
    }
    const { isOverrideBaseRate } = this.rateData;
    this.rateData = { ...onBaseRateChange(this.rateData, baseRate) };
    !this.isVariable && this.sendDataToApi();
    this.isVariable &&
      this.onBaseRateUpdateForInitial(baseRate, isOverrideBaseRate);
  }

  onBaseRateUpdateForInitial(baseRate, isOverrideBaseRate = false) {
    this.initialRate = {
      ...this.initialRate,
      isOverrideBaseRate,
    };
    this.initialRate = { ...onBaseRateChange(this.initialRate, baseRate) };
    this.sendDataToApi();
  }

  setProductName() {
    const { productName: referenceProductName } = this.referenceRate;
    const { recommendedProductName } = this.rateData;
    this.rateData = {
      ...this.rateData,
      productName: referenceProductName || recommendedProductName,
    };
  }

  closeDiscountBox() {
    this.rateData = {
      ...this.rateData,
      showDiscountBox: false,
      editDiscountBox: false,
    };
  }

  openDiscountBox(isEdit = false) {
    this.rateData = {
      ...this.rateData,
      showDiscountBox: !this.rateData.showDiscountBox,
      editDiscountBox: isEdit,
    };
    const referenceProductId = get(
      this.referenceRate,
      'referenceProductId',
      null,
    );
    const { discountRate, actualRate } = this.rateData;
    this.discountRate = {
      rateValue: discountRate ? actualRate : 0,
      showReferenceRate: !!referenceProductId,
      referenceProductId,
    };
  }

  deleteRate() {
    const { rateData, referenceRate } = updateOnDeleteRate(
      this.rateData,
      this.referenceRate,
    );
    this.rateData = { ...rateData };
    this.referenceRate = { ...referenceRate };
    this.getCurrenBaseRate();
    !this.isVariable && this.sendDataToApi();
    this.isVariable && this.onDeleteUpdateForInitial();
  }

  onDeleteUpdateForInitial() {
    const { rateData, referenceRate } = updateOnDeleteRate(
      this.initialRate,
      this.initialReferenceRate,
    );
    this.initialReferenceRate = { ...referenceRate };
    this.initialRate = { ...rateData };
    this.sendDataToApi();
  }

  showReferenceDropDown() {
    this.discountRate.showReferenceRate = true;
  }

  deleteReferenceRate() {
    this.discountRate = {
      ...this.discountRate,
      showReferenceRate: false,
      referenceProductId: null,
      rateValue: 0,
    };
  }

  checkValidDiscount() {
    const discountedRate = get(this.discountRate, 'rateValue', 0);
    const { referenceProductId } = this.discountRate;
    const { baseRate } = this.rateData;
    const rateToCompare = referenceProductId
      ? this.referenceRates.find(
          (rate) => rate.referenceProductId === referenceProductId,
        ).rate
      : baseRate;
    const invalidDiscount =
      rateToCompare <= discountedRate && discountedRate > 0;
    if (invalidDiscount) {
      toastWarning(
        `Discount must not be greater than ${rateToCompare} and should be greater than 0`,
        'Invalid discounted rate',
      );
      referenceProductId && this.updateReferenceRate();
      !referenceProductId && this.deleteReferenceRate();
    }
  }

  updateReferenceRate() {
    const { productRate } = this.rateData;
    this.discountRate = {
      ...this.discountRate,
      rateValue: productRate,
    };
  }

  saveDiscount() {
    const { rateData, referenceRate } = updateOnSaveRate(
      this.rateData,
      this.referenceRate,
      this.referenceRates,
      this.discountRate,
    );
    this.referenceRate = { ...referenceRate };
    this.rateData = { ...rateData };
    this.getCurrenBaseRate();
    !this.isVariable && this.sendDataToApi();
    this.isVariable && this.onSaveUpdateForInitial();
  }

  onSaveUpdateForInitial() {
    const { rateData, referenceRate } = updateOnSaveRate(
      this.initialRate,
      this.initialReferenceRate,
      this.referenceRates,
      this.discountRate,
    );
    this.initialReferenceRate = { ...referenceRate };
    this.initialRate = { ...rateData };
    this.sendDataToApi();
  }

  sendDataToApi() {
    const props = {
      loanId: this.loanId,
      productLoanStructureId: this.productLoanStructureId,
      favoriteProductId: this.productId,
      brokerEventId: this.brokerEventId,
    };
    this.setProductRate({
      ...this.rateData,
      ...props,
      referenceProduct: this.referenceRate,
    });
    this.isVariable &&
      this.setProductRate(
        {
          ...this.initialRate,
          ...props,
          referenceProduct: this.initialReferenceRate,
        },
        this.isVariable,
      );
  }

  setProductRate(props, isVariable) {
    this.loanSplitModelService
      .setProductRate(props)
      .then(({ selectedProductId }) => {
        if (isVariable) {
          this.initialReferenceRate = {
            ...this.initialReferenceRate,
            selectedProductId,
          };
        } else {
          this.isOnLoanDetailsModal && this.updateRepayment();
          this.referenceRate = {
            ...this.referenceRate,
            selectedProductId,
          };
        }
      });
  }
}
