export const setAllRoleLogics = ((realLogic) => {
  return {
    addAliases: ({ vm, feature, user }) => {
      user
        .aliasRolesAs('isNZAdviserLM')
        .withRoleValuesOf(
          user.getTypeThat('isNzAdviser') && user.getTypeThat('isLM'),
        );

      user
        .aliasRolesAs('isNZAssistantLM')
        .withRoleValuesOf(
          user.getTypeThat('orgMemberHasLoanWriter') &&
            user.getTypeThat('orgMemberHasRiskWriter'),
        );

      realLogic({ vm, feature, user });
    },
  };
})(({ vm, feature, user }) => {
  const setDefault = () => {
    vm.showLendingTools = true;
    vm.showInsuranceTools = true;
  };

  if (!feature.nzLendingTools) {
    setDefault();
  } else if (user.getTypeThat('isNZAdviserLM')) {
    vm.showLendingTools = user.getTypeThat('isLoanWriter');
    vm.showInsuranceTools = user.getTypeThat('isRiskWriter');
  } else if (user.getTypeThat('isNZAssistantLM')) {
    vm.showLendingTools = user.getTypeThat('orgMemberHasLoanWriter');
    vm.showInsuranceTools = user.getTypeThat('orgMemberHasRiskWriter');
  } else {
    setDefault();
  }
});
